import React from 'react';
import { Spin, Button, Tooltip } from 'antd';
import FilterPopover from '../../components/FilterPopover';
import { SyncOutlined } from '@ant-design/icons';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import ManualBookingModal from '../EventKanban/ManualBookingModal';
import { useEventsContext } from '../../context/EventsContext';
import CSVUploadModal from '../CSVUpload/CSVUploadModal';

function EventFilter({ showFunnelButtonText = false }: { showFunnelButtonText?: boolean }) {
  const {
    handleFunnelSelectionChange,
    handleTagsSelectionChange,
    handleOnSearchQueryChange,
    handleOnTypeChange,
    handleStatusChanged,
    handleDownload,
    availableStatus,
    refetchBookingInfo,
    eventLoaders: { downloadLoading, eventsRefreshLoading }
  } = useEventsContext();
  const funnels = useAllFunnels();

  const filterPopoverContent = (
    <FilterPopover
      handleTypeChange={handleOnTypeChange}
      handleFunnelSelectionChange={handleFunnelSelectionChange}
      handleTagsSelectionChange={handleTagsSelectionChange}
      handleOnSearchQueryChange={handleOnSearchQueryChange}
      funnels={funnels}
      availableStatus={availableStatus}
      handleChangeStatus={handleStatusChanged}
      showFunnelButtonText={showFunnelButtonText}
    />
  );

  if (showFunnelButtonText) {
    return <div className={'d-flex justify-content-center'}>{filterPopoverContent}</div>;
  }

  return (
    <div className="d-flex align-items-center">
      <CSVUploadModal />
      <ManualBookingModal availableStatus={availableStatus} />
      <div className="csv-download__wrapper">
        <Tooltip title="Export">
          <Button
            className="ant-btn__without-background ant-btn__disabled-default-style"
            style={{ margin: '0 4px 0 0' }}
            disabled={downloadLoading}
            loading={downloadLoading}
            onClick={handleDownload}
            icon={<AiOutlineCloudDownload size={28} className="agency-dashboard-brand-color" />}
          ></Button>
        </Tooltip>
      </div>

      <div className="d-flex">
        <Tooltip title="Aktualisieren">
          <Spin
            className="d-flex"
            indicator={
              <SyncOutlined
                className="agency-dashboard-brand-color"
                spin={eventsRefreshLoading}
                onClick={(e: any) => {
                  e.preventDefault();
                  e.stopPropagation();
                  refetchBookingInfo();
                }}
              />
            }
          />
        </Tooltip>
      </div>
      {filterPopoverContent}
    </div>
  );
}

export default EventFilter;
