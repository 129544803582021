import { Button, Form, Input, Modal, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import ColorPicker from '../../../Funnel/components/subcomponents/ColorPicker';
import { useEventsContext } from '../../context/EventsContext';
import { BookingTagsMessages } from '../../../config/messages';
import {
  BookingInterface,
  BookingTag,
  SelectedEvent,
  TrackingInterface
} from '../../container/Events';
import { defaultStringFieldValidation } from '../../../UI/utils/formValidation';
import { rgbaToHex } from '../../../Builder/helper/colorConversion';

interface TagsModalProps {
  tagsUpdateModalVisible: boolean;
  setTagsUpdateModalVisible: (e: boolean) => void;
  selectedTag: BookingTag;
  selectedEvent: { index: any; event: BookingInterface | TrackingInterface } | undefined;
  setSelectedEvent: Function;
}

const BookingTagsUpdateModal = ({
  tagsUpdateModalVisible,
  setTagsUpdateModalVisible,
  selectedTag,
  selectedEvent,
  setSelectedEvent
}: TagsModalProps) => {
  const [isDeleteConfirmationModalVisible, setIsDeleteConfirmationModalVisible] = useState<boolean>(
    false
  );

  const {
    handleUpdateBookingTags,
    handleDeleteBookingTag,
    setIsBookingTagsChange,
    allBookingTags,
    setAllBookingTags,
    handleSetApplicationStateForTags
  } = useEventsContext();

  const [tag, setTag] = useState(selectedTag);
  const [isTagNameExist, setIsTagNameExist] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedTag) {
      form.setFieldsValue({ name: selectedTag.name });
      setTag(selectedTag);
    }
  }, [selectedTag]);

  const updateEventState = (updatedTag: BookingTag, oldTag: BookingTag) => {
    const selectedTag = selectedEvent?.event.tags.find((tag: BookingTag) => {
      return tag.name === oldTag.name;
    });
    if (selectedTag) {
      selectedTag.name = updatedTag.name;
      selectedTag.color = updatedTag.color;
    }
    const filterAllBookingTags = allBookingTags.find((tag: BookingTag) => {
      return tag.name === oldTag.name;
    });
    if (filterAllBookingTags) {
      filterAllBookingTags.name = updatedTag.name;
      filterAllBookingTags.color = updatedTag.color;
    }
    setAllBookingTags([...allBookingTags]);
    if (selectedEvent?.event.tags) {
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          tags: [...selectedEvent?.event.tags]
        }
      });
    }
    
  }

  const handleInputChange = (e: any) => {
    setIsBookingTagsChange(true);
    setTag({ ...tag, name: e.target.value });
  };

  const handleColorChange = (color: string) => {
    const hexColor = rgbaToHex(color);
    setIsBookingTagsChange(true);
    setTag({ ...tag, color: hexColor });
  };

  const handleCancel = async () => {
    if (!isTagNameExist && tag.name.length >= 3) {
      updateEventState(tag, selectedTag);
      handleUpdateBookingTags(tag, selectedTag);
    } else {
      setTag(selectedTag);
    }
    setIsTagNameExist(false);
    setTagsUpdateModalVisible(false);
    form.resetFields();
  };

  const confirmDelete = async () => {
    const filteredSelectedBookingTags = selectedEvent?.event.tags.filter((tag: BookingTag) => {
      return tag.name !== selectedTag.name;
    });
    const filteredAllBookingTags = allBookingTags.filter((tag: BookingTag) => {
      return tag.name !== selectedTag.name;
    });
    setAllBookingTags([...filteredAllBookingTags]);

    const event = {
      ...selectedEvent,
      event: {
        ...selectedEvent?.event,
        tags: [...(filteredSelectedBookingTags as BookingTag[])]
      }
    };
    handleSetApplicationStateForTags(event as SelectedEvent);
    setSelectedEvent(event);

    await handleDeleteBookingTag(selectedTag);
    setTagsUpdateModalVisible(false);
    setIsDeleteConfirmationModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Tag aktualisieren"
        visible={tagsUpdateModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={300}
        centered
      >
        <Form form={form} layout="vertical" initialValues={{ name: tag?.name }}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              ...defaultStringFieldValidation,
              ...[
                {
                  validator: (_: any, value: any) => {
                    if (allBookingTags.some((tag: BookingTag) => tag.name === value)) {
                      setIsTagNameExist(true);
                      return Promise.reject(BookingTagsMessages.tagNameAlreadyExist);
                    } else {
                      setIsTagNameExist(false);
                      return Promise.resolve();
                    }
                  }
                }
              ]
            ]}
          >
            <Input onChange={handleInputChange} value={tag?.name} />
          </Form.Item>
          <Button
            type="primary"
            className="my-2"
            onClick={() => setIsDeleteConfirmationModalVisible(true)}
          >
            <DeleteOutlined />
            Löschen
          </Button>

          <div>Farben</div>
          <div className="d-flex align-items-center mt-2">
            <ColorPicker
              className="anti-styles-color-picker"
              palletteClassName="color-picker-fixed-center"
              color={tag?.color}
              onChange={handleColorChange}
            />
            <Tag className="mx-2" color={tag?.color}>
              {tag?.name}
            </Tag>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Bestätigen Sie den Löschvorgang"
        visible={isDeleteConfirmationModalVisible}
        onCancel={() => setIsDeleteConfirmationModalVisible(false)}
        footer={
          <>
            <Button type="primary" onClick={confirmDelete}>
              Bestätigen
            </Button>
            <Button onClick={() => setIsDeleteConfirmationModalVisible(false)}>Stornieren</Button>
          </>
        }
        centered
        width={400}
      >
        <div>
          <p>{BookingTagsMessages.confirmDelete}</p>
        </div>
      </Modal>
    </>
  );
};

export default BookingTagsUpdateModal;
