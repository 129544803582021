import React, { useState } from 'react';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';
import { useEmptyGeneralSettings } from '../../redux/builderSlice';
import { messages } from '../elements/RichTextComponent/helpers/aiTextSettingHelpers';
import { Button } from 'antd';
import GeneralSettingsModal from '../../AdJobBuilder/components/sharedUI/GeneralSettingsModal';
import { GeneralSettingsTabView } from '../../interfaces/builderSliceTypes';

interface BlockSettingsOverlayProps {
  conditions?: { condition: boolean; messageKey: string }[];
  conditionButton?: { [key: string]: any };
}

const BlockSettingsOverlay = ({ conditions, conditionButton }: BlockSettingsOverlayProps) => {
  const { status } = useSubscriptionInfo();
  const emptyGeneralSettings = useEmptyGeneralSettings();
  const [isGeneralSettingsModalOpen, setIsGeneralSettingsModalOpen] = useState(false);

  const isInTrial = status === 'TRIALING';
  const isSomeValueMissing = !!emptyGeneralSettings.length;

  const disableConditions = [
    ...(conditions || []),
    { condition: isInTrial, messageKey: 'isInTrial' },
    { condition: isSomeValueMissing, messageKey: 'isSomeValueMissing' }
  ];

  const activeCondition = disableConditions.find(({ condition }) => condition);

  const toggleGeneralSettingsModal = () => {
    setIsGeneralSettingsModalOpen(!isGeneralSettingsModalOpen);
  };

  const toRenderBasedOnMessageKey: any = {
    ...(conditionButton || {}),
    isSomeValueMissing: (
      <Button className="mt-2" type="primary" onClick={toggleGeneralSettingsModal}>
        Zu den KI-Einstellungen
      </Button>
    )
  };
  return (
    <>
      {activeCondition && (
        <div className="settings-missing-overlay">
          <h3 className="fill-text text-center">{messages[activeCondition.messageKey]}</h3>
          {toRenderBasedOnMessageKey[activeCondition.messageKey]}
        </div>
      )}

      <GeneralSettingsModal
        openJobValidationModal={isGeneralSettingsModalOpen}
        setOpenJobValidationModal={setIsGeneralSettingsModalOpen}
        settingsToOpen={emptyGeneralSettings?.[0] || GeneralSettingsTabView.COMPANY_INFORMATION}
      />
    </>
  );
};

export default BlockSettingsOverlay;
