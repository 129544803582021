import React, { useEffect } from 'react';
import { useAd } from '../redux/adBuilderSlice';
import { BuilderPreviewBase } from '../../components/ui/BuilderPreviewBase';
import { appendStylesAccordingToColorTheme } from '../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { useConnectedFonts } from '../../redux/builderSlice';

export default function AdBuilderPreview() {
  const theme = useCurrentlyEditingBuilderTheme();
  const connectedFonts = useConnectedFonts()
  const userAd = useAd();

  useEffect(() => {
    appendStylesAccordingToColorTheme(theme, false , connectedFonts);
  }, [theme,connectedFonts]);

  return (
    <BuilderPreviewBase
      selectedPage={userAd}
      pagesExisting={Boolean(userAd.craftState)}
      className="ad-builder-preview"
    />
  );
}
