import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Tooltip } from 'antd';
import Draft, { convertFromRaw, DraftHandleValue, EditorState } from 'draft-js';
import { useEditor, useNode } from '@craftjs/core';

import { ActionsController } from '../../../sharedUI/ActionsController';
import useOutsideAlerter from '../../../../../hooks/useOutsideAlerter';
import EmojiPickerModal from '../../../../../components/elements/RichTextComponent/components/EmojiPickerModal';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import { insertCharacterToEditor } from '../../../../../helper/sharedFunctions';
import { textTypesEnum } from '../../../../interfaces/TextTypeInterface';
import { getTextTemplateByText, useTextTemplateData } from '../../../../data';
import { useUserContentTemplate } from '../../../../redux/adBuilderSlice';
import { tooltipText } from '../../../../../helper/tooltipText';
import AiTextSettings from '../../../../../components/elements/RichTextComponent/components/AiTextSettings';
//@ts-ignore
import createStyles from 'draft-js-custom-styles';
import { useDispatch } from 'react-redux';

const { Editor } = Draft;
export interface PlainTextComponentPropTypes {
  editorState?: any;
  rawState?: any | null;
  onlySettingsReq?: boolean;
  elementType: textTypesEnum;
  isEdited: boolean;
  templateId: number;
}
const PlainTextComponent = ({
  templateId,
  editorState,
  rawState,
  onlySettingsReq,
  isEdited = false,
  elementType
}: PlainTextComponentPropTypes) => {
  const [isEditable, setIsEditable] = useState(true);
  const userTemplate = useUserContentTemplate(elementType);
  const { styles, exporter } = createStyles(['font-size', 'color'], 'CUSTOM_');
  const {
    query,
    actions: { history }
  } = useEditor();
  const {
    actions: { setProp },
    nodeId,
    isSelectedNode
  } = useNode(node => ({
    dragged: node.events.dragged,
    isSelectedNode: node.events.selected,
    nodeId: node.id
  }));

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    history.ignore().setProp(nodeId, (props: any) => {
      props.exporter = exporter;
    });

    if (rawState) {
      history.ignore().setProp(
        nodeId,
        (props: any) =>
          // @ts-ignore
          (props.editorState = EditorState.createWithContent(convertFromRaw(rawState)))
      );
      history.ignore().setProp(nodeId, (props: any) => (props.rawState = null));
      const templateData = {
        ...content,
        data: [...content.data, { content: userTemplate, category: 'Customer' }]
      };
      const template = getTextTemplateByText(rawState?.blocks[0]?.text, templateData);
      if (template) {
        history.ignore().setProp(nodeId, (props: any) => (props.templateId = template.id));
      }
    }
  }, []);

  const editorRef = useRef(null);
  const editorStateRef = useRef(editorState);

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  const onChange = (newEditorState: EditorState) => {
    if (
      editorState.getCurrentContent().getPlainText('\u0001') !==
      newEditorState.getCurrentContent().getPlainText('\u0001')
    ) {
      history.throttle().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
      });
    } else {
      history.ignore().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
      });
    }
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (['command underline', 'command bold', 'command italic'].includes(command)) {
      return 'handled';
    } else {
      return 'not-handled';
    }
  };

  const [disableDrag, setDisableDrag] = useState(false);
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });
  const content = useTextTemplateData(elementType);

  return (
    <ActionsController
      className="rich-text__wrapper"
      canDrag={true}
      onlySettingsReq={onlySettingsReq}
      label={content?.title}
      textAiReq={true}
      actionIcon={
        <>
          <Tooltip title={tooltipText.emoji}>
            <li>
              <EmojiPickerModal
                onChange={value => {
                  setProp(
                    (props: any) =>
                      (props.editorState = insertCharacterToEditor(value, props.editorState))
                  );
                }}
              >
                😉
              </EmojiPickerModal>
            </li>
          </Tooltip>
        </>
      }
    >
      <Editor
        editorState={editorState}
        onChange={onChange}
        placeholder="Gib hier deinen Text ein…"
        ref={editorRef}
        spellCheck={true}
        readOnly={!isEditable}
        stripPastedStyles
        handleKeyCommand={handleKeyCommand}
      />
    </ActionsController>
  );
};

export const PlainTextSettings = () => {

  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));

  const { editorState } = props;
  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card
        className="settings-card settings-card__no-padding"
        title="KI Vorschläge"
        bordered={false}
      >
        <AiTextSettings
          editorState={editorState}
          showOptionPicker={false}
          elementType={props.elementType}
        />
      </Card>
    </Col>
  );
};

PlainTextComponent.craft = {
  name: CRAFT_ELEMENTS.PLAIN_TEXT,
  props: {
    editorState: EditorState.createEmpty(),
    isEdited: false
  },
  related: {
    settings: PlainTextSettings
  },
  rules: {
    canMoveIn: (selectedNode: any) => false,
    canMoveOut: () => false
  }
};

export default PlainTextComponent;
