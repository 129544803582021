import React from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, Row } from 'antd';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { BasicElementWrapper } from '../sharedUI/BasicElementWrapper';
import BuilderColorPickerButton from '../../../components/sharedUI/BuilderColorPickerButton';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import { CraftElementBaseProps } from './ImageComponent';
import { useFunnelTheme } from '../../../redux/builderSlice';
import { FUNNEL_THEME_KEYS } from '../../../interfaces/builderSliceTypes';
import { getElementColor } from '../../../helper/craftJs';

interface Props extends CraftElementBaseProps {
  backgroundColor?: string;
  isCustomColor?: boolean;
  children?: any;
  onlySettingsReq?: boolean;
}

const ImageColorizedBanner = (props: Props) => {
  const { children } = props;
  const theme = useFunnelTheme();

  return (
    <BasicElementWrapper
      style={{
        margin: 0,
        backgroundImage: `linear-gradient(to bottom, rgba(255,0,0,0), ${getElementColor(
          props.backgroundColor,
          theme
        )})`
      }}
    >
      <div className="colorized-banner-element">
        <div className="colorized-banner-element__inner">{children}</div>
      </div>
    </BasicElementWrapper>
  );
};

const ImageColorizedBannerDefaultProps = {
  backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR
};

export const ImageColorizedBannerSettings = () => {
  const theme = useFunnelTheme();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  return (
    <Col className="builder__settings-sidebar__container h-100">
      <Card className="settings-card h-100" title="Farbbanner unten" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={12}>
            <BuilderSettingsTitle title="Hintergrund" />
            <BuilderColorPickerButton
              color={getElementColor(props.backgroundColor, theme)}
              onChange={(color, colorKey) =>
                setProp((props: any) => {
                  props.isCustomColor = true;
                  props.backgroundColor = colorKey || color;
                })
              }
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

ImageColorizedBanner.craft = {
  name: CRAFT_ELEMENTS.IMAGE_COLORIZED_BANNER,
  props: ImageColorizedBannerDefaultProps,
  related: {
    settings: ImageColorizedBannerSettings
  }
};

export default ImageColorizedBanner;
