import { Button, Modal, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { BiUpload, BiQuestionMark } from 'react-icons/bi';
import CSVSteps from './CSVSteps';
import UploadFile from '../Event/UploadFile';
import { acceptedFormatsForCSVBulkApplicants } from '../../helper/uploadFile';
import { parse } from 'papaparse';
import MapFieldsView from './MapFieldsView';
import setManualBookings from '../../graphql/setManualBookings';
import { useEventsContext } from '../../context/EventsContext';
import {
  CSVMappingFields,
  finalizeDataForCSVSubmission,
  handleValueMapping,
  mustRequiredFields
} from './helpersCSV';
import { CSVUploadMessages } from '../../../config/messages';
import CSVSubmitTab from './CSVSubmitTab';
import { StatusInterface } from '../../container/Events';

export enum CSVUploadStepsEnum {
  UPLOAD = 0,
  MAP = 1,
  SUBMIT = 2
}

type CSVUploadModalProps = {
  trigger?: React.ReactNode;
};

const CSVUploadModal = ({ trigger }: CSVUploadModalProps) => {
  const {availableStatus , handleAddCSVBookingEvent} = useEventsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<CSVUploadStepsEnum>(CSVUploadStepsEnum.UPLOAD);
  const [csvData, setCsvData] = useState<any[]>();
  const [csvFields, setCsvFields] = useState<string[]>();
  const [isOkButtonDisabled, setIsOkButtonDisabled] = useState(true);
  const [dataMapped, setDataMapped] = useState<any[]>([]);
  const [selectedFunnelId, setSelectedFunnelId] = useState(0);
  const [mappingSchema, setMappingSchema] = useState<any>({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [statusesMapped, setStatusesMapped] = useState<any[]>([]);
  const [defaultSelectedStatus, setDefaultSelectedStatus] = useState<StatusInterface | undefined>(
    availableStatus.find(e => e.value === 'NEW')
  );

  const isStatusSelectedInSchema = Object.values(mappingSchema).includes(
    CSVMappingFields.bookingStatusId
  );

  const isFirstStep = currentStep === CSVUploadStepsEnum.UPLOAD;
  const isLastStep = currentStep === CSVUploadStepsEnum.SUBMIT;
  const handleModalOpen = (): void => {
    setIsModalOpen(!isModalOpen);
  };
  const onCancelModal = (): void => {
    if (isFirstStep) {
      handleResetState();
      setIsModalOpen(false);
    } else {
      handlePrev();
    }
  };

  const handleUploadSubmit = (files: any) => {
    handleResetState();
    parse(files?.[0], {
      download: true,
      header: true,

      complete: response => {
        const { data, meta } = response;
        const { fields } = meta;
        setCsvData(data);
        setCsvFields(fields);
      },
      error: () => {
        message.error(CSVUploadMessages.uploadFailed);
      }
    });
  };

  const handleNext = (): void => {
    const nextStep = currentStep + 1;

    switch (currentStep) {
      case CSVUploadStepsEnum.UPLOAD:
        break;
      case CSVUploadStepsEnum.MAP:
        const newDataMapped = handleValueMapping(csvData as any[], mappingSchema, statusesMapped);
        setDataMapped(newDataMapped);
        break;
      case CSVUploadStepsEnum.SUBMIT:
        handleSubmit();
        break;
    }

    setCurrentStep(nextStep);
  };
  const handlePrev = (): void => {
    const nextStep = currentStep - 1;

    if (nextStep === -1) {
      onCancelModal();
      return;
    }

    setCurrentStep(nextStep);
  };

  useEffect(() => {
    const isValid = handleStatusValidation();
    if (isValid) {
      setIsOkButtonDisabled(false);
    } else {
      setIsOkButtonDisabled(true);
    }
  }, [statusesMapped, dataMapped, mappingSchema]);

  const handleStatusValidation = () => {
    if (
      Object.values(mappingSchema)?.includes(CSVMappingFields.noValue) ||
      mustRequiredFields.some(field => !Object.values(mappingSchema)?.includes(field))
    ) {
      return false;
    }
    if (isStatusSelectedInSchema) {
      const selectedStatuses = Object.entries(statusesMapped)
        .map(([key, value]) => {
          if (key) {
            return value;
          }
        })
        .filter(Boolean);
      return (
        !!selectedStatuses.length && selectedStatuses.every((item: any) => typeof item === 'number')
      );
    }
    return true;
  };

  const handleResetState = () => {
    setCurrentStep(CSVUploadStepsEnum.UPLOAD);
    setCsvData([]);
    setCsvFields([]);
    setDataMapped([]);
    setMappingSchema({});
    setStatusesMapped([]);
    setSelectedFunnelId(0);
  };

  const handleSubmit = async () => {
    setSubmitLoading(true);

    const finalData = finalizeDataForCSVSubmission({
      dataMapped,
      defaultSelectedStatus,
      isStatusSelectedInSchema,
      selectedFunnelId
    });

    try {
      const response = await setManualBookings(finalData);
      handleAddCSVBookingEvent(response.data.createBulkBookings)
    } catch (error) {
      message.error(CSVUploadMessages.submitFailed);
    } finally {
      handleResetState();
    }
    setSubmitLoading(false);
    setIsModalOpen(false);
  };

  const defaultTrigger = (
    <Button
      className={`ant-btn__without-background default-trigger`}
      style={{ marginRight: -5 }}
      type={`ghost`}
      icon={<BiUpload size={22} />}
    />
  );

  useEffect(() => {
    switch (currentStep) {
      case CSVUploadStepsEnum.UPLOAD:
        if (
          !csvData ||
          !csvFields ||
          !((csvData as unknown) as any[])?.length ||
          !((csvFields as unknown) as any[])?.length
        )
          setIsOkButtonDisabled(true);
        else setIsOkButtonDisabled(false);

        break;
      case CSVUploadStepsEnum.MAP:
        setIsOkButtonDisabled(false);
        break;

      case CSVUploadStepsEnum.SUBMIT:
        const shouldAllowSubmit =
          selectedFunnelId && (isStatusSelectedInSchema || defaultSelectedStatus);
        if (shouldAllowSubmit) {
          setIsOkButtonDisabled(false);
        } else {
          setIsOkButtonDisabled(true);
        }
        break;
      default:
        setIsOkButtonDisabled(true);
    }
  }, [currentStep, selectedFunnelId, defaultSelectedStatus, csvData, csvFields]);

  return (
    <>
      <Modal
        className="csv-upload-modal"
        width={750}
        closeIcon
        closable
        title={
          <div className="modal-title">
            <p>Upload CSV</p>
            <Button type="link" target="_blank" href="">
              <BiQuestionMark size={22} />
            </Button>
          </div>
        }
        visible={isModalOpen}
        footer={
          <>
            <Button onClick={onCancelModal}>{isFirstStep ? 'Stornieren' : 'Zurück'}</Button>
            <Button
              loading={submitLoading}
              onClick={handleNext}
              type="primary"
              disabled={isOkButtonDisabled}
            >
              {isLastStep ? 'Einreichen' : 'Nächste'}
            </Button>
          </>
        }
        destroyOnClose
      >
        <CSVSteps currentStep={currentStep} />
        {currentStep === CSVUploadStepsEnum.UPLOAD && (
          <UploadFile
            multiple={false}
            accept={acceptedFormatsForCSVBulkApplicants}
            onSubmit={handleUploadSubmit}
            disabled={!!csvFields?.length}
            fileInputChangeEvent={handleResetState}
          />
        )}
        {currentStep === CSVUploadStepsEnum.MAP && (
          <MapFieldsView
            csvData={csvData || []}
            csvFields={csvFields}
            setMappingSchema={setMappingSchema}
            mappingSchema={mappingSchema}
            statusesMapped={statusesMapped}
            setStatusesMapped={setStatusesMapped}
          />
        )}
        {currentStep === CSVUploadStepsEnum.SUBMIT && (
          <CSVSubmitTab
            selectedFunnelId={selectedFunnelId}
            setSelectedFunnelId={setSelectedFunnelId}
            defaultSelectedStatus={defaultSelectedStatus}
            setDefaultSelectedStatus={setDefaultSelectedStatus}
            showStatusSelector={!isStatusSelectedInSchema}
          />
        )}
      </Modal>
      <span className="cursor-pointer" onClick={handleModalOpen}>
        {trigger || defaultTrigger}
      </span>
    </>
  );
};

export default CSVUploadModal;
