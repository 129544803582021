import React from 'react';
import { SettingsComponents, SettingsElement } from './settingsGbTypes';
import { FaTextHeight } from 'react-icons/fa';
import { BiBorderRadius } from 'react-icons/bi';
import { BsGearFill } from 'react-icons/bs';
import { ButtonAvailableBorderRadius } from '../../interfaces/ButtonSizeInterface';
import {
  ImageAvailableBorderRadius,
  ImageAvailableOverlayTransparency,
  ImageAvailableSizes,
  defaultImageOpacity,
  defaultImageSize
} from '../../interfaces/ImageSizeInterface';
import { GiResize } from 'react-icons/gi';
import ButtonActionPicker from '../elements/BuilderButtonComponent/ButtonActionPicker';
import { CustomAlignmentOptionsForButton } from '../elements/BuilderButtonComponent/CustomAlignmentOptionsForButton';
import { CustomAlignmentOptionsForImage } from './CustomAlignmentOptionsForImage';
import { ImageUploader } from './GenericImageUploader';
import { VideoComponentSettings } from './VideoSettings';

const getKeyValueObjectByKeyValueArrayOfObjects = (sizes: any[]): { [key: number]: string } =>
  sizes.reduce((acc, { value, key }) => {
    acc[key] = value;
    return acc;
  }, {});

export const ButtonSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: false,
    disabled: false,
    key: 'actionLogic',
    customComponent: ButtonActionPicker,
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      'data-testid': 'craftjs-button-component-action-selector-select'
    }
  },
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: true,
    disabled: false,
    title: 'Ausrichtung',
    key: 'buttonAlign',
    customComponent: CustomAlignmentOptionsForButton,
    shouldNotDisableWhenPresetIsOn: true
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'isPresetOn',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Voreinstellungen übernehmen',
      description: 'Voreinstellungen, die du unter “Design” festgelegt hast',
      icon: <BsGearFill />
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Schriftgröße',
    key: 'size',
    icon: <FaTextHeight />,
    otherProps: {
      marks: {
        0: 'S',
        50: 'M',
        100: 'L'
      },
      step: 50,
      tooltipVisible: false,
      defaultValue: 50
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ButtonAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3
    }
  },
  {
    component: SettingsComponents.FONT_STYLE_BUTTONS,
    block: false,
    disabled: false,
    title: 'Style',
    key: 'boldText',
    otherProps: {
      buttonsToRender: ['BOLD']
    }
  },
  {
    component: SettingsComponents.COLOR_PICKER,
    block: true,
    disabled: false,
    title: 'Text',
    key: 'color',
    otherProps: {
      dataTestId: 'text-color-picker'
    }
  },
  {
    component: SettingsComponents.COLOR_PICKER,
    block: true,
    disabled: false,
    title: 'Button',
    key: 'backgroundColor',
    otherProps: {
      showColorTypeBtns: true,
      dataTestId: 'craftjs-button-component-button-color-picker'
    }
  }
];

export const VideoSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: false,
    disabled: false,
    title: '',
    key: 'videoSrc',
    customComponent: VideoComponentSettings,
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      videoSrc: 'https://vimeo.com/732025226',
    }
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'thumbnailEnabled',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Titelbild definieren',
    }
  },
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: false,
    disabled: false,
    title: '',
    key: 'thumbnailSrc',
    customComponent: ImageUploader,
    shouldNotDisableWhenPresetIsOn: false,
    enabilityKey: 'thumbnailEnabled',
    otherProps: {
      minWidth: 100,
      minHeight: 100,
      maxWidth: 1365,
      minZoom: 0,
      uploadedImage: true,
      grid: true,
      restrictPosition: false
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Transparenz',
    key: 'overlay',
    icon: <GiResize />,
    shouldNotDisableWhenPresetIsOn: false,
    enabilityKey: 'thumbnailEnabled',
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ImageAvailableOverlayTransparency),
      tooltipVisible: false,
      defaultValue: defaultImageOpacity.key,
      min: 0,
      max: 10,
      'data-testid': 'video-thumbnail-opacity-slider'
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    shouldNotDisableWhenPresetIsOn: false,
    enabilityKey: 'thumbnailEnabled',
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ImageAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3,
      className: 'builder-slider-style'
    }
  },
  {
    component: SettingsComponents.COLOR_PICKER,
    block: true,
    disabled: false,
    shouldNotDisableWhenPresetIsOn: false,
    enabilityKey: "thumbnailEnabled",
    title: 'Hintergrundfarbe',
    key: 'backgroundColor',
    otherProps: {
      showColorTypeBtns: true
    }
  }

]

export const ImageSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: false,
    disabled: false,
    title: '',
    key: 'imageSrc',
    customComponent: ImageUploader,
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      minWidth: 100,
      minHeight: 100,
      maxWidth: 1365,
      minZoom: 0,
      uploadedImage: true,
      grid: true,
      restrictPosition: false
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Größe',
    key: 'maxWidth',
    icon: <GiResize />,
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ImageAvailableSizes),
      tooltipVisible: false,
      defaultValue: defaultImageSize.key,
      min: 1,
      max: 4,
      'data-testid': 'image-size-slider'
    }
  },
  {
    component: SettingsComponents.CUSTOM_COMPONENT,
    block: true,
    disabled: false,
    title: 'Ausrichtung',
    key: 'align',
    customComponent: CustomAlignmentOptionsForImage,
    shouldNotDisableWhenPresetIsOn: true
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'isPresetOn',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Voreinstellungen übernehmen',
      description: 'Voreinstellungen, die du unter “Design” festgelegt hast',
      icon: <BsGearFill />
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ImageAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 4,
      className: 'builder-slider-style'
    }
  },
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'imageBorderEnabled',
    otherProps: {
      title: 'Umrandung',
      icon: <BsGearFill />
    }
  },
  {
    component: SettingsComponents.COLOR_PICKER,
    block: false,
    disabled: false,
    title: 'Farbe',
    key: 'imageBorderColor',
    enabilityKey: "imageBorderEnabled",
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: "Rahmenbreite",
    key: "borderWidth",
    enabilityKey: "imageBorderEnabled",
    otherProps: {
      min: 1,
      max: 15,
    }
  },
];

export const ImageCarouselSettingsItems: SettingsElement[] = [
  {
    component: SettingsComponents.SWITCH,
    block: false,
    disabled: false,
    key: 'isPresetOn',
    shouldNotDisableWhenPresetIsOn: true,
    otherProps: {
      title: 'Voreinstellungen übernehmen',
      description: 'Voreinstellungen, die du unter “Design” festgelegt hast',
      icon: <BsGearFill />
    }
  },
  {
    component: SettingsComponents.SLIDER,
    block: false,
    disabled: false,
    title: 'Randradius',
    key: 'borderRadius',
    icon: <BiBorderRadius />,
    otherProps: {
      marks: getKeyValueObjectByKeyValueArrayOfObjects(ImageAvailableBorderRadius),
      tooltipVisible: false,
      min: 1,
      max: 3
    }
  }
];
