import gql from 'graphql-tag';
import { BookingType } from '../context/EventsContext.types';

export type SetManualBookingInput = {
  funnelId: number;
  bookingStatusId: number;
  name?: string;
  email: string;
  phone?: string;
  type?: BookingType;
};

const SET_MANUAL_BOOKINGS = gql`
  mutation SetManualBookings($input: [CreateBulkBookings!]!) {
    createBulkBookings(input: $input) {
      id
      name
      firstName
      lastName
      email
      phone
      generalPurpose
      eventStart
      eventEnd
      createdAt
      funnelId
      utmParameter
      version
      type
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      tags {
        id
        name
        color
      }
      bookingStatus {
        color
        status
        value
        id
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
      bookingRating {
        id
        coachId
        competancy
        communicationSkills
        teamCompatibility
        partialUser {
          firstName
          lastName
          imageURL
        }
      }
    }
  }
`;

export default function setManualBookings(input: SetManualBookingInput[]) {
  return window.apolloClient.mutate({
    mutation: SET_MANUAL_BOOKINGS,
    variables: { input }
  });
}
