import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { EVENT_VIEWS } from '../../Events/utils/eventViewsTypes';
import moment from 'moment';
import { StateInterface } from '../../Events/container/Events';
import { modules } from '../../LearningCenter/utils/videos';
interface UIState {
  confettiMessage: string;
  globalSettingsFlags: { key: string, value: string }[] | null;
  funnelViewSelection: EVENT_VIEWS;
  challengeModuleName: string | null;
  challengeProgressPercent: number;
  challengeModulesCompleted: number;
}

const INITIAL_STATE: UIState = {
  confettiMessage: "",
  globalSettingsFlags: null,
  funnelViewSelection: EVENT_VIEWS.KANBAN,
  challengeModuleName: null,
  challengeProgressPercent: 0,
  challengeModulesCompleted: 0
};

export const uiSlice = createSlice({
  name: 'ui',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialUIState: () => {
      return INITIAL_STATE;
    },
    setConfettiMessage: (state, action: PayloadAction<any>) => {
      state.confettiMessage = action.payload;
    },
    setGlobalSettingsFlags: (state, action: PayloadAction<any>) => {
      state.globalSettingsFlags = action.payload;
    },
    setFunnelViewSelection: (state, action: PayloadAction<any>) => {
      state.funnelViewSelection = action.payload;
    },
    setChallengeModuleName: (state, action: PayloadAction<any>) => {
      state.challengeModuleName = action.payload;
    },
    setChallengeProgressPercent: (state, action: PayloadAction<any>) => {
      state.challengeProgressPercent = action.payload;
    },
    setChallengeModulesCompleted: (state, action: PayloadAction<any>) => {
      state.challengeModulesCompleted = action.payload;
    }
  }
});

export const { 
  resetToInitialUIState, 
  setConfettiMessage, 
  setGlobalSettingsFlags, 
  setFunnelViewSelection, 
  setChallengeModuleName,
  setChallengeProgressPercent,
  setChallengeModulesCompleted
} = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectConfettiMessage = (state: RootState) => state.ui.confettiMessage;
export const selectGlobalSettingsFlags = (state: RootState) => state.ui.globalSettingsFlags;
export const selectedFunnelViewSelection = (state: RootState) => state.ui.funnelViewSelection;
export const selectChallengeModuleName = (state: RootState) => state.ui.challengeModuleName;
export const challengeProgressPercent = (state: RootState) => state.ui.challengeProgressPercent;
export const challengeModulesCompleted = (state: RootState) => state.ui.challengeModulesCompleted;

export default uiSlice.reducer;
