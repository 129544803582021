import { Button, Col, Form, message, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { JobValidationModalTexts } from '../../../../config/messages';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  COMMUNICATION_ELEMENTS,
  COMPANY_INFORMATION_ELEMENTS
} from '../../../components/generalSettings/AiDataTab/components/AiDataHelper';
import { TARGET_GROUP_ELEMENTS } from '../../../components/generalSettings/TargetGroup/components/TargetGroupHelper';
import { GeneralSettingsTabView } from '../../../interfaces/builderSliceTypes';
import {
  setGeneralSettingsTabView,
  setJobInformation,
  useCommunication,
  useCompanyDetail,
  useJobInformation,
  useTargetAudience
} from '../../../redux/builderSlice';
import { setCompanyJobTargetAudienceThunk } from '../../../redux/thunk';
import { defaultJobInformation, hasFalsyValue } from '../../../redux/thunk.utils';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../../helper/AdJobHelper';
import {
  hashFormItemsCommunication,
  hashFormItemsCompany,
  hashFormItemsJobAttributes,
  hashFormItemsTargetGroup,
  hashTitlesCommunication,
  hashTitlesCompanyInformation,
  hashTitlesTargetGroup
} from './FormItemsCustom';
import { setShouldReplaceJobPlaceHolders } from '../../redux/jobBuilderSlice';
import { isAdBuilderPath } from '../../../AdBuilder/utils/adBuilderUtils';

interface JobValidationModalProps {
  openJobValidationModal: boolean;
  setOpenJobValidationModal: (value: boolean) => void;
  settingsToOpen: GeneralSettingsTabView;
}

export default function GeneralSettingsModal({
  openJobValidationModal,
  settingsToOpen,
  setOpenJobValidationModal
}: JobValidationModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const company = useCompanyDetail();
  const { name, benefits, uniqueSellingPoint, description } = company;
  const job = useJobInformation();
  const { title, address, category, employmentType, salary, salaryPlan, workplaceModel } = job;
  const targetAudience = useTargetAudience();
  const { age, education, experience, experienceDuration, goals, skills } = targetAudience;
  const communication = useCommunication();
  const { speech, writingStyle } = communication;

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    setIsSubmitLoading(true);
    form.validateFields();
    form.submit();
    const { textLength, ...values } = form.getFieldsValue();
    const falsyValues = hasFalsyValue(values);
    if (falsyValues) {
      message.info(JobValidationModalTexts.fillAllFields);
      setIsSubmitLoading(false);
      return;
    }

    await dispatch(setCompanyJobTargetAudienceThunk(values));
    setOpenJobValidationModal(false);
    setIsSubmitLoading(false);

    if (!isAdBuilderPath()) {
      dispatch(setShouldReplaceJobPlaceHolders(true));
    }
  };

  const handleChange = (value: any, identifier: any) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
    if (!identifier || value === undefined) return;
    if (identifier in defaultJobInformation) {
      dispatch(setJobInformation({ value, identifier }));
    }
  };

  return (
    <Modal
      width={1000}
      title={JobValidationModalTexts.title}
      visible={openJobValidationModal}
      closeIcon={<></>}
      footer={
        <div>
          <Button
            type="ghost"
            className="mt-2"
            onClick={() => {
              dispatch(setGeneralSettingsTabView(settingsToOpen));
              navigate('/builder/grundeinstellungen');
            }}
          >
            {JobValidationModalTexts.goToGeneralSettings}
          </Button>
          <Button
            type="primary"
            className="mt-2"
            loading={isSubmitLoading}
            onClick={() => {
              handleSubmit();
            }}
          >
            {JobValidationModalTexts.submit}
          </Button>
        </div>
      }
      className="job-validation-modal"
    >
      <Form
        name="general_settings_form"
        layout="vertical"
        form={form}
        scrollToFirstError={true}
        initialValues={{ ...company, ...job, ...targetAudience, ...communication }}
      >
        <div className="mt-0 px-4">
          <h3 className="fw-bold mb-3">Unternehmensinformationen</h3>
          <Row gutter={16}>
            <Col span={12}>
              {hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.NAME]()}
              {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.NAME](name, handleChange)}
            </Col>
            <Col span={12}>
              {hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.USP](true)}
              {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.USP](
                uniqueSellingPoint,
                handleChange
              )}
            </Col>
            <Col span={12}>
              {hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.PRODUCT](true)}
              {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.PRODUCT](
                description,
                handleChange
              )}
            </Col>
            <Col span={12}>
              {hashTitlesCompanyInformation[COMPANY_INFORMATION_ELEMENTS.BENEFITS](true)}
              {hashFormItemsCompany[COMPANY_INFORMATION_ELEMENTS.BENEFITS](benefits, handleChange)}
            </Col>
          </Row>
        </div>
        <div className="px-4">
          <h3 className="fw-bold mb-3">Job-Informationen</h3>
          <Row gutter={16}>
            <Col span={12}>
              {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE](title, handleChange)}
            </Col>

            <Col span={12}>
              <div>
                <h6 className="settings-title__required">Kategorie</h6>
              </div>
              {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.CATEGORY](
                category,
                handleChange
              )}
            </Col>

            <Col span={12}>
              <h6 className="settings-title__required">Beschäftigungsart</h6>
              {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.EMPLOYMENT_TYPE](
                employmentType,
                handleChange
              )}
            </Col>
            <Col span={12}>
              <div>
                <h6 className="settings-title__required m-0 mt-2">Ort</h6>
              </div>
              <div className="my-1">
                {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.JOB_ADDRESS](
                  address,
                  handleChange
                )}
              </div>
            </Col>
            <Col span={12}>
              <div>
                <h6 className="settings-title__required">Arbeitsplatzmodell</h6>
              </div>
              {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.WORKPLACE_MODEL](
                workplaceModel,
                handleChange
              )}
            </Col>
            <Col span={12}>
              {hashFormItemsJobAttributes[JOB_ATTRIBUTES_IDENTIFIER.SALARY](
                salary,
                handleChange,
                salaryPlan,
                form
              )}
            </Col>
          </Row>
        </div>

        <div className="px-4">
          <h3 className="fw-bold mb-3">Zielgruppe</h3>
          <Row gutter={16}>
            <Col span={12}>
              {hashTitlesTargetGroup[TARGET_GROUP_ELEMENTS.AGE](true)}
              {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.AGE](age, handleChange)}

              <div style={{height: 83}}>
                {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EXPERIENCE](
                  experience,
                  handleChange
                )}
              </div>

              {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EXPERIENCE_DURATION](
                experienceDuration,
                handleChange
              )}
            </Col>
            <Col span={12}>
              {hashTitlesTargetGroup[TARGET_GROUP_ELEMENTS.EDUCATION](true)}
              {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.EDUCATION](education, handleChange)}

              {hashTitlesTargetGroup[TARGET_GROUP_ELEMENTS.GOALS](true)}
              {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.GOALS](goals, handleChange)}

              {hashTitlesTargetGroup[TARGET_GROUP_ELEMENTS.SKILLS](true)}
              {hashFormItemsTargetGroup[TARGET_GROUP_ELEMENTS.SKILLS](skills, handleChange)}
            </Col>
          </Row>
        </div>
        <div className="px-4">
          <h3 className="fw-bold mb-3">Kommunikation</h3>
          <Row gutter={16}>
            <Col span={12}>
              {hashTitlesCommunication[COMMUNICATION_ELEMENTS.SPEECH](true)}
              {hashFormItemsCommunication[COMMUNICATION_ELEMENTS.SPEECH](speech, handleChange)}
            </Col>
            <Col span={12}>
              {hashTitlesCommunication[COMMUNICATION_ELEMENTS.WRITING_STYLE](true)}
              {hashFormItemsCommunication[COMMUNICATION_ELEMENTS.WRITING_STYLE](
                writingStyle,
                handleChange
              )}
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
