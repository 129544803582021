import { StatusInterface } from '../../container/Events';
import { BookingType } from '../../context/EventsContext.types';

export enum CSVMappingFields {
  noValue = '- Select',
  name = 'Name',
  firstName = 'Vorname',
  lastName = 'Nachname',
  email = 'E-Mail',
  phone = 'Telefon',
  bookingStatusId = 'Status',
  generalPurpose = 'Zusatzfeld',
  // kept for later
  // upload = 'Upload',
  applicationDate = 'Bewerbungsdatum',
  note = 'Notiz'
}

export const mustRequiredFields = [CSVMappingFields.name, CSVMappingFields.email];

export const doNotDuplicateFields = [
  CSVMappingFields.name,
  CSVMappingFields.firstName,
  CSVMappingFields.lastName,
  CSVMappingFields.email,
  CSVMappingFields.phone,
  CSVMappingFields.applicationDate,
  CSVMappingFields.bookingStatusId
];

export function getEnumKeyByValue<T extends object>(
  enumObj: T,
  value: T[keyof T]
): keyof T | undefined {
  return (Object.keys(enumObj) as Array<keyof T>).find(key => enumObj[key] === value);
}

export function getEnumValueByKey<T extends object>(
  enumObj: T,
  key: keyof T
): T[keyof T] | undefined {
  return enumObj[key];
}

export const sanitizeEmail = (email: string): { firstEmail: string; remaining: string } => {
  const [firstEmail, ...rest] = email.split(/[,; ]+/);
  return {
    firstEmail: firstEmail.trim(),
    remaining: rest.join(' ').trim()
  };
};

export const sanitizePhoneNumber = (
  phoneNumber: string
): { firstPhoneNumber: string; remaining: string } => {
  const [firstPhoneNumber, ...rest] = phoneNumber.split(/[,;]+/);
  return {
    firstPhoneNumber: firstPhoneNumber.trim(),
    remaining: rest.join(' ').trim()
  };
};

export const handleValueMapping = (csvData: any[], mappingSchema: any, statusMap?: any) => {
  const newData = csvData.map((row: any) => {
    const newRow: any = {};

    Object.entries(mappingSchema).forEach(([key, val]) => {
      const value = row[key];
      if (val === CSVMappingFields.noValue) return;

      if (newRow?.generalPurpose && val === CSVMappingFields.generalPurpose) {
        newRow.generalPurpose = `${newRow.generalPurpose || ''}\n${value}`;
        return;
      } else if (newRow?.note && val === CSVMappingFields.note) {
        newRow.note = `${newRow.note || ''}\n${value}`;
        return;
      }

      if (val === CSVMappingFields.email) {
        const { firstEmail, remaining } = sanitizeEmail(value);
        newRow.email = firstEmail;
        if (remaining) {
          newRow.note = `${newRow.note || ''}\n${remaining}`;
        }
        return;
      }

      if (val === CSVMappingFields.phone) {
        const { firstPhoneNumber, remaining } = sanitizePhoneNumber(value);
        newRow.phone = firstPhoneNumber;
        if (remaining) {
          newRow.note = `${newRow.note || ''}\n${remaining}`;
        }
        return;
      }

      if (val === CSVMappingFields.bookingStatusId) {
        newRow.bookingStatusId = statusMap[value];
        return;
      }

      // @ts-ignore
      newRow[getEnumKeyByValue(CSVMappingFields, val)] = value;
    });
    return newRow;
  });
  return newData;
};

export const validateCSVMappingField = (
  selectedField: string,
  field: CSVMappingFields,
  data: any
) => {
  if (selectedField === CSVMappingFields.noValue) {
    return Promise.resolve();
  }

  const nonEmptyString = (value: any) => typeof value === 'string' && value.trim() !== '';

  switch (selectedField) {
    case CSVMappingFields.name:
    case CSVMappingFields.firstName:
    case CSVMappingFields.lastName:
    case CSVMappingFields.generalPurpose:
    case CSVMappingFields.note:
      if (!nonEmptyString(data)) {
        return Promise.reject(`The data for ${field} must be a non-empty string!`);
      }
      break;

    case CSVMappingFields.email:
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emails = (data || '').split(';');
      const isValidEmail = emails.every((email: string) => emailPattern.test(email.trim()));
      if (!isValidEmail) {
        return Promise.reject('The data does not contain valid email(s)!');
      }
      break;

    case CSVMappingFields.phone:
      const phonePattern = /^[+\d]?(?:[\d-.\s()]*)$/;
      const phoneNumbers = data
        .split(';')
        .map((phone: string) => phone.trim().replace(/[()]/g, ''));

      for (const phoneNumber of phoneNumbers) {
        const isValidPhone = phonePattern.test(phoneNumber);
        if (!isValidPhone) {
          return Promise.reject('The data does not contain a valid phone number!');
        }
      }
      break;

    case CSVMappingFields.applicationDate:
      const date = new Date(data);
      if (isNaN(date.getTime())) {
        return Promise.reject('The data does not contain a valid date!');
      }
      break;

    default:
      break;
  }

  return Promise.resolve();
};

type CSVMappingResult = { [key: string]: CSVMappingFields };

export const mapCSVHeadersToSchema = (headers: string[]): CSVMappingResult => {
  const mappingResult: CSVMappingResult = {};

  headers.forEach(header => {
    const lowerHeader = header.toLowerCase();
    if (
      lowerHeader.includes('vorname') ||
      lowerHeader.includes('first name') ||
      lowerHeader.includes('first-name')
    ) {
      mappingResult[header] = CSVMappingFields.firstName;
    } else if (
      lowerHeader.includes('nachname') ||
      lowerHeader.includes('last name') ||
      lowerHeader.includes('last-name')
    ) {
      mappingResult[header] = CSVMappingFields.lastName;
    } else if (lowerHeader.includes('name')) {
      mappingResult[header] = CSVMappingFields.name;
    } else if (
      lowerHeader.includes('mail') ||
      lowerHeader.includes('e-mail') ||
      lowerHeader.includes('email') ||
      lowerHeader.includes('e-mail-adresse') ||
      lowerHeader.includes('e-mail address')
    ) {
      mappingResult[header] = CSVMappingFields.email;
    } else if (
      lowerHeader.includes('nummer') ||
      lowerHeader.includes('handynummer') ||
      lowerHeader.includes('telefon') ||
      lowerHeader.includes('telephone') ||
      lowerHeader.includes('telephonenumber') ||
      lowerHeader.includes('number') ||
      lowerHeader.includes('phone') ||
      lowerHeader.includes('mobile')
    ) {
      mappingResult[header] = CSVMappingFields.phone;
    } else if (
      lowerHeader.includes('bewerbungsdatum') ||
      lowerHeader.includes('beworben am') ||
      lowerHeader.includes('apply date') ||
      lowerHeader.includes('applied on')
    ) {
      mappingResult[header] = CSVMappingFields.applicationDate;
    } else if (
      lowerHeader.includes('dokumente') ||
      lowerHeader.includes('lebenslauf') ||
      lowerHeader.includes('documents') ||
      lowerHeader.includes('curriculum vitae') ||
      lowerHeader.includes('resume') ||
      lowerHeader.includes('cv')
    ) {
      mappingResult[header] = CSVMappingFields.generalPurpose;
    } else {
      mappingResult[header] = CSVMappingFields.note;
    }
  });

  return mappingResult;
};

export const finalizeDataForCSVSubmission = ({
  dataMapped,
  defaultSelectedStatus,
  isStatusSelectedInSchema,
  selectedFunnelId
}: {
  dataMapped: any;
  selectedFunnelId: number;
  isStatusSelectedInSchema: boolean;
  defaultSelectedStatus?: StatusInterface;
}) => {
  const finalData = dataMapped.map((data: any) => {
    const sanitizedEmail = sanitizeEmail(data?.email || '').firstEmail;
    const sanitizedPhoneNumber = data?.phone
      ? sanitizePhoneNumber(data?.phone || '').firstPhoneNumber
      : undefined;

    return {
      ...data,
      email: sanitizedEmail,
      ...(sanitizedPhoneNumber && { phone: sanitizedPhoneNumber }),
      funnelId: selectedFunnelId,
      ...(!isStatusSelectedInSchema && {
        bookingStatusId: defaultSelectedStatus?.id
      }),
      type: BookingType.CSV
    };
  });
  return finalData;
};
