import { Button, Modal, Result } from 'antd';
import React from 'react';

const WarningModalForDisablingPage = ({
  onCancel,
  visible,
  onSubmit,
  title,
  content,
  cancelButtonText,
  submitButtonText,
  iconPath
}: {
  visible: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  title: string;
  content: string;
  cancelButtonText?: string;
  submitButtonText?: string;
  iconPath?: string;
}) => {

  return (
    <Modal visible={visible} onOk={onSubmit} onCancel={onCancel} footer={null}>
      <Result
        icon={iconPath ? <img width={70} src={iconPath} /> : null}
        status="info"
        title={title}
        subTitle={content}
        extra={[
          <Button
            type="primary"
            key="WarningForWithoutContainerElement__1"
            onClick={onSubmit}
          >
            {submitButtonText || 'Ja'}
          </Button>,
          <Button
            key="WarningForWithoutContainerElement__2"
            type="link"
            onClick={onCancel}
          >
            {cancelButtonText || 'Nein'}
          </Button>
        ]}
      />
    </Modal>
  );
};

export default WarningModalForDisablingPage;
