import { Modal } from 'antd';
import React, { useState } from 'react';
import EmojiPicker from '../../../../../GeneralComponents/EmojiPicker';

const EmojiPickerModal: React.FC<{ onChange: (val: string) => void }> = ({
  children,
  onChange
}) => {
  const [visible, setVisible] = useState(false);
  const toggleModal = () => {
    setVisible(!visible);
  };
  return (
    <>
      <div onClick={toggleModal}>{children}</div>
      <Modal visible={visible} onOk={toggleModal} onCancel={toggleModal}>
        <div style={{ height: '80vh' }}>
          <EmojiPicker
            emojiSize={30}
            onChange={(val: any) => {
              setVisible(false);
              onChange(val);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default EmojiPickerModal;
