import mainTextData from './mainTextData';
import ctaTextData from './ctaTextData';
import headlineTextData from './headlineTextData';
import teaserTextData from './teaserTextData';
import { adTemplates } from './adTemplates';
import { textTypesEnum } from '../interfaces/TextTypeInterface';

import jobTeaserTextData from './jobTeaserTextData';
import JobCtaTextData from './jobCtaTextData';
import jobAdvantagesTextData from './jobAdvantagesTextData';
import jobTasksTextData from './jobTasksTextData';
import jobRequirementsTextData from './jobRequirementsTextData';

export interface TemplateType {
  title: string;
  data: {
    category: string;
    content: { id: number; text: string }[];
  }[];
}
export interface TemplateContent {
  id: number;
  text: string;
}

export const useTextTemplateData = (textType: textTypesEnum) => {
  switch (textType) {
    case textTypesEnum.HEADLINE:
      return headlineTextData;

    case textTypesEnum.MAIN_TEXT:
      return mainTextData;

    case textTypesEnum.TEASER:
      return teaserTextData;

    case textTypesEnum.CTA:
      return ctaTextData;

    case textTypesEnum.JOB_TITLE:
      return headlineTextData;

    case textTypesEnum.JOB_MIDDLE_PART:
      return mainTextData;

    case textTypesEnum.ADVANTAGES:
      return jobAdvantagesTextData;

    case textTypesEnum.TASKS:
      return jobTasksTextData;

    case textTypesEnum.REQUIREMENTS:
      return jobRequirementsTextData;

    case textTypesEnum.JOB_TEASER:
      return jobTeaserTextData;

    case textTypesEnum.JOB_CTA:
      return JobCtaTextData;

    case textTypesEnum.QUALIFICATION:
      return ctaTextData;

    case textTypesEnum.RESPONSIBILITIES:
      return ctaTextData;

    case textTypesEnum.SKILLS:
      return ctaTextData;
  }
};


export const useDefaultAdTemplate = () => {
  return adTemplates;
};

export const getNextTemplate = ({
  currentId,
  direction,
  templateData
}: {
  currentId: string | number;
  direction: 'NEXT' | 'PREV';
  templateData: TemplateType;
}) => {
  const templates = templateData.data.reduce((prev: any[], curr) => {
    return [...prev, ...curr.content];
  }, []);

  const index = templates.findIndex(single => single.id === currentId);

  if (index === -1) {
    return templates[0];
  }

  if (direction === 'NEXT') {
    if (templates.length - 1 === index) {
      return templates[0];
    } else {
      return templates[index + 1];
    }
  } else {
    if (index === 0) {
      return templates[templates.length - 1];
    } else {
      return templates[index - 1];
    }
  }
};

export const getTextTemplateById = (id: number, templateData: TemplateType) => {
  const data = templateData.data.reduce((prev: { id: number; text: string }[], curr) => {
    return [...prev, ...curr.content];
  }, []);

  const template = data.find(({ id: _id }) => _id === id);

  if (template) {
    return template;
  } else {
    return data[0];
  }
};

export const getTextTemplateByText = (currentText: string, templateData: TemplateType) => {
  const data = templateData.data.reduce((prev: { id: number; text: string }[], curr) => {
    return [...prev, ...curr.content];
  }, []);
  const template = data.find(({ text }) => text === currentText);
  return template;
};

