import { SizeInterface } from '../config/builderUi';

export const ImageAvailableSizes: SizeInterface[] = [
  {
    key: 1,
    value: '25%'
  },
  {
    key: 2,
    value: '50%'
  },
  {
    key: 3,
    value: '75%'
  },
  {
    key: 4,
    value: '100%'
  }
];

export const ImageAvailableBorderRadius: SizeInterface[] = [
  {
    key: 1,
    value: 'Eckig',
    pixelValue: '0px'
  },
  {
    key: 2,
    value: 'Leicht gerundet',
    pixelValue: '15px'
  },
  {
    key: 3,
    value: 'Rund',
    pixelValue: '30px'
  },
  {
    key: 4,
    value: "Kreis",
    pixelValue: "100%"
  }
];

export const ImageAvailableOverlayTransparency: SizeInterface[] = [
  {
    key: 0,
    value: "0%",
    pixelValue: 0,
  },
  {
    key: 1,
    value: "10%",
    pixelValue: .1
  },
  {
    key: 2,
    value: "20%",
    pixelValue: .2
  },
  {
    key: 3,
    value: "30%",
    pixelValue: .3
  },
  {
    key: 4,
    value: "40%",
    pixelValue: .4
  },
  {
    key: 5,
    value: "50%",
    pixelValue: .5
  },
  {
    key: 6,
    value: "60%",
    pixelValue: .6
  },
  {
    key: 7,
    value: "70%",
    pixelValue: .7
  },
  {
    key: 8,
    value: "80%",
    pixelValue: .8
  },
  {
    key: 9,
    value: "90%",
    pixelValue: .9
  },
  {
    key: 10,
    value: "100%",
    pixelValue: 1
  }
]

export const defaultImageOpacity = ImageAvailableOverlayTransparency[10];

export const defaultImageSize = ImageAvailableSizes[0];

export const defaultImageBorderRadius = ImageAvailableBorderRadius[0];
