import React from 'react';
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import { Button } from 'antd';

type FontStyles = 'BOLD' | 'ITALIC' | 'UNDERLINE' | '';
interface Props {
  onChange(align: FontStyles): void;
  hasStyle(style: string): boolean;
  buttonsToRender?: FontStyles[];
  disabledStyleButton?: boolean
}

const FontStyleButtons = ({ onChange, hasStyle, buttonsToRender , disabledStyleButton }: Props) => {
  if (!buttonsToRender) buttonsToRender = ['BOLD', 'ITALIC', 'UNDERLINE'];

  return (
    <div className="text-formatting-buttons text-formatting-buttons__font-style">
      {buttonsToRender.includes('BOLD') && (
        <Button
          size="large"
          className="mt-1"
          type={hasStyle('BOLD') ? 'primary' : undefined}
          icon={<BoldOutlined />}
          onClick={(e: any) => {
            onChange('BOLD');
          }}
          disabled={disabledStyleButton}
          shape="circle"
          data-testid='font-styles-bold-text-button'
        />
      )}
      {buttonsToRender.includes('ITALIC') && (
        <Button
          className="m-2 mt-1 mb-1"
          size="large"
          type={hasStyle('ITALIC') ? 'primary' : undefined}
          icon={<ItalicOutlined />}
          disabled={disabledStyleButton}
          onClick={(e: any) => {
            onChange('ITALIC');
          }}
          shape="circle"
        />
      )}
      {buttonsToRender.includes('UNDERLINE') && (
        <Button
          size="large"
          className="mt-1"
          type={hasStyle('UNDERLINE') ? 'primary' : undefined}
          icon={<UnderlineOutlined />}
          disabled={disabledStyleButton}
          onClick={(e: any) => {
            onChange('UNDERLINE');
          }}
          shape="circle"
        />
      )}
    </div>
  );
};

export default FontStyleButtons;
