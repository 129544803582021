import React from 'react';
import { Button } from 'antd';

interface Props {
  style?: any;
  children: React.ReactNode;
}

export default function PostFooterHOC({ style, children }: Props) {
  return (
    <div className="footer-wrapper w-100" >
      <div className="footer-wrapper__inner" style={{ ...style }}>
        <p>company-ipsum.de/karriere</p>
        {children}
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit.
        </p>
      </div>
      <Button type="primary">Jetzt Bewerben</Button>
    </div>
  );
}
