import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { JOB_ATTRIBUTES_IDENTIFIER } from '../../../AdJobBuilder/helper/AdJobHelper';
import { useJobInformation } from '../../../redux/builderSlice';
import { JobInformation } from '../../../interfaces/builderSliceTypes';
import { customStringFieldValidation, defaultStringFieldValidation } from '../../../../UI/utils/formValidation';
import { AiGeneratedTypeProps } from './StatementTemplate';
import { AD_TYPES } from './TemplateTypesView';
import { InfoCircleFilled } from '@ant-design/icons';

const EmployeeTestimonialTemplate = ({
  formInstance,
  onSubmit,
  initialData
}: AiGeneratedTypeProps) => {
  const [form] = Form.useForm();
  const { title } = useJobInformation();

  const handleChange = (value: any, identifier: keyof JobInformation) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
  };
  useEffect(() => {
    if (formInstance) {
      formInstance.current = form;
    }
  }, [formInstance, form]);
  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="px-4">
          <Form
            layout="vertical"
            form={form}
            style={{ maxWidth: '600px', margin: '0 auto' }}
            initialValues={{ title, ...initialData }}
            onFinish={onSubmit}
          >
            <Form.Item
              label="Vor- und Nachname"
              name="employeeTestimonialName"
              rules={customStringFieldValidation(3, 50)}
            >
              <Input placeholder="z.B. Max Mustermann" />
            </Form.Item>

            <Form.Item
              label="Job-Bezeichnung"
              name="title"
              rules={[defaultStringFieldValidation[0]]}
            >
              <Input
                placeholder="z.B. Software-Entwickler (m/w/d) für Schnittstellen und Datenbank"
                maxLength={60}
                value={title}
                onChange={e => {
                  handleChange(e.target.value, JOB_ATTRIBUTES_IDENTIFIER.JOB_TITLE);
                }}
              />
            </Form.Item>

            <Form.Item
              label="Positive Erfahrung (ca. 3 Sätze)"
              name={`promptMessage${AD_TYPES.EMPLOYEE_TESTIMONIAL}`}
              rules={customStringFieldValidation(5, 80)}
            >
              <Input.TextArea
                placeholder="z.B. Max Mustermann fing vor 3 Jahren bei uns an..."
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </Form.Item>

            <p style={{ fontSize: '12px', color: '#888' }}>
              <InfoCircleFilled /> Befrage am besten den Mitarbeiter persönlich, um es noch
              authentischer zu machen.
            </p>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

export default EmployeeTestimonialTemplate;
