import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useEditor, useNode, Element } from '@craftjs/core';
import Choice from '../SingleChoiceComponent/components/Choice';
import { defaultChoices } from '../../../config/singleChoiceDefaultValues';
import { TEMPLATE_KEYS, getChoiceTemplateClassName } from '../Container/helper';
import { getUniqueId } from '../../../helper/craftJs';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useElementsPresets } from '../../../redux/builderSlice';
import { builderPresetElements } from '../../../interfaces/builderSliceTypes';
import AddNewTile from '../SingleChoiceComponent/components/AddNewTile';

export enum CHOICE_TYPES {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export interface Props {
  children?: any;
  type: string;
  className?: string;
  onlySettingsReq?: any;
}

export default function ChoiceContainerHoc({ children, className, onlySettingsReq, ...props }: Props) {
  const { actions, query } = useEditor();
  const isMobileView = useIsMobileView();
  const {
    currentNode,
    connectors: { connect },
    childNodeProps
  } = useNode(node => {
    const nodeId = node.id;
    const SerializedNodes = query.getSerializedNodes();
    const childNode = SerializedNodes[nodeId]?.nodes[0];
    const childNodeProps = SerializedNodes[childNode]?.props;
    return {
      currentNode: node,
      childNodeProps
    };
  });

  const appendChoiceNode = (choiceProps: {
    content: string;
    type?: string;
    value?: string;
    choiceType?: string;
    templateKey?: TEMPLATE_KEYS;
    deactivateIconButton?: boolean;
    borderRadius?: string;
    backgroundColor?: string;
    color?: string;
    cardIconColor?: string;
  }) => {
    const canvasNodeTree = query
      .parseReactElement(<Element id={getUniqueId()} is={Choice} {...choiceProps} />)
      .toNodeTree();

    const { content } = choiceProps;

    actions.addNodeTree(canvasNodeTree, currentNode.id);
    actions.selectNode(canvasNodeTree.rootNodeId);
    return { craftJsNodeId: canvasNodeTree.rootNodeId, title: content };
  };

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const actionCreator = actions.history.throttle(500);
      defaultChoices.map((single, i) => {
        const nodeTree = query
          .parseReactElement(
            <Element key={i} id={getUniqueId()} is={Choice} {...single} choiceType={props.type} />
          )
          .toNodeTree();
        actionCreator.addNodeTree(nodeTree, currentNode.id);
      });
    }
  }, []);

  const presets = useElementsPresets(builderPresetElements.CHOICE);

  const choiceChildNodeProps = childNodeProps?.isPresetOn
    ? { ...childNodeProps, ...presets }
    : childNodeProps;

  return (
    <Row
      {...props}
      ref={connect as any}
      className={`${className ?? ''} choice-container-hoc ${
        isMobileView ? 'mobile-choice-view' : ''
      } ${getChoiceTemplateClassName(choiceChildNodeProps?.templateKey)}`}
    >
      {children}

      <AddNewTile
        templateKey={choiceChildNodeProps?.templateKey}
        handleAddClick={() => appendChoiceNode({ content: 'Antworten', choiceType: props.type , ...childNodeProps  })}
      />
    </Row>
  );
}
