import React, { useEffect, useState } from 'react';
import { Input, Typography, Alert, Table, message, Select } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import {
  EmailTemplateType,
  EmailType,
  MessagingAndEmailTempaltes,
  MessagingTemplateTabType
} from '../../interfaces/builderSliceTypes';
import { columns, explanations } from './utils';
import { BUILDER_VALIDATION_ERRORS } from '../../container/BuilderValidator';
import { translateEmailToolbarText } from '../../helper/sharedFunctions';
import NewApplicationNotification from './NewApplicationNotification';
import {
  addBuilderValidationError,
  editFunnelSavedIndicator,
  removeBuilderValidationError,
  updateEmailTemplateByType,
  useChannelTemplatesData,
  useIsFunnelSaved
} from '../../redux/builderSlice';
import { useAppDispatch } from '../../../redux/hooks';
import { filteredTokensAsPerEmailType } from '../../helper/filterTokens';
import { EditorState } from 'draft-js';
import { maxRichTextLength } from '../../../constants';
import useCustomDebounce from '../../../hooks/useCustomDebounce';
import ScheduledNotificationTemplateSelectList from './ScheduledNotificationTemplateSelectList';

const EmailTemplateForm = ({
  onChange,
  emailTemplate,
  emailTemplateEnabled
}: {
  onChange: (e: EmailTemplateType | MessagingTemplateTabType) => void;
  emailTemplate: EmailTemplateType;
  emailTemplateEnabled: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { templates } = useChannelTemplatesData();
  const isSaved = useIsFunnelSaved();
  const { emailTemplate: internalConfirmationEmailTemplate } =
    (templates.find(
      ({ emailTemplate }) => emailTemplate.type === EmailType.INTERNAL_CONFIRMATION
    ) as MessagingAndEmailTempaltes) || {};

  const filteredTokens = filteredTokensAsPerEmailType(emailTemplate.type);

  useEffect(() => {
    translateEmailToolbarText();
  }, []);

  const handleUpdateOtherEmailAddressesToSend = (emailsAddresses: string) => {
    dispatch(
      updateEmailTemplateByType({
        type: EmailType.INTERNAL_CONFIRMATION,
        template: {
          ...internalConfirmationEmailTemplate,
          otherEmailAddressesToSend: emailsAddresses
        }
      })
    );
    if (isSaved) {
      dispatch(editFunnelSavedIndicator(false));
    }
  };

  const debouncedMaxTextLengthCheck = useCustomDebounce((e: EditorState) => {
    const newText = e.getCurrentContent().getPlainText('\u0001');
    if (newText.length > maxRichTextLength) {
      message.warning(`Maximale Wortlänge ist ${maxRichTextLength}`);
      return;
    }
  }, 200);

  const handleEditorStateChanged = (e: EditorState) => {
    onChange({ ...emailTemplate, html: e });
    debouncedMaxTextLengthCheck(e);
  };

  const handlePasteText = (e: string) => {
    const wordLength = emailTemplate.html.getCurrentContent().getPlainText('\u0001').length;
    const pastedWordLength = e.length;
    if (wordLength + pastedWordLength > maxRichTextLength) {
      message.warning(`Die maximale E-Mail-Länge ist ${maxRichTextLength.toLocaleString()}`);
      return true;
    }
    return false;
  };

  return (
    <div
      className={`template-form disable-layer ${!emailTemplateEnabled &&
        'disable-layer__disabled'}`}
    >
      <Alert
        message={explanations[emailTemplate.type]}
        className="remove-margin-top"
        type="info"
        showIcon
      />
      {(emailTemplate.type === EmailType.SCHEDULED ||
        emailTemplate.type === EmailType.REMINDER) && (
        <ScheduledNotificationTemplateSelectList
          scheduledTime={emailTemplate?.scheduledTime}
          timeInMinutesTillEvent={emailTemplate?.timeInMinutesTillEvent}
          onChange={onChange}
          templateType={emailTemplate.type}
          data={emailTemplate}
        />
      )}

      <>
        <div className="mb-3">
          {emailTemplate.type === EmailType.INTERNAL_CONFIRMATION && (
            <NewApplicationNotification
              otherEmailAddressesToSend={
                internalConfirmationEmailTemplate?.otherEmailAddressesToSend || ''
              }
              onChange={handleUpdateOtherEmailAddressesToSend}
            />
          )}
          <Typography.Text strong>Betreff</Typography.Text>
          <Input
            className="mt-2"
            placeholder="Bestätigung"
            value={emailTemplate.subject}
            onChange={e => {
              onChange({ ...emailTemplate, subject: e.target.value });
              e.target.value
                ? dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_SUBJECT))
                : dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_SUBJECT));
            }}
          />
        </div>

        <Editor
          defaultEditorState={emailTemplate.html}
          toolbarClassName="toolbar"
          toolbar={{
            link: {
              popupClassName: 'email-link-popup',
              link: { className: 'email-link-btn' }
            },
            options: ['inline', 'emoji', 'link'],
            inline: { inDropdown: false, options: ['bold', 'italic', 'underline'] }
          }}
          wrapperClassName="wrapper"
          editorClassName="editor"
          editorState={emailTemplate.html}
          onEditorStateChange={handleEditorStateChanged}
          onChange={e => {
            emailTemplate.html.getCurrentContent().hasText()
              ? dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_EDITOR))
              : dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.EMAIL_EDITOR));
          }}
          handlePastedText={handlePasteText}
          stripPastedStyles
        />
      </>
      <Table
        className="mt-4"
        pagination={false}
        columns={columns}
        dataSource={filteredTokens}
        size="middle"
      />
    </div>
  );
};

export default EmailTemplateForm;
