import { EventDrawerTabView, EventsContextTypes } from './EventsContext.types';

export const eventsContextInitialState: EventsContextTypes = {
  selectedEvent: undefined,
  handleSetSelectedEvent: () => { },
  onEventDragEnd: () => { },
  handleAddNewColumn: async () => { },
  handleDeleteStatusColumn: async () => { },
  handleUpdateStatusColumn: async () => { },
  trackings: [],
  leadQualifier: [],
  funnels: [],
  availableStatus: [],
  kanBanState: {
    columns: {},
    ordered: []
  },
  refetchBookingInfo: () => { },
  eventLoaders: {
    deleteBookingLoading: false,
    deleteApplicationLoading: false,
    deleteTrackingLoading: false,
    manualBookingLoading: false,
    loadingCreateBookingStatus: false,
    loadingUpdateBookingStatus: false,
    loadingDeleteBookingStatus: false,
    downloadLoading: false,
    eventsRefreshLoading: false,
    bookingLoading: false,
    columnStatusUpdating: false,
    deleteEventLoading: false
  },
  eventDrawerTabView: EventDrawerTabView.CONTACT,
  setEventDrawerTabView: () => { },
  handleAddManualBookingEvent: async () => { },
  handleAddCSVBookingEvent: async () => { },
  handleUpdateManualLeadQualifier: async () => { },
  applicationState: [],
  setApplicationState: () => { },
  handleFunnelSelectionChange: () => { },
  handleOnSearchQueryChange: () => { },
  handleOnDateRangeChange: () => { },
  handleOnTypeChange: () => { },
  handleStatusChanged: () => { },
  handleDeleteEvent: async () => { },
  handleDownload: async () => { },
  handleLocalDelete: () => { },
  handleLocalUpdateBookingEvents: () => { },
  handleUpdateBookingStatus: async () => { },
  changeBookingStatusLocally: () => { },
  eventSearchInput: '',
  setEventSearchInput: () => { },
  bookingFilters: {
    start: '',
    end: '',
    type: '',
    searchQuery: '',
    statusId: [0],
    funnelIds: [],
    tagIds: []
  },
  handleTagsSelectionChange: () => { },
  setIsBookingTagsChange: () => { },
  handleUpdateBookingTags: async () => { },
  handleDeleteBookingTag: async () => { },
  handleCreateBookingTags: async () => { },
  allBookingTags: [],
  allBookingTagsHashMap: {},
  setAllBookingTags: () => { },
  handleSetApplicationStateForTags: () => { },
  handleKanbanRating: () => { },
  deleteEventLoading: false,
  selectedEventLoading: false,
  selectedRowKey: null,
};
