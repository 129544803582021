import React, { CSSProperties } from 'react';
import {
  IconProvider
  // @ts-ignore
} from 'meetovo-frontend-booking';

interface PlayCircleInterface {
  className: string;
  onClick(): any;
  isPlaying: boolean;
  style?: CSSProperties;
}

export default function PlayCircle({ className, onClick, isPlaying, style }: PlayCircleInterface) {
  return (
    <div
      className={`${className} play-circle ${isPlaying ? 'is-playing' : ''}`}
      onClick={() => onClick && onClick()}
      style={style}
    >
      {!isPlaying ? <IconProvider name="FaPlay" /> : <IconProvider name="FaPause" />}
    </div>
  );
}
