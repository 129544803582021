import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_FUNNEL_TEMPLATES_PAGES = gql`
  query funnelTemplatePages {
    funnelTemplatePages {
      id
      title
      image
      craftState
      public
      userId
      tags
      type
    }
  }
`;

export default function useGetAllFunnelTemplatePages() {
  return useQuery(GET_FUNNEL_TEMPLATES_PAGES);
}
