import React, { useState } from 'react';
import { useBuilderPages, useMainFunnelLoader } from '../../redux/builderSlice';
import { Col, Input, message, Modal, Row, Tabs } from 'antd';
import { Nodes, useEditor } from '@craftjs/core';
import { useDispatch } from 'react-redux';
import { addFunnelPageThunk } from '../../redux/thunk';
import Search from 'antd/lib/input/Search';
import { debounce } from 'debounce';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import { SelectedTemplate, Template as TemplateType } from '../TemplatesPanel/interfaces';
import lz from 'lzutf8';
import { Template } from '../TemplatesPanel/Template';
import CreateNewPageModal from './CreateNewPageModal';
import { defaultTemplate } from '../TemplatesPanel/defaultTemplate';
import { defaultCustomJS } from '../../helper/defaultValues';
import { defaultCraftState } from '../../helper/defaultCraftStates';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { craftToJSON, getClonedTree, JSONtoCraft } from '../../helper/craftJs';
import useGetAllFunnelTemplatePages from '../../hooks/useGetAllFunnelTemplatePages';

type props = {
  visible: boolean;
  toggleVisible: (state: boolean) => void;
};

const CreateNewQuestionModal = ({ visible, toggleVisible }: props) => {
  const [searchValue, setSearchValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [editTemplateDate, setEditTemplateDate] = useState<{
    id: number;
    title: string;
    tags: string[];
  } | null>(null);
  const [emptyModalVisible, setEmptyModalVisible] = useState(false);
  const { data, loading = true, refetch } = useGetAllFunnelTemplatePages();
  const { query } = useEditor();
  const builderPages = useBuilderPages();
  const dispatch = useDispatch();

  const handleSettingEmptyModalVisible = (state: boolean) => {
    setEmptyModalVisible(state);
    handleCancel();
  }

  const handleCancel = () => {
    toggleVisible(false);
  };

  const handleOnChange = debounce((value: string) => {
    setSearchQuery(value);
  }, 1000);

  const tags: string[] = Array.from(
    new Set(data?.funnelTemplatePages.map((template: { tags: string[] }) => template.tags).flat())
  );

  const handleFilteredResult = (templates: TemplateType[]) => {
    if (searchQuery) {
      const lowerCasedSearchQuery = searchQuery.toLowerCase();
      return templates.filter(
        template =>
          template.title.toLowerCase().includes(lowerCasedSearchQuery) ||
          (template.tags
            ? template.tags.find((t: string) => t.toLowerCase().includes(lowerCasedSearchQuery))
            : false)
      );
    }
    return templates;
  };

  const filteredResult = handleFilteredResult([
    ...(data?.funnelTemplatePages ? [...data.funnelTemplatePages] : [])
  ]);

  const handleAddTemplate = (selectedTemplate: SelectedTemplate) => {
    const newStateJSON = craftToJSON(defaultCraftState);
    Object.keys(newStateJSON).forEach(key => {
      newStateJSON[key] = { data: { ...newStateJSON[key] } };
    });

    const defaultMainContainerNodeKey = newStateJSON['ROOT'].data.nodes.find((key: string) => {
      if (newStateJSON[key].data.displayName === CRAFT_ELEMENTS.MAIN_CONTAINER) {
        return key;
      }
    });

    const containerNodes: Record<string, any> = {};
    if (selectedTemplate.id)
      containerNodes[selectedTemplate.id] = selectedTemplate.nodes[selectedTemplate.id];
    else if (selectedTemplate.ids) {
      selectedTemplate.ids.forEach(id => {
        containerNodes[id] = selectedTemplate.nodes[id];
      });
    }

    Object.keys(containerNodes).forEach(key => {
      newStateJSON[defaultMainContainerNodeKey].data.nodes.push(key);
      const props = {...containerNodes[key].props, showSaveableModal: false};
      newStateJSON[key] = {
        data: { ...containerNodes[key], parent: defaultMainContainerNodeKey, props: props  }
      };
    });
    Object.keys(selectedTemplate.nodes).forEach(key => {
      if (!newStateJSON.hasOwnProperty(key)) {
        newStateJSON[key] = { data: selectedTemplate.nodes[key] };
      }
    });
    const newTree = getClonedTree({
      rootNodeId: defaultMainContainerNodeKey,
      nodes: newStateJSON
    });
    const finalState: Record<string, any> = {
      ROOT: {
        ...newStateJSON['ROOT'].data,
        nodes: [
          newStateJSON['ROOT'].data.nodes[0],
          newTree.rootNodeId,
          newStateJSON['ROOT'].data.nodes[2]
        ]
      },
      [newStateJSON['ROOT'].data.nodes[0]]: {
        ...newStateJSON[newStateJSON['ROOT'].data.nodes[0]].data
      },
      [newStateJSON['ROOT'].data.nodes[2]]: {
        ...newStateJSON[newStateJSON['ROOT'].data.nodes[2]].data
      }
    };
    Object.keys(newTree.nodes).forEach(key => {
      if (newTree.nodes[key].data.displayName === CRAFT_ELEMENTS.BUTTON) {
        newTree.nodes[key].data.props.actionLogic = 'next-page';
      } else if (newTree.nodes[key].data.displayName === CRAFT_ELEMENTS.CHOICE) {
        newTree.nodes[key].data.props.optionLogic = 'next-page';
      }
      finalState[key] = newTree.nodes[key].data;
    });

    const updatedState = JSONtoCraft(finalState);

    dispatch(
      addFunnelPageThunk({
        name: selectedTemplate.name,
        craftState: updatedState,
        customJs: defaultCustomJS,
        type: 'QUESTION',
        pageOrder: builderPages.length ? builderPages[builderPages.length - 1].pageOrder + 1 : 1,
        query: query
      })
    );
    toggleVisible(false);
  };

  const handleTagSelect = (tag: string) => {
    setSearchValue(tag);
    setSearchQuery(tag);
  };

  const handleEmptyPageCreation = (template: SelectedTemplate) => {
    setEmptyModalVisible(true);
  };

  return (
    <>
      <Modal
        title="Fragen"
        centered
        visible={visible}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={'60%'}
        bodyStyle={{ overflow: 'scroll', height: '80vh' }}
        footer={null}
        className="mb-6 builder__settings__image-select-modal"
        destroyOnClose
      >
        <Row>
          <Col span={24}>
            <Search
              placeholder="z.B. Vorteile, Kunde XYZ ..."
              className="w-100 mt-3"
              value={searchValue}
              onSearch={value => setSearchQuery(value)}
              onChange={e => {
                const value = e.target.value;
                setSearchValue(value);
                handleOnChange(value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="mb-2 builder__settings__image-select-modal__template-tags">
              {!!tags.length &&
                tags.map(tag => (
                  <CheckableTag
                    key={tag}
                    checked={searchQuery === tag}
                    onChange={() => {
                      handleTagSelect(tag);
                    }}
                  >
                    {tag}
                  </CheckableTag>
                ))}
            </div>
          </Col>
        </Row>
        <Row gutter={10}>
          <DefaultLoader loading={loading} />
          <Col span={8} key="default">
            <Template
              id={0}
              serializedNode={JSON.parse(
                lz.decompress(lz.decodeBase64(defaultTemplate.craftState))
              )}
              name={'Eigene Frage'}
              tags={defaultTemplate.tags}
              previewImageURL={defaultTemplate.image}
              handleAddTemplate={handleEmptyPageCreation}
              deletable={false}
              setEditTemplateDate={setEditTemplateDate}
              plusIconFilled={true}
              refetchFunnelTemplates={refetch}
              imageClasses="height-200"
            />
          </Col>
          {!loading &&
            filteredResult.map((template: TemplateType, index: number) => (
              <Col span={8} key={index}>
                <Template
                  id={template.id}
                  serializedNode={JSON.parse(lz.decompress(lz.decodeBase64(template.craftState)))}
                  name={template.title}
                  tags={template.tags}
                  previewImageURL={template.image}
                  handleAddTemplate={handleAddTemplate}
                  deletable={false}
                  setEditTemplateDate={setEditTemplateDate}
                  plusIconFilled={true}
                  refetchFunnelTemplates={refetch}
                  imageClasses="height-200"
                />
                <div className="mt-2 mx-2 builder__settings__image-select-modal__template-tags">
                  {template.tags &&
                    template.tags.map(tag => (
                      <CheckableTag
                        key={tag}
                        checked={searchQuery === tag}
                        onChange={() => {
                          handleTagSelect(tag);
                        }}
                      >
                        {tag}
                      </CheckableTag>
                    ))}
                </div>
              </Col>
            ))}
        </Row>
      </Modal>

      <CreateNewPageModal pageType='QUESTION' visible={emptyModalVisible} toggleVisible={handleSettingEmptyModalVisible} />
    </>
  );
};

export default CreateNewQuestionModal;
