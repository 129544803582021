import React from 'react';
import FunnelSelectList from '../../../UI/components/FunnelSelectList';
import { Select } from 'antd';
import { useEventsContext } from '../../context/EventsContext';
import { StatusInterface } from '../../container/Events';

interface CSVSubmitTabProps {
  selectedFunnelId: number | undefined;
  setSelectedFunnelId: (id: number) => void;
  defaultSelectedStatus?: StatusInterface;
  setDefaultSelectedStatus: (status: StatusInterface) => void;
  showStatusSelector?: boolean;
}

const CSVSubmitTab = ({
  selectedFunnelId,
  setSelectedFunnelId,
  setDefaultSelectedStatus,
  defaultSelectedStatus,
  showStatusSelector
}: CSVSubmitTabProps) => {
  const { availableStatus } = useEventsContext();
  return (
    <div className="submit-tab">
      <div className="w-100">
        <h3 className="submit-tab__title">Select funnel</h3>
        <FunnelSelectList
          onChange={id => {
            setSelectedFunnelId(id);
          }}
          value={selectedFunnelId || undefined}
          showActiveFunelsOnly
        />
      </div>
      {showStatusSelector && (
        <div className="w-100">
          <h3 className="submit-tab__title">Select Status</h3>
          <Select
            defaultValue="NEW"
            className="w-100"
            style={{ width: 120 }}
            options={availableStatus.map(status => ({
              label: status.status,
              value: status.value
            }))}
            value={defaultSelectedStatus?.value}
            onChange={e => {
              setDefaultSelectedStatus(
                availableStatus.find(status => status.value === e) as StatusInterface
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CSVSubmitTab;
