import React, { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import Templates from '../../../Templates/container';

export const AppendNewContainer = () => {
  const [templateModalVisible, setTemplatesModalVisible] = useState(false);
  const toggleTemplateModalVisibility = () => {
    setTemplatesModalVisible(!templateModalVisible);
  };
  return (
    <React.Fragment>
      <div
        className="add-button-green circular-plus-button container__add-wrapper-icon append-container-icon"
        onClick={toggleTemplateModalVisibility}
      >
        <FaPlus className="container-plus-icon" />
      </div>
      <Templates
        visible={templateModalVisible}
        toggleVisible={() => setTemplatesModalVisible(!templateModalVisible)}
      ></Templates>
    </React.Fragment>
  );
};
