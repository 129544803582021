import { EventMap } from '../components/EventKanban/types';
import { ActionInterface } from '../components/EventTable';
import {
  BookingActionEventInterface,
  BookingFilterInterface,
  BookingInterface,
  BookingStatus,
  BookingTag,
  EventStatus,
  LeadQualifierInterface,
  StatusInterface,
  TrackingInterface
} from '../container/Events';
import { Store } from 'antd/lib/form/interface';

export enum EventDrawerTabView {
  CONTACT = 'CONTACT',
  INPUTS = 'INPUTS',
  NOTES = 'NOTES',
  UPLOAD = 'UPLOAD',
  COMMUNICATION = 'COMMUNICATION'
}

export enum BookingType {
  NORMAL = 'NORMAL',
  MANUAL = 'MANUAL',
  CSV = 'CSV'
}

interface ContextBookingType extends BookingInterface {
  leadQualifiers?: LeadQualifierInterface[];
}


export interface SelectedEvent {
  event: ContextBookingType;
  index: number;
}

export interface EventsContextTypes {
  selectedEvent:
    | {
        event: ContextBookingType | TrackingInterface;
        index: any;
      }
    | undefined;
  handleSetSelectedEvent: (e: any) => void;
  onEventDragEnd: any;
  handleAddNewColumn: (name: string, color: string) => Promise<void>;
  handleDeleteStatusColumn: (bookingStatus: BookingStatus) => Promise<void>;
  handleUpdateStatusColumn: (
    bookingStatus: BookingStatus,
    newColor: string,
    newStatus: string
  ) => Promise<void>;
  trackings: TrackingInterface[];
  leadQualifier: LeadQualifierInterface[];
  funnels: { id: number; title: string }[];
  availableStatus: StatusInterface[];
  kanBanState: {
    columns: EventMap;
    ordered: string[];
  };
  refetchBookingInfo: () => void;
  eventDrawerTabView: EventDrawerTabView;
  setEventDrawerTabView: (e: EventDrawerTabView) => void;
  handleAddManualBookingEvent: (e: Store) => Promise<void>;
  handleAddCSVBookingEvent: (e: BookingInterface[]) => Promise<void>;
  handleUpdateManualLeadQualifier: (e: any, leadQualifierData: any) => Promise<void>;
  applicationState: BookingInterface[];
  setApplicationState: (b: BookingInterface[]) => void;
  handleFunnelSelectionChange: (b: number[]) => void;
  handleOnSearchQueryChange: (b: string) => void;
  handleOnDateRangeChange: (b: any) => void;
  handleOnTypeChange: (b: string) => void;
  handleStatusChanged: (b: number[]) => void;
  handleDeleteEvent: (
    id: number,
    version: string,
    type: string,
    callBack?: Function
  ) => Promise<any>;
  handleDownload: () => void;
  handleLocalDelete: (eventId: number) => void;
  handleLocalUpdateBookingEvents: (
    eventId: number,
    bookingEvents: BookingActionEventInterface
  ) => void;
  handleUpdateBookingStatus: (e: any, action: ActionInterface) => Promise<void>;
  changeBookingStatusLocally: (
    id: number,
    eventStatus: EventStatus,
    action?: ActionInterface
  ) => void;
  eventLoaders: {
    deleteBookingLoading: boolean;
    deleteApplicationLoading: boolean;
    deleteTrackingLoading: boolean;
    manualBookingLoading: boolean;
    loadingCreateBookingStatus: boolean;
    loadingUpdateBookingStatus: boolean;
    loadingDeleteBookingStatus: boolean;
    downloadLoading: boolean;
    eventsRefreshLoading: boolean;
    bookingLoading: boolean;
    columnStatusUpdating: boolean;
    deleteEventLoading: boolean;
  };
  eventSearchInput: string;
  setEventSearchInput: (e: string) => void;
  bookingFilters: BookingFilterInterface;
  setIsBookingTagsChange: (e: boolean) => void;
  handleTagsSelectionChange: (b: number[]) => void;
  handleUpdateBookingTags: (updatedTag: BookingTag, oldTag: BookingTag) => Promise<void>;
  handleDeleteBookingTag: (deletedTag: BookingTag) => Promise<void>;
  handleCreateBookingTags: (bookingId: number, selectedEvent: SelectedEvent) => Promise<void>;
  handleSetApplicationStateForTags: (selectedEvent: SelectedEvent) => void;
  allBookingTags: BookingTag[];
  allBookingTagsHashMap: { [key: string]: BookingTag };
  setAllBookingTags: (t: BookingTag[]) => void;
  handleKanbanRating: (newBookingRating: any) => void;
  deleteEventLoading: boolean;
  selectedEventLoading: boolean;
  selectedRowKey: number | null;
}
