import { Button } from 'antd';
import React, { useState } from 'react';

export default function ReadMore({ children }: { children: any }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <div className="read-more-content">
      <p>{isReadMore ? text?.slice(0, 150) : text}</p>
      <Button onClick={toggleReadMore} type="link" className="no-padding display-block">
        {isReadMore ? 'Mehr anzeigen' : 'Weniger anzeigen'}
      </Button>
    </div>
  );
}
