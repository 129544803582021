import gql from 'graphql-tag';
import { pixelImagePerPage } from '../constants';

export const GET_IMAGES = gql`
  query getFunnelData($filter: PexelFilter!) {
    getPexelsImages(filter: $filter) {
      urls {
        small
        regular
        full
      }
      user {
        name
        profile_url
      }
    }
  }
`;

export function getPexelImages(
  directKeyword: string,
  quality?: number,
  perPage?: number,
  page?: number
) {
  return window.apolloClient.query({
    query: GET_IMAGES,
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        keyword: directKeyword,
        perPage: perPage || pixelImagePerPage,
        quality: quality || 80,
        page: page || 1
      }
    }
  });
}
