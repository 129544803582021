import React from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, Row, Input, Form, Slider } from 'antd';
import { ActionsController } from '../sharedUI/ActionsController';
import { Form as RBForm } from 'react-bootstrap';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../helper/craftJs';
import MinMaxNumberInput from '../../../SharedUI/components/MinMaxNumberInput';
import { SliderValue } from 'antd/lib/slider';
import { FaArrowsAltV } from 'react-icons/fa';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';

interface Props extends CraftElementBaseProps {
  min: number;
  max: number;
  placeholder: string;
  rows: number;
}

const LongAnswerComponent = (props: Props) => {
  const { placeholder, rows } = props;
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  return (
    <ActionsController
      className="max-width__700px-centered long-answer-wrapper"
      style={{ boxShadow: 'none' }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <RBForm>
        <RBForm.Control as="textarea" placeholder={placeholder || ''} rows={rows} />
      </RBForm>
    </ActionsController>
  );
};

export const LongAnswerDefaultProps = {
  placeholder: 'Ich bin XYZ und...',
  min: 10,
  max: 1000,
  rows:5,
  onlySettingsReq: true
};

export const LongAnswerSettings = () => {
  const [form] = Form.useForm();
  const marks = {
    1: 'S',
    3: 'M',
    5: 'L'
  };

  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const handleLabelClick = (value: number) => {
    setProp((props: any) => {
      props.rows = value;
    });
  };

  const handleMinChange = (value: number) => {
    setProp((props: any) => {
      props.min = value;
    });
  };

  const handleMaxChange = (value: number) => {
    setProp((props: any) => {
      props.max = value;
    });
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Textantwort" bordered={false}>
        <Row className="builder__settings-sidebar__row">
        <Col span={24}>
            <BuilderSettingsTitle title="Feldgröße" icon={<FaArrowsAltV/>}/>
          </Col>
          <Col span={24}>
            <Form
              name="lead-qualifier_settings_form"
              layout="vertical"
              form={form}
              scrollToFirstError={true}
              initialValues={{
                placeholder: props.placeholder || '',
                min: props.min,
                max: props.max
              }}
              onChange={form.submit}
              onFinish={values => {
                setProp((props: any) => {
                  props.placeholder = values.placeholder;
                });
              }}
            >
                <Slider
                className="builder-slider-style"
                  marks={marks}
                  step={null}
                  min={1}
                  max={5}
                  value={props.rows}
                  onChange={(value: SliderValue) => {
                    setProp((props: any) => {
                      props.rows = value;
                    });
                  }}
                  tooltipVisible={false}
                />
              <Form.Item name="placeholder" label="Platzhalter">
                <Input placeholder="z.B. Ich bin XYZ und..." />
              </Form.Item>
              <MinMaxNumberInput
                title="Minimale und maximale Länge der Antwort."
                minLimit={props.min}
                maxLimit={props.max}
                minTitle="Mindestlänge"
                maxTitle="Maximallänge"
                setMinLimit={handleMinChange}
                setMaxLimit={handleMaxChange}
              />
            </Form>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

LongAnswerComponent.craft = {
  name: CRAFT_ELEMENTS.LONG_ANSWER,
  props: LongAnswerDefaultProps,
  rules: {
    canMoveIn: () => {
      return false;
    },
    canMoveOut: () => {
      return false;
    }
  },
  related: {
    settings: LongAnswerSettings
  }
};

export default LongAnswerComponent;
