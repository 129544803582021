import React, { useEffect } from 'react';
import { Card, Col, Row, Tabs, Switch, Slider } from 'antd';
import { Col as RBCol } from 'react-bootstrap';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';

import { useElementsPresets } from '../../../../redux/builderSlice';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { useEditor, useNode } from '@craftjs/core';

import EmojiPicker from '../../../../../GeneralComponents/EmojiPicker';
import FrequentlyUsedIconPicker from '../../../../../GeneralComponents/FrequentlyUsedIconPicker/container/FrequentlyUsedIconPicker';
import IconPickerGrid from '../../../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { FUNNEL_THEME_KEYS, builderPresetElements } from '../../../../interfaces/builderSliceTypes';
import {
  CraftElementBaseProps,
  getParentNodes,
  getValueByKey,
  getKeyByValue,
  getKeyValueObjectByKeyValueArrayOfObjects,
  getElementColor
} from '../../../../helper/craftJs';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { BiBorderRadius } from 'react-icons/bi';
import { BsGearFill } from 'react-icons/bs';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import BuilderSettingsTitleWithSwitch from '../../../sharedUI/BuilderSettingsTitleWithSwitch';
import { ChoiceAvailableBorderRadius } from '../../../../interfaces/ChoiceSizeInterface';

const { TabPane } = Tabs;

export enum IconType {
  icon = 'icon',
  emoji = 'emoji'
}

export interface FAQTileProps extends CraftElementBaseProps {
  children?: any;
  type?: string;
  value?: string;
  backgroundColor?: string;
  cardIconColor?: string;
  borderRadius?: string;
  deactivateIconButton?: boolean;
  isPresetOn?: boolean;
  question?: string;
  answer?: string;
  accordionCollapse?: boolean;
}

export default function FAQTile({ children, backgroundColor, ...props }: FAQTileProps) {
  const isMobileView = useIsMobileView();
  const theme = useCurrentlyEditingBuilderTheme();

  const {
    actions: { setProp }
  } = useNode(node => node);

  const { nodeId, currentNode } = useNode(node => {
    return {
      nodeId: node.id,
      currentNode: node
    };
  });

  const { query, actions } = useEditor();

  useEffect(() => {
    if (!query.node(nodeId).get()) return;
    if (getParentNodes(nodeId, query).length === 1) {
      actions.history.ignore().setProp(nodeId, (props: any) => (props.deleteNotReq = true));
    } else {
      actions.history.ignore().setProp(nodeId, (props: any) => (props.deleteNotReq = false));
    }
  }, [getParentNodes(nodeId, query).length]);

  const presets = useElementsPresets(builderPresetElements.FAQ);

  const presetsOrProps = props?.isPresetOn ? { ...props, ...presets } : props;

  const defaultBorderRadius = getKeyByValue(
    presetsOrProps.borderRadius as string,
    ChoiceAvailableBorderRadius,
    'pixelValue'
  ).pixelValue;

  const tileBackgroundColor = props?.isPresetOn
    ? getElementColor(presets.backgroundColor, theme)
    : getElementColor(backgroundColor, theme);

  const iconColor = props?.isPresetOn ? presets.cardIconColor : props.cardIconColor;

  const icon = props?.isPresetOn ? presets.icon : props.value;

  const customBorderRadius = props?.isPresetOn ? presetsOrProps.borderRadius : defaultBorderRadius;

  return (
    <ActionsController
      {...props}
      ParentTag={RBCol}
      className={`choice-wrapper px-0 ${isMobileView ? 'mobile-choice-view' : ''}`}
      deleteNotReq={getParentNodes(nodeId, query).length === 1}
      ParentTagProps={{
        sm: 12,
        md: 12,
        ...(isMobileView && {
          xs: 12,
          sm: 12,
          md: 12,
          lg: 12
        })
      }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div
        className={`choice-inner-wrapper`}
        style={{
          borderRadius: customBorderRadius,
          background: tileBackgroundColor
        }}
      >
        <div className="faq-container">
          <div
            className={`${props.type === 'emoji' ? 'faq-icon' : ''}`}
            style={props.type === 'emoji' && props.accordionCollapse ? { marginTop: '-5px' } : {}}
            onClick={() => {
              setProp((props: any) => {
                props.accordionCollapse = !props.accordionCollapse;
              });
            }}
          >
            <Icon
              className="icon-element faq-icon"
              name={icon}
              color={iconColor}
              style={{
                color: getElementColor(iconColor, theme),
                ...(!props.accordionCollapse && { transform: 'rotate(180deg)' })
              }}
            />
          </div>
          <div className="d-flex flex-column justify-content-center ms-4 w-100">
            {children?.props?.children?.[0] || 'Default Header'}
            {props.accordionCollapse && (
              <div>{children?.props?.children?.[1] || 'Default Body Content'}</div>
            )}
          </div>
        </div>
      </div>
    </ActionsController>
  );
}

const FAQTileDefaultProps = {
  type: IconType.icon,
  value: 'IoIosArrowUp',
  borderRadius: ChoiceAvailableBorderRadius[2].pixelValue,
  backgroundColor: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT,
  cardIconColor: FUNNEL_THEME_KEYS.CARD_ICON_COLOR,
  deactivateIconButton: true,
  isPresetOn: true,
  accordionCollapse: true
};

export const FAQComponentSettings = () => {
  const { query, actions } = useEditor();
  const {
    actions: { setProp },
    props,
    childNodes
  } = useNode(node => {
    const parentID = node.data.parent;
    const SerializedNodes = query.getSerializedNodes();
    const parentNode = SerializedNodes[parentID];
    const childNodes = parentNode.nodes;
    return {
      childNodes: childNodes,
      props: node.data.props
    };
  });

  const theme = useCurrentlyEditingBuilderTheme();

  const presets = useElementsPresets(builderPresetElements.FAQ);

  const settingDisabled = props.isPresetOn;

  const presetsOrProps = props.isPresetOn ? { ...props, ...presets } : props;

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Auswahlkarte" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitleWithSwitch
              title={'Voreinstellungen übernehmen'}
              checked={presetsOrProps.isPresetOn}
              onChange={checked =>
                actions.setProp(childNodes, (props: any) => (props.isPresetOn = checked))
              }
              description={'Voreinstellungen, die du unter “Design” festgelegt hast'}
              icon={<BsGearFill />}
            />
          </Col>
        </Row>
        <Row
          className={`builder__settings-sidebar__row ${settingDisabled &&
            'disable-layer__disabled'}`}
        >
          <Col span={24}>
            <BuilderSettingsTitle title="Randradius" icon={<BiBorderRadius />} />
            <Slider
              className="builder-slider-style"
              marks={getKeyValueObjectByKeyValueArrayOfObjects(ChoiceAvailableBorderRadius)}
              min={1}
              max={3}
              tooltipVisible={false}
              value={
                getKeyByValue(
                  presetsOrProps.borderRadius,
                  ChoiceAvailableBorderRadius,
                  'pixelValue'
                ).key
              }
              onChange={value => {
                actions.setProp(
                  childNodes,
                  (props: any) =>
                    (props.borderRadius = getValueByKey(
                      // @ts-ignore
                      value,
                      ChoiceAvailableBorderRadius
                    )?.pixelValue)
                );
              }}
            />
          </Col>
        </Row>
        <Row
          className={`builder__settings-sidebar__row ${settingDisabled &&
            'disable-layer__disabled'}`}
        >
          <Col span={11}>
            <BuilderSettingsTitle title="Hintergrund" />
            <BuilderColorPickerButton
              color={getElementColor(presetsOrProps.backgroundColor, theme)}
              onChange={(backgroundColor, colorKey) => {
                actions.setProp(childNodes, (props: any) => {
                  props.backgroundColor = colorKey || backgroundColor;
                });
              }}
              showColorTypeBtns={true}
            />
          </Col>
          <Col span={11} offset={1}>
            <BuilderSettingsTitle title="Icon" />
            <BuilderColorPickerButton
              color={getElementColor(presetsOrProps.cardIconColor, theme)}
              onChange={(cardIconColor, colorKey) => {
                actions.setProp(childNodes, (props: any) => {
                  props.cardIconColor = colorKey || cardIconColor;
                });
              }}
              showColorTypeBtns={false}
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={22}>
            <Tabs
              className={`tabs__reset-left-padding ${
                !presetsOrProps.deactivateIconButton ? 'choice-tabs-disabled' : ''
              }`}
              defaultActiveKey={presetsOrProps.type}
            >
              <TabPane tab="Icons" key={IconType.icon}>
                <Row className="builder__settings-sidebar__row ">
                  <Col span={24}>
                    <BuilderSettingsTitle title="Häufig verwendet" />
                    <FrequentlyUsedIconPicker
                      value={presetsOrProps.value}
                      onChange={(value: string) => {
                        setProp((props: any) => {
                          props.value = value;
                          props.type = IconType.icon;
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="builder__settings-sidebar__row">
                  <Col span={24}>
                    <IconPickerGrid
                      onChange={value => {
                        setProp((props: any) => {
                          props.value = value;
                          props.type = IconType.icon;
                        });
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Emoji's" key={IconType.emoji} style={{ height: 600 }}>
                <EmojiPicker
                  onChange={value => {
                    setProp((props: any) => {
                      props.value = value;
                      props.type = IconType.emoji;
                    });
                  }}
                />
              </TabPane>
            </Tabs>
          </Col>
          <Col span={2}>
            <Switch
              className="mt-3"
              size="small"
              checked={presetsOrProps.deactivateIconButton}
              onChange={e => {
                actions.setProp(
                  childNodes,
                  (props: any) => (props.deactivateIconButton = !props.deactivateIconButton)
                );
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

FAQTile.craft = {
  name: CRAFT_ELEMENTS.FAQ_TILE,
  props: FAQTileDefaultProps,
  related: {
    settings: FAQComponentSettings
  }
};
