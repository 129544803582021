import lz from 'lzutf8';
import React from 'react';
import { useCraftSerializedState } from '../../../../helper/craftJs';
import { AiFillSave } from 'react-icons/ai';
import { CreateTemplateButtonProps } from '../interfaces';
import { useEditor } from '@craftjs/core';
import { getNodeIdsForTree, removeUnnecessaryNodes } from '../../../../helper/elementTemplate';
import { Tooltip } from 'antd';
import { tooltipText } from '../../../../helper/tooltipText';

function CreateTemplateButton({
  currentNode,
  setTemplateCraftState,
  setVisible
}: CreateTemplateButtonProps) {
  const {
    query,
    actions: { selectNode }
  } = useEditor();

  const setSerializedState = () => {
    selectNode('');
    const json = useCraftSerializedState(query);

    if (json[currentNode.id]) {
      json[currentNode.id].props = { ...json[currentNode.id].props, hasAdGenerationInput: false, isAiAd: false };
      delete json[currentNode.id].props.id
    }

    const filteredJson = removeUnnecessaryNodes(json, getNodeIdsForTree(json, currentNode.id));

    setTemplateCraftState(
      lz.encodeBase64(
        lz.compress(
          JSON.stringify({
            id: currentNode.id,
            nodes: filteredJson
          })
        )
      )
    );
    setVisible(true);
  };

  return (
    <Tooltip title={tooltipText.save}>
      <li onClick={setSerializedState}>
        <AiFillSave />
      </li>
    </Tooltip>
  );
}

export default CreateTemplateButton;
