import React, { useState, useEffect } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/react-hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List, Input, message } from 'antd';
import { debounce } from 'debounce';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { usePrevious } from '../../../helper/hooks';
import { UltimateImagePickerMessages } from '../../../config/messages';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { pixelImagePerPage } from '../../../constants';
import { GET_IMAGES } from '../../../hooks/useGetPexelImages';

declare global {
  interface Window {
    resetPexelesPickerValue(): any;
  }
}

function PexelesImagePicker({
  value,
  onChange,
  quality,
  height
}: {
  value?: string;
  onChange(value: string): any;
  quality?: number;
  height?: string | number;
}) {
  const [currentUrl, setCurrentUrl] = useState(value);
  const [search, setSearch] = useState({
    page: 1,
    keyword: 'mountain',
    currentImages: [],
    allLoaded: false
  });
  const { page, keyword, currentImages, allLoaded } = search;
  const [loadImages, { loading, error, data }] = useLazyQuery(GET_IMAGES);

  let newImages: any = [];
  if (data) newImages = data.getPexelsImages;

  const handleLoadImages = (directKeyword: string, directPage?: number) => {
    loadImages({
      variables: {
        filter: {
          keyword: directKeyword,
          page: directPage || page + 1,
          perPage: pixelImagePerPage,
          quality
        }
      }
    });
  };

  useEffect(() => {
    handleLoadImages(keyword);
  }, []);

  window.resetPexelesPickerValue = () => setCurrentUrl('');

  useEffect(() => {
    if (error) message.error(UltimateImagePickerMessages.couldntLoadPexelsImages);
  }, [error]);

  const prevImages = usePrevious(newImages);

  useEffect(() => {
    if (JSON.stringify(prevImages) != JSON.stringify(newImages))
      setSearch({
        ...search,
        page: page + 1,
        // @ts-ignore
        currentImages: [...currentImages, ...(newImages || [])],
        allLoaded: newImages.length < pixelImagePerPage
      });
  });

  const loadMore = () => {
    handleLoadImages(keyword);
  };

  const handleSerach = debounce((searchTerm: string) => {
    setSearch({
      ...search,
      keyword: searchTerm,
      page: 1,
      // @ts-ignore
      currentImages: [],
      allLoaded: false
    });
    handleLoadImages(searchTerm || 'mountain', 1);
  }, 200);
  return (
    <div className="image-picker">
      <Input
        onChange={(e: any) => handleSerach(e.target.value)}
        size="large"
        placeholder="Suche..."
        prefix={<SearchOutlined />}
        suffix={<img className="" src={`${process.env.PUBLIC_URL}/pexels-logo.png`} />}
      />
      <p>Tipp: Benutze auch englische Suchbegriffe</p>

      <InfiniteScroll
        dataLength={currentImages.length}
        next={loadMore}
        height={height}
        hasMore={
          currentImages.length === 0 ||
          ((currentImages.length / pixelImagePerPage) % 1 === 0 && !allLoaded)
        }
        loader={
          <div className="d-flex justify-content-center">
            <DefaultSmallLoader loading={loading} />
          </div>
        }
      >
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={currentImages}
          renderItem={({ urls, user }: any) => {
            const { small, regular } = urls;
            const active = regular === currentUrl;
            const { profile_url, name } = user;

            return (
              <List.Item>
                <div
                  className={'image-picker__image ' + (active ? 'active' : '')}
                  onClick={() => {
                    if (regular) {
                      setCurrentUrl(regular);
                      onChange({
                        ...urls,
                        custom1920: regular.replace('w=1080', 'w=1920').replace('q=80', 'q=60')
                      });
                    }
                  }}
                >
                  {active && <CheckOutlined />}
                  {small && <img src={small.replace('h=130', 'h=230')} />}
                  <div className="image-picker__image__author-info">
                    <a
                      target="_blank"
                      href={profile_url + '?utm_source=MEETOVO&utm_medium=referral'}
                    >
                      {name}
                    </a>
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
      </InfiniteScroll>
      {currentImages.length !== 0 && <DefaultSmallLoader loading={loading} makeItCenter />}
    </div>
  );
}

export default PexelesImagePicker;
