import React from 'react';
import { Element } from '@craftjs/core';
import FAQContainerHOC from './FAQContainerHOC';
import { getRawState } from '../../../../config/FAQDefaultValues';
import FAQTile, { FAQTileProps } from '../components/FAQTile';
import RichTextComponent from '../../RichTextComponent/container/RichTextComponent';
import { FAQ } from '../../../../AdBuilder/interfaces/TextTypeInterface';

export interface DefaultFAQItems {
  question: string;
  answer: string;
}

export const defaultFAQItems: DefaultFAQItems[] = [
  {
    question: 'Wann bekomme ich eine Rückmeldung zu meiner Bewerbung?',
    answer:
      'Innerhalb von 24 Stunden bis zu 3 Werktagen nehmen wir per Telefon oder E-Mail Kontakt zu dir auf, um ein erstes Kennlerngespräch zu vereinbaren.'
  },
  {
    question: 'Wie läuft der Bewerbungsprozess ab?',
    answer:
      'In der Regel starten wir mit einem kurzen telefonischen Interview. Wenn das gut verläuft, folgt ein ausführliches Vorstellungsgespräch, in dem wir uns besser kennenlernen.'
  },
  {
    question: 'Werden meine Daten vertraulich behandelt?',
    answer:
      'Ja, deine Daten werden ausschließlich für den Bewerbungsprozess genutzt. Falls du derzeit noch woanders beschäftigt bist, kannst du sicher sein, dass nur wir Zugang zu deinen Informationen haben.'
  }
];

export const getUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomChars = Math.random()
    .toString(36)
    .substring(2, 5);
  return `${timestamp}-${randomChars}`;
};

const FAQSingleTileElement = (data: DefaultFAQItems, props: FAQTileProps) => (
  <Element id={getUniqueId()} is={FAQTile} showPlusIcon={false} canDrag={true} {...props}>
    <Element
      key={1}
      id={getUniqueId()}
      is={RichTextComponent}
      elementType={FAQ.QUESTION}
      showAiTextSettings={false}
      showPlusIcon={false}
      rawState={getRawState(data.question, FAQ.QUESTION)}
      deleteNotReq={true}
      alignmentDisabled={true}
      copyNotReq={true}
    />
    <Element
      key={2}
      id={getUniqueId()}
      is={RichTextComponent}
      elementType={FAQ.ANSWER}
      showAiTextSettings={false}
      showPlusIcon={false}
      rawState={getRawState(data.answer, FAQ.ANSWER)}
      deleteNotReq={true}
      alignmentDisabled={true}
      copyNotReq={true}
    />
  </Element>
);

const completeFAQElement = (
  <Element id={getUniqueId()} is={FAQContainerHOC} onlySettingsReq={true} canvas={true}>
    {defaultFAQItems.map((single, i) => {
      return (
        <Element key={i} id={getUniqueId()} is={FAQTile} showPlusIcon={false} canDrag={true}>
          <Element
            key={1}
            id={getUniqueId()}
            is={RichTextComponent}
            elementType={FAQ.QUESTION}
            showAiTextSettings={false}
            showPlusIcon={false}
            rawState={getRawState(single.question, FAQ.QUESTION)}
            deleteNotReq={true}
            alignmentDisabled={true}
            copyNotReq={true}
          />
          <Element
            key={2}
            id={getUniqueId()}
            is={RichTextComponent}
            elementType={FAQ.ANSWER}
            showAiTextSettings={false}
            showPlusIcon={false}
            rawState={getRawState(single.answer, FAQ.ANSWER)}
            deleteNotReq={true}
            alignmentDisabled={true}
            copyNotReq={true}
          />
        </Element>
      );
    })}
  </Element>
);

export { completeFAQElement, FAQSingleTileElement };
