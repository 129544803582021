import moment from 'moment';

export const getApplicationFiltersFromQueryParams = () => {
  const query = new URLSearchParams(location.search);
  return {
    searchQuery: query.get('searchQuery') || '',
    start:
      query.get('start') ||
      moment()
        .startOf('day')
        .subtract(2, 'weeks')
        .toISOString(),
    end:
      query.get('end') ||
      moment()
        .startOf('day')
        .add(2, 'weeks')
        .toISOString(),
    statusId: query.get('statusId')
      ? query
          .get('statusId')!
          .split(',')
          .map(Number)
      : [0],
    type: query.get('type') || 'ALL',
    funnelIds: query.get('funnelIds')
      ? query
          .get('funnelIds')!
          .split(',')
          .map(Number)
      : [],
    tagIds: query.get('tagIds')
      ? query
          .get('tagIds')!
          .split(',')
          .map(Number)
      : []
  };
};
