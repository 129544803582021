import React, { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import * as htmlToImage from 'html-to-image';
import { appendStyles } from '../../../helper/craftJs';
import { useDispatch } from 'react-redux';
import { resetStylesDuringTemplateScreenshot } from '../../../components/TemplatesPanel/resetStylesDuringScreenshot';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGE } from '../../../../Funnel/helper/uploadImages';
import { defaultStringFieldValidation } from '../../../../UI/utils/formValidation';
import { createAdTemplateThunk } from '../../redux/thunk';

const CreateNewTemplate = ({
  visible = false,
  toggleVisible = (data: boolean) => {},
  templateCraftState,
  containerRef
}: {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  templateCraftState: string;
  containerRef: any;
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [savingImageSrc, setSavingImageSrc] = useState('');
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const backgroundColor = '#FFF';
  const dispatch = useDispatch();

  const handleOk = async (e: any) => {
    setLoading(true);
    uploadImage({ variables: { input: [{ imageURL: savingImageSrc }] } })
      .then(async res => {
        const imageLink = res.data.uploadImages[0].imageLink;
        if (imageLink) {
          dispatch(
            createAdTemplateThunk({
              craftState: templateCraftState,
              name: e.title,
              imageUrl: imageLink,
            })
          );
          form.resetFields();
          toggleVisible(false);
        }
      })
      .catch(e => {
        setLoading(false);
        // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (containerRef && visible) {
      appendStyles({
        _document: document,
        id: resetStylesDuringTemplateScreenshot.id,
        styles: resetStylesDuringTemplateScreenshot.style
      });

      setTimeout(() => {
        setImageLoading(true);
        htmlToImage
          .toJpeg(containerRef.childNodes[1].childNodes[4], {
            backgroundColor,
            cacheBust: true
          })
          .then(function(dataUrl) {
            setSavingImageSrc(dataUrl);
            setImageLoading(false);
          })
          .catch(e => {
            setImageLoading(false);
            // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
          });
      }, 0);
    }

    if (!visible) document.getElementById(resetStylesDuringTemplateScreenshot.id)?.remove();
  }, [visible]);

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);
  };

  return (
    <Modal
      title="Ad als Vorlage speichern"
      okText="Speichern"
      className="funnel-block-template-wrapper"
      visible={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      confirmLoading={imageLoading || loading}
      width={600}
    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={handleOk}
        autoComplete="off"
      >
        <Form.Item label="Titel" name="title" className="" rules={defaultStringFieldValidation}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateNewTemplate;
