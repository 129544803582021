import React, { useEffect, useRef, useState } from 'react';

import { Col, Dropdown, Menu, message, Row, Tooltip } from 'antd';
import { useEditor } from '@craftjs/core';
import { debounce } from 'debounce';
import { useNavigate } from 'react-router';
import ContentEditable from 'react-contenteditable';
import {
  EditOutlined,
  DeleteOutlined,
  CodeOutlined,
  BarChartOutlined,
  CopyOutlined
} from '@ant-design/icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MdDragHandle } from 'react-icons/md';
import { GrFormClose } from 'react-icons/gr';
import { RiShareForwardLine, RiBarChartLine, RiMore2Line } from 'react-icons/ri';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import CreateNewPageModal from './CreateNewPageModal';
import {
  editBuilderPage,
  setSelectedPageId,
  useAllPages,
  useBuilderPages,
  useBuilderQuestionPages,
  useBuilderSelectedPageData,
  useCommonElements,
  useContactPage,
  useDisqualificationPage,
  useThankYouPage
} from '../../redux/builderSlice';
import EmptyPageList from './EmptyPageList';
import { FaPlus } from 'react-icons/fa';
import Forwarding from '../pages/Forwarding';
import Tracking from '../pages/Tracking';
import { BuilderPageDataType } from '../../interfaces/builderSliceTypes';
import {
  addFunnelPageThunk,
  deleteFunnelPageThunk,
  duplicatePageThunk,
  reOrderBuilderPagesThunk,
  saveCompleteBuilderDataThunk,
  setPageNameThunk
} from '../../redux/thunk';
import BuilderSettingsTitle from './BuilderSettingsTitle';
import PageCustomJs from '../pages/PageCustomJs';
import { useDispatch } from 'react-redux';
import { getTextFromHtml } from '../../helper/sharedFunctions';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';
import { contactPageType, defaultCustomJS } from '../../helper/defaultValues';
import { findIfElementExistsAlready } from '../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { ContactFormMessages } from '../../../config/messages';
import { defaultContactPageCraftState } from '../../helper/defaultCraftStates';
import useSingleAndDoubleClick from '../../hooks/useSingleAndDoubleClick';
import CreateNewQuestionModal from './CreateNewQuestionModal';

const DragHandle = SortableHandle(({ className }: any) => (
  <span tabIndex={0} className={className}>
    <MdDragHandle />
  </span>
));
const SortableListItem = SortableElement(
  ({
    value,
    Index,
    onChange,
    handleDelete,
    pageData,
    handleOpen,
    isSelected,
    setPopupVisibility,
    pageOpenDisabled
  }: any) => {
    const fieldRef = useRef<HTMLInputElement | null>(null);
    const [toBeDeleted, setToBeDeleted] = useState(false);
    const [contentEditableIsDisabled, setContentEditableIsDisabled] = useState(true);
    const [pageName, setPageName] = useState(value);
    const dispatch = useDispatch();
    const allPages = useAllPages();
    const disablePageCloning = findIfElementExistsAlready([pageData], CRAFT_ELEMENTS.CONTACT_FORM);

    useEffect(() => {
      if (!contentEditableIsDisabled) {
        fieldRef.current?.focus();

        // Move cursor to the end of the input field
        const element = fieldRef.current;
        const range = document.createRange();
        const sel = window.getSelection();
        if (element) {
          const childNode = element.childNodes[0];
          if (childNode.nodeType !== 3) {
            return;
          }
          range.setStart(childNode, element?.textContent?.length || 0);
          range.collapse(true);
          sel?.removeAllRanges();
          sel?.addRange(range);
        }
      }
    }, [contentEditableIsDisabled]);

    const handleNameChange = debounce((pageName: string) => {
      setPageName(pageName);
      if (pageName) onChange(pageName);
    }, 1000);

    const containerRef = useRef(null);

    useOutsideAlerter(containerRef, () => {
      setContentEditableIsDisabled(true);
    });

    const handleSingleClick = () => {
      !pageOpenDisabled && contentEditableIsDisabled && handleOpen();
    };

    const handleDoubleClick = () => {
      setContentEditableIsDisabled(false);
    };

    const handleClick = useSingleAndDoubleClick(handleSingleClick, handleDoubleClick, 250);

    return (
      <div
        className={`page-name__wrapper  ${isSelected && 'active'} ${contentEditableIsDisabled &&
          ' cursor-pointer'}`}
        style={
          pageOpenDisabled && !isSelected
            ? { cursor: 'not-allowed', pointerEvents: 'none' }
            : { userSelect: 'none' }
        }
      >
        <DragHandle className="drag-handle" />
        <span className={`page-number`}>{Index + 1}. </span>
        <span ref={containerRef} className="page-name">
          <ContentEditable
            data-testid="page-name-editable"
            innerRef={fieldRef}
            html={`${pageName}`}
            disabled={contentEditableIsDisabled}
            onChange={(e: any) => {
              const value = getTextFromHtml(e.target.value)!;
              const updatedName = value.replace(/^\d+\.\s*/, '');
              handleNameChange(updatedName || '');
            }}
            tagName="p"
            className={'page-name'}
            onClick={handleClick}
            onKeyDown={e => e.key === 'Enter' && setContentEditableIsDisabled(true)}
          />
        </span>

        {toBeDeleted ? (
          <div className="d-flex align-items-center">
            <GrFormClose
              size={'20'}
              className="cursor-pointer me-1"
              color="#212B6D"
              onMouseDown={() => {
                setToBeDeleted(false);
              }}
            />
            <div
              className="d-flex align-items-center cursor-pointer"
              style={{ color: 'red' }}
              onMouseDown={() => {
                handleDelete();
              }}
            >
              <DeleteOutlined />
              Bestätigen
            </div>
          </div>
        ) : (
          <Dropdown
            placement="bottomLeft"
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    setContentEditableIsDisabled(false);
                  }}
                  className="d-flex align-items-center"
                >
                  <EditOutlined className="me-1" /> Umbenennen
                </Menu.Item>
                <Menu.Item
                  className="d-flex align-items-center"
                  onClick={() => {
                    setPopupVisibility({ type: VisibilityModalType.TRACKING });
                  }}
                >
                  <BarChartOutlined className="me-1" /> Tracking
                </Menu.Item>
                <Menu.Item
                  className="d-flex align-items-center"
                  onClick={() => {
                    setPopupVisibility({ type: VisibilityModalType.CUSTOM_JS, pageData: value });
                  }}
                >
                  <CodeOutlined className="me-1" /> Eigener Code
                </Menu.Item>
                <Menu.Item
                  className={`d-flex align-items-center ${disablePageCloning && 'deactive'}`}
                  onClick={() => {
                    !disablePageCloning &&dispatch(duplicatePageThunk({ id: pageData.id }));
                  }}
                >
                  <Tooltip title={disablePageCloning && ContactFormMessages.disablePageCloningWithContactPage}>
                    <div className="d-flex align-items-center">
                      <CopyOutlined className="me-1" /> Duplizieren
                    </div>
                  </Tooltip>
                </Menu.Item>

                <Menu.Item
                  className="d-flex align-items-center"
                  style={{ color: 'red' }}
                  onClick={() => {
                    setToBeDeleted(true);
                  }}
                >
                  <DeleteOutlined /> Löschen
                </Menu.Item>
              </Menu>
            }
          >
            <RiMore2Line className="icon-btn cursor-pointer" size={25} />
          </Dropdown>
        )}
      </div>
    );
  }
);

const SortableListContainer = SortableContainer(({ children }: any) => {
  return <div className="sortable-container">{children}</div>;
});

enum VisibilityModalType {
  FORWARDING = 'FORWARDING',
  TRACKING = 'TRACKING',
  CUSTOM_JS = 'CUSTOM_JS'
}

const PagesList = () => {
  const [pageOpenDisabled, setPageOpenDisabled] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalVisibility, setModalVisibility] = useState<
    undefined | { type: VisibilityModalType; pageData: BuilderPageDataType | undefined }
  >();
  const builderPages = useBuilderPages();
  const allPages = useAllPages();
  const builderQuestionPages = useBuilderQuestionPages();
  const thankYouPage = useThankYouPage();
  const contactPage = useContactPage();
  const disqualificationPage = useDisqualificationPage();
  const dispatch = useAppDispatch();
  const selectedPageData = useBuilderSelectedPageData();
  const contactFormExistsInAnotherPage = findIfElementExistsAlready(
    allPages,
    CRAFT_ELEMENTS.CONTACT_FORM
  );
  const selectedPageDataRef = useRef(selectedPageData);
  const [enteredCommonPages, setEnteredCommonPages] = useState<boolean>(false);
  const [enteredQuestionPages, setEnteredQuestionPages] = useState<boolean>(false);
  const [pageCreationType, setPageCreationType] = useState<string>('');
  const [dragging, setDragging] = useState<boolean>(false);
  const query = useEditor();

  const pagesExisting = builderPages.length > 0 || builderQuestionPages.length > 0;

  const createContactPage = async () => {
    await dispatch(
      addFunnelPageThunk({
        name: 'Dateneingabe',
        craftState: defaultContactPageCraftState,
        customJs: defaultCustomJS,
        type: contactPageType,
        pageOrder: builderPages.length ? builderPages[builderPages.length - 1].pageOrder + 1 : 1,
        query: query
      })
    );
  };

  useEffect(() => {
    if (!contactPage) (async () => await createContactPage())();
  }, []);

  useEffect(() => {
    selectedPageDataRef.current = selectedPageData;
  }, [selectedPageData]);

  useEffect(() => {
    if (!pageOpenDisabled) return;
    setTimeout(() => setPageOpenDisabled(false), 1500);
  }, [pageOpenDisabled]);

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    setDragging(false)
    if (oldIndex === newIndex && !enteredCommonPages && !enteredQuestionPages) return;

    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.ON_SORT_END_IN_PAGES_LIST,
      additionalData: {
        orderData: { oldIndex, newIndex }
      }
    });

    const pageType = enteredCommonPages ? 'COMMON' : enteredQuestionPages ? 'QUESTION' : undefined;

    await dispatch(saveCompleteBuilderDataThunk());
    dispatch(reOrderBuilderPagesThunk({ oldIndex, newIndex, pageType }));
  };

  const handlePageOpen = async (value: BuilderPageDataType) => {
    window.currentStepForSentryDebugging = 1;
    if (pageOpenDisabled) return;
    window.currentStepForSentryDebugging = 2;
    setPageOpenDisabled(true);
    window.currentStepForSentryDebugging = 3;

    await dispatch(saveCompleteBuilderDataThunk());
    window.currentStepForSentryDebugging = 4;
    dispatch(setSelectedPageId(value.id));
    window.currentStepForSentryDebugging = 5;
    document.getElementsByClassName('builder__content')?.[0]?.scrollTo(0, 0);
  };

  const handleOpenCreatePage = async (pageType: string) => {
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_OPEN_CREATE_PAGE_IN_PAGES_LIST
    });

    await dispatch(saveCompleteBuilderDataThunk());
    setPageCreationType(pageType);
    setVisible(true);
  };

  const handleDeletePage = async (pageData: BuilderPageDataType) => {
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_DELETE_PAGE_IN_PAGES_LIST,
      additionalData: { id: pageData.id }
    });

    await dispatch(saveCompleteBuilderDataThunk());
    dispatch(deleteFunnelPageThunk({ id: pageData.id }));
  };

  const handleChangePageName = (pageData: BuilderPageDataType, name: string) => {
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_CHANGE_PAGE_NAME_IN_PAGES_LIST,
      additionalData: { pageData, name }
    });
    dispatch(setPageNameThunk({ id: pageData.id, name }));
  };

  return (
    <div className="builder__settings-sidebar__container page-list overflow-auto">
      <label className="builder__settings-sidebar__container__simple-title">Seitenübersicht</label>
      {pagesExisting && (
        <Row className="builder__settings-sidebar__row px-4" aria-disabled>
          <Col span={24}>
            <BuilderSettingsTitle
              title="Seiten"
              rightAlignedElement={
                <FaPlus className="green-text" onClick={() => handleOpenCreatePage('COMMON')} />
              }
            />
            <SortableListContainer
              pressDelay={1}
              useDragHandle
              onSortEnd={onSortEnd}
              helperClass="sortable-helper"
              onSortStart={() => { setDragging(true) }}
            >
              <div
                className={`${enteredCommonPages && dragging && "dropable-area-animate"}`}
                onMouseEnter={() => setEnteredCommonPages(true)}
                onMouseLeave={() => setEnteredCommonPages(false)}
              >
                {builderPages.length ? (
                  builderPages.map((value, index) => (
                    <SortableListItem
                      setPopupVisibility={({ type }: { type: VisibilityModalType }) => {
                        setModalVisibility({ type, pageData: value });
                      }}
                      key={`item-${value.id}-${pageOpenDisabled}`}
                      index={index}
                      Index={index}
                      value={value.name}
                      pageData={value}
                      pageOpenDisabled={pageOpenDisabled}
                      handleOpen={() => handlePageOpen(value)}
                      handleDelete={() => handleDeletePage(value)}
                      isSelected={value.id === selectedPageData?.id}
                      onChange={(name: string) => handleChangePageName(value, name)}
                    />
                  ))
                ) : (
                  <p>Mit der Schaltfläche + können Sie Seiten hinzufügen.</p>
                )}
              </div>

              <BuilderSettingsTitle
                title="Fragen"
                rightAlignedElement={
                  <FaPlus className="green-text" onClick={() => handleOpenCreatePage('QUESTION')} />
                }
              />
              <div
                className={`${enteredQuestionPages && dragging && "dropable-area-animate"}  builder__settings-sidebar__row px-4`}
                onMouseEnter={() => setEnteredQuestionPages(true)}
                onMouseLeave={() => setEnteredQuestionPages(false)}
              >
                {builderQuestionPages.length ? (
                  builderQuestionPages.map((value, index) => {
                    return (
                      <SortableListItem
                        setPopupVisibility={({ type }: { type: VisibilityModalType }) => {
                          setModalVisibility({ type, pageData: value });
                        }}
                        key={`item-${value.id}-${pageOpenDisabled}`}
                        index={builderPages.length + index}
                        Index={index}
                        value={value.name}
                        pageData={value}
                        pageOpenDisabled={pageOpenDisabled}
                        handleOpen={() => handlePageOpen(value)}
                        handleDelete={() => handleDeletePage(value)}
                        isSelected={value.id === selectedPageData?.id}
                        onChange={(name: string) => handleChangePageName(value, name)}
                      />
                    );
                  })
                ) : (
                  <p>Mit der Schaltfläche + können Sie Seiten in Fragen hinzufügen.</p>
                )}
              </div>
            </SortableListContainer>
          </Col>
        </Row>
      )}

      {pagesExisting && (
        <Row className="builder__settings-sidebar__row px-4">
          <BuilderSettingsTitle title="Abschluss" />
          {[contactPage, disqualificationPage, thankYouPage].map(page => {
            const contactPage = page?.type == contactPageType;
            const disableCraftPage = contactPage && contactFormExistsInAnotherPage;
            const tootlTipTitle = disableCraftPage
              ? ContactFormMessages.duplicateContactFormTitle
              : '';

            if (!page) return;

            return (
              <Col span={24}>
                <div
                  className={`page-name__wrapper ${disableCraftPage &&
                    'deactive'} ${selectedPageData?.id === page?.id && 'active'}`}
                >
                  <Tooltip placement="topLeft" title={tootlTipTitle} key={page?.name}>
                    <p
                      className={`page-name cursor-pointer`}
                      onClick={() => {
                        !pageOpenDisabled &&
                          page &&
                          !disableCraftPage &&
                          handlePageOpen(page as any);
                      }}
                    >
                      {page?.name}
                    </p>
                  </Tooltip>
                  <Dropdown
                    placement="bottomLeft"
                    disabled={disableCraftPage && contactPage}
                    overlay={
                      <Menu className={`meetovo-funnel-builder-popover`}>
                        <Menu.Item
                          onClick={() => {
                            setModalVisibility({
                              type: VisibilityModalType.FORWARDING,
                              pageData: page
                            });
                          }}
                          className="d-flex align-items-center"
                        >
                          <RiShareForwardLine className="me-1" /> Weiterleitung
                        </Menu.Item>
                        <Menu.Item
                          className="d-flex align-items-center"
                          onClick={() => {
                            setModalVisibility({
                              type: VisibilityModalType.TRACKING,
                              pageData: page
                            });
                          }}
                        >
                          <RiBarChartLine className="me-1" /> Tracking
                        </Menu.Item>
                        <Menu.Item
                          className="d-flex align-items-center"
                          onClick={() => {
                            setModalVisibility({
                              type: VisibilityModalType.CUSTOM_JS,
                              pageData: page
                            });
                          }}
                        >
                          <CodeOutlined className="me-1" /> Eigener Code
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <RiMore2Line className="icon-btn cursor-pointer" size={25} />
                  </Dropdown>
                </div>
              </Col>
            );
          })}
        </Row>
      )}

      {!pagesExisting && <EmptyPageList />}

      {pageCreationType === 'COMMON' ? (
        <CreateNewPageModal
          visible={visible}
          toggleVisible={setVisible}
          pageType={pageCreationType}
        />
      ) : (
        <CreateNewQuestionModal visible={visible} toggleVisible={setVisible} />
      )}

      <Forwarding
        visible={modalVisibility?.type === VisibilityModalType.FORWARDING}
        toggleVisible={val => setModalVisibility(undefined)}
        pageData={modalVisibility?.pageData}
      />
      <Tracking
        visible={modalVisibility?.type === VisibilityModalType.TRACKING}
        toggleVisible={val => setModalVisibility(undefined)}
        pageData={modalVisibility?.pageData}
      />
      <PageCustomJs
        visible={modalVisibility?.type === VisibilityModalType.CUSTOM_JS}
        toggleVisible={val => setModalVisibility(undefined)}
        pageData={modalVisibility?.pageData}
      />
    </div>
  );
};

export default PagesList;
