import React, { useRef, useEffect, useState, ChangeEvent } from 'react';
import { Button, Input, Popover } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  selectFunnelSearchValue,
  setFunnelSearchValue
} from '../../Funnel/redux/funnelSlice';
import { FiFilter } from 'react-icons/fi';
const { Search } = Input;

const FunnelSearch = () => {
  const searchInputRef = useRef<any | null>(null);

  const searchValue = useAppSelector(selectFunnelSearchValue);

  const dispatch = useAppDispatch();
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    dispatch(setFunnelSearchValue(value));
  };

  return (
    <div className="funnel-search hidden-on__max-sm d-flex align-items-center" style={{ columnGap: 8 }}>
      <Popover
        className="default-popover"
        placement="top"
        content={
          <Search
            value={searchValue}
            allowClear
            autoFocus
            ref={searchInputRef}
            onChange={handleSearch}
            placeholder="Funnel suchen..."
          />
        }
        trigger="click"
      >
        <Button
          className="ant-btn__without-background m-0 agency-dashboard-brand-color"
          type="ghost"
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (searchInputRef.current) {
              setTimeout(() => {
                searchInputRef.current.focus();
              }, 0)
            }
          }}
          icon={<FiFilter size={22} />}
        >
        </Button>
      </Popover>
    </div>
  );
};

FunnelSearch.propTypes = {};

export default FunnelSearch;
