import React, { useEffect, useState } from 'react';
import lz from 'lzutf8';
import { Button, Modal } from 'antd';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { getCraftNodeProps } from '../../../helper/craftJs';
import ReadMore from '../component/ReadMore';
import { BsFillTrashFill } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { ExclamationCircleOutlined, ArrowRightOutlined } from '@ant-design/icons';
import PostHeader from '../component/PostHeader';
import PostFooterHOC from '../component/PostFooter';
import { textTypesEnum } from '../../interfaces/TextTypeInterface';
import { deleteAdTemplateThunk } from '../../redux/thunk';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';
import { useDeleteAdTemplateLoading } from '../../redux/adBuilderSlice';
interface Props {
  id: number;
  name: string;
  craftState: string;
  imageUrl: string;
  default?: boolean;
  handleTemplateSelection: () => void;
}

export default function TemplatePreview({
  id,
  name,
  craftState,
  imageUrl,
  handleTemplateSelection,
  ...props
}: Props) {
  const [deleteLocalLoading, setDeleteLocalLoading] = useState(false);
  const deleteAdTemplateLoading = useDeleteAdTemplateLoading();
  const dispatch = useDispatch();
  const serializedNode = JSON.parse(lz.decompress(lz.decodeBase64(craftState)));

  const plainTexts = getCraftNodeProps(serializedNode.nodes, CRAFT_ELEMENTS.PLAIN_TEXT);
  const plainText = plainTexts
    .filter(text =>
      [textTypesEnum.CTA, textTypesEnum.MAIN_TEXT, textTypesEnum.TEASER].includes(text.elementType)
    )
    .reduce(
      (total, currentValue) =>
        `${
          total
            ? `${total}
${currentValue.plainText}`
            : currentValue.plainText
        }`,
      ''
    );

  useEffect(() => {
    !deleteAdTemplateLoading && deleteLocalLoading && setDeleteLocalLoading(false);
  }, [deleteAdTemplateLoading]);

  const headlineText = plainTexts.filter(text =>
    [textTypesEnum.HEADLINE].includes(text.elementType)
  )[0].plainText;

  const handleTemplateDelete = () => {
    setDeleteLocalLoading(true);
    dispatch(deleteAdTemplateThunk(id));
  };

  const confirm = () => {
    Modal.confirm({
      title: 'Löschen',
      icon: <ExclamationCircleOutlined />,
      content: 'Bist du sicher, dass du diese Vorlage löschen möchtest?',
      okText: 'Löschen',
      cancelText: 'Abbrechen',
      onOk: handleTemplateDelete
    });
  };

  return (
    <div className="ads-builder-template__wrapper">
      <div className="ads-builder-template__label-wrapper">
        <label className="ads-builder-template__label">{name}</label>
        {!props.default && (
          <div className="trash-icon-wrapper">
            <DefaultSmallLoader loading={deleteLocalLoading} />
            {!deleteLocalLoading && (
              <div className="trash-icon">
                <BsFillTrashFill onClick={confirm} />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="ads-builder-template">
        <PostHeader />
        <ReadMore>{plainText}</ReadMore>
        <img src={imageUrl} />
        <PostFooterHOC>
          <h3>{headlineText}</h3>
        </PostFooterHOC>
      </div>
      <Button type="link" block onClick={handleTemplateSelection}>
        Vorlage verwenden <ArrowRightOutlined />
      </Button>
    </div>
  );
}
