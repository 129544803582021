import React, { useEffect, useState } from 'react';
import {
  convertMinutesToHoursMinutes,
  convertMinutesToWeeksDaysHoursMinutes
} from '../../helper/sharedFunctions';
import ScheduledSelectList from './ScheduledSelectList';
import { reminderNotificationSelectListData, scheduledNotificationSelectListData } from './utils';
import { SelectedScheduledTime, convertTimeToMinutes } from '../../helper/convertTimeToMinutes';
import {
  EmailTemplateType,
  EmailType,
  MessagingTemplateTabType,
  MessagingType
} from '../../interfaces/builderSliceTypes';
import { useAppDispatch } from '../../../redux/hooks';
import { editFunnelSavedIndicator, useIsFunnelSaved } from '../../redux/builderSlice';

export default function ScheduledNotificationTemplateSelectList({
  scheduledTime,
  timeInMinutesTillEvent,
  onChange,
  data,
  templateType
}: {
  scheduledTime: number | undefined;
  timeInMinutesTillEvent: number | undefined;
  onChange: (e: EmailTemplateType | MessagingTemplateTabType) => void;
  data: EmailTemplateType | MessagingTemplateTabType;
  templateType: EmailType | MessagingType;
}) {
  const dispatch = useAppDispatch();
  const isSaved = useIsFunnelSaved();
  const [selectedScheduledTime, setSelectedScheduledTime] = useState<SelectedScheduledTime>({
    days: 0,
    weeks: 0,
    hours: 0,
    minutes: 0
  });
  const isScheduledType =
    templateType === EmailType.SCHEDULED || templateType === MessagingType.SCHEDULED;

  const listData = isScheduledType
    ? scheduledNotificationSelectListData
    : reminderNotificationSelectListData; // only show hours and minutes for reminder template

  useEffect(() => {
    if (scheduledTime) {
      const convertedTime = convertMinutesToWeeksDaysHoursMinutes(scheduledTime);
      setSelectedScheduledTime(convertedTime);
    } else if (timeInMinutesTillEvent) {
      const convertedTime = convertMinutesToHoursMinutes(timeInMinutesTillEvent);
      setSelectedScheduledTime(prev => ({ ...prev, ...convertedTime }));
    }
  }, [scheduledTime, timeInMinutesTillEvent]);

  return (
    <ScheduledSelectList
      selectListData={listData}
      handleChange={(value: number, key: string) => {
        const convertedTime = convertTimeToMinutes({ ...selectedScheduledTime, [key]: value });
        onChange({
          ...data,
          [isScheduledType ? 'scheduledTime' : 'timeInMinutesTillEvent']: convertedTime
        });
        setSelectedScheduledTime(prevSelectedTime => ({
          ...prevSelectedTime,
          [key]: value
        }));
        if (isSaved) {
          dispatch(editFunnelSavedIndicator(false));
        }
      }}
      selectedTime={selectedScheduledTime}
    />
  );
}
