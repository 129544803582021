import { convertDraftStateToHtml, useCraftSerializedState } from '../../helper/craftJs';
import { getNodeIdsForTree, removeUnnecessaryNodes } from '../../helper/elementTemplate';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import lz from 'lzutf8';

export const checkIfMediaIsSame = (oldState: string, newState: string): boolean => {
  let serializedNodeOLD = JSON.parse(lz.decompress(lz.decodeBase64(oldState)));
  let serializedNodeNEW = JSON.parse(lz.decompress(lz.decodeBase64(newState)));

  const oldProps = getMediaProps(serializedNodeOLD);
  const newProps = getMediaProps(serializedNodeNEW);

  const checkIfSame = Object.keys(oldProps).find(key => {
    // @ts-ignore
    if (JSON.stringify(oldProps[key]) === JSON.stringify(newProps[key])) {
      return false;
    } else {
      return true;
    }
  });

  return !checkIfSame;
};

export const getMediaProps = (serializedNode: any) => {
  const nodes = serializedNode.nodes;
  const nodeProps = {};
  Object.keys(nodes).map(key => {
    if (nodes[key].displayName === CRAFT_ELEMENTS.RICH_TEXT) {
      nodes[key].props.html = convertDraftStateToHtml(
        nodes[key].props.editorState,
        nodes[key].props.exporter,
        nodes[key].props.html
      );
      delete nodes[key].props.editorState;
      delete nodes[key].props.styles;
      delete nodes[key].props.rawState;
      delete nodes[key].props.id;
      // @ts-ignore
      nodeProps[nodes[key].props.textType] = nodes[key].props;
    } else {
      delete nodes[key].props.id;
      delete nodes[key].props.templateId;
      // @ts-ignore
      nodeProps[nodes[key].displayName] = nodes[key].props;
    }
  });
  return nodeProps;
};

export const getCurrentContentState = (query: any, currentNode: any) => {
  const json = useCraftSerializedState(query);
  const filteredJson = removeUnnecessaryNodes(json, getNodeIdsForTree(json, currentNode.id));

  return lz.encodeBase64(
    lz.compress(
      JSON.stringify({
        id: currentNode.id,
        nodes: filteredJson
      })
    )
  );
};

export const isAdBuilderPath = () => {
  return !!location.pathname.includes(`/ad-builder`);
};
