import React, { useEffect, useState } from 'react';
import lz from 'lzutf8';
import { Form, Input, message, Modal, Select } from 'antd';
import * as htmlToImage from 'html-to-image';
import addFunnelTemplate, { FunnelTemplateTypes } from '../../graphql/addFunnelTemplate';
import { useMutation } from '@apollo/react-hooks';
import { UPLOAD_IMAGE } from '../../../Funnel/helper/uploadImages';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import {
  CreateNewTemplateMessages,
  GeneralMessages,
  UpdateTemplateMessages
} from '../../../config/messages';
import useGetAllFunnelTemplate from '../../hooks/useGetAllFunnelTemplate';
import { appendStyles, useCraftSerializedState } from '../../helper/craftJs';
import { resetStylesDuringTemplateScreenshot } from './resetStylesDuringScreenshot';
import { defaultStringFieldValidation } from '../../../UI/utils/formValidation';
import { Node, useEditor } from '@craftjs/core';
import { getNodeIdsForTree, removeUnnecessaryNodes } from '../../helper/elementTemplate';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import updateFunnelTemplate from '../../graphql/updateFunnelTemplate';

const CreateAndUpdateBlockTemplate = ({
  visible = false,
  toggleVisible = (data: boolean) => {},
  containerRef,
  currentNode,
  editTemplateDate
}: {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  containerRef?: any;
  currentNode?: Node;
  editTemplateDate?: { title: string; id: number; tags: string[] };
}) => {
  const {
    query,
    actions: { selectNode }
  } = useEditor();
  const [form] = Form.useForm();
  const [templateCraftState, setTemplateCraftState] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const { data, loading: funnelTemplatesLoading, refetch } = useGetAllFunnelTemplate();

  useEffect(() => {
    if (visible && !editTemplateDate && currentNode) {
      selectNode('');
      const json = useCraftSerializedState(query);
      const filteredJson = removeUnnecessaryNodes(json, getNodeIdsForTree(json, currentNode.id));

      setTemplateCraftState(
        lz.encodeBase64(
          lz.compress(
            JSON.stringify({
              id: currentNode.id,
              nodes: filteredJson
            })
          )
        )
      );
    }
  }, [visible]);

  useEffect(() => {
    if (editTemplateDate)
      form.setFieldsValue({
        title: editTemplateDate.title,
        tags: editTemplateDate.tags
      });
  }, [editTemplateDate]);

  useEffect(() => {
    if (!editTemplateDate) {
      if (containerRef && visible) {
        appendStyles({
          _document: document,
          id: resetStylesDuringTemplateScreenshot.id,
          styles: resetStylesDuringTemplateScreenshot.style
        });

        setTimeout(() => {
          setImageLoading(true);
          htmlToImage
            .toJpeg(containerRef, { cacheBust: true })
            .then(function(dataUrl) {
              setImageSrc(dataUrl);
              setImageLoading(false);
            })
            .catch(e => {
              console.error(e);
              // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
              setImageLoading(false);
            });
        }, 0);
      }

      if (!visible) document.getElementById(resetStylesDuringTemplateScreenshot.id)?.remove();
    }
  }, [visible]);

  const onSuccess = () => {
    form.resetFields();
    toggleVisible(false);
    refetch();
  };
  const handleOk = async (e: any) => {
    setLoading(true);
    if (editTemplateDate) {
      updateFunnelTemplate({
        ...e,
        id: editTemplateDate.id
      })
        .then(() => {
          onSuccess();
          message.success(UpdateTemplateMessages.templateBlockUpdatedSuccessfully);
        })
        .catch(() => {
          message.error(GeneralMessages.error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      uploadImage({ variables: { input: [{ imageURL: imageSrc }] } })
        .then(async res => {
          const imageLink = res.data.uploadImages[0].imageLink;
          if (imageLink) {
            addFunnelTemplate({
              ...e,
              public: false,
              image: imageLink,
              craftState: templateCraftState,
              type: FunnelTemplateTypes.CONTAINER
            })
              .then(() => {
                onSuccess();
                message.success(CreateNewTemplateMessages.templateBlockCreatedSuccessfully);
              })
              .catch(() => {
                message.error(GeneralMessages.error);
              })
              .finally(() => {
                setLoading(false);
              });
          }
        })
        .catch(e => {
          setLoading(false);
          // cosmetic fix for beta that user don't see screenshots are sometimes not created message.error(CreateNewTemplateMessages.itWasNotPossibleToCreateAFunnelScreenshot);
        });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);
  };

  const tags: string[] = Array.from(
    new Set(data?.funnelTemplates.map((template: { tags: string[] }) => template.tags).flat())
  );

  return (
    <>
      <DefaultLoader loading={funnelTemplatesLoading} />
      <Modal
        title="Als Vorlage speichern"
        okText="Speichern"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={600}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleOk}
          autoComplete="off"
        >
          <Form.Item
            label="Titel"
            name="title"
            className="mb-1"
            rules={defaultStringFieldValidation}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Tags" name="tags" className="mb-1">
            <Select
              mode="tags"
              placeholder="z.B. Vorteile, Kunde XYZ ..."
              options={tags.map(tag => ({ label: tag, value: tag }))}
            />
          </Form.Item>
          {!editTemplateDate && (
            <>
              <div className="loading-wrapper">
                <DefaultSmallLoader loading={imageLoading} />
              </div>
              {!imageLoading && <img src={imageSrc} />}
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};
export default CreateAndUpdateBlockTemplate;
