import React from 'react';
import { AI_PROMPT_TEXT_TYPES } from '../../../../hooks/ai-conversation/getAiTextResponse';
import { mediaTypeEnum } from '../../../../AdBuilder/interfaces/TextTypeInterface';

export type AiTextSettingsProps = {
  editorState: any;
  showOptionPicker?:boolean;
  elementType?:mediaTypeEnum
};

export const textTypeOptions = [
  {
    label: 'Überschrift',
    value: AI_PROMPT_TEXT_TYPES.HEADLINE,
    icon: <img src="/dashboard/funnel-builder/headline.svg" />
  },
  {
    label: 'Vorteil',
    value: AI_PROMPT_TEXT_TYPES.ADVANTAGES,
    icon: <img src="/dashboard/funnel-builder/benefit.svg" />
  },
  {
    label: 'Aufgabe',
    value: AI_PROMPT_TEXT_TYPES.TASK,
    icon: <img src="/dashboard/funnel-builder/tasks.svg" />
  },
  {
    label: 'Über uns',
    value: AI_PROMPT_TEXT_TYPES.ABOUT_US,
    icon: <img src="/dashboard/funnel-builder/about-us.svg" />
  },
  {
    label: 'Frage',
    value: AI_PROMPT_TEXT_TYPES.ASK,
    icon: <img src="/dashboard/funnel-builder/question.svg" />
  },
  {
    label: 'Frei',
    value: AI_PROMPT_TEXT_TYPES.FREE,
    icon: <img src="/dashboard/funnel-builder/idea.svg" />
  }
];

export const textTypeDefaultLength = [
  { type: AI_PROMPT_TEXT_TYPES.HEADLINE, min: 5, max: 15 },
  { type: AI_PROMPT_TEXT_TYPES.ADVANTAGES, min: 30, max: 50 },
  { type: AI_PROMPT_TEXT_TYPES.TASK, min: 30, max: 50 },
  { type: AI_PROMPT_TEXT_TYPES.ABOUT_US, min: 100, max: 250 },
  { type: AI_PROMPT_TEXT_TYPES.ASK, min: 10, max: 20 },
  { type: AI_PROMPT_TEXT_TYPES.FREE, min: 20, max: 50 }
];

export const commandPlaceholderByTextType = {
  [AI_PROMPT_TEXT_TYPES.HEADLINE]: 'z.B. Hauptüberschrift auf der ersten Seite',
  [AI_PROMPT_TEXT_TYPES.ADVANTAGES]: 'z.B. Weihnachtsgeld',
  [AI_PROMPT_TEXT_TYPES.TASK]: 'z.B. Social-Media-Posts erstellen',
  [AI_PROMPT_TEXT_TYPES.ABOUT_US]: 'z.B. betone unsere Remote-first-Kultur',
  [AI_PROMPT_TEXT_TYPES.ASK]: 'z.B. herausfinden wie der Bewerber Probleme löst',
  [AI_PROMPT_TEXT_TYPES.FREE]: 'z.B. verschlüsselte Dateneingabe und Löschung nach 7 Tagen'
};

export const messages: any = {
  isSomeValueMissing:
    'Um die Text KI zu nutzen, musst du die Zielgruppen, KI und Job-Information hinterlegen. Dies sorgt für Ergebnisse, die genau auf deine Zielgruppe ausgerichtet sind.',
  isQuotaExceeded:
    'Dein Kontingent ist erschöpft. Bitte mache ein Upgrade oder melde dich im Support-Chat.',
  isInTrial: 'In der Testphase ist der Text KI leider nicht verfügbar.',
  isAdGeneratedInputMissing : "Um die Text-KI nutzen zu können, müssen Sie die Anzeigeninformationen speichern."
};

export function tokensToWords(tokens: number) {
  const wordCount = Math.floor(tokens * 0.75);
  return wordCount;
}
