import gql from 'graphql-tag';
import { AD_TYPES } from '../AdBuilder/Templates/container/TemplateTypesView';

export interface AdGeneration {
  id?: number;
  nodeId?: string;
  employeeTestimonialName?: string;
  promptMessage?: string;
  alignment?: string;
  purpose?: string;
  type?: AD_TYPES;
  regenerateAd?: Boolean;
}

const SET_AD_GENERATION_INPUT = gql`
  mutation SetAdGenerationInput($input: SetAdGenerationInput!) {
    setAdGenerationInput(input: $input) {
      id
      nodeId
      employeeTestimonialName
      promptMessage
      alignment
      purpose
      type
    }
  }
`;

export function setAdGenerationInput(data: AdGeneration) {
  return window.apolloClient.mutate({
    mutation: SET_AD_GENERATION_INPUT,
    variables: {
      input: data
    }
  });
}
