import React, { useEffect, useRef, useState } from 'react';
import { Alert, Card, Col, Row, Slider, Tabs } from 'antd';
import Draft, { ContentBlock, convertFromRaw, DraftHandleValue, EditorState } from 'draft-js';
import { useEditor, useNode } from '@craftjs/core';
// @ts-expect-error
import createStyles from 'draft-js-custom-styles';
import { FaTextHeight } from 'react-icons/fa';

import { ActionsController } from '../sharedUI/ActionsController';
import useOutsideAlerter from '../../../hooks/useOutsideAlerter';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { defaultFontSize, FontAvailableSizes } from '../../../interfaces/FontSizeInterface';
import { getKeyByValue, getValueByKey } from '../../../helper/craftJs';
import TextAlignmentButtons from '../../../../SharedUI/components/TextAlignmentButtons';
import FontStyleButtons from '../../../../SharedUI/components/FontStyleButtons';
import BuilderColorPickerButton from '../../../components/sharedUI/BuilderColorPickerButton';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import EmojiPicker from '../../../../GeneralComponents/EmojiPicker';
import { insertCharacterToEditor } from '../../../helper/sharedFunctions';
import { RichTextComponentMessages } from '../../../../config/messages';
import { useCurrentlyEditingBuilderTheme } from '../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { CraftElementBaseProps } from './ImageComponent';
import { elementTypeObj, mediaTypeEnum } from '../../interfaces/TextTypeInterface';
import {
  initializeDraftWithContent,
  RICH_TEXT_SETTINGS_VALUE,
  useElementSubSettings
} from '../../../helper/richTextHelpers';
import { useSearchParams } from 'react-router-dom';
import AiTextSettings from '../../../components/elements/RichTextComponent/components/AiTextSettings';
import { isWidgetBuilderPath } from '../../../WidgetsBuilder/helper/helper';

const { Editor, RichUtils } = Draft;
const { TabPane } = Tabs;

interface Props extends CraftElementBaseProps {
  editorState?: any;
  rawState?: any;
  onlySettingsReq?: boolean;
  textType?: string;
  elementType: string;
}
const RichTextComponent = ({ editorState, rawState, onlySettingsReq, elementType }: Props) => {
  const [isEditable, setIsEditable] = useState(true);

  const { styles, exporter } = createStyles(['font-size', 'color'], 'CUSTOM_');
  const { nodeId } = useNode(node => ({
    dragged: node.events.dragged,
    hasSelectedNode: node.events.selected,
    nodeId: node.id
  }));
  const {
    query,
    actions: { setProp, history }
  } = useEditor();

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    history.ignore().setProp(nodeId, (props: any) => {
      props.styles = styles;
      props.exporter = exporter;
      props.elementType = elementType;
    });
  }, []);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    if (rawState) {
      history
        .ignore()
        .setProp(
          nodeId,
          (props: any) =>
            (props.editorState = EditorState.createWithContent(convertFromRaw(rawState)))
        );
      history.ignore().setProp(nodeId, (props: any) => (props.rawState = null));
    }
  }, []);

  const editorRef = useRef(null);
  const focus = () => {
    // @ts-ignore
    editorRef.current.focus();
    setIsEditable(true);
    setDisableDrag(true);
  };
  const onChange = (newEditorState: EditorState) => {
    if (
      editorState.getCurrentContent().getPlainText('\u0001') !==
      newEditorState.getCurrentContent().getPlainText('\u0001')
    ) {
      history.throttle().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
      });
    } else {
      history.ignore().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
      });
    }
  };

  function _handleKeyCommand(command: string, editorState: EditorState): DraftHandleValue {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  const [disableDrag, setDisableDrag] = useState(false);
  const wrapperRef = useRef();
  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });

  return (
    <ActionsController
      className={`rich-text__wrapper ${
        elementType === elementTypeObj[mediaTypeEnum.HAUPTINHALT] ? 'mx-5' : ''
      }`}
      canDrag={true}
      onlySettingsReq={onlySettingsReq}
      label={elementType}
      textAiReq={true}
    >
      <div
        className="rich-text__inner"
        // @ts-ignore
        ref={wrapperRef}
        onClick={focus}
        style={{ height: '100%' }}
      >
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          customStyleFn={customStyleFn}
          handleKeyCommand={_handleKeyCommand}
          onChange={onChange}
          placeholder="Gib hier deinen Text ein…"
          ref={editorRef}
          spellCheck={false}
          readOnly={!isEditable}
          stripPastedStyles
        />
      </div>
    </ActionsController>
  );
};
const customStyleFn = (style: any) => {
  const styleMap = style._map?._list?._tail?.array?.filter((single: any) => {
    return single?.[0].includes('CUSTOM_');
  });
  const styles = {};
  styleMap?.forEach((element: any) => {
    if (element?.[0]?.includes('CUSTOM_COLOR_')) {
      // @ts-ignore
      styles.color = element?.[0]?.split('CUSTOM_COLOR_')[1];
    } else if (element?.[0]?.includes('CUSTOM_FONT_SIZE_')) {
      // @ts-ignore
      styles.fontSize = element?.[0]?.split('CUSTOM_FONT_SIZE_')[1];
    }
  });
  return styles;
};

function getBlockStyle(block: ContentBlock) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'left':
      return 'align-left';
    case 'center':
      return 'align-center';
    case 'right':
      return 'align-right';
    default:
      return 'normal';
  }
}

const marks = {
  1: 'S',
  2: 'M',
  3: 'L',
  4: 'XL'
};

export const RichTextSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const [emojiPickerCollapsed, setEmojiPickerCollapsedState] = useState(true);

  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('elementSubSettings');
  const { goToElementSubSettings } = useElementSubSettings();
  const [selectedElementTab, setSelectedElementTab] = useState(
    paramValue || RICH_TEXT_SETTINGS_VALUE.AI
  );

  useEffect(() => {
    setSelectedElementTab(
      paramValue === RICH_TEXT_SETTINGS_VALUE.AI
        ? RICH_TEXT_SETTINGS_VALUE.AI
        : RICH_TEXT_SETTINGS_VALUE.GENERAL
    );
  }, [paramValue]);

  const themeColors = useCurrentlyEditingBuilderTheme();

  const { editorState, styles } = props;

  const editorStateRef = useRef(editorState);

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  const onChange = (editorState: EditorState) => {
    setProp((props: any) => (props.editorState = editorState));
  };

  function _toggleInlineStyle(inlineStyle: string) {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  function _toggleBlockType(blockType: string) {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }

  const handleChangeTab = (value: string) => {
    goToElementSubSettings(value as RICH_TEXT_SETTINGS_VALUE);
    setSelectedElementTab(value);
  };
  const selection = editorState.getSelection();
  const currentStyle = editorState.getCurrentInlineStyle();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const isWidgetBuilder = isWidgetBuilderPath();
  const enableAiText = !isWidgetBuilder;

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card settings-card__no-padding" title="Text" bordered={false}>
        <Tabs
          className="tab-container-style builder__settings-sidebar__container"
          activeKey={selectedElementTab}
          onChange={handleChangeTab}
        >
          <TabPane
            tab="Formatierung"
            className="single-tab-container"
            key={RICH_TEXT_SETTINGS_VALUE.GENERAL}
          >
            <Card className="settings-card" title="Text" bordered={false}>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
                  <Slider
                    className="builder-slider-style"
                    marks={marks}
                    min={1}
                    max={4}
                    step={1}
                    tooltipVisible={false}
                    defaultValue={defaultFontSize.key}
                    value={
                      getKeyByValue(
                        styles?.fontSize?.current(editorState)?.split('em')[0],
                        FontAvailableSizes
                      ).key
                    }
                    onChange={(value: any) => {
                      let newEditorState = styles.fontSize.toggle(
                        editorState,
                        `${getValueByKey(value, FontAvailableSizes).value}em`
                      );
                      onChange(newEditorState);
                    }}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Ausrichtung" />
                  <TextAlignmentButtons align={blockType} onChange={_toggleBlockType} />
                </Col>
                <Col span={11} offset={2}>
                  <BuilderSettingsTitle title="Style" />
                  <FontStyleButtons
                    hasStyle={style => currentStyle.has(style)}
                    onChange={_toggleInlineStyle}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Farbe" />
                  <BuilderColorPickerButton
                    color={styles.color.current(editorState) || themeColors.textColor}
                    onChange={(textColor: any) => {
                      const newEditorState = styles.color.toggle(editorState, textColor);
                      onChange(newEditorState);
                    }}
                  />
                </Col>
                <Col span={11} offset={2}>
                  {/* 
            Just temporary removed bevause its not wokring for launch
            <BuilderSettingsTitle title="List" />
            <ListStyleButtons style={blockType} onChange={_toggleBlockType} /> */}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle
                    title="😊 Emoji"
                    onCollapseToggleClick={value => setEmojiPickerCollapsedState(value)}
                    collapsed={emojiPickerCollapsed}
                  />
                  {!emojiPickerCollapsed && (
                    <EmojiPicker
                      height={'500px'}
                      emojiSize={30}
                      onChange={value => {
                        setProp(
                          (props: any) =>
                            (props.editorState = insertCharacterToEditor(
                              value,
                              editorStateRef.current
                            ))
                        );
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <Alert
                    message={RichTextComponentMessages.changeFontFamilyInDesignSettings}
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </Card>
          </TabPane>
          <TabPane tab="Text KI" key={RICH_TEXT_SETTINGS_VALUE.AI}>
            {enableAiText && (
              <AiTextSettings
                editorState={editorState}
                showOptionPicker={false}
                elementType={props.elementType}
              />
            )}
          </TabPane>
        </Tabs>
      </Card>
    </Col>
  );
};

RichTextComponent.craft = {
  name: CRAFT_ELEMENTS.RICH_TEXT,
  props: {
    editorState: initializeDraftWithContent('THis is an Awesome Ad')
  },
  related: {
    settings: RichTextSettings
  }
};

export default RichTextComponent;
