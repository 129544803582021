import React, { useState } from 'react';
import { SelectedTemplate } from './interfaces';
import removeFunnelTemplate from '../../graphql/removeFunnelTemplate';
import { GeneralMessages } from '../../../config/messages';
import { message } from 'antd';
import ImageWithoutCacheOnError from '../sharedUI/ImageWithoutCacheOnError';
import ActionProviderHOC from '../sharedUI/ActionProviderHOC';

interface Props {
  id: number;
  name: string;
  serializedNode: SelectedTemplate;
  previewImageURL: string;
  deletable: boolean;
  handleAddTemplate: (serializedNode: SelectedTemplate) => void;
  refetchFunnelTemplates: () => void;
  setEditTemplateDate?: any;
  tags?: string[];
  plusIconFilled?: boolean;
  imageClasses?: string;
}

export const Template = ({
  id,
  serializedNode,
  name,
  tags,
  previewImageURL,
  deletable,
  handleAddTemplate,
  setEditTemplateDate,
  plusIconFilled = false,
  refetchFunnelTemplates,
  imageClasses = ''
}: Props) => {
  return (
    <div className="template-action-provider">
      <ActionProviderHOC
        deletable={deletable}
        title={name}
        onDelete={() => removeFunnelTemplate({ id })}
        onEdit={() => setEditTemplateDate({ id, title: name, tags })}
        onSelect={() => handleAddTemplate({ ...serializedNode, name: name })}
        previewImageURL={previewImageURL}
        deleteCallback={() => refetchFunnelTemplates()}
        plusIconFilled={plusIconFilled}
      >
        <ImageWithoutCacheOnError className={`m-0 ${imageClasses}`} src={previewImageURL} />
      </ActionProviderHOC>
    </div>
  );
};
