export const updateUrlQueryParams = (params: { [key: string]: string | number | null }) => {
  const url = window.location.href;
  const urlObj = new URL(url);
  const searchParams = new URLSearchParams(urlObj.search);

  Object.keys(params).forEach(key => {
    const value = params[key];
    if (value !== null && value !== undefined && value !== '') {
      searchParams.set(key, value.toString());
    } else {
      searchParams.delete(key);
    }
  });

  return searchParams.toString();
};