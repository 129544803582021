import gql from 'graphql-tag';

export const GET_LEAD_QUALIFIER = gql`
  query GetLeadQualifier($input: LeadQualifierFilter) {
    getLeadQualifier(input: $input) {
      id
      funnelId
      question
      messageTitle
      messageText
      messageSkippable
      messageCtaLink
      messageCtaTitle
      pixelName
      gTag
      weight
      type
      voiceMessageAWSKey
      voiceMessageSignedUrl
      craftJsNodeId
      pageId
      choices {
        id
        funnelId
        title
        weight
        icon
        imageURL
        placeholder
        length
        min
        max
        unit
        initialStringValue
        initialNumberValue
        craftJsNodeId
        pageId
      }
      logic {
        id
        fallback
        leftSideValue
        operator
        rightSideValue
        thenLeadQualifierId
        thenStepKey
      }
    }
  }
`;


