import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNode } from '@craftjs/core';
import { ActionsController } from '../sharedUI/ActionsController';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { ImageLoadingSpinner } from '../../../SharedUI/components/ImageLoadingSpinner';
import { videoPlaceholder } from '../../config/builderUi';
import { useDraggingElement } from '../../hooks/useDraggingElement';
import {
  CraftElementBaseProps,
  getElementColor,
  getKeyByValue,
  getValueByKey
} from '../../helper/craftJs';
import SettingsGB from '../Settings/SettingsGB';
import { VideoSettingsItems } from '../Settings/SettingsTemplates';
import {
  ImageAvailableBorderRadius,
  ImageAvailableOverlayTransparency,
  ImageAvailableSizes
} from '../../interfaces/ImageSizeInterface';
import { FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import PlayCircle from '../voiceMessage/PlayCircle';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

interface Props extends CraftElementBaseProps {
  videoSrc?: string;
  src?: string;
  thumbnailEnabled?: boolean;
  borderRadius?: string;
  backgroundColor?: string;
  overlay?: number;
}

const VideoComponent = (props: Props) => {
  const theme = useCurrentlyEditingBuilderTheme();
  const { videoSrc } = props;
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const draggingElement = useDraggingElement();

  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  useEffect(() => {
    setLoading(true);
  }, [props.videoSrc]);

  const currentProps = currentNode.data.props;

  return (
    <ActionsController
      className="video-wrapper"
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
      style={{
        borderRadius: currentProps.thumbnailEnabled ? currentProps.borderRadius : 0
      }}
    >
      <div
        className={`video-wrapper__inner w-100 h-100 p-0 ${
          draggingElement ? 'disable-video-player' : ''
        }`}
        style={{
          backgroundImage: `url(${videoPlaceholder})`,
          backgroundSize: `100%`,
          borderRadius: currentProps.thumbnailEnabled ? currentProps.borderRadius : 0
        }}
      >
        {!isPlaying && currentProps.thumbnailEnabled && (
          <div
            onClick={() => setIsPlaying(true)}
            className="video-wrapper__overlay"
            style={{
              backgroundImage: `url(${currentProps.src})`,
              opacity: currentProps.overlay,
              borderRadius: currentProps.borderRadius
            }}
          >
            <PlayCircle
              className="video-wrapper__overlay__play-icon"
              isPlaying={isPlaying}
              style={{
                // @ts-ignore
                '--play-icon-bg': getElementColor(currentProps.backgroundColor, theme),
                background: getElementColor(currentProps.backgroundColor, theme)
              }}
              onClick={() => {
                setIsPlaying(true);
              }}
            />
          </div>
        )}
        <ReactPlayer
          style={{
            borderRadius: currentProps.thumbnailEnabled ? currentProps.borderRadius : 0,
            overflow: 'hidden'
          }}
          thumbnail={props.src || ''}
          id="react-player-wrapper"
          url={videoSrc || ''}
          controls={true}
          onReady={() => {
            setLoading(false);
          }}
          onError={() => {
            setLoading(false);
          }}
          playing={isPlaying}
          width="100%"
          height="100%"
          onPlay={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
        />
        {loading && <ImageLoadingSpinner />}
      </div>
    </ActionsController>
  );
};

export const VideoDefaultProps: Props = {
  videoSrc: 'https://vimeo.com/732025226',
  src: '',
  thumbnailEnabled: false,
  borderRadius: '0px',
  overlay: 1,
  backgroundColor: FUNNEL_THEME_KEYS.ACCENT_COLOR
};

const VideoSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const values = {
    ...props,
    borderRadius: getKeyByValue(props.borderRadius, ImageAvailableBorderRadius, 'pixelValue').key,
    overlay: getKeyByValue(props.overlay, ImageAvailableOverlayTransparency, "pixelValue").key
  }
  const handleChange = (key: string, value: any) => {
    setProp((props: any) => {
      switch (key) {
        case 'borderRadius':
          props.borderRadius = getValueByKey(value, ImageAvailableBorderRadius).pixelValue;
          break;
        case 'overlay':
          props.overlay = getValueByKey(value, ImageAvailableOverlayTransparency).pixelValue;
          break;
        default:
          props[key] = value;
      }
    });
  };
  return (
    <SettingsGB
      elements={VideoSettingsItems}
      onChange={handleChange}
      values={values}
      settingTitle="Video"
    />
  );
};

VideoComponent.craft = {
  name: CRAFT_ELEMENTS.VIDEO,
  props: VideoDefaultProps,
  rules: {
    canMoveIn: () => {
      return false;
    }
  },
  related: {
    settings: VideoSettings
  }
};

export default VideoComponent;
