import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const SET_MANUAL_BOOKING = gql`
  mutation SetManualBooking($input: SetManualBooking!) {
    setManualBooking(input: $input) {
      id
    }
  }
`;

export default () => useMutation(SET_MANUAL_BOOKING);
