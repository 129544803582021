import React, { useEffect } from 'react';
import { Switch, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import EmailTemplateForm from './EmailTemplateForm';
import {
  switchEmailTemplateTab,
  toggleChannelEnable,
  updateEmailTemplate,
  updateMessageTemplates,
  useChannelTemplatesData
} from '../../redux/builderSlice';
import {
  CHANNEL_TYPE,
  EmailType,
  MessagingTemplateTabType
} from '../../interfaces/builderSliceTypes';
import { isCalendarExistInFunnel } from '../../helper/craftJs';
import { getEmailTemplateTabName } from '../../helper/sharedFunctions';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';
import MessagingTemplateBlock from '../../container/messageSender/MessagingTemplateBlock';

const defaultEnabledEmailTemplates = [
  EmailType.CONFIRMATION,
  EmailType.INTERNAL_CONFIRMATION,
  EmailType.CANCELATION,
  EmailType.SCHEDULED,
  EmailType.NOT_REACHED
];

const alwaysEnabledEmailTemplates = [EmailType.CANCELATION, EmailType.NOT_REACHED];

const { TabPane } = Tabs;

const CreateTemplateTabs = () => {
  const { templates, activeTab, brandingEnabled } = useChannelTemplatesData();
  const { calendarEnabled } = isCalendarExistInFunnel();
  const dispatch = useDispatch();

  const handleUpdateEmailTemplate = (data: any) => {
    dispatch(updateEmailTemplate(data));
  };

  const handleUpdateMessageTemplate = (data: MessagingTemplateTabType) => {
    dispatch(updateMessageTemplates(data));
  };

  const handleTabChange = () =>
    dispatch(saveCompleteBuilderDataThunk({ shouldOnlySaveEmailTemplates: true }));

  return (
    <Tabs
      defaultActiveKey={activeTab}
      type="card"
      size={'large'}
      onChange={async e => {
        await handleTabChange();
        dispatch(switchEmailTemplateTab(e));
      }}
      className={`px-5 ${!brandingEnabled && 'create-email-template__wrapper__disabled'}`}
    >
      {templates?.map((tabContent, i) => {
        const emailTemplate = templates[i].emailTemplate;
        const tabDisabled =
          !calendarEnabled && !defaultEnabledEmailTemplates.includes(emailTemplate.type);

        const isReminderTemplate = emailTemplate.type === EmailType.REMINDER;

        if (isReminderTemplate && !calendarEnabled) {
          return;
        }

        return (
          <TabPane
            tab={
              <>
                {getEmailTemplateTabName(emailTemplate.type)}
                {!alwaysEnabledEmailTemplates.includes(emailTemplate.type) && !isReminderTemplate && (
                  <Switch
                    disabled={!(+activeTab === i)}
                    className="ml-1"
                    size="small"
                    defaultChecked
                    checked={tabContent.channelEnabled && !tabDisabled}
                    onChange={() => {
                      dispatch(toggleChannelEnable());
                    }}
                  />
                )}
              </>
            }
            key={`${i}`}
            disabled={tabDisabled}
          >
            {tabContent.sendingChannel === CHANNEL_TYPE.EMAIL ? (
              <EmailTemplateForm
                onChange={handleUpdateEmailTemplate}
                emailTemplate={tabContent.emailTemplate}
                emailTemplateEnabled={tabContent.channelEnabled}
              />
            ) : (
              <MessagingTemplateBlock
                template={tabContent.messagingTemplate}
                enabled={tabContent.channelEnabled}
                onChange={handleUpdateMessageTemplate}
              />
            )}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default CreateTemplateTabs;
