import React from 'react';
import { useNode } from '@craftjs/core';
import { Card, Col, Row, Slider } from 'antd';
import mixpanel from 'mixpanel-browser';
import { commonAspectRatios } from '../../../GeneralComponents/helper/cropImage';
import ImageUploadV3 from '../../../GeneralComponents/ImageUploadV3';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import {
  getFunnelIdFromToken,
  getMixPanelEventExistingTrackingData,
  mixpanelEvents,
  saveMixpanelEventOccurrence
} from '../../../UI/utils/mixpanel';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import {
  getElementColor,
  CraftElementBaseProps,
  getKeyByValue,
  getValueByKey
} from '../../helper/craftJs';
import { defaultLogoSize, LogoSizes } from '../../interfaces/LogoSizes';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import ImageWithoutCacheOnError from '../sharedUI/ImageWithoutCacheOnError';
import { FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { isAdBuilderPath } from '../../AdBuilder/utils/adBuilderUtils';

interface PropTypes extends CraftElementBaseProps {
  align?: string;
  url?: string;
  backgroundColor?: string;
  maxHeight?: string;
}

const LogoComponent = ({ align, url, backgroundColor, maxHeight }: PropTypes) => {
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const theme = useCurrentlyEditingBuilderTheme();
  return (
    <ActionsController
      onlySettingsReq
      className="p-4"
      style={{
        textAlign: align,
        background: getElementColor(backgroundColor, theme)
      }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <ImageWithoutCacheOnError src={url} style={{ maxHeight, maxWidth: '480px' }} />
    </ActionsController>
  );
};

const marks = {
  1: 'S',
  2: 'M',
  3: 'L'
};

export const LogoComponentSettings = () => {
  const isAdBuilder = isAdBuilderPath();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  const theme = useCurrentlyEditingBuilderTheme();
  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card p-0" title="Logo" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Größe" />
          </Col>
          <Col span={24}>
            <Slider
              className="builder-slider-style"
              marks={marks}
              min={1}
              max={3}
              tooltipVisible={false}
              defaultValue={defaultLogoSize.key}
              value={getKeyByValue(props.maxHeight, LogoSizes).key}
              onChange={value => {
                setProp(
                  // @ts-ignore
                  (props: any) => (props.maxHeight = getValueByKey(value, LogoSizes).value)
                );
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={12}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.align}
              onChange={align => {
                setProp((props: any) => (props.align = align));
              }}
            />
          </Col>
          {!isAdBuilder && (
            <Col span={12}>
              <BuilderSettingsTitle title="Hintergrundfarbe" />
              <BuilderColorPickerButton
                showColorTypeBtns
                color={getElementColor(props.backgroundColor, theme)}
                onChange={(backgroundColor, colorKey) => {
                  setProp((state: any) => {
                    state.backgroundColor = colorKey || backgroundColor;
                  });
                }}
              />
            </Col>
          )}
        </Row>
        <Row className="builder__settings-sidebar__row  m-0">
          <Col span={24}>
            <BuilderSettingsTitle title="Logo hochladen" />
            <ImageUploadV3
              previewImage={props.url}
              onChange={(url: string) => {
                setProp((prop: any) => {
                  prop.url = url;
                });
                if (
                  url &&
                  !getMixPanelEventExistingTrackingData(mixpanelEvents.ADDED_LOGO_INSIDE_FUNNEL)
                ) {
                  mixpanel.track(mixpanelEvents.ADDED_LOGO_INSIDE_FUNNEL, {
                    funnelId: getFunnelIdFromToken()
                  });
                  saveMixpanelEventOccurrence(mixpanelEvents.ADDED_LOGO_INSIDE_FUNNEL);
                }
              }}
              maxHeight={200}
              minWidth={100}
              minHeight={100}
              uploadedImage
              grid
              restrictPosition={false}
              possibleAspectRatios={commonAspectRatios}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const LogoComponentDefaultProps = {
  maxHeight: '50px',
  backgroundColor: FUNNEL_THEME_KEYS.BACKGROUND_COLOR,
  align: 'center',
  url: '/dashboard/funnel-builder/default-logo.png',
  onlySettingsReq: true
};

LogoComponent.craft = {
  name: CRAFT_ELEMENTS.LOGO,
  props: LogoComponentDefaultProps,
  related: {
    settings: LogoComponentSettings
  }
};

export default LogoComponent;
