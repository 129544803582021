import React from 'react';
import { CRAFT_ELEMENTS } from '../../../../../../config/craftElements';
import { ElementAppenderProps } from '../config';
import ContainerComponentHOC from '../../ContainerHOC';
import PostHeader from '../../../../../Templates/component/PostHeader';

const ElementAppender = ({ elementTitle, childNodes, ...props }: any) => {
  const { children } = props;

  return (
    <ContainerComponentHOC {...props} canDrag={true}>
      <div className="position-relative element-appender__inner post-preview">
        <PostHeader />
        {children}
      </div>
    </ContainerComponentHOC>
  );
};

ElementAppender.craft = {
  name: CRAFT_ELEMENTS.ELEMENT_APPENDER,
  rules: {
    canMoveOut: () => {
      return false;
    },
    canMoveIn: () => {
      return false;
    }
  },
  props: ElementAppenderProps
};

export default ElementAppender;
