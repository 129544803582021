import React, { useRef, useState } from 'react';
import { useNode } from '@craftjs/core';

import { ActionsController } from '../../sharedUI/ActionsController';

import { ContainerComponentProps as ContainerComponentPropsInterface } from './ElementAppender/interfaces';

import CreateNewTemplate from '../../TemplatesPanel/CreateNewTemplate';
import CreateTemplateButton from '../../../../components/elements/Container/components/CreateTemplateButton';

const ContainerComponentHOC = ({
  backgroundColor,
  paddingTopClass,
  paddingBottomClass,
  minHeight,
  children,
  isFirstRender,
  ...props
}: ContainerComponentPropsInterface) => {
  const [visible, setVisible] = useState(false);
  const [templateCraftState, setTemplateCraftState] = useState('');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { currentNode } = useNode(node => ({
    currentNode: node
  }));

  return (
    <>
      <ActionsController
        {...props}
        className={`container__wrapper ${paddingTopClass} ${paddingBottomClass}`}
        addNewContainerBtnReq={true}
        settingsNotReq={true}
        innerRef={containerRef}
        actionIcon={
          <CreateTemplateButton
            currentNode={currentNode}
            setVisible={setVisible}
            setTemplateCraftState={setTemplateCraftState}
          />
        }
      >
        {children}
      </ActionsController>
      <CreateNewTemplate
        visible={visible}
        toggleVisible={setVisible}
        templateCraftState={templateCraftState}
        containerRef={containerRef.current}
      />
    </>
  );
};

export default ContainerComponentHOC;
