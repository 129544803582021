import { FAQ } from "../AdBuilder/interfaces/TextTypeInterface";
import { IconType } from "../components/elements/FrequentlyAskedQuestionsComponent/components/FAQTile";



export const getRawState = (injectable: string, type: string) => {
    const questionStyles = [
        { offset: 0, length: injectable.length, style: "CUSTOM_FONT_SIZE_1.5em" },
        { offset: 0, length: injectable.length, style: "BOLD" },
    ];

    const rawState: any = {
        blocks: [
            {
                key: "ex07s",
                text: injectable,
                type,
                depth: 0,
                inlineStyleRanges: type === FAQ.QUESTION ? questionStyles : [],
                entityRanges: [],
                data: {},
            },
        ],
        entityMap: {},
    };

    return rawState;
};
