import React, { ChangeEvent, useCallback } from 'react';
import { Popover, Select, DatePicker, Button, Input, Row, Col, Tooltip, Tag } from 'antd';
import { FunnelInterface } from '../../Funnel/components/GeneralSettingsBuilder';
import { eventTypes } from '../utils/eventTypes';
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { BookingStatus, BookingTag } from '../container/Events';
import moment, { Moment } from 'moment';
import { predefinedDatePickerRanges } from '../utils/datePickerRanges';
import { FiFilter } from 'react-icons/fi';
import { useEventsContext } from '../context/EventsContext';
import { debounce } from 'debounce';
import { getApplicationFiltersFromQueryParams } from '../helper/getApplicationFiltersFromQueryParams';

const { RangePicker } = DatePicker;

const defaultRangePickerProps: RangePickerProps<moment.Moment> = {
  allowClear: false,
  format: 'DD.MM.YYYY',
  allowEmpty: [false, false]
};

interface FilterPopover {
  funnels: FunnelInterface[];
  handleFunnelSelectionChange(value: number[]): any;
  handleTagsSelectionChange(value: number[]): any;
  handleOnSearchQueryChange(value: string): void;
  handleTypeChange(value: string): void;
  availableStatus: BookingStatus[];
  handleChangeStatus: (value: number[]) => void;
  showFunnelButtonText?: boolean;
}

const FilterPopover = ({
  funnels,
  handleTypeChange,
  handleFunnelSelectionChange,
  handleTagsSelectionChange,
  handleOnSearchQueryChange,
  availableStatus,
  handleChangeStatus,
  showFunnelButtonText = false
}: FilterPopover) => {
  const { funnelIds, statusId, type, start, end, tagIds } = getApplicationFiltersFromQueryParams();
  const {
    handleOnDateRangeChange,
    eventSearchInput,
    setEventSearchInput,
    allBookingTags
  } = useEventsContext();

  const debouncedSearchQuery = useCallback(
    debounce((value: string) => {
      handleOnSearchQueryChange(value);
    }, 1500),
    []
  );

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setEventSearchInput(value);
    debouncedSearchQuery(value);
  }

  return (
    <div className="d-flex align-items-center">
      <Tooltip title="Filter">
        <Popover
          className="default-popover"
          placement="top"
          content={
            <div className="default-popover__content" style={{ width: 400, maxWidth: 400 }}>
              <div className="default-popover__item">
                <label>Datumsbereich</label>
                <RangePicker
                  {...defaultRangePickerProps}
                  // @ts-ignore
                  value={[moment(start), moment(end)] as [Moment, Moment]}
                  onChange={handleOnDateRangeChange}
                  //@ts-ignore
                  ranges={predefinedDatePickerRanges}
                />
              </div>

              <Row>
                <Col span={24}>
                  <div className="default-popover__item">
                    <label>Suche</label>
                    <Input
                      value={eventSearchInput}
                      placeholder="z.B. Jane Doe"
                      className="w-100"
                      onChange={handleOnChange}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="default-popover__item">
                    <label>Funnels</label>
                    <Select
                      mode="multiple"
                      placeholder="Auswählen..."
                      onChange={handleFunnelSelectionChange}
                      optionFilterProp={'label'}
                      filterOption={(input, option) => {
                        return !!option?.label
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      value={funnelIds}
                    >
                      {funnels.map((funnel: any, idx: number) => {
                        return (
                          <Select.Option key={idx} label={funnel.title} value={funnel.id}>
                            {funnel.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="default-popover__item">
                    <label>Tags</label>
                    <Select
                      mode="multiple"
                      placeholder="tags..."
                      onChange={handleTagsSelectionChange}
                      value={tagIds}
                      filterOption={(input, option) => {
                        return !!option?.label
                          ?.toString()
                          .toLocaleLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      tagRender={props => {
                        const { value, closable, onClose } = props;
                        const tag = allBookingTags.find(
                          (tag: BookingTag) => tag.id === value || tag.name === value
                        );
                        const color = tag?.color || 'blue';
                        return (
                          <Tag color={color} closable={closable} onClose={onClose} className="m-1 rounded">
                            {tag?.name}
                          </Tag>
                        );
                      }}
                    >
                      {allBookingTags?.map((tag: BookingTag, idx: number) => {
                        return (
                          <Select.Option key={tag.id} label={tag.name} value={tag.id as number}>
                            <Tag color={tag.color}>{tag.name}</Tag>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>

              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <div className="default-popover__item">
                    <label>Typ</label>
                    <Select
                      defaultValue="all"
                      value={type}
                      onChange={(typeSelectionValue: string, _) =>
                        handleTypeChange(typeSelectionValue)
                      }
                    >
                      {eventTypes.map((o: any, idx: number) => (
                        <Select.Option key={idx} value={o.key}>
                          <Tooltip title={o.text}>{o.text}</Tooltip>
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="default-popover__item">
                    <label>Status</label>
                    <Select
                      value={statusId}
                      style={{ width: '100%' }}
                      onChange={handleChangeStatus}
                      mode="multiple"
                    >
                      <Select.Option key={0} value={0}>
                        Alle
                      </Select.Option>
                      {availableStatus?.map(single => {
                        return (
                          <Select.Option key={single.id} value={single.id}>
                            {single.status}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </div>
          }
          trigger="click"
        >
          <Button
            className={showFunnelButtonText ? `ant-btn__primary` : `ant-btn__without-background`}
            style={!showFunnelButtonText ? { margin: 0, padding: 0 } : {}}
            type={showFunnelButtonText ? `primary` : `ghost`}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            icon={
              <FiFilter
                className={
                  showFunnelButtonText ? 'funnel-icon-button' : 'agency-dashboard-brand-color'
                }
                size={22}
              />
            }
          >
            {showFunnelButtonText && <span>Funnel auswählen</span>}
          </Button>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default FilterPopover;
