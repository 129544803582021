import React, { useEffect } from 'react';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import { useEditor, useNode } from '@craftjs/core';
import { ActionsController } from '../sharedUI/ActionsController';

export interface Props {
  children?: any;
  elementTree?: any,
  elementTitle: string
}

const MultiReactElementAppender = ({ children, elementTree, elementTitle, ...props }: Props) => {
  const {
    currentNode,
  } = useNode(node => {
    return {
      currentNode: node
    };
  });

  const { actions, query } = useEditor();

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const actionCreator = actions.history.throttle(500);
      const nodeTree = query.parseReactElement(elementTree.nodes).toNodeTree();
      actionCreator.addNodeTree(nodeTree, currentNode.id);
    }
  }, []);

  return <ActionsController
    {...props}
    label={CRAFT_ELEMENTS_LABEL[elementTitle]}
    className='p-3'>
    {children}</ActionsController>;
};

export const MultiReactElementAppenderProps = {};

export default MultiReactElementAppender;

MultiReactElementAppender.craft = {
  name: CRAFT_ELEMENTS.MULTI_REACT_ELEMENT_APPENDER,
  props: MultiReactElementAppenderProps,
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.FAQContainerHOC;
    },
    canMoveOut: () => false
  }
};
