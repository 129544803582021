import React, { useEffect } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';
import { getUniqueId } from '../../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { BasicElementWrapper } from '../sharedUI/BasicElementWrapper';
import { textTypesEnum } from '../../interfaces/TextTypeInterface';
import PlainTextComponent from './PlainTextComponent/container/PlainTextComponent';
import headlineTextData from '../../data/headlineTextData';
import PostFooterHOC from '../../Templates/component/PostFooter';
import { initializeDraftWithContent } from '../../../helper/richTextHelpers';

interface Props {
  children?: any;
}

const Footer = (props: Props) => {
  const { children } = props;
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const { actions, query } = useEditor();

  useEffect(() => {
    if (currentNode.data.nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getUniqueId()}
            is={PlainTextComponent}
            elementType={textTypesEnum.HEADLINE}
            templateId={headlineTextData.data[0].content[0].id}
            editorState={initializeDraftWithContent(headlineTextData.data[0].content[0].text)}
            onlySettingsReq={true}
            isEdited={false}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, currentNode.id);
    }
  }, []);

  return (
    <BasicElementWrapper
      style={{
        margin: 0
      }}
    >
      <PostFooterHOC>{children}</PostFooterHOC>
    </BasicElementWrapper>
  );
};

const FooterDefaultProps = {};

Footer.craft = {
  name: CRAFT_ELEMENTS.FOOTER,
  props: FooterDefaultProps
};

export default Footer;
