import React from 'react';
import { ActionsController } from '../sharedUI/ActionsController';
import ImageWithoutCacheOnError from '../../../components/sharedUI/ImageWithoutCacheOnError';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { CraftElementBaseProps } from './ImageComponent';
import { LogoComponentSettings } from '../../../components/elements/LogoComponent';
import { mediaTypeEnum } from '../../interfaces/TextTypeInterface';

interface Props extends CraftElementBaseProps {
  align?: string;
  url?: string;
  maxHeight?: string;
  elementType?: string;
}

const LogoComponent = ({ align, url, maxHeight, elementType }: Props) => {
  return (
    <ActionsController
      label={elementType}
      onlySettingsReq
      className="py-4 my-4 logo-container"
      style={{
        textAlign: align
      }}
    >
      {url && (
        <ImageWithoutCacheOnError
          src={url}
          style={{ maxHeight, width:"auto", objectFit: 'contain' }}
        />
      )}
    </ActionsController>
  );
};

const LogoComponentDefaultProps = {
  maxHeight: '50px',
  align: 'center',
  url: '/dashboard/funnel-builder/default-logo.png',
  onlySettingsReq: true
};

LogoComponent.craft = {
  name: CRAFT_ELEMENTS.LOGO,
  props: LogoComponentDefaultProps,
  related: {
    settings: LogoComponentSettings
  }
};

export default LogoComponent;
