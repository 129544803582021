import React, { useEffect } from 'react';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { Element, useEditor, useNode } from '@craftjs/core';
import {
  CraftElementBaseProps,
  getUniqueId,
  parentSelectionOnChildClick
} from '../../../../helper/craftJs';
import RichTextComponent from '../../RichTextComponent/container/RichTextComponent';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { useElementsPresets } from '../../../../redux/builderSlice';
import { builderPresetElements } from '../../../../interfaces/builderSliceTypes';
import { useSetSelectedElementTab } from '../../../../reduxCommonMutations/setSelectedElementTab';
import { ImageAvailableBorderRadius } from '../../../../interfaces/ImageSizeInterface';

interface Props extends CraftElementBaseProps {
  image: string;
  label: string;
  editorState?: any;
  opacity?: number;
  isPresetOn?: boolean;
  children?: any;
  borderRadius?: string;
}

const rawState = `{"blocks":[{"key":"aa9lq","text":"🚀 Consectetur adipiscing elit.","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":33,"style":"CUSTOM_COLOR_white"}],"entityRanges":[],"data":{}},{"key":"ffe5a","text":"Lorem ipsum dolor sit amet, consectetur adipiscing","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":56,"style":"CUSTOM_FONT_SIZE_2.5em"},{"offset":0,"length":56,"style":"CUSTOM_COLOR_white"}],"entityRanges":[],"data":{}},{"key":"9ot8f","text":"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed magna dictum porta.","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":117,"style":"CUSTOM_COLOR_white"},{"offset":0,"length":117,"style":"unstyled"}],"entityRanges":[],"data":{}}],"entityMap":{}}`

export const defaultCarouselSlideImage =
  'https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/bilder/coach-image-cid-1-1687352510049-e0rlig.jpeg';

const CarouselSlide = ({ image, opacity, editorState, children, label }: Props) => {
  const { nodes, id, props , currentNode} = useNode(node => ({
    nodes: node.data.nodes,
    props: node.data.props,
    currentNode: node,
  }));

  const { actions, query } = useEditor();

  useEffect(() => {
    if (!children && nodes.length === 0) {
      const nodeTree = query
        .parseReactElement(
          <Element
            id={getUniqueId()}
            is={RichTextComponent}
            onlySettingsReq={true}
            rawState={JSON.parse(rawState)}
            canDrag={true}
          ></Element>
        )
        .toNodeTree();
      actions.history.ignore().addNodeTree(nodeTree, id);
    }
  }, []);

  const presets = useElementsPresets(builderPresetElements.CAROUSEL);

  const finalProps = props?.isPresetOn ? { ...props, ...presets } : props;

  const { borderRadius } = finalProps;
  const setSelectedElementTab = useSetSelectedElementTab();

  const handelOpenSettings = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedElementTab(
      {
        payload: '2'
      },
      {
        navigationReq: true
      }
    );
    parentSelectionOnChildClick(id, query, actions.selectNode);
  };

  return (
    <ActionsController
      className={`carousel-item `}
      ParentTag="div"
      id={`slide-${id}`}
      style={{ borderRadius }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div
        className="carousel-item-overlay"
        style={{ opacity: (opacity || 5) / 10, borderRadius }}
        onClick={handelOpenSettings}
      ></div>
      <img src={image} style={{ borderRadius }} className="d-block w-100" alt="..." />
      <div
        style={{ borderRadius }}
        className="carousel-caption"
        onClick={() => {
          parentSelectionOnChildClick(id, query, actions.selectNode);
        }}
      >
        {children}
      </div>
    </ActionsController>
  );
};

export default CarouselSlide;

CarouselSlide.craft = {
  name: CRAFT_ELEMENTS.CAROUSEL_SLIDE,
  props: {
    image: defaultCarouselSlideImage,
    canDrag: false,
    activeActionControllerClassNotRequired: true,
    isPresetOn: true,
    borderRadius: ImageAvailableBorderRadius[1].pixelValue
  },
  rules: {
    canMoveIn: () => {
      return false;
    },
    canMoveOut: () => {
      return false;
    }
  }
};
