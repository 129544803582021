import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Select, Tooltip, message } from 'antd';
import { useEventsContext } from '../../context/EventsContext';
import PhoneInput from '../../../Builder/components/elements/ContactForm/components/PhoneInput';
import LoginValidationRules from '../../../Login/utils/LoginValidationRules';
import {
  defaultDateValidation,
  defaultSelectFieldValidation,
  defaultStringFieldValidation
} from '../../../UI/utils/formValidation';
import { PlusOutlined } from '@ant-design/icons';
import { BookingStatus } from '../../container/Events';
import moment from 'moment';
import { ManualBookingMessages } from '../../../config/messages';
import { Store } from 'antd/lib/form/interface';
import FunnelSelectList from '../../../UI/components/FunnelSelectList';

interface ManualBookingModalProps {
  availableStatus: BookingStatus[];
}

export default function ManualBookingModal({ availableStatus }: ManualBookingModalProps) {
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState(false);
  const {
    handleAddManualBookingEvent,
    eventLoaders: { manualBookingLoading }
  } = useEventsContext();

  const handleSubmit = async (values: Store) => {
    try {
      await handleAddManualBookingEvent(values);
    } catch (error) {
      message.error(ManualBookingMessages.error);
    } finally {
      form.resetFields();
      setShowModal(false);
    }
  };

  return (
    <div className="d-flex">
      <Tooltip title="Manuell anlegen">
        <Button
          className={`ant-btn__without-background`}
          style={{ margin: 0, padding: 0 }}
          type={`ghost`}
          onClick={() => setShowModal(true)}
          icon={<PlusOutlined className={'agency-dashboard-brand-color'} size={22} />}
        />
      </Tooltip>
      <Modal
        title="Manual Booking"
        visible={showModal}
        onOk={() => {
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={manualBookingLoading}
            disabled={manualBookingLoading}
            onClick={() => form.submit()}
          >
            Erstellen
          </Button>
        ]}
      >
        <Form
          name="account_update_form"
          form={form}
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleSubmit}
          initialValues={{
            applicationDate: moment()
          }}
        >
          <Form.Item label="Funnel" name="funnel" rules={defaultSelectFieldValidation}>
            <FunnelSelectList />
          </Form.Item>

          <Form.Item label="Status" name="status" rules={defaultSelectFieldValidation}>
            <Select
              placeholder={'Status auswählen'}
              options={availableStatus?.map(status => ({
                label: status.status,
                value: status.id
              }))}
            />
          </Form.Item>

          <Form.Item label="Vorname" name="firstName" rules={defaultStringFieldValidation}>
            <Input placeholder="Vorname" />
          </Form.Item>

          <Form.Item label="Nachname" name="lastName">
            <Input placeholder="Nachname" />
          </Form.Item>

          <Form.Item label="E-mail" name="email" rules={LoginValidationRules.email}>
            <Input placeholder="E-mail" />
          </Form.Item>
          <Form.Item label="Anrufnummer" name="phone" className="mb-0 message-contact-number">
            <PhoneInput inputClass="w-100" />
          </Form.Item>
          <Form.Item label="Antragsdatum" name="applicationDate" rules={defaultDateValidation}>
            <DatePicker
              className="w-100"
              showTime={{ use12Hours: false }}
              disabledDate={current => current && current > moment().endOf('day')}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
