import { useNode } from '@craftjs/core';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { youtubeOrVimeoUrlFormValidator } from '../../../helper/url';

export const VideoComponentSettings = ({ element }: any) => {
  const [form] = Form.useForm();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title="Video-URL" />
        <Form
          form={form}
          initialValues={{ url: props.videoSrc }}
          onFinish={values => {
            setProp((props: any) => (props.videoSrc = values.url));
          }}
          autoComplete="off"
        >
          <Form.Item
            name="url"
            rules={[
              {
                required: true,
                message: 'URL ist erforderlich'
              },
              youtubeOrVimeoUrlFormValidator
            ]}
          >
            <Input
              className="mt-2"
              placeholder="YouTube, Vimeo, Wistia oder MP4-Url"
              onChange={form.submit}
            />
          </Form.Item>
        </Form>
      </Col>
      <Col span={24}>
        <p>
          Nutze Vimeo, YouTube oder jeden anderen beliebigen Video-Hoster für das Lagern der Video.
          Nutze entweder das Thumbnail des Hosters oder stelle es bei uns ein. Mehr dazu im{' '}
          <a
            target="_blank"
            href="https://hilfe.meetovo.de/de/article/videos-in-deinen-funnel-einfugen-vimeo-youtube-wistia-etc-bfrxtg/?bust=1729855354542"
          >
            Hilfeartikel
          </a>
        </p>
      </Col>
    </Row>
  );
};