import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  Badge,
  Descriptions,
  Select,
  Typography,
  Space,
  message,
  Button,
  Tabs,
  Avatar as AntAvatar,
  Popover,
  Table,
  Form,
  Rate,
  Tag,
  Input,
  Tooltip
} from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  RocketOutlined,
  FunnelPlotOutlined,
  MoreOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  BookingInterface,
  LeadQualifierInterface,
  BookingChoiceInterface,
  LeadQualifierChoiceInterface,
  TrackingInterface,
  ProgressCollectorInterface,
  ProgressCollectorChoiceInterface,
  BookingActionEventInterface,
  BookingTag,
  SelectedEvent
} from '../../container/Events';
import formatEventTitle from '../../helper/formatEventTitle';
import AWSKeyVoiceMessagePlayer from '../AWSKeyVoiceMessagePlayer';
import Notes from '../Notes';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { StatusResponseType, StatusType } from '../../utils/responseTypes';
import { EventMessages, EventsMessages, ManualBookingMessages } from '../../../config/messages';
import dateFormats from '../../../config/dateFormats';
import { getTimeString } from '../../helper/dates';
import download from 'downloadjs';
import DeclineApplicantModal from './DeclineApplicantModal';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import NotReachedModal from './NotReachedModal';
import Avatar from 'react-avatar';
import { AiFillClockCircle } from 'react-icons/ai';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { useEventsContext } from '../../context/EventsContext';
import FilesTable from './FilesTable';
import CommunicationTable from './CommunicationTable';
import ConditionalTooltip from '../../../SharedUI/components/ConditionalTooltip';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import BookingRating from './BookingRating';
import ApplicantEngagement from './ApplicantEngagement';
import { LoginMessages } from '../../../config/messages';
const { Paragraph } = Typography;
import { GET_LEAD_QUALIFIER } from '../../../Builder/hooks/useGetLeadQualifier';
import LeadQualifierForm from './LeadQualifierForm';
import { getTextFromHtml } from '../../../Builder/helper/sharedFunctions';
import { EditOutlined } from '@ant-design/icons';
import BookingTagsUpdateModal from '../EventKanban/BookingTagsUpdateModal';
import { selectUserData } from '../../../UI/redux/userSlice';
import { useAppSelector } from '../../../redux/hooks';
import { EventDrawerTabView } from '../../context/EventsContext.types';

export const CHANGE_BOOKING_STATUS = gql`
  mutation($input: ChangeBookingStatus!) {
    changeBookingStatus(input: $input) {
      funnelId
      name
      email
    }
  }
`;

const UPDATE_APPLICANT_DATA = gql`
  mutation($input: UpdateApplicantData!) {
    updateApplicantData(input: $input) {
      name
      email
      phone
    }
  }
`;

//Change cancel schedule message accordingly
const CANCEL_SCHEDULE_MESSAGE = gql`
  mutation($input: CancelScheduleMessage!) {
    cancelScheduleMessage(input: $input) {
      funnelId
      messageId
    }
  }
`;

export interface PropsInterface {
  leadQualifier: LeadQualifierInterface[];
  eventType: string;
  selectedEvent: SelectedEvent | undefined;
  setSelectedEvent: Function;
  changeBookingStatusLocally: Function;
  clearEventCache: Function;
  deleteLoading: boolean;
  eventLoading?: boolean;
  handleUpdateBookingEvents?: (eventId: number, bookingEvents: BookingActionEventInterface) => void;
  handleBookingChange: Function;
}

interface AdaptedBookingChoiceInterface extends BookingChoiceInterface {
  totalDurationInSeconds?: number;
}

function EventDetailContent({
  leadQualifier,
  eventType,
  changeBookingStatusLocally,
  clearEventCache,
  deleteLoading,
  handleUpdateBookingEvents,
  handleBookingChange,
  setSelectedEvent
}: PropsInterface) {
  const [declineApplicantModalVisible, setDeclineApplicantModalVisibility] = useState(false);
  const [notReachedModalVisible, setNotReachedModalVisible] = useState(false);
  const {
    availableStatus,
    eventDrawerTabView,
    handleDeleteEvent,
    allBookingTags,
    setAllBookingTags,
    setIsBookingTagsChange,
    selectedEvent,
    handleSetSelectedEvent
  } = useEventsContext();
  const { id: coachId } = useAppSelector(selectUserData);

  const [isEditing, setIsEditing] = useState(false);
  const [openInput, setOpenInput] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  
  const selectedEventTags = useMemo(() => {
    return selectedEvent?.event?.tags?.map(tag => tag.id || tag.name);
  }, [selectedEvent?.event?.tags]);

  const { loading: leadQualifierLoading, data: leadQualifierData } = useQuery(GET_LEAD_QUALIFIER, {
    variables: {
      input: {
        showAll: false,
        funnelIds: [selectedEvent?.event?.funnelId],
        shouldCleanLeadQualifiers: true
      }
    }
  });
  const [tagsUpdateModalVisible, setTagsUpdateModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState<BookingTag>({ id: 0, name: '', color: '' });

  const [bookingStatus, setBookingStatus] = useState<StatusType[]>();
  //@ts-ignore
  // const [currentStatus, setCurrentStatus] = useState(selectedEvent?.event?.bookingStatus?.status);
  const [currentStatus, setCurrentStatus] = useState(selectedEvent?.event?.bookingStatus?.value);
  const [inputValue, setInputValue] = useState('');
  
  const handleSearch = (value: string) => {
    setInputValue(value);
  };
  
  const handleTagChange = (values: (string | number)[]) => {
    setIsBookingTagsChange(true);
    const tags = allBookingTags.filter((tag: BookingTag) => {
      return values.includes(tag?.id || tag?.name);
    });
    const newTagName = values.filter(
      (value: number | string) =>
        !allBookingTags.some((tag: BookingTag) => tag.id === value || tag.name === value)
    );
    let newTag;
    if (newTagName.length) {
      newTag = {
        name: newTagName[0] as string,
        color: 'blue',
        coachId
      };
      setAllBookingTags([...allBookingTags, newTag]);
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          tags: [...tags, newTag]
        }
      });
      setInputValue('');
    } else {
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          tags: [...tags]
        }
      });
      setInputValue('');
    }
  };
  
  // const [currentStatus, setCurrentStatus] = useState(selectedEvent?.event?.bookingStatus?.value);
  //@ts-ignore
  const [currentStatusColor, setCurrentStatusColor] = useState(
    availableStatus[
      //@ts-ignore
      availableStatus.map(val => val.status).indexOf(selectedEvent?.event?.bookingStatus?.status)
    ]?.color
  );

  const [applicantData, setApplicantData] = useState({
    id: (selectedEvent as any).event?.id,
    name: (selectedEvent as any).event?.name,
    email: (selectedEvent as any).event?.email,
    phone: (selectedEvent as any).event?.phone,
    formUpdate: false
  });

  const [syncPhoneField, setSyncPhoneField] = useState(true)

  const [applicantEngagementData, setApplicantEngagementData] = useState<any>({});

  const [updateApplicantData, {}] = useMutation(UPDATE_APPLICANT_DATA);

  const [
    changeBookingStatus,
    { data, loading: statusChangeLoading, error: statusChangeError }
  ] = useMutation(CHANGE_BOOKING_STATUS);

  useEffect(() => {
    if (availableStatus?.length) {
      const temp = availableStatus?.sort((a, b) => {
        if (a.sortOrder > b.sortOrder) return 1;
        else if (a.sortOrder < b.sortOrder) return -1;
        return 0;
      });

      setBookingStatus(temp);
    }
  }, [availableStatus]);

  useEffect(() => {
    //@ts-ignore
    setCurrentStatus(selectedEvent?.event?.bookingStatus?.value);
    //@ts-ignore
    const colorIndex = availableStatus
      .map(val => val.status)
      //@ts-ignore
      .indexOf(selectedEvent?.event?.bookingStatus?.status);
    setCurrentStatusColor(availableStatus[colorIndex]?.color);

    if (selectedEvent) {
      const data = {
        id: (selectedEvent as any).event?.id,
        name: (selectedEvent as any).event?.name,
        email: (selectedEvent as any).event?.email,
        phone: (selectedEvent as any).event?.phone,
        formUpdate: false
      };
      setApplicantData(data);
      setApplicantEngagementData(data);
    }
  }, [JSON.stringify(selectedEvent)]);

  if (!selectedEvent) {
    return null;
  }

  useEffect(() => {
    if (applicantData?.formUpdate) {
      setApplicantEngagementData(applicantData);
      updateApplicantData({
        variables: {
          input: {
            id: applicantData?.id,
            name: applicantData.name,
            email: applicantData.email,
            phone: applicantData.phone
          }
        }
      })
        .then(() => {
          clearEventCache();
          setApplicantData(prevData => ({
            ...prevData,
            formUpdate: false
          }));
          setSyncPhoneField(false)
          message.success(EventMessages.success);
        })
        .catch(e => {
          message.error(EventMessages.error);
        });
    }
  }, [applicantData]);


  const inputRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setApplicantData(prevData => {
        return {
          ...prevData,
          formUpdate: true
        };
      });
      setOpenInput(false);
      setSyncPhoneField(true)
    }
  };

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (syncPhoneField) {
      handleBookingChange('phone', applicantData.phone);
    }
  }, [syncPhoneField, applicantData.phone]);


  if (!selectedEvent) {
    return null;
  }

  const choiceCollector: any = {};
  const { event } = selectedEvent || {};
  let files: string[] = [];
  let rawEvent: BookingInterface | any = {};
  let rawTracking: TrackingInterface | any = {};
  if (eventType !== 'tracking') rawEvent = event as BookingInterface;
  if (eventType === 'tracking') rawTracking = event as TrackingInterface;

  const { choices: initChoices, content, title } =
    eventType === 'tracking'
      ? prepareTrackingContent(event as TrackingInterface)
      : prepareBookingContent(event as BookingInterface);

  const choices = initChoices ?? [];

  choices.forEach((ch: AdaptedBookingChoiceInterface) => {
    let leadQualifierObj:
      | LeadQualifierInterface
      | undefined = leadQualifierData?.getLeadQualifier?.find(
      (lq: LeadQualifierInterface) => ch.leadQualifierId === lq.id
    );
    if (leadQualifierObj) {
      let choiceObj: LeadQualifierChoiceInterface | undefined = leadQualifierObj.choices.find(
        (c: LeadQualifierChoiceInterface) => ch.choiceId === c.id
      );
      if (choiceObj) {
        const question = `${leadQualifierObj.question}`;

        if (!choiceCollector[question]) choiceCollector[question] = { title: [], value: [] };

        if (ch.stringValue || ch.numberValue) {
          choiceCollector[question].value.push(
            `${ch.stringValue || ch.numberValue} ${choiceObj.unit || ''}`
          );
        } else {
          choiceCollector[question].title.push(`${choiceObj.title}`);
        }

        if (ch.voiceMessageAWSKey)
          choiceCollector[question].voiceMessageAWSKey = ch.voiceMessageAWSKey;

        if (ch.totalDurationInSeconds) choiceCollector[question].time = ch.totalDurationInSeconds;
      }
    }
  });

  const handleChangeStatus = (value: string, props: any): void => {
    const eventId = selectedEvent.event.id;
    changeBookingStatus({
      variables: {
        input: { id: eventId, value }
      }
    })
      .then(() => {
        const { status, value, color } = props.data;
        changeBookingStatusLocally(
          eventId,
          {
            status,
            value,
            color
          },
          event
        );
        //@ts-ignore
        if (value === 'DECLINE' && selectedEvent?.event?.version !== 'V1') {
          setDeclineApplicantModalVisibility(true);
        }
        message.success(EventMessages.success);
      })
      .catch(e => {
        message.error(EventMessages.error);
      });
  };

  const handleDownloadFile = (url: string, name: string) => {
    const hideMessage = message.loading(EventsMessages.downloadFileInProgress, 0);

    fetch(url)
      .then(async r => {
        const file = await r.blob();
        download(file, name);
      })
      .catch(() => message.error(EventsMessages.downloadFileFailed))
      .finally(hideMessage);
  };

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleFieldChange = (field: string, newValue: string) => {
    if (field === 'email') {
      if (!validateEmail(newValue)) {
        message.error(LoginMessages.invalidEmail);
        return;
      }
    }
    if (
      (applicantData as any)[field] !== newValue &&
      newValue !== '' &&
      newValue !== 'Keine Angabe'
    ) {
      setApplicantData(prevData => ({
        ...prevData,
        [field]: newValue,
        formUpdate: true
      }));
      if (['name', 'email'].includes(field)) {
        handleBookingChange(field, newValue);
      }
    }
  };

  const handlePhoneChange = (newValue: string) => {
    setApplicantData(prevData => ({
      ...prevData,
      phone: newValue
    }));
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setApplicantData(prevData => ({
        ...prevData,
        phone: prevData.phone,
        formUpdate: true
      }));
      setSyncPhoneField(true)
      setOpenInput(false);
    }
  };

  const handlePhoneClick = (event: any) => {
    if (
      !(
        event.target.closest('.ant-typography-edit-content') ||
        event.target.closest('.editable-paragraph .ant-typography-edit')
      )
    ) {
      window.location.href = `tel:${
        applicantData.phone.startsWith('+') ? applicantData.phone : '+' + applicantData.phone
      }`;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2" style={{ columnGap: 8 }}>
        <Avatar name={rawEvent.name} size="50" textSizeRatio={2} round={true} />
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex align-items-center">
            <ConditionalTooltip
              disable={!(rawEvent?.firstName || rawEvent.lastName || rawEvent.name)}
              title={
                rawEvent.firstName || rawEvent.lastName
                  ? `${rawEvent.firstName || ''} ${rawEvent.lastName || ''}`
                  : rawEvent.name
              }
            >
              <div className="event-name">{rawEvent.name}</div>
            </ConditionalTooltip>
            <Popover
              placement="rightTop"
              title=""
              content={
                <DeleteButtonWithConfirmation
                  loading={deleteLoading}
                  onDelete={async () => {
                    handleDeleteEvent(
                      selectedEvent?.event?.id,
                      // @ts-ignore
                      selectedEvent?.event?.version,
                      eventType,
                      () => {
                        handleSetSelectedEvent(undefined);
                      }
                    );
                  }}
                />
              }
              trigger="click"
            >
              <Button
                className="ant-btn__without-background"
                type="ghost"
                data-testid="booking-delete-button"
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <DefaultSmallLoader makeItCenter loading={deleteLoading} />
                ) : (
                  <IoEllipsisHorizontalCircle />
                )}
              </Button>
            </Popover>
          </div>
          <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
            <AiFillClockCircle color="rgba(0,0,0,0.35)" />
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.35)' }}>
              {moment(rawEvent.createdAt).fromNow() || 'Keine Angabe'}
            </div>
            {selectedEvent?.event?.type && (
              <Tag className="mx-2" color="green">
                {selectedEvent.event.type}
              </Tag>
            )}
          </div>
        </div>
      </div>

      <Select
        className="w-100"
        mode="multiple"
        placeholder="Tags..."
        optionFilterProp="label"
        value={selectedEventTags}
        onChange={handleTagChange}
        onSearch={handleSearch}
        tagRender={props => {
          const { value, closable, onClose } = props;
          const tag = allBookingTags.find(
            (tag: BookingTag) => tag.id === value || tag.name === value
          );
          const color = tag?.color || 'blue';
          return (
            <Tag color={color} closable={closable} onClose={onClose} className="m-1 rounded">
              {tag?.name}
            </Tag>
          );
        }}
      >
        {allBookingTags.map((bookingTag: BookingTag, idx: number) => (
          <Select.Option key={idx} label={bookingTag.name} value={bookingTag.id || bookingTag.name}>
            <div className="d-flex justify-content-between align-items-center">
              <Tag className="rounded" color={bookingTag.color}>
                {bookingTag.name}
              </Tag>

              {bookingTag?.coachId === coachId && (
                <Button
                  type="link"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedTag(bookingTag);
                    setTagsUpdateModalVisible(true);
                  }}
                >
                  <MoreOutlined className="more-icon" />
                </Button>
              )}
            </div>
          </Select.Option>
        ))}
        {inputValue && !allBookingTags.find((tag: BookingTag) => tag.name === inputValue) && (
          <Select.Option key="new" label={inputValue} value={inputValue}>
            <Tag className="rounded" color="blue">
              {inputValue}
            </Tag>
          </Select.Option>
        )}
      </Select>
      <BookingTagsUpdateModal
        tagsUpdateModalVisible={tagsUpdateModalVisible}
        setTagsUpdateModalVisible={setTagsUpdateModalVisible}
        selectedTag={selectedTag}
        selectedEvent ={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
      <Tabs defaultActiveKey={eventDrawerTabView}>
        <Tabs.TabPane tab="Kontakt" key={EventDrawerTabView.CONTACT}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {eventType !== 'tracking' ? (
              <div>
                <Select
                  // @ts-ignore
                  defaultValue={
                    <div className="d-flex align-items-center" style={{ columnGap: 8 }}>
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          borderRadius: '50%',
                          backgroundColor: currentStatusColor
                        }}
                      ></div>
                      <span data-testid="current-booking-status">
                        {getTextFromHtml(currentStatus)}
                      </span>
                    </div>
                  }
                  value={currentStatus}
                  style={{ width: '100%' }}
                  loading={statusChangeLoading}
                  onChange={handleChangeStatus}
                  {...(process.env.NODE_ENV === 'test' && { open: true })}
                  data-testid="booking-status-selection"
                >
                  {bookingStatus &&
                    bookingStatus.map(single => {
                      const colorIndex = availableStatus
                        .map(val => val.status)
                        .indexOf(single.status);
                      return (
                        <Select.Option key={single.id} value={single.value} data={single}>
                          <div
                            className="d-flex align-items-center"
                            style={{ columnGap: 8 }}
                            data-testid={single.status}
                          >
                            <div
                              style={{
                                height: 12,
                                width: 12,
                                borderRadius: '50%',
                                backgroundColor: availableStatus[colorIndex]?.color
                              }}
                            ></div>
                            <span>{getTextFromHtml(single.status)}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            ) : null}

            {eventType !== 'tracking' ? (
              <div>
                <Typography.Title level={4}>Kontakt</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Name">
                    <Paragraph
                      className="editable-paragraph"
                      editable={{
                        onChange: newValue => handleFieldChange('name', newValue)
                      }}
                    >
                      {applicantData?.name ? applicantData.name : 'Keine Angabe'}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="E-Mail">
                    <Paragraph
                      className="editable-paragraph"
                      editable={{
                        onChange: newValue => handleFieldChange('email', newValue)
                      }}
                    >
                      {applicantData?.email ? applicantData.email : 'Keine Angabe'}
                    </Paragraph>
                  </Descriptions.Item>
                  <Descriptions.Item label="Telefon">
                    {!openInput && (
                      <div
                        onMouseEnter={() => setShowEditIcon(true)}
                        onMouseLeave={() => setShowEditIcon(false)}
                        style={{marginBottom: '14px'}}
                      >
                        <span
                          className={`cursor-pointer editable-paragraph ${applicantData.phone ? 'link-style': ''}`}
                          onClick={applicantData.phone ? handlePhoneClick : () => {}}
                        >
                          {applicantData.phone
                            ? applicantData.phone.startsWith('+')
                              ? applicantData.phone
                              : '+' + applicantData.phone
                            : 'Keine Angabe'}
                        </span>
                        <span
                          onClick={() => {
                            setOpenInput(true);
                          }}
                          className="cursor-pointer"
                        >
                          <Tooltip title="Bearbeiten">
                            <EditOutlined
                              style={{ marginLeft: 8, opacity: showEditIcon ? 1 : 0 }}
                            />
                          </Tooltip>
                        </span>
                      </div>
                    )}
                    {openInput && (
                      <div ref={inputRef} >
                        <Input
                          value={applicantData.phone ? parseFloat(applicantData.phone) : ''}
                          onChange={(e: any) => {
                            handlePhoneChange(e.target.value);
                          }}
                          onKeyPress={handleKeyPress}
                          autoFocus
                          type='number'
                        />
                      </div>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Datum">
                    {' '}
                    {moment(rawEvent.createdAt).format(dateFormats.dateReadableMonth)}
                  </Descriptions.Item>
                  {rawEvent.generalPurpose && (
                    <Descriptions.Item label="Zusatzfeld">
                      {rawEvent.generalPurpose}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            ) : null}

            <NotReachedModal
              visible={notReachedModalVisible}
              setVisibility={setNotReachedModalVisible}
              eventId={selectedEvent?.event?.id}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              handleUpdateBookingEvents={handleUpdateBookingEvents}
            />

            <DeclineApplicantModal
              visible={declineApplicantModalVisible}
              setVisibility={setDeclineApplicantModalVisibility}
              eventId={selectedEvent?.event?.id}
            />

            {eventType !== 'tracking' && rawEvent?.utmParameter ? (
              <div>
                <Typography.Title level={4}>Tracking</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="UTM-Parameter" style={{ lineBreak: 'anywhere' }}>
                    {rawEvent.utmParameter || 'Keine Angabe'}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Eingaben" key={EventDrawerTabView.INPUTS}>
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <ApplicantEngagement applicantEngagementData={applicantEngagementData} />

            {eventType === 'tracking' ? (
              <div>
                <Typography.Title level={4}>Übersicht</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Start">{`${moment(rawTracking.createdAt).format(
                    dateFormats.dateTime
                  )}`}</Descriptions.Item>
                  <Descriptions.Item label="Gesamtzeit">
                    {getTimeString(rawTracking.totalDurationInSeconds)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Schritte im Funnel">
                    {rawTracking.progressCollectors.length}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}

            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              {Object.keys(choiceCollector).length && !isEditing ? (
                <div>
                  <Typography.Title level={4}>Antworten</Typography.Title>
                  <Descriptions column={1} layout="vertical" bordered>
                    {Object.keys(choiceCollector).map((key, idx) => (
                      <Descriptions.Item
                        key={idx}
                        label={
                          choiceCollector[key].time
                            ? `[Zeit: ${choiceCollector[key].time}] ${key}`
                            : key
                        }
                      >
                        {choiceCollector[key].voiceMessageAWSKey ? (
                          <AWSKeyVoiceMessagePlayer
                            voiceMessageAWSKey={choiceCollector[key].voiceMessageAWSKey}
                          />
                        ) : choiceCollector[key].value.length ? (
                          `Wert: ${choiceCollector[key].value.join(', ')}`
                        ) : (
                          `Auswahl: ${choiceCollector[key].title.join(', ')}`
                        )}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>

                  <Button
                    className="mt-4"
                    type="primary"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    Bearbeiten
                  </Button>
                </div>
              ) : (
                <LeadQualifierForm
                  leadQualifierData={leadQualifierData?.getLeadQualifier}
                  leadQualifierLoading={leadQualifierLoading}
                  setIsEditing={setIsEditing}
                  isEditing={isEditing}
                  selectedEvent={selectedEvent}
                />
              )}
            </Space>

            {eventType === 'tracking' ? (
              <div>
                <Typography.Title level={4}>Quelle</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Link">{rawTracking.source}</Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}

            {rawEvent.eventStart ? (
              <div>
                <Typography.Title level={4}>Termin</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Start">
                    {moment(rawEvent.eventStart).format(dateFormats.dateTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ende">
                    {moment(rawEvent.eventEnd).format(dateFormats.dateTime)}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notizen" key={EventDrawerTabView.NOTES}>
          {eventType !== 'tracking' ? <Notes eventId={selectedEvent?.event.id} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Uploads" key={EventDrawerTabView.UPLOAD}>
          {eventType !== 'tracking' && (
            <div>
              <Typography.Title level={4}>Dateien</Typography.Title>
              <FilesTable handleDownloadFile={handleDownloadFile} selectedEvent={selectedEvent} />
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kommunikation" key={EventDrawerTabView.COMMUNICATION}>
          {eventType !== 'tracking' && (
            <div>
              <CommunicationTable selectedEvent={selectedEvent} />
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Bewertung" key="5">
          {eventType !== 'tracking' && (
            <BookingRating
              selectedEvent={selectedEvent?.event}
              clearEventCache={clearEventCache}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default EventDetailContent;

const prepareBookingContent = (booking: BookingInterface) => {
  const content = (
    <React.Fragment>
      <label>
        <FunnelPlotOutlined /> Funnel:{' '}
      </label>
      <p>{booking?.funnelName}</p>
      <label>
        <UserOutlined /> Name
      </label>
      <p>{booking.name}</p>
      <label>
        <MailOutlined /> E-Mail
      </label>
      <p>{booking.email}</p>
      <label>
        <PhoneOutlined /> Telefon
      </label>
      <p>
        {booking.phone
          ? `${booking.phone.startsWith('+') ? booking.phone : '+' + booking.phone}`
          : 'Keine Angabe'}
      </p>
    </React.Fragment>
  );

  const title = formatEventTitle(booking.eventStart, booking.eventEnd);

  return {
    content,
    choices: booking.choices,
    title: <Badge color={booking.eventStart ? 'green' : 'blue'} text={title} />
  };
};

const prepareTrackingContent = (tracking: TrackingInterface) => {
  const getTimeString = (seconds: number) => {
    let timeString: string;
    if (seconds < 60) {
      timeString = `${seconds}s`;
    } else {
      let minutes = Math.floor(seconds / 60);
      timeString = `${minutes}min ${seconds % 60}s`;
    }

    return timeString;
  };

  const content = (
    <React.Fragment>
      <label>
        <FunnelPlotOutlined /> Funnel:{' '}
      </label>
      <p>{tracking.funnelName}</p>
      <label>
        <CalendarOutlined /> Interaktionsstart
      </label>
      <p>{`${moment(tracking.createdAt).format('DD.MM.YYYY HH:mm:ss')} Uhr`}</p>
      <label>
        <ClockCircleOutlined /> Gesamtzeit
      </label>
      <p>{getTimeString(tracking.totalDurationInSeconds)}</p>
      <label>
        <RocketOutlined /> Anzahl der Schritte insgesamt
      </label>
      <p>{tracking.progressCollectors.length}</p>
    </React.Fragment>
  );

  let choices: AdaptedBookingChoiceInterface[] = [];

  tracking.progressCollectors
    .filter((pc: ProgressCollectorInterface) => pc.leadQualifierId)
    .forEach((pc: ProgressCollectorInterface) => {
      // @ts-ignore
      const transformedChoices: AdaptedBookingChoiceInterface[] = pc.choices.map(
        (c: ProgressCollectorChoiceInterface) => ({
          leadQualifierId: pc.leadQualifierId,
          totalDurationInSeconds: getTimeString(pc.durationInSeconds),
          ...c
        })
      );
      choices = [...choices, ...transformedChoices];
    });

  return { content, choices, title: <Badge status="warning" text="Unvollständige Anfrage" /> };
};
