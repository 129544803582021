import React, { useEffect } from 'react';
import { Drawer, Skeleton } from 'antd';
import EventDetailContent, { PropsInterface } from './EventDetailContent';
import { useEventsContext } from '../../context/EventsContext';
import { EventDrawerTabView } from '../../context/EventsContext.types';
import { SelectedEvent } from '../../container/Events';

function EventModal({
  clearEventCache,
  eventLoading,
  handleUpdateBookingEvents,
  handleBookingChange,
  selectedEvent,
  setSelectedEvent,
  ...rest
}: PropsInterface) {
  const {
    setEventDrawerTabView,
    handleCreateBookingTags,
    handleSetSelectedEvent,
  } = useEventsContext();

  useEffect(() => {
    const helpButton = document.getElementById('help-button-container') as any;
    const isVisible = !!selectedEvent || eventLoading;
    if (helpButton?.style) helpButton.style.display = isVisible ? 'none' : 'block';
  }, [!!selectedEvent, eventLoading]);

  return (
    <div className="events__event" data-testid="event-item-drawer">
      <Drawer
        className="event-summary-modal"
        title="Zusammenfassung"
        visible={!!selectedEvent || eventLoading}
        onClose={() => {
          selectedEvent?.event?.id &&
            handleCreateBookingTags(selectedEvent?.event?.id, selectedEvent as SelectedEvent);
          handleSetSelectedEvent(undefined);
          setEventDrawerTabView(EventDrawerTabView.CONTACT);
        }}
      >
        {eventLoading ? (
          <Skeleton active />
        ) : (
          selectedEvent && (
            <EventDetailContent
              {...rest}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              handleUpdateBookingEvents={handleUpdateBookingEvents}
              clearEventCache={clearEventCache}
              handleBookingChange={handleBookingChange}
            />
          )
        )}
      </Drawer>
    </div>
  );
}

export default EventModal;
