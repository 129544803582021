export const GeneralMessages: { [key: string]: any } = {
  success: 'Erfolgreich gespeichert',
  error: 'Oopps! Etwas ist schief gelaufen. Bitte versuche es erneut.',
  graphql: {
    ['Identifier is not unique.']:
      'Die URL ist nicht einzigartig. Die Grundeinstellungen wurden nicht gespeichert. Bitte versuche es erneut.',
    default: 'Oopps! Etwas ist schief gelaufen. Bitte versuche es erneut.'
  }
};

export const LoginMessages = {
  noCookiesAllowed:
    'Du kannst dich nicht einloggen, da dein Browser keine Cookies erlaubt. Dies passiert z.B. im Inkognito-Modus.',
  generalLoginError: 'Es gab einen Fehler beim Login. Bitte versuche es erneut.',
  accountDisabledMEssage:
    'Dein Account wurde deaktiviert. Bei Fragen melde dich bitte bei support@meetovo.de.',
  getUsersError: 'Die Benutzer konnten nicht geladen werden. Bitte versuche es später erneut.',
  hasNoRefreshTokenError:
    'Bitte lösche die Zugriffsberechtigung in deinem Google-Konto und logge dich erneut ein.',
  loginByAdminError:
    'Es konnte sich nicht ins Benutzer-Profil über den Adminzugang eingeloggt werden.',
  couldntSetAlternativeEmail: 'Die alternative E-Mail-Adresse konnte nicht gesetzt werden.',
  invalidEmail: 'Die E-Mail-Adresse ist nicht valide.',
  couldntLoginToGoogleAccountalreadyRegisteredViaEmail:
    'Du bist bereits mit deiner E-Mail-Adresse registriert. Logge dich per E-Mail ein und verbinde deinen Google Account unter Connect.',
  checkboxNotChecked: 'Bitte akzeptiere die Nutzungsbedingungen und die Datenschutzerklärung.',
  automaticLogout: 'Dein Token ist abgelaufen. Du wurdest automatisch ausgeloggt.'
};

export const BusinessHoursMessages = {
  overlappingShifts: 'Die Arbeitszeiten dürfen sich nicht überschneiden.',
  startBeforeEnd: 'Die Startzeit muss vor der Endzeit liegen.',
  endBeforeEnd: 'Die Startzeit muss vor der Endzeit liegen.'
};

export const SpecialClosuresMessages = {
  startBeforeEnd: 'Die Startzeit muss vor der Endzeit liegen.'
};

export const ServiceMessages = {
  setServicesSuccess: 'Die Leistungen wurden erfolgreich aktualisiert.',
  setServicesError: 'Die Leistungen konnten nicht aktualisiert werden.'
};

export const EventsMessages = {
  loadingError: 'Bitte aktualisiere die Seite und versuche es erneut.',
  deleteBookingSuccess: 'Die Bewerbung wurde erfolgreich gelöscht.',
  deleteBookingError: 'Die Bewerbung konnte nicht gelöscht werden.',
  deleteTrackingSuccess: 'Die unvollständige Anfrage wurde erfolgreich gelöscht.',
  deleteTrackingError: 'Die unvollständige Anfrage konnte nicht gelöscht werden.',
  deleteBookingNotPermitted: 'Du bist nicht berechtigt den Kontakt zu löschen.',
  pleaseChooseAFunnelBeforeExport: 'Wähle erst einen Funnel im Filter aus.',
  noDataToExport: 'Es sind keine Daten verfügbar',
  downloadFileInProgress: 'Die Datei wird heruntergeladen...',
  downloadFileFailed: 'Die Datei konnte nicht heruntergeladen werden. Bitte versuche es erneut.',
  audioFileAllowed: 'Es sind nur MP3-, M4A- und OGG-Dateien erlaubt.',
  tooManyBookings:
    'Es werden nur maximal 500 Bewerbungen im Kanban-Board angezeigt. Bitte nutze die Tabellenansicht, um mehr Bewerbungen zu filtern.',
  ratingNotPermitted:
    'Diese Bewertung wurde von einer anderen Person abgegeben und kann daher nicht bearbeitet werden. Du hast die Möglichkeit, die Bewertung zu löschen und eine neue zu erstellen.',
  deleteRatingSuccess: 'Bewertung erfolgreich gelöscht',
  updateRatingSuccess: 'Bewertung erfolgreich abgegeben',
  updateRatingError: 'Fehler beim Aktualisieren der Bewertung'
};

export const AnalyticsMessages = {
  loadingError: 'Es gab einen Fehler beim Laden der Daten. Bitte versuche es erneut.',
  noPageHitData: 'Es sind keine Daten vorhanden.',
  noSourceData: 'Es sind keine Daten vorhanden.',
  noLocationData: 'Es sind keine Daten vorhanden.',
  analyticsDeleted: 'Die Analysedaten wurden zurückgesetzt.',
  analyticsErrorDuringDeletion:
    'Die Analysedaten konnten nicht zurückgesetzt werden. Bitte versuche es erneut.'
};

export const ProfileMessages = {
  loadingError: 'Es gab einen Fehler beim Laden der Profildaten. Bitte versuche es erneut.',
  setUserInfoSuccess: 'Deine Einstellungen wurden erfolgreich gespeichert.',
  setUserInfoError: 'Deine Einstellungen konnten nicht gespeichert werden.'
};

export const FunnelMessagesApiErrors: { [key: string]: string } = {
  'Identifier is not unique.': 'Die URL ist nicht verfügbar.'
};

export const FunnelMessages = {
  funnelIsBeingMutated: 'Der Funnel wird bearbeitet.',
  noMoreFunnelsAvailable: (funnelCount: number, maxFunnelsCount: number) =>
    `Du hast bereits ${funnelCount}/${maxFunnelsCount} aktive Funnels`,
  funnelCouldntDuplicated: 'Der Funnel konnte nicht kopiert werden.',
  uniqueIdentifierNotAvailable: 'Die URL ist nicht verfügbar.',
  twoTabsOpen:
    'Du kannst nur in einem Tab/Fenster bearbeiten, damit andere Änderungen nicht überschrieben werden.',
  getAllFunnelsError:
    'Es gab einen Fehler beim Laden der Funnel-Informationen. Bitte versuche es erneut.',
  alterFunnelSelectionError: 'Der Funnel konnte nicht gewechselt werden.',
  alterFunnelSelectionSuccess: 'Der Funnel wurde erfolgreich gewechselt.',
  getFunnelInfoError: 'Es gab einen Fehler beim Laden des Funnels. Bitte versuche es erneut.',
  deleteFunnelSuccess: 'Der Funnel wurde erfolgreich gelöscht.',
  deleteFunnelError: 'Es gab einen Fehler beim Löschen des Funnels. Bitte versuche es erneut.',
  couldntAddFunnel: 'Der neue Funnel konnte nicht hinzugefügt werden.',
  funnelBuilderOnlyOnDesktop:
    'Der Funnel-Builder ist nur im Vollbild auf dem PC oder Laptop verfügbar. Bitte vergrößere dein Fenster bzw. wechsele zu deinem PC oder Laptop.',
  newBuilderAlreadyOpen:
    'Der neue Builder ist in einem anderen Tab geöffnet. Öffne deinen Funnel erneut, um Komplikationen zu vermeiden.',
  apiErrors: {
    ...FunnelMessagesApiErrors
  },
  couldNotOpenFunnel: 'Der Funnel konnte nicht geöffnet werden. Bitte versuche es erneut.'
};

export const EmailSettingsMessages = {
  setEmailSettingsError: 'Es gab einen Fehler bei der Aktualisierung der Email-Einstellungen.',
  setEmailSettingsSuccess: 'Die Email-Einstellungen wurden erfolgreich aktualisiert.',
  formIncomplete: 'Bitte fülle alle Pflichtfelder aus.'
};

export const UltimateImagePickerMessages = {
  couldntLoadPexelsImages:
    'Es konnten keine Bilder geladen werden. Bitte versuche es später noch einmal.',
  couldntLoadIcons: 'Es konnten keine Icons geladen werden. Bitte versuche es später noch einmal.',
  noImageCropped: 'Bitte wähle einen Bildauschnitt, um zu speichern.'
};

export const GeneralSettingsMessages = {
  setGeneralSettingsError: 'Es gab einen Fehler bei der Aktualisierung der Grundeinstellungen.',
  setGeneralSettingsSuccess: 'Die Grundeinstellungen wurden erfolgreich aktualisiert.',
  colorsEqual:
    'Wenn beide Farben gleich sind, kann es dazu führen, dass bestimmte Elemente nicht sichbar sind.',
  profileImageDescription:
    'Dieses Bild wird bei Sprachnachrichten oder über der Dateneingabe am Ende angezeigt.',
  logoDescription: 'Dieses Logo wird in allen Schritten im Kopfbereich angezeigt.',
  expertModeInfo:
    'Wenn der Expertenmodus aktiviert ist, kannst du die Farben und Pre-Pages individueller einstellen. Für ein professionelles Ergebnis solltest du allerdings ein gutes gestalterisches Auge haben.'
};

export const SocialProofSettingsMessages = {
  setSocialProofSettingsError:
    'Es gab einen Fehler bei der Aktualisierung der SocialProof-Einstellungen.',
  setSocialProofSettingsSuccess: 'Die SocialProof-Einstellungen wurden erfolgreich aktualisiert.',
  providerNotConnectedHint: 'Dieses Bewertungsportal muss erst unter Connect verbunden werden.'
};

export const LegalSettingsMessages = {
  setLegalSettingsError:
    'Es gab einen Fehler bei der Aktualisierung der Rechtlichen-Einstellungen.',
  setLegalSettingsSuccess: 'Die Rechtlichen-Einstellungen wurden erfolgreich aktualisiert.',
  tippGoogleDoc:
    'Tipp: Wenn du keine Website mit Impressum und Datenschutz hast, dann erstelle einfach ein öffenliches Google-Dokument.'
};

export const SubmitFormSettingsMessages = {
  setSubmitFormSettingsError: 'Es gab einen Fehler bei der Aktualisierung des Formulars.',
  setSubmitFormSettingsSuccess: 'Das Formular wurde erfolgreich aktualisiert.'
};

export const CalendarSettingsMessages = {
  setCalendarSettingsError:
    'Es gab einen Fehler bei der Aktualisierung der Kalender-Einstellungen.',
  setCalendarSettingsSuccess: 'Die Kalender-Einstellungen wurden erfolgreich aktualisiert.',
  noLivePreveiwWarning: 'Die Zeiteinstellungen werden nicht in die Vorschau übernommen.'
};

export const WelcomePageMessages = {
  setWelcomePagesError: 'Es gab einen Fehler bei der Aktualisierung.',
  setWelcomePagesSuccess: 'Die Pages wurden erfolgreich aktualisiert.'
};

export const BookingLinkMessages = {
  noLinkAvailableError: 'Es ist kein Link verfügbar.'
};

export const ContractMessages = {
  setNewContractsSuccess:
    'Deine Angaben zu unseren Nutzungsverträgen wurden erfolgreich gespeichert.',
  setNewContractsError:
    'Deine Angaben zu unseren Nutzungsverträgen konnten nicht gespeichert werden.'
};

export const BookingButtonMessages = {
  noLinkAvailableError: BookingLinkMessages.noLinkAvailableError,
  embedInformation:
    'Damit MEETOVO auf deinen Button reagiert, muss diesem die ID "meetovo-booking__custom-button" zugewiesen werden.'
};

export const BrowserActions = {
  copySuccess: 'In der Zwischenablage gespeichert.'
};

export const UIMessages = {
  noDashboardInfoError: 'Das Dashboard konnte nicht geladen werden.',
  elementsAlreadyOpen: 'Ziehe ein Element von der linken Seite in die Vorschau.'
};

export const RouteMessages = {
  notFoundError: 'Diese Seite kann zur Zeit nicht abgerufen werden.'
};

export const WelcomePageBuilderMessages = {
  formIncomplete: 'Das Formular wurde nicht vollständig ausgefüllt',
  couldntUploadImage: 'Das Bild konnte nicht hochgeladen werden. Bitte versuche es erneut.'
};

export const GeneralComponentsMessages = {
  noBlobSupport: 'Dein Browser unterstützt diese Funktion nicht.',
  orderingDisabled:
    'Aufgrund der Analysen kannst du die Reihenfolge nach dem Erstellen nicht mehr ändern.',
  currentStepFormIncomplete: 'Bitte fülle alle Pflichtfelder aus.'
};

export const ThankyouPageSettingsMessages = {
  setThankyouPageSettingsError: 'Es gab einen Fehler bei der Aktualisierung der Dankesseite.',
  setThankyouPageSettingsSuccess: 'Die Dankesseite wurde erfolgreich aktualisiert.'
};

export const LeadQualifierMessages = {
  setLeadQualifierSuccess: 'Lead-Qualifier wurden erfolgreich aktualisiert.',
  setLeadQualifierError: 'Es gab einen Fehler bei der Aktualisierung der Lead-Qualifier.',
  leadQualifierNotCompleted: 'Bitte fülle alle Pflichtfelder des Lead-Qualifiers aus.',
  sortingNotAllowed:
    'Aufgrund der Analysen kannst du die Reihenfolge nach dem Speichern nicht mehr ändern.'
};

export const ConnectSettingsMessages = {
  setRatingsProviderSettingsError:
    'Es gab einen Fehler bei der Aktualisierung des Bewertungsportals.',
  setRatingsProviderSettingsSuccess: 'Dein Bewertungsprofil wurde gespeichert.',
  setThirdPartyTrackingSettingsError:
    'Es gab einen Fehler bei der Aktualisierung deiner Tracking-Informationen.',
  setThirdPartyTrackingSettingsSuccess: 'Deine Tracking-Informationen wurden gespeichert.',
  setDomainsError: 'Deine Domains konnten nicht gespeichert werden. Bitte versuche es erneut.',
  setDomainsSuccess: 'Deine Domains wurden gespeichert.',
  couldNotAddDomainBeforeIsInvalid: 'Die vorherige Domain ist nicht valide.',
  couldNotDisconnectGoogleAccount:
    'Die Verbindung zu deinem Google Account konnte nicht aufgehoben werden. Bitte versuche es erneut.',
  disconnectGoogleAccountSuccess:
    'Bitte beachte, dass die Verbindung zu deinem Google Account erst vollständig aufgehoben ist, wenn du MEETOVO aus deinem Account entfernst.',
  domainAlreadyExists: 'Diese Domain existiert bereits.',
  domainAlreadyConnected:
    'Diese Domain ist bereits verbunden und kann nicht mehrfach verbunden werden. Bitte wähle eine andere Domain.',
  notEnaugthDomains:
    'Du hast nicht genügend Domains in deinem Paket. Bitte mache ein Upgrade oder sprich uns für ein Custom-Paket über den Support-Chat an.',
  duplicateDomain: 'Du hast eine oder mehrere Domains doppelt angegeben.',
  domainNotValid:
    '<p>Die Subdomain ist nicht valide. Erfahre im <a href="https://hilfe.meetovo.de/de/article/eigene-subdomain-domain-verknupfen-woykz1/#3-wie-erkenne-ich-eine-subdomain">Hilfeartikel<a/>, was eine Subdomain ist. Beispiel: sub.domain.de</p>',
  notEnoughSubDomains:
    'Du hast nicht genügend Subdomains in deinem Paket. Bitte mache ein Upgrade oder sprich uns für ein Custom-Paket über den Support-Chat an.',
  slugNotValid: 'Der Slug ist nicht valide. Beispiel: /dein-slug oder /',
  duplicateSlugs: 'Ein Slug darf pro Domain nur ein mal verwendet werden.',
  addSlugFirst: 'Bitte füge zuerst eine Domain hinzu.',
  duplicateResources: 'Diese Einstellungen werden zurzeit von einem anderen Nutzer bearbeitet.',
  maxSlugCountReached: (maximumSlugs: number) =>
    `Du kannst pro Subdomain nur ${maximumSlugs} Slugs verbinden. Du kannst im "Pro-Paket" beliebig viele nachbuchen. Kontaktiere uns im Support-Chat.`,
  slugConnectionDuration:
    'Es dauert zwischen 5-15 Min. bis ein neuer Slug aufrufbar ist, nachdem du ihn verbunden hast.'
};

export const AccountOverviewMessages = {
  advContract:
    'Wenn du noch keinen ADV-Vertrag nach DSGVO mit uns geschlossen hast, dann melde dich über den Support-Chat. Wir schicken ihn dir.',
  couldntGetAccountOverview: 'Es gab einen Fehler beim Laden der Daten.',
  moreVisitsContingent:
    'Keine Sorge, wir werden dich benachrichtigen, wenn noch 20% deiner Aufrufe übrig sind. Wenn du weitere benötigst, dann mache einfach ein Upgrade oder melde dich für ein Custom-Paket bei uns.',
  moreFunnelContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine weiteren Funnels erstellt werden. Wenn du weitere benötigst, dann mache einfach ein Upgrade oder melde dich für ein Custom-Paket bei uns.',
  moreWidgetContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine weiteren Widgets erstellt werden. Wenn du weitere benötigst, dann melde dich einfach für ein Custom-Paket bei uns.',
  moreGoogleJobsContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine weiteren Google Jobs veröffentlicht werden. Wenn du weitere benötigst, dann melde dich einfach für ein Custom-Paket bei uns.',
  moreSmsContingent:
    'Wenn das Kontingent aufgebraucht ist, werden automatisch weitere SMS in 100 Schritten gebucht.',
  moreDomainContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine weiteren Domains verbunden werden. Wenn du weitere Domains benötigst, dann mache einfach ein Upgrade oder melde dich für ein Custom-Paket bei uns.',
  moreSubDomainContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine Subdomains mehr verbunden werden. Mit jeder Domain kommen 5 Subdomains. Wenn du mehr Domains benötigst, mache einfach ein Upgrade oder melde dich für ein Custom-Paket bei uns.',
  googleProfileSync:
    'Solltest du mit Google registriert sein, ändere deine Daten einfach bei Google, anschließend musst du dich erneut einloggen, dann werden die Änderungen übernommen.',
  agencyDashboardContingent:
    'Wenn das Kontingent aufgebraucht ist, können keine weiteren Nutzer hinzugefügt werden. Wenn du weitere Nutzer benötigst, dann mache einfach ein Upgrade oder melde dich für ein Custom-Paket bei uns.',
  moreSmsCreditsContingent:
    'Wenn das Kontingent aufgebraucht ist, kannst du keine weiteren SMS an Bewerber senden. Wenn du zusätzliche Credits benötigst, führen einfach ein Upgrade durch oder kontaktiere uns für ein individuelles Paket.',
  moreExtraSmsCreditsContingent:
    'Dieses Kontingent wird automatisch hinzugefügt, wenn dein Inklusivkontingent aufgebraucht ist.',
  noInvoicesAvailable: 'Es sind keine Rechnungen verfügbar.',
  freezePlanSuccess: 'Der Freeze-Plan wurde aktiviert',
  freezePlanError: 'Der Freeze-Plan konnte nicht aktiviert werden. Bitte versuche es erneut.'
};

export const AccountUpdateModalMessages = {
  accountUpdateSuccess: 'Deine Daten wurden aktualisiert.',
  accountUpdateError: 'Deine Daten konnten nicht aktualisiert werden. Bitte versuche es erneut.'
};

export const SwitchToEmailLoginModalMessages = {
  switchToEmailLoginSuccess:
    'Du hast eine "Passwort vergessen"-E-Mail erhalten. Bitte lege über den darin enthaltenen Link dein Passwort fest.',
  switchToEmailLoginError: 'Etwas ist schief gelaufen. Bitte versuche es erneut.'
};

export const AccountSharingMessages = {
  successfullSaveChanges: 'Die Aktualisierung war erfolgreich!',
  errorWhileSaveChanges: 'Es gab einen Fehler beim speichern der Account-Freigabe.'
};

export const AWSKeyVoiceMessagePlayerMessages = {
  couldNotGetUrl: 'Die Sprachnachricht konnte nicht geladen werden.'
};

export const TestimonialMessages = {
  formIncomplete: 'Das Formular wurde nicht vollständig ausgefüllt',
  placeholderDescription:
    'Nachdem du die Testimonials gespeichert hast, wird dieser Platzhalter [TESTIMONIALS] auf der Pre-Page eingefügt. Dieser kann beliebig positioniert werden und wird, sobald du deinen Funnel veröffentlichst, mit deinen Testimonials ersetzt.'
};

export const CheckoutMessages = {
  couldNotUpgrade: 'Dein Paket konnte nicht upgegradet werden. Bitte versuche es erneut.',
  upgradeSuccessfull: 'Das Upgrade bzw. Downgrade war erfolgreich.'
};

export const FunnelDisableModalMessages = {
  couldNotDisableFunnels: 'Die Funnels konnten nicht deaktiviert werden. Bitte versuche es erneut.'
};

export const LearningCenterMessages = {
  couldNotStartChallenge: 'Die Challenge konnte nicht gestartet werden, bitte versuche es erneut.',
  couldNotLoadChallenge: 'Die Challenge konnte nicht geladen werden, bitte versuche es erneut.',
  couldNotRefreshVideoPlaytime: 'Die Abspielzeit des Videos konnte nicht aktualisiert werden.'
};

export const EmailLoginAndRegistrationMessages = {
  sendPasswordChangeToken: {
    success: {
      message: 'E-Mail versendet',
      description:
        'Bitte klicke auf den Link, den wir dir per E-Mail gesendet haben, checke auch den Spam-Ordner. Anschließend kannst du dein Passwort ändern.'
    },
    error: {
      message: 'E-Mail-Adresse nicht gefunden',
      description: 'Bitte checke ob du einen Tippfehler gemacht hast.'
    }
  },
  changePasswordViaToken: {
    success: {
      message: 'Dein Passwort wurde geändert.'
    },
    error: {
      message: 'Passwort ändern fehlgeschlagen',
      description: 'Der Link zum zurücksetzen deines Passworts ist ungültig oder abgelaufen.'
    }
  },
  registration: {
    error: {
      isTempMail: {
        message: 'Wegwerf-Email-Adressen sind nicht erlaubt',
        description: 'Bitte verwende deine normale E-Mail-Adresse.'
      },
      emailExisting: {
        message: 'Registrieren fehlgeschlagen',
        description: 'Die E-Mail-Adresse ist bereits registriert.'
      }
    }
  },
  login: {
    error: {
      message: 'Login fehlgeschlagen',
      description:
        'Die Login-Daten sind falsch oder deine E-Mail-Adresse ist mit einer anderen Login-Methode registriert.'
    }
  }
};

export const GoogleConnectorMessages = {
  refreshTokenNotAvailable:
    'Du kannst deinen Google-Account nur einmal mit MEETOVO verbinden, bitte lösche die bereits vorhandene Autorisierung. Gehe dafür unter Google Profil -> Sicherheit -> Zugriff von Drittanbietern.',
  generalError: 'Dein Google Calendar konnte nicht verbunden werden. Bitte versuche es erneut.'
};

export const WelcomePageBuilderV2Messages = {
  imagesUploading: 'Die Bilder werden hochgeladen',
  imagesUploadError: 'Fehler beim hochladen. Möglicherweise wird das Format nicht unterstützt..'
};

export const ThemeStylerMessages = {
  fontInfo: 'Die Schriftart wird im ganzen Funnel für alle Texte verwendet.'
};

export const EventMessages = {
  success: GeneralMessages.success,
  error: GeneralMessages.error
};

export const BecomeAPartnerMessages = {
  success: 'Vielen Dank für deine Anfrage. Wir werden uns in Kürze bei dir melden.',
  error: 'Diese Partner-ID existiert bereits. Bitte versuche es erneut.'
};

export const AppMessages = {
  useAgencyDashboardInkognito:
    'Um dein Agency-Dashboard zu sehen, nutze den Inkognitio-Modus oder logge dich aus. Ansonsten wirst du in dein normales Dashboard weitergeleitet.',
  resetTrial: {
    successLoggedout: 'Deine Testphase wurde neugestartet. Du kannst dich nun einloggen.',
    error: 'Die Testphase konnte nicht neugestartet werden. Der Token ist ungültig.',
    apiErrors: {
      'user is still trialling':
        'Die Testphase konnte nicht neugestartet werden. Du befindest dich noch in der Testphase.',
      'user is already subscribed':
        'Die Testphase konnte nicht neugestartet werden. Du bist bereits Kunde.',
      'user is free for ever':
        'Die Testphase konnte nicht neugestartet werden. Dein Account ist kostenfrei.',
      'user has already resetted in the last 2 weeks':
        'Die Testphase konnte nicht neugestartet werden. Du hast deine Testphase bereits neugestartet.',
      'user has reached the maximum reset count':
        'Die Testphase konnte nicht neugestartet werden. Du hast deine Testphase bereits mehrfach neugestartet.'
    }
  }
};

export const AgencyDashboarMessages = {
  addNewCustomerSuccess: 'Der Kunde wurde angelegt',
  updateCustomerSuccess: 'Der Kunde wurde geändert',
  deleteCustomerSuccess: 'Der Kunde wurde gelöscht',
  changeStatusSuccess: 'Der Status wurde geändert'
};

export const ImageUploadV2Messages = {
  max8MbImage: 'Maximalgröße 8 MB',
  imageNotBigEnough: (width: number, height: number) =>
    `Das Bild muss mindestens ${width}x${height}px groß sein.`
};

export const UserInteractingComponentsMessages = {
  popoverTitle: 'Deaktiviert',
  singleUsage: 'Du kannst pro Seite nur eine Komponente verwenden, die Benutzereingaben erwartet.',
  singleUsageForTemplate:
    'Dieser Block beinhaltet eine Komponente, die Benutzereingaben erwartet. Auf dieser Seite befindet sich bereits eine dieser Komponenten.',
  noEmptyQuestion: 'Diese Frage darf nicht leer sein'
};

export const SaveIndicatorMessages = {
  popoverTitle: 'Speicherstatus',
  popoverAllSavedMessage: 'Alle Änderungen sind gespeichert',
  popoverUnsavedChanges: 'Ungespeicherte Änderungen vorhanden'
};

export const CreateNewTemplateMessages = {
  itWasNotPossibleToCreateAFunnelScreenshot:
    'Das Thumbnail des Funnels konnte nicht aktualisiert werden.',
  templateBlockCreatedSuccessfully: 'Die Vorlage wurde erfolgreich erstellt.'
};

export const UpdateTemplateMessages = {
  templateBlockUpdatedSuccessfully: 'Die Vorlage wurde erfolgreich aktualisiert.'
};

export const BuilderSettingsColorMessages = {
  saveSuccessfull: 'Die Farben und Schrift wurde gespeichert',
  couldNotSsave: 'Die Farben und Schrift konnte nicht gespeichert werden. Bitte versuche es erneut',
  colorLibraryDescription:
    'Erstelle Farbpaletten, die du in anderen Funnels wiederverwenden kannst.'
};

export const FooterComponentMessages = {
  brandingEnabled:
    'Das Branding ist ab dem Expert-Paket deaktivierbar. Du kannst unter "Mein Account" ein Upgrade durchführen.',

  customBrandingEnabled:
    '"Mein Branding" kann über das Pro-Paket aktiviert werden. Mache jetzt ein Upgrade unter "Mein Account" um die Funktion zu nutzen.'
};

export const BuilderMetaDataSettingsMessages = {
  brandingEnabled:
    'Die Meta-Daten können ab dem Expert-Paket geändert werden. Du kannst unter "Mein Account" ein Upgrade durchführen.'
};

export const ElementTemplateMessages = {
  youNeedToDrag: 'Ziehe die Elemente in die Vorschau.'
};

export const TemplatePanelMessages = {
  youNeedToClick:
    'Klicke anstatt zu ziehen. Der Block wird anstelle des ausgewählten Abschnitts eingefügt'
};

export const VoiceMessageComponentMessages = {
  uploadFailed: 'Bei Hochladen der Sprachnachricht ist ein Fehler aufgetreten.'
};

export const ContainerMessages = {
  columnsOnMobile: 'Die Spalten werden mobil immer untereinander angezeigt.'
};

export const RichTextComponentMessages = {
  changeFontFamilyInDesignSettings: 'Tipp: Ändere die Schriftart in den Design-Einstellungen'
};

export const PagesListMessages = {
  duplicatedSuccessfully: 'Die Seite wurde dupliziert und am Ende des Funnels eingefügt.',
  renameError: 'Der Name konnte nicht gespeichert werden, bitte versuche es erneut.'
};

export const CustomDomainsMessages = {
  couldNotDeleteDomain: 'Die Domain konnte nicht gelöscht werden. Bitte versuche es erneut.',
  slugsLimitExplanation:
    'Slugs werden an die Enden deiner verknüpften Domain angehängt, nach einem Schrägstrich (”/”). Auf diese Weise können mehrere Funnels mit derselben Domain verknüpft werden, wobei sich nur der hintere Teil nach dem Schrägstrich unterscheidet und somit die URL einmalig macht. Solltest du das Standard-Kontingent erschöpft haben und weitere Slugs für diese spezielle Domain benötigen, kontaktiere uns bitte im Support-Chat, um zusätzliche Slugs hinzuzufügen. Vergiss nicht, uns die betreffende Domain mitzuteilen.'
};

export const CreateNewFolderMessages = {
  folderCreatedSuccessfully: 'Der Ordner wurde erfolgreich erstellt.',
  error: 'Der Ordner wurde nicht erfolgreich erstellt.'
};

export const UpdateFolderMessages = {
  folderUpdatedSuccessfully: 'Der Ordner wurde erfolgreich umbenannt.',
  error: 'Ordnerumbenennung fehlgeschlagen.'
};

export const FunnelFolderMessages = {
  folderDeleteFailed: 'Löschen des Trichters fehlgeschlagen.'
};

export const DeclineApplicantEmailMessages = {
  success: 'Nachricht wurde erfolgreich Versendet.',
  error: 'Senden der Nachricht fehlgeschlagen.'
};

export const SmsSendingStatusMessages = {
  success: 'Die Nachricht wurde zur Versendung geplant.',
  error: 'Senden der Nachricht fehlgeschlagen.'
};

export const DontUseBrowserButtonsWarningMessages = {
  doNotUseTheBrowserButtons:
    'Nutze ausschließlich die Oberfläche von MEETOVO. Ansonsten können deine Änderungen verloren gehen.'
};
export const MoveFunnelToFolderMessage = {
  moveSuccessfully: 'Der Funnel wurde verschoben',
  funnelNotMove: 'Der Funnel konnte nicht verschoben werden. Bitte versuche es erneut.'
};

export const MoveFunnelFromFolderMessage = {
  moveSuccessfully: MoveFunnelToFolderMessage.moveSuccessfully,
  funnelNotMove: MoveFunnelToFolderMessage.funnelNotMove
};

export const CustomerManagementLogsMessages = {
  noDataFound: 'Keine Änderungen zum Anzeigen.',
  success: 'Email wurde erfolgreich Versendet.',
  error: 'Senden der Ablehnungs-E-Mail fehlgeschlagen.'
};

export const AgencyOSBuilderMessages = {
  twoTabsOpen:
    'Du kannst nur in einem Tab/Fenster bearbeiten, damit andere Änderungen nicht überschrieben werden.'
};

export const ValidatePinMessages = {
  correctPin: 'Pin erfolgreich verifiziert',
  incorrectPin: 'Ihre PIN ist falsch.'
};

export const KanbanActionMessages = {
  columnAddError: 'Spalte konnte nicht hinzugefügt werden.',
  columnDeleteError: 'Es befinden sich noch Bewerbungen aus anderen Funnels in dieser Spalte.',
  columnUpdateError: 'Spalte konnte nicht aktualisiert werden.',
  cannotAddImportantColumns: 'NEW, DECLINE und APPROVED können nicht geändert werden.'
};

export const MarketplaceMessages = {
  subscribeAddOnSuccess: 'Das Add-on wurde freigeschaltet',
  subscribeAddOnError: 'Das Add-on konnte nicht freigeschaltet werden',
  cancelAddOnSuccess: 'Das Add-on wurde gekündigt',
  cancelAddOnError: 'Das Add-on konnte nicht gekündigt werden',
  getAllAddOnsError: 'Die Add-ons konnten nicht geladen werden',
  cannotSubscribeDuringTrial: 'Du kannst während der Testphase keine Add-ons kaufen.'
};

export const NotReachedMessages = {
  info:
    'Sobald die Person kontaktiert, aber nicht erreicht wurde, klicke den unteren Button, um die Person als “Nicht erreicht” zu markieren und optional eine Nachricht zu senden. Wenn die Phase verändert wird, dann wird die Nicht-Erreicht-Markierung entfernt!'
};

export enum InvalidWebHookErrors {
  NO_URL_INPUT_VALUE = 'Die URL ist erforderlich',
  NO_FUNNEL_SELECTED = 'Der Funnel ist erforderlich',
  INVALID_URL_INPUT_STRUCTURE = 'Es muss eine valide URL angegeben werden. z.B. https://deine-seite.de'
}

export enum InvalidFacebookPixelErrors {
  INVALID_FB_PIXEL_TITLE_VALUE = 'Der Wert muss min. 3 und max. 60 Zeichen lang sein.',
  INVALID_FB_PIXEL_ID_VALUE = 'Es muss ein valider Wert angegeben werden.'
}

export const uploadFileMessage = {
  fileNotUploaded: 'Datei wurde nicht hochgeladen',
  uploadFileErrorMessage: 'Bitte wähle eine Datei aus',
  uploadFileSizeGeneralMessage: 'Etwas ist schief gelaufen, bitte wähle die Datei erneut aus.',
  uploadMessage: 'Hier klicken und eine Datei hochladen',
  uploadSuccessfulMessage: 'Upload erfolgreich',
  uploadFileSizeErrorMessage: (size: number): string =>
    `Die Dateigröße darf ${size} MB nicht überschreiten`,
  uploadFileTypeErrorMessage: (innerText: string): string =>
    `Nur ${innerText} Dateien sind zulässig.`
};

export const GoogleJobsMessages = {
  noAddOnPermission: 'Du musst zu erst das Google-Jobs-Add-on über den Marketplace hinzufügen.',
  noRemainingContingent: (maxContingent: number) =>
    `Du kannst nur maximal ${maxContingent} Google-Jobs gleichzeitig veröffentlichen. Melde dich bei uns, um weitere hinzuzufügen.`
};

export const CSVUploadMessages = {
  uploadFailed: 'CSV konnte nicht hochgeladen werden. Versuchen Sie es erneut.',
  submitFailed:
    'Es konnten nicht mehrere Buchungen hinzugefügt werden. Bitte versuchen Sie es später erneut.',
  checkAllFields: (
    names: string
  ): string => `Bitte wählen Sie alle markierten Felder aus, um zum nächsten Schritt fortzufahren.
              ${
                names
                  ? `\nUnd Sie müssen auch die ${names}-Felder auswählen.\nSie können den Standardstatus für alle im nächsten Tab auswählen, wenn Sie in diesem Schritt kein Statusfeld auswählen`
                  : ''
              }`
};

export const uniqueIdentifierMessage = {
  noLongerAccessible:
    'Bitte beachte, dass der Funnel oder das Widget unter dem alten Link nicht mehr erreichbar ist.'
};

export const CustomFontsMessages = {
  deleteFontSuccess: 'Schriftart erfolgreich gelöscht',
  deleteFontError:
    'Diese Schriftart kann nicht gelöscht werden, da sie in einem anderen Trichter oder Widget verbunden ist.',
  fontNotDeleted: 'Schriftart nicht gelöscht',
  infoUploadFont:
    'Bitte laden Sie nur in den Formaten ttf, woff und woff2 hoch, um schnelle Ladezeiten zu gewährleisten. Wenn Ihre Schriftart in einem anderen Dateityp vorliegt, konvertieren Sie sie mit einem Online-Konverter in die Formate ttf, woff und woff2.',
  fontAlreadyExist: 'Die verfügbare Schriftart ist bereits vorhanden.',
  selectedFont: 'Diese Schriftart kann nicht gelöscht werden, da sie derzeit ausgewählt ist.'
};
export const ManualBookingMessages = {
  leadQualifiersSuccessfullyCreated: 'Erfolgreich erstellt',
  info:
    'Diese importierte Bewerbung wurde einem Funnel zugeordnet. Beantworte nachträglich die Funnel-Fragen, indem du die Eingaben anklickst.',
  error: 'Manuelle Buchung fehlgeschlagen.',
  success: 'Manuelle Buchung erfolgreich.'
};

export const BookingTagsMessages = {
  confirmDelete:
    'Möchtest du diesen Tag wirklich löschen? Dieser Tag wird auch bei allen anderen Kandidaten entfernt, die diesen haben .',
  tagNameAlreadyExist: 'Dieser Tag-Name ist bereits vorhanden.'
};

export const AiTextMessages = {
  generalTitle: 'Text KI Kontingent',
  generateFailed:
    'Beim Generieren des Textes ist ein Fehler aufgetreten. Bitte versuche es erneut!',
  selectionTooLong:
    'Dein markierter Text ist zu lang. Bitte markiere weniger Text, um einen alternativen Text zu schreiben',
  funnelDuplicated:
    'Dieser Funnel ist dupliziert, daher sind auch die Daten für die Zielgruppe, KI und Job-Informationen dupliziert',
  quota: 'Kontingent',
  quotaInfo: 'Ungefähre Anzahl an Wörtern, die du noch mit dem Text KI generieren kannst.',
  markTextToUseThis:
    'Markiere einen Text und aktiviere diese Funktion, um die Text KI eine veränderte Variante schreiben zu lassen.'
};

export const JobValidationModalTexts = {
  title: 'Zielgruppeninformationen anlegen',
  goToGeneralSettings: 'Zu den Einstellungen',
  submit: 'Speichern',
  fillAllFields: 'Bitte fülle alle Felder aus'
};

export const ContactFormMessages = {
  duplicateContactFormTitle: "'Kontaktfeld' bereits anderweitig verwendet",
  duplicateContactFormMessage:
    "Dieses 'Kontaktfeld' wird bereits in einer anderen Seite oder Frage verwendet. Da eine Weiterleitung zur 'Dankesseite' nach der Eingabe der Kontaktdaten erfolgt, muss das bestehende Kontaktfeld entfernt werden, um diese Seite bearbeiten zu können.",
  contactFormPageDisableTitle: 'Die Dateneingabeseite wird deaktiviert. Möchten Sie fortfahren?',
  contactFormPageDisableMessage:
    "Ein 'Kontaktfeld' kann nur einmal im Funnel platziert werden. Nach der Dateneingabe erfolgt die sofortige Weiterleitung zur Dankesseite. Wenn du das 'Kontaktfeld' hier einfügst, wird 'Dateneingabe' unter 'Abschluss' deaktiviert.",
  disablePageCloningWithContactPage: 'Du kannst nur ein Kontaktformular pro Funnel einbauen.'
};

export const LoadingComponentMessages = {
  executeTestLoadingInfo : "Die Animation wird im Bearbeitungsmodus nicht abgespielt, sondern nur in der Live-Ansicht. Klicke hier, um sie auch hier zu testen"
}