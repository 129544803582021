import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import TemplateTypesView from './TemplateTypesView';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import RenderAdTemplates from './RenderAdTemplates';

const { TabPane } = Tabs;

interface Props {
  visible: boolean;
  toggleVisible: (data: boolean) => void;
  adGenerationData?: AdGeneration;
  hideDefaultTemplateTab?: Boolean;
  shouldGenerateAd?: Boolean;
}

const Templates = ({
  visible = false,
  toggleVisible,
  adGenerationData,
  hideDefaultTemplateTab,
  shouldGenerateAd
}: Props) => {
  const [activeTab, setActiveTab] = useState('1');

  const handleCancel = () => {
    toggleVisible(false);
  };

  const onTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Modal
      className="ads-builder-template__modal funnel-block-template-wrapper"
      visible={visible}
      width={1200}
      footer={null}
      onCancel={handleCancel}
    >
      <div style={{ position: 'relative', top: '-30px' }}>
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab="Anzeige generieren" key="1" />
          {hideDefaultTemplateTab || <TabPane tab="Eigene Vorlagen" key="2" />}
        </Tabs>
      </div>

      {activeTab === '1' ? (
        <TemplateTypesView
          toggleVisible={toggleVisible}
          adGenerationData={adGenerationData as AdGeneration}
          shouldGenerateAd={shouldGenerateAd}
        />
      ) : (
        <RenderAdTemplates toggleVisible={toggleVisible} />
      )}
    </Modal>
  );
};
export default Templates;
