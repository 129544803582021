import gql from 'graphql-tag';

export const GET_AD_GENERATION_INPUT = gql`
  query GetAdGenerationInput($nodeId: String!) {
    getAdGenerationInput(nodeId: $nodeId) {
      id
      nodeId
      employeeTestimonialName
      promptMessage
      alignment
      purpose
      type
    }
  }
`;

export default function getAdGenerationInput(adId: string) {
  return window.apolloClient.query({
    query: GET_AD_GENERATION_INPUT,
    variables: {
      nodeId: adId
    },
    fetchPolicy: 'no-cache'
  });
}
