import { Col, Row, Tabs } from 'antd';
import React from 'react'
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import { getElementColor } from '../../helper/craftJs';
import { useCurrentlyEditingTheme } from '../../redux/builderSlice';
import EmojiPicker from '../../../GeneralComponents/EmojiPicker';
import { IconType } from '../elements/IconComponent';
import FrequentlyUsedIconPicker from '../../../GeneralComponents/FrequentlyUsedIconPicker/container/FrequentlyUsedIconPicker';
import IconPickerGrid from '../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';


const { TabPane } = Tabs;

const FAQPreset = ({ element, onChange, values }: any) => {
    const theme = useCurrentlyEditingTheme();
    return (
        <>
            <Row
                className={`builder__settings-sidebar__row`}
            >
                <Col span={11}>
                    <BuilderSettingsTitle title="Hintergrund" />
                    <BuilderColorPickerButton

                        color={getElementColor(values.backgroundColor, theme)}
                        onChange={(backgroundColor, colorKey) => {
                            onChange("backgroundColor", colorKey)
                        }}
                        showColorTypeBtns={true}
                    />
                </Col>
                <Col span={11} offset={1}>
                    <BuilderSettingsTitle title="Icon" />
                    <BuilderColorPickerButton
                        color={getElementColor(values.cardIconColor, theme)}
                        onChange={(cardIconColor, colorKey) => {
                            onChange("cardIconColor", colorKey)
                        }}
                        showColorTypeBtns={false}
                    />
                </Col>
            </Row>
            <Row className="builder__settings-sidebar__row">
                <Col span={22}>
                    <Tabs
                        className={`tabs__reset-left-padding
                            }`}
                    >
                        <TabPane tab="Icons" key={IconType.icon}>
                            <Row className="builder__settings-sidebar__row ">
                                <Col span={24}>
                                    <BuilderSettingsTitle title="Häufig verwendet" />
                                    <FrequentlyUsedIconPicker
                                        value={values.icon}
                                        onChange={(value: string) => {
                                            onChange("icon", value)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="builder__settings-sidebar__row">
                                <Col span={24}>
                                    <IconPickerGrid
                                        onChange={value => {
                                            onChange("icon", value)
                                        }}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Emoji's" key={IconType.emoji} style={{ height: 600 }}>
                            <EmojiPicker
                                onChange={value => {
                                    onChange("icon", value)
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </>

    )
}

export default FAQPreset