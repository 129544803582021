import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, PlusCircleFilled, PlusOutlined } from '@ant-design/icons';
import { GrFormClose } from 'react-icons/gr';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';

interface ActionProps {
  previewImageURL: string;
  deletable: boolean;
  children: React.ReactNode;
  onDelete: () => any;
  deleteCallback?: () => void;
  title: string;
  onSelect: () => void;
  onEdit: () => void;
  plusIconFilled?: boolean;
}
const ActionProviderHOC = ({
  children,
  onDelete,
  deleteCallback,
  deletable,
  title,
  onEdit,
  onSelect,
  previewImageURL,
  plusIconFilled = false
}: ActionProps) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(false);
  return (
    <>
      <div className="d-flex">
        <p className="ellipsis-title small-label">{title || ''}</p>
        {deletable && (
          <div className="settings-title__right px-1 pe-auto">
            <EditOutlined className="me-2" onClick={onEdit} />
            {toBeDeleted ? (
              <div className="d-flex align-items-center">
                <GrFormClose
                  size={'20'}
                  className="cursor-pointer me-1"
                  color="#212B6D"
                  onMouseDown={() => {
                    setToBeDeleted(false);
                  }}
                />
                <div className="d-flex align-items-center cursor-pointer">
                  <DefaultSmallLoader loading={deleteLoading} />
                </div>
                {!deleteLoading && (
                  <div
                    className="d-flex align-items-center cursor-pointer delete-confirmation-text"
                    onClick={() => {
                      setDeleteLoading(true);
                      onDelete()
                        .then(() => {
                          setDeleteLoading(false);
                          deleteCallback && deleteCallback();
                          setToBeDeleted(false);
                        })
                        .catch(() => {
                          setToBeDeleted(false);
                          setDeleteLoading(false);
                        });
                    }}
                  >
                    <DeleteOutlined />
                    Bestätigen
                  </div>
                )}
              </div>
            ) : (
              <DeleteOutlined
                onClick={() => {
                  setToBeDeleted(true);
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="template-block__inner" style={{ background: `url("${previewImageURL}")` }}>
        {children}
        <div className="overlay">
          {plusIconFilled ? (
            <PlusCircleFilled className="icon-shadow" onClick={onSelect} />
          ) : (
            <PlusOutlined onClick={onSelect} />
          )}
        </div>
      </div>
    </>
  );
};

export default ActionProviderHOC;
