import React, { useEffect, useState } from 'react';
import { Form, Select, Slider } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { formRules } from '../../../Builder/DebugTracking/utils/formValidationRules';
import { LeadQualifierTypes, OldChoice } from '../../../Builder/interfaces/builderSliceTypes';
import { defaultSelectFieldValidation } from '../../../UI/utils/formValidation';

export enum LEAD_QUALIFIER_TYPES {
  RANGE = 'range',
  RADIO = 'radio',
  MULTIPLE = 'multiple',
  TEXT = 'text'
}
export type LeadQualifierRendererProps = {
  type: LeadQualifierTypes;
  values: OldChoice[];
  name: number | undefined;
  rangeValue?: number | undefined;
};

const generateOptions = (values: OldChoice[]) => {
  return values?.map((choice: any) => (
    <Select.Option key={choice.id} value={choice.id}>
      <span dangerouslySetInnerHTML={{ __html: choice.title }} />
    </Select.Option>
  ));
};

const LeadQualifierRenderer = ({ type, values, name, rangeValue }: LeadQualifierRendererProps) => {
  const [max, setMax] = useState(100);

  useEffect(() => {
    if (rangeValue) {
      setMax(rangeValue + 100);
    }
  }, [rangeValue]);

  const handleChange = (newValue: any) => {
    if (newValue === max) {
      setMax(prev => prev + 20);
    }
  };
  let elementTemp = null;
  switch (type) {
    case LEAD_QUALIFIER_TYPES.RANGE:
      return (
        <Form.Item name={name} rules={formRules.required}>
          <Slider onChange={handleChange} max={max} style={{ margin: '20px 0' }} />
        </Form.Item>
      );

    case LEAD_QUALIFIER_TYPES.TEXT:
      return (
        <Form.Item name={name} rules={formRules.required}>
          <TextArea rows={4} placeholder="z.B. Ich bin XYZ und..." />
        </Form.Item>
      );

    case LEAD_QUALIFIER_TYPES.RADIO:
      return (
        <Form.Item name={name} rules={defaultSelectFieldValidation}>
          <Select
            showSearch
            placeholder="Single Choices"
            style={{ width: '100%', minWidth: '100px' }}
          >
            {generateOptions(values)}
          </Select>
        </Form.Item>
      );

    case LEAD_QUALIFIER_TYPES.MULTIPLE:
      return (
        <Form.Item name={name} rules={defaultSelectFieldValidation}>
          <Select
            showSearch
            placeholder="Multiple Choices"
            style={{ width: '100%', minWidth: '100px' }}
            mode="multiple"
          >
            {generateOptions(values)}
          </Select>
        </Form.Item>
      );
  }
  return <div>{elementTemp}</div>;
};

export default LeadQualifierRenderer;
