import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const DELETE_BOOKING_RATING = gql`
  mutation removeBookingRating($id: Int!) {
    removeBookingRating(id: $id)
  }
`;

const useDeleteBookingRating = () => {
  const [deleteBookingRating, { loading, error }] = useMutation(DELETE_BOOKING_RATING);
  return { deleteBookingRating, loading, error };
};

export default useDeleteBookingRating;
