import React, { useState } from 'react';
import {
  Typography,
  Avatar as AntAvatar,
  Form,
  Rate,
  Row,
  Col,
  message,
  Spin,
  Popover,
  Button
} from 'antd';
import Avatar from 'react-avatar';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import useCreateOrUpdateRating from '../../../hooks/mutateCreateOrUpdateRating';
import useDeleteBookingRating from '../../../hooks/mutateDeleteRating';
import ConditionalTooltip from '../../../SharedUI/components/ConditionalTooltip';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { EventsMessages } from '../../../config/messages';
import { selectUserData } from '../../../UI/redux/userSlice';
import { useAppSelector } from '../../../redux/hooks';
import { useEventsContext } from '../../context/EventsContext';

interface Ratings {
  id?: number;
  competancy: number;
  communicationSkills: number;
  teamCompatibility: number;
  coachId?: number | null;
  partialUser?: {
    firstName: string;
    lastName: string;
  };
}

const initialRatings: Ratings = {
  competancy: 0,
  communicationSkills: 0,
  teamCompatibility: 0,
  coachId: null
};

export interface PropsInterface {
  selectedEvent: any;
  clearEventCache: Function;
}

function BookingRating({ selectedEvent, clearEventCache }: PropsInterface) {
  const [rating, setRating] = useState<Ratings>(selectedEvent?.bookingRating || initialRatings);
  const { createOrUpdateRating, loading } = useCreateOrUpdateRating();
  const { deleteBookingRating, loading: deleteLoading } = useDeleteBookingRating();
  const { handleKanbanRating } = useEventsContext();

  const currentLoginInUser = useAppSelector(selectUserData);
  const handleRatingUpdate = async (attribute: keyof Ratings, value: number) => {
    if (checkPermission()) {
      const updatedRating = { ...rating, [attribute]: value };
      createOrUpdateRating({
        variables: {
          input: {
            bookingId: selectedEvent.id,
            competancy: updatedRating.competancy,
            communicationSkills: updatedRating.communicationSkills,
            teamCompatibility: updatedRating.teamCompatibility
          }
        }
      })
        .then(response => {
          setRating(response.data.createOrUpdateBookingRating);
          handleKanbanRating && handleKanbanRating(response.data.createOrUpdateBookingRating);
          clearEventCache();
          message.success(EventsMessages.updateRatingSuccess);
        })
        .catch(e => {
          message.error(EventsMessages.updateRatingError);
        });
    }
  };

  const handleDeleteRating = (id: any) => {
    if (rating?.id) {
      deleteBookingRating({ variables: { id: rating?.id } })
        .then(() => {
          setRating(initialRatings);
          handleKanbanRating && handleKanbanRating(initialRatings);
          clearEventCache();
          message.success(EventsMessages.deleteRatingSuccess);
        })
        .catch(e => {
          message.error(EventsMessages.updateRatingError);
        });
    }
  };

  const calculateAverage = (rating: Ratings) => {
    return parseFloat(
      ((rating.competancy + rating.communicationSkills + rating.teamCompatibility) / 3).toFixed(1)
    );
  };

  const checkPermission = () => {
    if (rating?.coachId && rating?.coachId !== currentLoginInUser?.id) {
      message.error(EventsMessages.ratingNotPermitted);
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <Spin spinning={loading || deleteLoading}>
        <Form layout="vertical" style={{ width: '85%' }}>
          <Form.Item label="" className="rating-form-item">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Text className="label">Fachliche Kompetenz:</Typography.Text>
              <Rate
                onChange={value => handleRatingUpdate('competancy', value)}
                value={rating.competancy}
                className="ml-auto"
                allowClear={false}
              />
            </div>
          </Form.Item>
          <Form.Item label="" className="rating-form-item">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Text className="label">Kommunikationsfähigkeit:</Typography.Text>
              <Rate
                onChange={value => handleRatingUpdate('communicationSkills', value)}
                value={rating.communicationSkills}
                className="ml-auto"
                allowClear={false}
              />
            </div>
          </Form.Item>
          <Form.Item label="" className="rating-form-item">
            <div className="d-flex justify-content-between align-items-center">
              <Typography.Text className="label">Teamkompatibilität:</Typography.Text>
              <Rate
                onChange={value => handleRatingUpdate('teamCompatibility', value)}
                value={rating.teamCompatibility}
                className="ml-auto"
                allowClear={false}
              />
            </div>
          </Form.Item>
          {calculateAverage(rating) !== 0 ? (
            <div className="d-flex align-items-center mb-2" style={{ columnGap: 8 }}>
              <Avatar
                name={`${rating?.partialUser?.firstName} ${rating?.partialUser?.lastName}`}
                size="50"
                textSizeRatio={2}
                round={true}
              />
              <div className="d-flex flex-column justify-content-start">
                <div className="d-flex align-items-center">
                  <ConditionalTooltip
                    disable={!(rating?.partialUser?.firstName || rating?.partialUser?.lastName)}
                    title={`${rating?.partialUser?.firstName || ''} ${rating?.partialUser
                      ?.lastName || ''}`}
                  >
                    <div className="event-name">{`${rating?.partialUser?.firstName} ${rating?.partialUser?.lastName}`}</div>
                  </ConditionalTooltip>
                  <Popover
                    placement="rightTop"
                    title=""
                    content={
                      <DeleteButtonWithConfirmation
                        loading={deleteLoading}
                        onDelete={async () => {
                          handleDeleteRating(rating?.id);
                        }}
                      />
                    }
                    trigger="click"
                  >
                    <Button
                      className="ant-btn__without-background"
                      type="ghost"
                      data-testid="booking-delete-button"
                      disabled={deleteLoading}
                    >
                      {deleteLoading ? (
                        <DefaultSmallLoader makeItCenter loading={deleteLoading} />
                      ) : (
                        <IoEllipsisHorizontalCircle />
                      )}
                    </Button>
                  </Popover>
                </div>
                <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
                  <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.35)' }}>
                    Durchschnitt: {calculateAverage(rating)}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Form>
      </Spin>
    </>
  );
}
export default BookingRating;
