import { isCalendarExistInFunnel, isQueryFNAndLNEnabled } from '../../helper/craftJs';

export const columns = [
  {
    title: 'Platzhalter',
    dataIndex: 'token'
  },
  {
    title: 'Beschreibung',
    dataIndex: 'description'
  }
];

export enum applicantNotificationTypeEnum {
  CANCELATION = 'CANCELATION',
  NOT_REACHED = 'NOT_REACHED'
}

// Key value is just to prevent console warning for undefined keys passed to DOM Elements
export const tokens = [
  { key: 1, description: 'Name bzw. Vor- und Nachname des Bewerbers.', token: '[NAME]' },
  {
    key: 2,
    description: 'Vorname des Bewerbers.',
    token: '[VORNAME]'
  },
  {
    key: 3,
    description: 'Nachname des Bewerbers.',
    token: '[NACHNAME]'
  },
  {
    key: 4,
    description: 'Zeitpunkt des Termins.',
    token: '[ZEITPUNKT]',
    mustShowOn: ['CANCELATION'],
    hideOn: [
      () => {
        const { calendarEnabled } = isCalendarExistInFunnel();
        return !calendarEnabled;
      }
    ]
  },
  {
    key: 5,
    description: 'Zusammenfassung bestehend aus allen Fragen und Antworten.',
    token: '[ALLE_FRAGEN_ANTWORTEN]',
    hideOn: ['CANCELATION', 'REMINDER']
  },
  {
    key: 6,
    description: 'E-Mail-Adresse, die bei deiner Anmeldung angegeben wurde.',
    token: '[MEINE_EMAIL_ADRESSE]',
    hideOn: []
  },
  {
    key: 7,
    description: 'Zusatzfeld im Kontaktformular',
    token: '[ZUSATZFELD]',
    hideOn: []
  },
  {
    key: 8,
    description: 'E-Mail-Adresse des Bewerbers',
    token: '[EMAIL_ADRESSE]',
    hideOn: ['CONFIRMATION', 'REMINDER', 'SCHEDULED', 'NOT_REACHED']
  },
  {
    key: 9,
    description: 'Telefonnummer des Bewerbers',
    token: '[TELEFON]',
    hideOn: ['REMINDER']
  },
  {
    key: 10,
    description: 'Titel des Funnels',
    token: '[FUNNEL_TITEL]',
    hideOn: ['CONFIRMATION', 'CANCELATION', 'REMINDER', 'SCHEDULED', 'NOT_REACHED']
  },
  {
    key: 11,
    description: 'UTM-Parameter',
    token: '[UTM_PARAMETER]',
    hideOn: ['CONFIRMATION', 'CANCELATION', 'REMINDER', 'SCHEDULED', 'NOT_REACHED']
  },
  {
    key: 12,
    description: 'Unterlagen des Bewerbers',
    token: '[DATEIEN]',
    hideOn: ['CONFIRMATION', 'CANCELATION', 'REMINDER', 'SCHEDULED', 'NOT_REACHED']
  }
];

export const explanations: { [key: string]: string } = {
  CONFIRMATION: 'Die Bestätigung wird direkt nach der Bewerbung an deinen Bewerber gesendet.',
  CANCELATION:
    'Unter "Bewerbungen" kannst du den Status des Kontakts auf "Abgelehnt" stellen, anschließend wird diese Absage geladen und du kannst sie verschicken.',
  REMINDER:
    'Deine Terminerinnerung wird je nach Einstellung vor dem bevorstehenden Termin gesendet.',
  INTERNAL_CONFIRMATION:
    'Die Benachrichtigung wird direkt nach der Bewerbung an die angegeben E-Mail-Adressen gesendet. Du kannst somit z.B. deinen Kunden über die Bewerbung benachrichtigen.',
  SCHEDULED:
    'Die zeitgesteuerten Nachrichten werden nach dem Eingang einer Bewerbung automatisch versendet, basierend auf der Zeitauswahl. Wenn z.B. die Bewerbung um 14:00 Uhr versendet wird, du “22 Stunden” ausgewählt hast, dann erhält der Bewerber diese Nachricht am nächsten Tag um 12:00 Uhr.',
  NOT_REACHED:
    'Wenn du eine neue Bewerbung anklickst, findest du den Button: “Nicht erreicht”, wird dieser geklickt, dann wird diese Nachricht geladen und du kannst sie verschicken.'
};

export const scheduledNotificationSelectListData = [
  {
    name: 'Wochen:',
    timeKey: 'weeks',
    length: 53
  },
  {
    name: 'Tage:',
    timeKey: 'days',
    length: 31
  },
  {
    name: 'Stunden:',
    timeKey: 'hours',
    length: 24
  },
  {
    name: 'Minuten:',
    timeKey: 'minutes',
    length: 60
  }
];

export const reminderNotificationSelectListData = [
  {
    name: 'Stunden:',
    timeKey: 'hours',
    length: 48
  },
  {
    name: 'Minuten:',
    timeKey: 'minutes',
    length: 60
  }
];

export const ChannelMessages = {
  buySMSAddOn: 'Du musst erst das SMS-Add-on über den Marketplace hinzufügen.'
};
