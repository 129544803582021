import React, { useState } from 'react';
import { Card } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ApplicantEngagementTable from './ApplicantEngagementTable';

export interface ApplicantEngagementData {
  id: string;
  name: string;
  email: string;
  phone: string;
  formUpdate: boolean;
}

interface PropsInterface {
  applicantEngagementData: ApplicantEngagementData;
}

const ApplicantEngagement = ({ applicantEngagementData }: PropsInterface) => {
  const [isCardOpen, setIsCardOpen] = useState(false);

  const handleExpandChange = () => {
    setIsCardOpen(!isCardOpen);
  };

  return (
    <Card
      title="Applications"
      className="applications-card"
      bordered={false}
      extra={
        <a onClick={handleExpandChange}>
          {isCardOpen ? <UpOutlined /> : <DownOutlined />}
        </a>
      }
    >
      {isCardOpen && (
        <ApplicantEngagementTable
          applicantEngagementData={applicantEngagementData}
          isCardOpen={isCardOpen}
        />
      )}
    </Card>
  );
};

export default ApplicantEngagement;
