export enum textTypesEnum {
  TEASER = 'TEASER',
  MAIN_TEXT = 'MAIN_TEXT',
  CTA = 'CTA',
  HEADLINE = 'HEADLINE',
  JOB_MIDDLE_PART = 'JOB_MIDDLE_PART',
  JOB_TITLE = 'JOB_TITLE',
  JOB_CTA = 'JOB_CTA',
  JOB_TEASER = 'JOB_TEASER',
  QUALIFICATION = 'Qualifications',
  SKILLS = 'Skills',
  RESPONSIBILITIES = 'Responsibilities',
  ADVANTAGES = 'ADVANTAGES',
  TASKS = 'TASKS',
  REQUIREMENTS = 'REQUIREMENTS'
}

export enum FAQ {
  QUESTION ='QUESTION',
  ANSWER = 'ANSWER'
}

export enum mediaTypeEnum {
  MEDIA = 'MEDIA',
  BANNER_TEXT = 'BANNER_TEXT',
  MEDIA_TEXT = 'MEDIA_TEXT',
  BANNER = 'BANNER',
  LOGO = 'LOGO',
  TEASER = 'TEASER',
  MAIN_TEXT = 'MAIN_TEXT',
  CTA = 'CTA',
  HEAD_lINE = 'HEAD_lINE',
  HAUPTINHALT = 'HAUPTINHALT',
  CALL_TO_ACTION = 'CALL_TO_ACTION'
}

export const elementTypeObj: { [key: string]: string } = {
  TEASER: 'Teaser',
  MAIN_TEXT: 'MainText',
  CTA: 'Cta',
  HEADLINE: 'Headline',
  BANNER_TEXT: 'Banner text',
  MEDIA_TEXT: 'Medien text',
  LOGO: 'Logo',
  BANNER: 'Banner',
  HAUPTINHALT: 'Hauptinhalt',
  CALL_TO_ACTION: 'Call to action'
};

export enum settingTyßpeEnum {
  SUGGESTION = 'SUGGESTION',
  EDITOR = 'EDITOR'
}

export interface SizeInterface {
  key: number;
  value: string | number;
}
