import React, { useEffect, useState } from 'react';
import { Steps, Button } from 'antd';
import TemplateTypeFields from './TemplateTypeFields';
import { adTypesData, stepData } from '../../data/adTypesData';
import DefaultSmallLoader from '../../../../SharedUI/components/DefaultSmallLoader';
import { AdGeneration } from '../../../graphql/setAdGenerationInput';
import BlockSettingsOverlay from '../../../components/sharedUI/BlockSettingsOverlay';
import { useSubscriptionInfo } from '../../../../UI/redux/userSlice';
import { messages } from '../../../components/elements/RichTextComponent/helpers/aiTextSettingHelpers';
import DefaultLoader from '../../../../SharedUI/components/DefaultLoader';
import { useCreateAdTemplateTypeLoading } from '../../redux/adBuilderSlice';

const { Step } = Steps;

export enum AD_TYPES {
  STATEMENT = 'Statement',
  ADVANTAGES = 'Advantages',
  EMPLOYEE_TESTIMONIAL = 'EmployeeTestimonial',
  FREE = 'Free'
}

export const adTypeObject: { [key: string]: string } = {
  Aussage: 'Statement',
  Vorteile: 'Advantages',
  Mitarbeiterstimme: 'EmployeeTestimonial',
  Frei: 'Free'
};

export interface TemplateTypesViewProps {
  toggleVisible: (e: boolean) => void;
  adGenerationData: AdGeneration;
  shouldGenerateAd?: Boolean;
}

const TemplateTypesView = ({
  toggleVisible,
  adGenerationData,
  shouldGenerateAd = true
}: TemplateTypesViewProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const loading = useCreateAdTemplateTypeLoading();
  const [selectedType, setSelectedType] = useState(adGenerationData?.type as AD_TYPES);
  const { remainingChatAiTokens } = useSubscriptionInfo();
  const isQuotaExceeded = remainingChatAiTokens <= 5;

  useEffect(() => {
    if (adGenerationData?.type) {
      setCurrentStep(0);
      setSelectedType(adGenerationData?.type);
    }
  }, [adGenerationData?.type]);

  const nextStep = (type?: string) => {
    setCurrentStep((prevStep: number) => Math.min(prevStep + 1, stepData.length - 1));
    if (type) {
      setSelectedType(adTypeObject[type] as AD_TYPES);
    }
  };

  const backStep = (goToStep?: number) => {
    if ((goToStep as number) >= 0) {
      setCurrentStep(goToStep as number);
    } else {
      setCurrentStep((prevStep: number) => Math.min(prevStep - 1));
    }
    setSelectedType(' ' as AD_TYPES);
  };

  return (
    <div className="ai-generated-ad">
      {isQuotaExceeded && (
        <div className="settings-missing-overlay">
          <h3 className="fill-text">{messages.isQuotaExceeded}</h3>
        </div>
      )}
      <DefaultLoader loading={!shouldGenerateAd && loading} />
      <BlockSettingsOverlay />
      <Steps style={{ marginBottom: '10px' }} current={currentStep}>
        {stepData.map((item, index) => {
          return !shouldGenerateAd && item.step === 'Erstellung' ? null : (
            <Step key={index} title={item.step} description={item.description} />
          );
        })}
      </Steps>
      <div className="pt-4">
        {currentStep === 1 ? (
          <TemplateTypeFields
            selectedType={selectedType}
            toggleVisible={toggleVisible}
            backStep={backStep}
            nextStep={nextStep}
            adGenerationData={adGenerationData}
            shouldGenerateAd={shouldGenerateAd}
          />
        ) : currentStep === 2 && shouldGenerateAd ? (
          <div className="loader-container">
            <DefaultSmallLoader makeItCenter={true} fontSize={24} loading={true} />
            <h5>Anzeige erstellen ...</h5>
          </div>
        ) : (
          adTypesData.map((item, index) => (
            <div
              key={index}
              className={`ad-type-card ${
                adGenerationData?.type === adTypeObject[item.type] ? 'selected' : ''
              }`}
            >
              <div>
                <img className="card-img" alt="example" src={item.imgUrl} />
              </div>
              <div style={{ padding: '5%' }}>
                <h2>{item.type}</h2>
                <h3>{item.description}</h3>
                <Button className="p-0" type="link" onClick={() => nextStep(item.type)}>
                  {item.button}
                </Button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default TemplateTypesView;
