import React, { useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import download from 'downloadjs';
import LoginValidationRules from '../../../Login/utils/LoginValidationRules';
import { useAppSelector } from '../../../redux/hooks';
import { useCommonElements } from '../../redux/builderSlice';
import lz from 'lzutf8';
import { reWampFunnelPages } from '../../helper/craftJs';

const SaveTemplateModal: React.FC = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const funnelData = useAppSelector(state => state.builderUI);
  const common = useCommonElements();

  const handleOk = async ({ name }: any) => {
    const { id, ...colorTheme } = funnelData.funnelTheme;
    const funnelPresets = funnelData.funnelPresets.map(
      ({ id, ...rest }: { [key: string]: any }) => {
        return rest;
      }
    );
    const logoURL = JSON.parse(lz.decompress(lz.decodeBase64(common.header))).props.url;

    const data = {
      bookingSettings: {
        bookingRangeInHours: [1, 360],
        durationInMinutes: 30
      },
      funnel: {
        logoURL,
        type: 'ADVANCED',
        customJs: null,
        language: funnelData.generalSettings.language,
        enabled: true,
        brandingEnabled: true,
        cookieBanner: true
      },
      colorTheme: { ...colorTheme },
      shifts: [{ dow: [1, 2, 3, 4, 5], start: '09:00', end: '17:00' }],
      presets: funnelPresets
    };

    // @ts-ignore
    /*     data.emailTemplates = funnelData.emailTemplates.templates.map(single => {
      const html = customDraftToHtml(single.html.getCurrentContent());
      const sanitizedHtml = sanitizeHtml(html, sanitizerConfig);
      return {
        accountOwnerRequestConfirmationEnabled: false,
        type: single.type,
        subject: single.subject,
        html: sanitizedHtml
      };
    }); */
    // @ts-ignore
    data.pages = reWampFunnelPages(funnelData.builderPages).map(
      ({
        name,
        pageOrder,
        craftState,
        type,
        facebookPixelEvent,
        tikTokPixelEvent,
        linkedInEvent,
        googleTagTrigger,
        customJs
      }) => ({
        name,
        craftState,
        type,
        pageOrder,
        facebookPixelEvent,
        tikTokPixelEvent,
        googleTagTrigger,
        linkedInEvent,
        customJs
      })
    );

    const file = new Blob([`export default  ${JSON.stringify(data)}`], {
      type: 'application/ts'
    });
    download(file, `${name}.ts`);
    setVisible(false);
  };

  return (
    <>
      <div onClick={() => setVisible(true)}>{children}</div>
      <Modal
        title="Als Vorlage speichern"
        okText="Speichern"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={() => {
          setVisible(false);
        }}
        width={600}
        style={{ zIndex: 9999 }}
      >
        <Form form={form} name="basic" layout="vertical" onFinish={handleOk} autoComplete="off">
          <Row>
            <Col span={24}>
              <Form.Item label={'File Name'} rules={LoginValidationRules.name} name={'name'}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SaveTemplateModal;
