import { Steps } from 'antd';
import React, { FC } from 'react';
import { CSVUploadStepsEnum } from './CSVUploadModal';

const steps = [
  { title: 'Hochladen', description: 'Laden Sie Ihre CSV-Datei hoch' },
  { title: 'Karte', description: 'Ordnen Sie Ihre Felder zu' },
  { title: 'Einreichen', description: 'Senden Sie Ihre Daten' }
];

interface CSVStepsProps {
  currentStep: CSVUploadStepsEnum;
}

const CSVSteps: FC<CSVStepsProps> = ({ currentStep }) => {
  const { Step } = Steps;

  return (
    <Steps current={currentStep} className="mb-2" direction="horizontal">
      {steps.map((step, index) => (
        <Step key={index} title={step.title} description={step.description} />
      ))}
    </Steps>
  );
};

export default CSVSteps;
