import React from 'react';

import { Form, Input, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {
  useBuilderPages,
  useMainFunnelLoader
} from '../../redux/builderSlice';
import { addFunnelPageThunk } from '../../redux/thunk';
import { useEditor } from '@craftjs/core';
import { defaultStringFieldValidation } from '../../../UI/utils/formValidation';
import { defaultCustomJS } from '../../helper/defaultValues';
import { defaultCraftState } from '../../helper/defaultCraftStates';

interface propTypes  {
  visible : boolean,
  toggleVisible: (data: boolean) => void,
  pageType?: string,
}

const CreateNewPageModal = ({ visible = false, toggleVisible, pageType = "COMMON" }:propTypes) => {
  const loading = useMainFunnelLoader();
  const builderPages = useBuilderPages();
  const [form] = Form.useForm();
  const { query } = useEditor();

  const dispatch = useDispatch();
  const handleOk = async (e: any) => {
    form.resetFields();
    await dispatch(
      addFunnelPageThunk({
        name: e.title,
        craftState: defaultCraftState,
        customJs: defaultCustomJS,
        type:pageType,
        // As page always gets sorted after fetching from api as per the page order, so the last builder page will highest order
        pageOrder: builderPages.length ? builderPages[builderPages.length - 1].pageOrder + 1 : 1,
        query: query
      })
    );
    toggleVisible(false);
  };

  const handleCancel = () => {
    form.resetFields();
    toggleVisible(false);

  };

  return (
    <>
      <Modal
        title="Seite hinzufügen"
        okText="Hinzufügen"
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        confirmLoading={loading}
        destroyOnClose
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleOk}
          autoComplete="off"
        >
          <Form.Item label="Titel" name="title" className="" rules={defaultStringFieldValidation}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default CreateNewPageModal;
