import React, { useEffect, useState } from 'react';
import lz from 'lzutf8';
import { Form, Input, message, Modal, Select } from 'antd';
import addFunnelTemplate, { FunnelTemplateTypes } from '../../graphql/addFunnelTemplate';

import { CreateNewTemplateMessages, GeneralMessages } from '../../../config/messages';
import useGetAllFunnelTemplate from '../../hooks/useGetAllFunnelTemplate';
import { useCraftSerializedState } from '../../helper/craftJs';
import { defaultStringFieldValidation } from '../../../UI/utils/formValidation';
import { useEditor } from '@craftjs/core';
import { getNodeIdsForTree, removeUnnecessaryNodes } from '../../helper/elementTemplate';
import DefaultLoader from '../../../SharedUI/components/DefaultLoader';
import { resetSelectedBlockNodeIds, useSelectedBlockNodeIds } from '../../redux/builderSlice';
import { useAppDispatch } from '../../../redux/hooks';

const CreateMultiBlockTemplate = ({
  visible = false,
  toggleVisible = () => {}
}: {
  visible: boolean;
  toggleVisible: () => void;
}) => {
  const {
    query,
    actions: { selectNode }
  } = useEditor();
  const [form] = Form.useForm();
  const [templateCraftState, setTemplateCraftState] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { data, loading: funnelTemplatesLoading, refetch } = useGetAllFunnelTemplate();

  const allSelectedNodes = useSelectedBlockNodeIds();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible) {
      selectNode('');
      const json = useCraftSerializedState(query);
      let requiredNodes: string[] = [];
      allSelectedNodes.forEach(nodeId => {
        requiredNodes = [...requiredNodes, ...getNodeIdsForTree(json, nodeId)];
      });
      const filteredJson = removeUnnecessaryNodes(json, requiredNodes);
      setTemplateCraftState(
        lz.encodeBase64(
          lz.compress(
            JSON.stringify({
              ids: allSelectedNodes,
              nodes: filteredJson
            })
          )
        )
      );
    }
  }, [visible]);

  const onSuccess = () => {
    form.resetFields();
    dispatch(resetSelectedBlockNodeIds());
    toggleVisible();
    refetch();
  };

  const handleOk = async (e: any) => {
    setLoading(true);

    addFunnelTemplate({
      ...e,
      public: true,
      craftState: templateCraftState
    })
      .then(() => {
        onSuccess();
        message.success(CreateNewTemplateMessages.templateBlockCreatedSuccessfully);
      })
      .catch(() => {
        message.error(GeneralMessages.error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    toggleVisible();
  };

  const tags: string[] = Array.from(
    new Set(data?.funnelTemplates.map((template: { tags: string[] }) => template.tags).flat())
  );

  return (
    <>
      <DefaultLoader loading={funnelTemplatesLoading} />
      <Modal
        title="Als Vorlage speichern"
        okText="Speichern"
        className="funnel-block-template-wrapper"
        visible={visible}
        onOk={form.submit}
        onCancel={handleCancel}
        confirmLoading={loading}
        width={600}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleOk}
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            className="mb-1"
            rules={defaultStringFieldValidation}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Tags" name="tags" className="mb-1">
            <Select
              mode="tags"
              placeholder="Tags"
              options={tags.map(tag => ({ label: tag, value: tag }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateMultiBlockTemplate;
