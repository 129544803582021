import { TEMPLATE_KEYS } from '../components/elements/Container/helper';
import { FUNNEL_THEME_KEYS } from './builderSliceTypes';

export const buttonPresetsInitialValues = {
  boldText: false,
  borderRadius: '30px',
  size: 50,
  isDefaultGradient: false,
  gradientSettings: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT
};

export const imagePresetsInitialValues = {
  borderRadius: '0px',
  borderWidth: '0px',
};

export const imageCarousalPresetsInitialValues = {
  borderRadius: '0px'
};

export const choicePresetsInitialValues = {
  templateKey: TEMPLATE_KEYS.ICON_ON_RIGHT,
  borderRadius: '10px',
  isDefaultGradient: false,
  gradientSettings: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT
};

export const faqPresetsInitialValues = {
  borderRadius: '10px',
  backgroundColor: FUNNEL_THEME_KEYS.DEFAULT_GRADIENT,
  cardIconColor: FUNNEL_THEME_KEYS.CARD_ICON_COLOR,
  icon: 'IoIosArrowUp'
};
