import React from 'react';
import { useState } from 'react';
import { Button, Dropdown, Menu, Typography } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { EditorToolbarProps } from './EditorTypes';
import { ToolbarItem } from './EditorItem';

export type HeadingLevel = 1 | 2 | 3 ;

const HEADING_OPTIONS = [
  'Heading 1',
  'Heading 2',
  'Heading 3'
];

export function HeadingBlock({ editor }: Pick<EditorToolbarProps, 'editor'>) {
  const [visible, setVisible] = useState(false);

  const handleMenuClick = () => {
    setVisible(prev => !prev);
  };

  const handleClose = () => {
    setVisible(false);
  };

  if (!editor) {
    return null;
  }

  const menu = (
    <Menu onClick={handleClose}>
      <Menu.Item key="paragraph">
        <ToolbarItem
          label="Paragraph"
          style={{ width: '100%' }}
          active={editor.isActive('paragraph')}
          onClick={() => {
            editor
              .chain()
              .focus()
              .setParagraph()
              .run();
          }}
        />
      </Menu.Item>
      {HEADING_OPTIONS.map((heading, index) => {
        const level = (index + 1) as HeadingLevel;

        return (
          <Menu.Item key={heading}>
            <ToolbarItem
              aria-label={heading}
              label={heading}
              active={editor.isActive('heading', { level })}
              onClick={() => {
                editor
                  .chain()
                  .focus()
                  .setHeading({ level })
                  .setBold()
                  .run();
              }}
              style={{
                fontSize: `${18 - index}px`,
                width: "100%",
                fontWeight: level > 3 ? 'normal' : 'bold'
              }}
            />
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={['click']}
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Button
          onClick={handleMenuClick}
          style={{
            padding: '0 8px',
            width: 120,
            height: 32,
            borderRadius: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid rgba(0, 0, 0, 0.2)',
          }}
        >
          <Typography.Text>
            {(editor.isActive('heading', { level: 1 }) && 'Heading 1') ||
              (editor.isActive('heading', { level: 2 }) && 'Heading 2') ||
              (editor.isActive('heading', { level: 3 }) && 'Heading 3') ||

              'Paragraph'}
          </Typography.Text>
          {visible ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </Dropdown>
    </>
  );
}
