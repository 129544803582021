import React from 'react';
import ImageWithoutCacheOnError from '../sharedUI/ImageWithoutCacheOnError';
import MediaDisplayVoiceMessage from './MediaDisplayVoiceMessage';
import PlayCircle from './PlayCircle';
import './VoiceMessagePreview.scss';

interface propTypes {
  voiceMessageSignedUrl?: string;
  imageUrl?: string;
  width?: string;
  color?: string;
}

const VoiceMessagePreview = ({ voiceMessageSignedUrl = '', imageUrl, width, color }: propTypes) => {
  return (
    <MediaDisplayVoiceMessage
      className="voice-message-preview__image"
      style={{ width, height: width, borderColor: color }}
      voiceMessageSignedUrl={voiceMessageSignedUrl}
      render={({ canPlay, isPlaying, play, pause }) => {
        return (
          <>
            <ImageWithoutCacheOnError src={imageUrl} />
            {canPlay && (
              <PlayCircle
                style={{ background: color }}
                className="voice-message-preview__image-voice-message__play play-circle "
                isPlaying={isPlaying}
                onClick={() => {
                  isPlaying ? pause() : play();
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default VoiceMessagePreview;
