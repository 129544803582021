import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import {
  AiFieldsRules,
  COMPANY_INFORMATION_ELEMENTS
} from '../../../components/generalSettings/AiDataTab/components/AiDataHelper';
import { useCompanyDetail } from '../../../redux/builderSlice';
import { CompanyDetail } from '../../../interfaces/builderSliceTypes';
import CategoryPicker from '../../../components/sharedUI/CategoryPicker';
import { predefinedBenefitOptions } from '../../../components/generalSettings/utils/predefined-options';
import { AiGeneratedTypeProps } from './StatementTemplate';
import { AD_TYPES } from './TemplateTypesView';
import { customStringFieldValidation } from '../../../../UI/utils/formValidation';

const AdvantagesTemplate = ({ formInstance, onSubmit, initialData }: AiGeneratedTypeProps) => {
  const { benefits } = useCompanyDetail();
  const [form] = Form.useForm();

  useEffect(() => {
    if (formInstance) {
      formInstance.current = form;
    }
  }, [formInstance, form]);

  const handleChange = (value: any, identifier: keyof CompanyDetail) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, [identifier]: value });
  };

  return (
    <Form
      name="advantages_form"
      layout="vertical"
      form={form}
      initialValues={{ benefits, ...initialData }}
      style={{ maxWidth: '600px', margin: '0 auto' }}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Thema (ca. 5 Worte)"
        name={`promptMessage${AD_TYPES.ADVANTAGES}`}
        rules={customStringFieldValidation(5, 50)}
      >
        <Input.TextArea
          placeholder="z.B. Warum können sich karriereorientierte Menschen bei dir entwickeln"
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </Form.Item>

      <Form.Item
        validateTrigger="onBlur"
        label="Vorteile"
        name={COMPANY_INFORMATION_ELEMENTS.BENEFITS}
        rules={AiFieldsRules.generalTags}
        getValueFromEvent={value => {
          handleChange(value, COMPANY_INFORMATION_ELEMENTS.BENEFITS);
          return value;
        }}
      >
        <CategoryPicker
          placeholder="z.B. JobRad, Remote-Anteil, Weihnachtsgeld"
          mode="tags"
          value={benefits}
          options={predefinedBenefitOptions}
        />
      </Form.Item>
    </Form>
  );
};

export default AdvantagesTemplate;
