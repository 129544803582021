import React, { useEffect } from 'react';
import { useEventsContext } from '../../context/EventsContext';
import { Col, Form, Row, Select } from 'antd';

const { Option } = Select;

type StatusSelectorProps = {
  csvStatuses: any[];
  statusesMapped: any;
  setStatusesMapped: (statusMap: any[]) => void;
  onChangeStatus?: (statusMap?: any[]) => void;
};

const StatusSelector = ({
  csvStatuses,
  setStatusesMapped,
  onChangeStatus,
  statusesMapped
}: StatusSelectorProps) => {
  const { availableStatus } = useEventsContext();
  const uniqueStatuses = new Set(csvStatuses) as any;
  const arrayStatuses = Array.from(uniqueStatuses);
  const [form] = Form.useForm();

  const handleValuesChange = (_: any, allValues: any) => {
    onChangeStatus && onChangeStatus(allValues);
    setStatusesMapped(allValues);
    form.setFieldsValue(allValues);
  };

  useEffect(() => {
    const doesAlreadyExist = !!Object.values(statusesMapped).filter(val => val !== '- Select')
      .length;
    if (doesAlreadyExist) return;
    resetStatusMapped();
  }, []);

  const resetStatusMapped = () => {
    const initialMap: any = {};
    csvStatuses.forEach(single => {
      initialMap[single] = '- Select';
    });
    form.setFieldsValue(initialMap);
    setStatusesMapped(initialMap);
  };

  return (
    <div className="q">
      <Form form={form} initialValues={statusesMapped || {}} onValuesChange={handleValuesChange}>
        <>
          {arrayStatuses?.map((status: any) => (
            <Row align="middle">
              <>
                <Col span={12}>{status}</Col>
                <Col span={12}>
                  <Form.Item
                    name={status}
                    validateTrigger={'onChange'}
                    rules={[
                      {
                        required: true,
                        validator: (_, value) => {
                          if (!value) {
                            return Promise.reject(new Error('Please select a status'));
                          }
                          return Promise.resolve();
                        }
                      }
                    ]}
                  >
                    <Select defaultValue={'- Select'}>
                      {availableStatus.map(val => (
                        <Option value={val?.id} key={val?.id}>
                          {val?.status}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            </Row>
          ))}
        </>
      </Form>
    </div>
  );
};

export default StatusSelector;
