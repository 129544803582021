import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const DELETE_TRACKING = gql`
  mutation deleteTracking($trackingId: Int!) {
    deleteTracking(id: $trackingId)
  }
`;

export default () => useMutation(DELETE_TRACKING);
