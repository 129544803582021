import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Select, Form, Alert } from 'antd';
import {
  CSVMappingFields,
  doNotDuplicateFields,
  mapCSVHeadersToSchema,
  mustRequiredFields,
  validateCSVMappingField
} from './helpersCSV';
import StatusSelector from './StatusSelector';
import { CSVUploadMessages } from '../../../config/messages';

const { Option } = Select;

type MapFieldsViewProps = {
  csvData: any[];
  csvFields?: string[];
  setMappingSchema: (value: any) => void;
  mappingSchema: any;
  setStatusesMapped: (value: any) => void;
  statusesMapped: any;
};

const MapFieldsView = ({
  csvData,
  csvFields,
  mappingSchema,
  setMappingSchema,
  statusesMapped,
  setStatusesMapped
}: MapFieldsViewProps) => {
  const [form] = Form.useForm();

  const [requiredFields, setRequiredFields] = useState(csvFields);

  const handleChange = (field: string, value: any) => {
    if (field.startsWith('check-')) {
      const fieldToCheck = field.replace('check-', '');
      const fieldsSchema = { ...mappingSchema, [fieldToCheck]: CSVMappingFields.noValue };
      form.setFieldsValue(fieldsSchema);
      setMappingSchema(fieldsSchema);
      if (value) {
        const fieldsSchema = { ...mappingSchema, [fieldToCheck]: CSVMappingFields.noValue };
        setMappingSchema(fieldsSchema);
        setRequiredFields([...(requiredFields as string[]), fieldToCheck]);
      } else {
        const { [fieldToCheck]: currentUncheckField, ...rest } = mappingSchema;
        if (currentUncheckField === CSVMappingFields.bookingStatusId) {
          setStatusesMapped({});
        }
        setMappingSchema(rest);
        setRequiredFields(requiredFields?.filter(f => f !== fieldToCheck));
      }
      return;
    }
    const fieldsSchema = { ...mappingSchema, [field]: value };
    setMappingSchema(fieldsSchema);
  };

  useEffect(() => {
    const doesAlreadyExist = !!Object.values(mappingSchema).filter(
      val => val !== CSVMappingFields.noValue
    ).length;
    if (doesAlreadyExist) return;
    const defaultMapped = mapCSVHeadersToSchema(csvFields || []);
    form.setFieldsValue(defaultMapped);
    setMappingSchema(defaultMapped);
  }, []);

  const handleValuesChange = (values: any, allValues: any) => {
    const changedField = Object.keys(values)[0];
    const changedValue = values[changedField];
    handleChange(changedField, changedValue);
  };

  const isDuplicationAllowed = (field: any) => {
    return doNotDuplicateFields.includes(field) && Object.values(mappingSchema)?.includes(field);
  };

  return (
    <div className="map-fields">
      <Form form={form} onValuesChange={handleValuesChange} initialValues={mappingSchema || {}}>
        {csvFields?.map((field, index) => {
          const selectedField = csvFields?.[index];
          const data = csvData?.[1]?.[field];
          const isChecked = requiredFields?.includes(field);
          const currentSelectedField = mappingSchema[field];
          const isStatusSelected = currentSelectedField === CSVMappingFields.bookingStatusId;
          const currentFieldInCsv = csvData?.map((record: any) => record[field]);
          return (
            <Row align="middle" key={index}>
              <Col span={6}>
                <Form.Item valuePropName="checked" initialValue={true} name={`check-${field}`}>
                  <Checkbox key={field}>{field}</Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={field}
                  validateTrigger={'onChange'}
                  initialValue={CSVMappingFields.noValue}
                  rules={[
                    {
                      validator: (_, value) =>
                        validateCSVMappingField(value, field as CSVMappingFields, data)
                    }
                  ]}
                >
                  <Select
                    disabled={!isChecked}
                    style={{ width: 120 }}
                    value={mappingSchema[selectedField as string] || CSVMappingFields.noValue}
                  >
                    {Object.values(CSVMappingFields).map((val, idx) => (
                      <Option disabled={isDuplicationAllowed(val)} value={val} key={idx}>
                        {val}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isStatusSelected ? (
                <>
                  <Col span={12}>
                    <StatusSelector
                      statusesMapped={statusesMapped}
                      setStatusesMapped={setStatusesMapped}
                      csvStatuses={currentFieldInCsv}
                    />
                  </Col>
                </>
              ) : (
                <Col span={12}>{data}</Col>
              )}
            </Row>
          );
        })}
      </Form>
      <Alert
        style={{ marginBottom: '0px !important' }}
        message={CSVUploadMessages.checkAllFields(mustRequiredFields.join(', ') || '')}
        type="info"
        showIcon
      />
    </div>
  );
};

export default MapFieldsView;
