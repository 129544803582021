import { Alert, Button, Form, message, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import LeadQualifierRenderer, { LEAD_QUALIFIER_TYPES } from '../EventKanban/LeadQualifierRenderer';
import { OldLeadQualifier } from '../../../Builder/interfaces/builderSliceTypes';
import { ManualBookingMessages } from '../../../config/messages';
import { LoadingOutlined } from '@ant-design/icons';
import { useEventsContext } from '../../context/EventsContext';
import { BookingInterface, TrackingInterface } from '../../container/Events';

interface LeadQualifierFormProps {
  leadQualifierData: any[] | undefined;
  leadQualifierLoading: boolean;
  setIsEditing: (value: boolean) => void;
  isEditing: boolean;
  selectedEvent:
    | {
        event: BookingInterface | TrackingInterface;
        index: any;
      }
    | undefined;
}

const LeadQualifierForm = ({
  leadQualifierData,
  leadQualifierLoading,
  setIsEditing,
  isEditing,
  selectedEvent
}: LeadQualifierFormProps) => {

  const [form] = Form.useForm();
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>({});
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const choicesMap: { [key: string]: any[] } = {};
    selectedEvent?.event?.choices?.forEach((item: any) => {
      if (choicesMap[item.leadQualifierId]) {
        choicesMap[item.leadQualifierId].push(item);
      } else {
        choicesMap[item.leadQualifierId] = [item];
      }
    });

    const initialValues: { [key: string]: any } = {};

    leadQualifierData?.forEach((leadQualifiers: any) => {
      const qualifierId = leadQualifiers.id;

      if (choicesMap[qualifierId]) {
        const item = choicesMap[qualifierId];

        switch (leadQualifiers.type) {
          case LEAD_QUALIFIER_TYPES.RANGE:
            initialValues[qualifierId] = item[0].numberValue;
            break;
          case LEAD_QUALIFIER_TYPES.TEXT:
            initialValues[qualifierId] = item[0].stringValue;
            break;
          case LEAD_QUALIFIER_TYPES.RADIO:
            initialValues[qualifierId] = item[0].choiceId;
            break;
          case LEAD_QUALIFIER_TYPES.MULTIPLE:
            initialValues[qualifierId] = item.map((i: any) => i.choiceId);
            break;
          default:
            break;
        }
      }
    });

    setInitialValues(initialValues);
  }, [leadQualifierData, selectedEvent]);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const isFormValid = () => {
    const isValid = form.getFieldsError().some(item => item.errors.length > 0);
    setShowInfo(isValid);
  };
  const {
    handleUpdateManualLeadQualifier,
    eventLoaders: { manualBookingLoading }
  } = useEventsContext();

  const onFinish = async (values: any) => {
    try {
      await handleUpdateManualLeadQualifier(values, leadQualifierData);
      if (isEditing) {
        setIsEditing(false);
      }
      !manualBookingLoading &&
        message.success(ManualBookingMessages.leadQualifiersSuccessfullyCreated);
    } catch (error) {
      message.error('Manual Lead Qualifier failed');
    }
  };

  return (
    <div>
      <Typography.Title level={4}>Antworten</Typography.Title>
      <p>Es stehen keine weiteren Informationen zur Verfügung</p>
      {showInfo && <Alert message={ManualBookingMessages.info} type="info" showIcon />}
      {leadQualifierData?.length ? (
        leadQualifierLoading ? (
          <LoadingOutlined
            className="centered"
            style={{
              marginTop: '120px',
              position: 'relative',
              zIndex: 111
            }}
            spin
          />
        ) : (
          <Form
            form={form}
            initialValues={initialValues}
            layout="vertical"
            onFinish={onFinish}
            onFieldsChange={isFormValid}
          >
            {leadQualifierData?.map((leadQualifiers: OldLeadQualifier, index: number) => (
              <div className="mx-3" key={index}>
                <h5>{leadQualifiers.question}</h5>
                <LeadQualifierRenderer
                  type={leadQualifiers.type}
                  values={leadQualifiers.choices}
                  name={leadQualifiers.id}
                  rangeValue={
                    leadQualifiers.type === LEAD_QUALIFIER_TYPES.RANGE && leadQualifiers.id
                      ? initialValues[leadQualifiers.id]
                      : undefined
                  }
                />
              </div>
            ))}

            <Form.Item className="mx-3">
              <Button loading={manualBookingLoading} type="primary" htmlType="submit">
                {isEditing ? 'Aktualisieren' : 'Einreichen'}
              </Button>
              {isEditing && (
                <Button
                  className="mx-2"
                  disabled={manualBookingLoading}
                  type="default"
                  onClick={() => setIsEditing(false)}
                >
                  Stornieren
                </Button>
              )}
            </Form.Item>
          </Form>
        )
      ) : null}
    </div>
  );
};

export default LeadQualifierForm;
