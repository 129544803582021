import React from 'react';
import { useCurrentNodeSettings } from './useCurrentNodeSettings';
import { useEditor } from '@craftjs/core';
import { Col } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';

const SettingsPanelBase = ({ emptyViewComponent }: { emptyViewComponent: React.ReactNode }) => {
  const { selected, isEnabled, ...rest } = useCurrentNodeSettings();
  const { query, actions } = useEditor();
  // @ts-ignore
  if (selected?.isHidden) {
    return (
      <Col span={24} className="builder__settings-sidebar__container" style={{ height: '100%' }}>
        <div className="empty-page-list--container">
          <h4>Element verborgen</h4>
          <p>Aktivere es über das Augesymbol, um es wieder anzuzeigen.</p>
          <EyeInvisibleOutlined
            className="mb-3"
            style={{ color: '#e0e0e0', fontSize: 60 }}
            onClick={() =>
              // @ts-ignore
              actions.setProp(selected?.id, (props: any) => {
                props.canHide.hideSwich = !props?.canHide?.hideSwich;
              })
            }
          />
        </div>
      </Col>
    );
  }

  //@ts-ignore
  return isEnabled && Object.entries(selected).length && selected.settings ? (
    <>
      {//@ts-ignore
        React.createElement(selected.settings)}
    </>
  ) : (
    <>{emptyViewComponent}</>
  );
};

export default SettingsPanelBase;
