import gql from 'graphql-tag';
export interface UpdateFunnelColorThemeInput {
  id: number;
  headlineColor?: string;
  textColor?: string;
  accentColor?: string;
  backgroundColor?: string;
  cardBackgroundColor?: string;
  cardIconColor?: string;
  cardTextColor?: string;
  accentColorContrast?: string;
  fontFamily?: string;
  fontLink?: string;
  isCustom?: boolean;
  name?: string;
  defaultGradient?: string;
}

export const UPDATE_FUNNEL_COLOR_THEME = gql`
  mutation updateFunnelColorTheme($input: UpdateFunnelColorThemeInput!) {
    updateFunnelColorTheme(updateFunnelColorThemeInput: $input) {
      id
      headlineColor
      accentColorContrast
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      fontFamily
      fontLink
      isCustom
      defaultGradient
      headlineFontFamily
      headlineFontLink
    }
  }
`;

export default function updateFunnelTheme(input: UpdateFunnelColorThemeInput) {
  return window.apolloClient.mutate({
    mutation: UPDATE_FUNNEL_COLOR_THEME,
    variables: { input }
  });
}
