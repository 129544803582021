import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { getDefaultBase, builderConfig } from '../config/builderUi';
import {
  AgencyOSBuilderPageDataType,
  AgencyOSBuilderState,
  AgencyDashboard,
  AgencyOSBuilderStateChecksums,
  AgencyOSThemeType,
  AllDomains
} from '../interfaces/agencyOSBuilderSliceTypes';
import { DraggingElement, MetaData } from '../../interfaces/builderSliceTypes';
import { getCompleteAgencyOSBuilder, saveCompleteAgencyOSBuilderDataThunk } from './thunk';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';
import { getEncodedAgencyBuilderPages } from '../../helper/craftJs';
import { CardTypes } from '../../config/builderUi';
import { AgencyOSBuilderPagesTypes } from '../graphql/setAgencyDashboardPages';

const defaultAgencyOSTheme: AgencyOSThemeType = {
  primaryColor: '#CD3434',
  primaryContrastColor: '#DACA08',
  accentColor: '#23d3d3',
  headlineColor: '#DACA28',
  textColor: '#555555',
  fontFamily: 'Open Sans',
  fontLink: ''
};

const agencyOSBuilderPages: AgencyOSBuilderPageDataType[] = [];

const INITIAL_STATE: AgencyOSBuilderState = {
  configData: builderConfig,
  selectedBase: getDefaultBase(builderConfig),
  selectedCard: null,
  selectedElementTab: '1',
  agencyOSBuilderPages,
  allDomains: [],
  selectedAgencyBuilderPageId: null,
  checksums: {
    agencyOSBuilderPages: null,
    agencyDashboard: null,
    metaData: null,
    agencyOSTheme: null
  },
  ui: {
    agencyOSBuilderIsEditingModalVisibility: false
  },
  draggingElement: {
    name: '',
    nodeId: ''
  },
  agencyDashboard: {
    id: 0,
    uniqueIdentifier: '',
    primaryColor: '',
    secondaryColor: '',
    isEditing: false
  },
  loaders: {
    mainAgencyOSBuilderLoader: true,
    isAgencyOSBuilderSaved: true
  },
  shouldSaveAgencyOSBuilder: true,
  lastSaveSuccessful: true,
  agencyOSTheme: defaultAgencyOSTheme,
  currentlyEditingTheme: defaultAgencyOSTheme,
  metaData: {
    metaTitle: '',
    description: '',
    customMetaDataEnabled: false,
    iconURL: '',
    previewImageURL: ''
  }
};

export const agencyOSBuilderUISlice = createSlice({
  name: 'agencyOSBuilderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialAgencyOSBuilderState: state => {
      return {
        ...INITIAL_STATE
      };
    },
    setAgencyOSCurrentlyEditingTheme: (state, action: PayloadAction<AgencyOSThemeType>) => {
      state.currentlyEditingTheme = action.payload;
    },

    editAgencyOSMetaData: (state, action: PayloadAction<MetaData>) => {
      state.metaData = { ...state.metaData, ...action.payload };
      state.loaders.isAgencyOSBuilderSaved = false;
    },
    setSelectedCard: (state, action: PayloadAction<{ payload: CardTypes }>) => {
      state.selectedCard = action.payload.payload;
    },
    setAgencyOSBuilderSelectedElementTab: (state, action: PayloadAction<{ payload: string }>) => {
      state.selectedElementTab = action.payload.payload;
    },
    setSelectedPageId: (state, action: PayloadAction<number>) => {
      state.selectedElementTab = '1';
      state.selectedAgencyBuilderPageId = action.payload;
    },

    editDraggingElement: (state, action: PayloadAction<DraggingElement>) => {
      const { name, nodeId, ...rest } = action.payload;
      state.draggingElement.name = name;
      nodeId && (state.draggingElement.nodeId = nodeId);
      name === null && (state.draggingElement.nodeId = '');
      state.draggingElement = {
        ...state.draggingElement,
        ...rest
      };
    },
    editAgencyOSBuilderSavedIndicator: (state, action: PayloadAction<boolean>) => {
      state.loaders.isAgencyOSBuilderSaved = action.payload;
    },
    setShouldSaveAgencyOSBuilder: (state, action: PayloadAction<boolean>) => {
      state.shouldSaveAgencyOSBuilder = action.payload;
    },
    setAgencyOSBuilderIsEditingModalVisibility: (state, action: PayloadAction<boolean>) => {
      state.ui.agencyOSBuilderIsEditingModalVisibility = action.payload;
    },
    setMainAgencyOSBuilderLoader: (state, action: PayloadAction<boolean>) => {
      state.loaders.mainAgencyOSBuilderLoader = action.payload;
    },
    setIsAgencyOSBuilderSaved: (state, action: PayloadAction<boolean>) => {
      state.loaders.isAgencyOSBuilderSaved = action.payload;
    }
  },
  extraReducers: {
    [`${getCompleteAgencyOSBuilder.fulfilled}`]: (
      state: AgencyOSBuilderState,
      action: PayloadAction<{
        pages: AgencyOSBuilderPageDataType[];
        agencyDashboard: AgencyDashboard;
        metaData: MetaData;
        checksums: AgencyOSBuilderStateChecksums;
        agencyOSTheme: AgencyOSThemeType;
        allDomains: AllDomains[];
      }>
    ) => {
      state.loaders.mainAgencyOSBuilderLoader = false;

      state.agencyOSBuilderPages = action.payload.pages;

      state.agencyDashboard = action.payload.agencyDashboard;

      state.metaData = action.payload.metaData;

      state.agencyOSTheme = action.payload.agencyOSTheme;

      state.currentlyEditingTheme = action.payload.agencyOSTheme;

      state.checksums = action.payload.checksums;

      state.allDomains = action.payload.allDomains;
    },
    [`${getCompleteAgencyOSBuilder.rejected}`]: (state: AgencyOSBuilderState) => {
      state.loaders.mainAgencyOSBuilderLoader = false;
      message.error(GeneralMessages.error);
    },
    [`${getCompleteAgencyOSBuilder.pending}`]: (state: AgencyOSBuilderState) => {
      state.loaders.mainAgencyOSBuilderLoader = true;
    },
    [`${saveCompleteAgencyOSBuilderDataThunk.pending}`]: (state: AgencyOSBuilderState) => {
      if (!state.shouldSaveAgencyOSBuilder) return;
      state.loaders.mainAgencyOSBuilderLoader = true;
      const { agencyOSBuilderPages } = getEncodedAgencyBuilderPages({
        agencyOSBuilderPages: state.agencyOSBuilderPages,
        selectedAgencyBuilderPageId: state.selectedAgencyBuilderPageId
      });
      state.agencyOSBuilderPages = agencyOSBuilderPages;
    },
    [`${saveCompleteAgencyOSBuilderDataThunk.rejected}`]: (state: AgencyOSBuilderState) => {
      state.lastSaveSuccessful = false;
      state.loaders.isAgencyOSBuilderSaved = false;
      state.loaders.mainAgencyOSBuilderLoader = false;
    },
    [`${saveCompleteAgencyOSBuilderDataThunk.fulfilled}`]: (
      state: AgencyOSBuilderState,
      {
        payload
      }: PayloadAction<{
        checksums: AgencyOSBuilderStateChecksums;
        agencyOSTheme: AgencyOSThemeType;
      }>
    ) => {
      if (!state.shouldSaveAgencyOSBuilder) return;

      state.checksums = { ...state.checksums, ...payload.checksums };
      state.agencyOSTheme = payload.agencyOSTheme;
      state.currentlyEditingTheme = payload.agencyOSTheme;
      state.lastSaveSuccessful = true;
      state.loaders.isAgencyOSBuilderSaved = true;
      state.loaders.mainAgencyOSBuilderLoader = false;
    }
  }
});

export const {
  setSelectedCard,
  setAgencyOSBuilderSelectedElementTab,
  setSelectedPageId,
  editDraggingElement,
  editAgencyOSBuilderSavedIndicator,
  setShouldSaveAgencyOSBuilder,
  setMainAgencyOSBuilderLoader,
  setAgencyOSBuilderIsEditingModalVisibility,
  resetToInitialAgencyOSBuilderState,
  setIsAgencyOSBuilderSaved,
  setAgencyOSCurrentlyEditingTheme,
  editAgencyOSMetaData
} = agencyOSBuilderUISlice.actions;

export const useAgencyOSBuilderIsEditingModalVisibility = () => {
  return useAppSelector(
    (state: RootState) => state.agencyOSBuilderUI.ui.agencyOSBuilderIsEditingModalVisibility
  );
};

export const useLoginPage = () =>
  useAppSelector((state: RootState) =>
    state.agencyOSBuilderUI.agencyOSBuilderPages.find(
      page => page.type === AgencyOSBuilderPagesTypes.LOGIN
    )
  );

export const useHubPage = () =>
  useAppSelector((state: RootState) =>
    state.agencyOSBuilderUI.agencyOSBuilderPages.find(
      page => page.type === AgencyOSBuilderPagesTypes.HUB
    )
  );

export const useAllAgencyOSBuilderPages = () => {
  const agencyOSBuilderPages = useAppSelector(
    (state: RootState) => state.agencyOSBuilderUI.agencyOSBuilderPages
  );
  return agencyOSBuilderPages;
};

export const useAgencyOSBuilderSelectedPageData = () => {
  return useAppSelector((state: RootState) =>
    state.agencyOSBuilderUI.agencyOSBuilderPages.find(
      (item: { id: any }) => item.id === state.agencyOSBuilderUI.selectedAgencyBuilderPageId
    )
  );
};

export const useAgencyDashboard = (): AgencyDashboard => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.agencyDashboard);
};

export const useCompleteAgencyOSBuilderState = (): AgencyOSBuilderState => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI);
};

// Loaders
export const useMainAgencyOSBuilderLoader = (): boolean => {
  return useAppSelector(
    (state: RootState) => state.agencyOSBuilderUI.loaders.mainAgencyOSBuilderLoader
  );
};

export const useIsAgencyOSBuilderSaved = (): boolean => {
  return useAppSelector(
    (state: RootState) => state.agencyOSBuilderUI.loaders.isAgencyOSBuilderSaved
  );
};

export const useShouldSaveAgencyOSBuilder = (): boolean => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.shouldSaveAgencyOSBuilder);
};

export const useAgencyOSTheme = () => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.agencyOSTheme);
};

export const useAgencyOSCurrentlyEditingTheme = () => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.currentlyEditingTheme);
};

export const useAgencyOSBuilderMetaData = () => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.agencyDashboard);
};

export const useAllDomains = () => {
  return useAppSelector((state: RootState) => state.agencyOSBuilderUI.allDomains);
};

export default agencyOSBuilderUISlice.reducer;
