import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';
import createAdMediaTemplate, {
  Input as CreateAdMediaTemplateInput
} from '../../graphql/createAdMediaTemplate';
import createAdTemplate, { Input as CreateAdTemplateInput } from '../../graphql/createAdTemplate';
import createAdTextTemplate, {
  Input as CreateAdTextTemplateInput
} from '../../graphql/createAdTextTemplate';
import deleteAdMediaTemplate from '../../graphql/deleteAdMediaTemplate';
import deleteAdTemplate from '../../graphql/deleteAdTemplate';
import deleteAdTextTemplate from '../../graphql/deleteAdTextTemplate';
import { shouldSaveOrShowMessage } from '../../helper/sharedFunctions';
import { fetchCompleteAdBuilder } from '../../hooks/useGetCompleteAdBuilder';
import { JobBuilderStateType } from './jobBuilderSlice';
import { useCraftSerializedState } from '../../helper/craftJs';
import lz from 'lzutf8';
import { Delete } from 'craftjs-utils-meetovo';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import setFunnelJobPost, { FunnelJobPostStatus } from '../../graphql/setFunnelJobPost';
import { getFunnelJobPost } from '../../hooks/useGetAdJobBuilder';
import setFunnelJobPostForIndexing, {
  JobNotificationType
} from '../../graphql/setFunnelJobPostForIndexing';

export const getCompleteJobBuilder = createAsyncThunk(
  'get-complete-job-builder',
  async (data, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      const { data: jobBuilderState } = await getFunnelJobPost();

      return { ...jobBuilderState };
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createJobTemplateThunk = createAsyncThunk(
  'create-job-template',
  async (data: CreateAdTemplateInput, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      const res = await createAdTemplate(data);
      return res.data.createAdTemplate;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createJobMediaTemplateThunk = createAsyncThunk(
  'create-job-media-template',
  async (data: CreateAdMediaTemplateInput, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      const res = await createAdMediaTemplate(data);
      return res.data.createAdMediaTemplate;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const createTextTemplateThunk = createAsyncThunk(
  'create-job-text-template',
  async (data: CreateAdTextTemplateInput, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      const res = await createAdTextTemplate(data);
      return res.data.createAdTextTemplate;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteJobMediaTemplateThunk = createAsyncThunk(
  'delete-job-media-template',
  async (id: number, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      await deleteAdMediaTemplate({ id });
      return id;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteJobTextTemplateThunk = createAsyncThunk(
  'delete-job-text-template',
  async (data: { id: number; type: string }, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      await deleteAdTextTemplate({ id: data.id });
      return data;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const deleteJobTemplateThunk = createAsyncThunk(
  'delete-job-template',
  async (id: number, thunkAPI) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    const shouldSave = shouldSaveOrShowMessage(jobBuilderUI.shouldSaveJob);

    if (!shouldSave) return;

    try {
      await deleteAdTemplate({ id });
      return id;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const unPublishJobThunk = createAsyncThunk(
  'unpublish-job',
  async (
    args: {
      data: { type: JobNotificationType; status: FunnelJobPostStatus };
    },
    thunkAPI
  ) => {
    let { data } = args || {};
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };

    try {
      await setFunnelJobPostForIndexing({
        jobPostId: jobBuilderUI.job.id,
        type: data.type
      });
      return data;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const saveJobBuilderStateThunk = createAsyncThunk(
  'save-job-builder-state',
  async (
    args:
      | undefined
      | {
        query?: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
        callbackOnSucces?(): void;
        data?: { status?: FunnelJobPostStatus; validThrough: Date };
      },
    thunkAPI
  ) => {
    const { jobBuilderUI } = thunkAPI.getState() as { jobBuilderUI: JobBuilderStateType };
    let { query, callbackOnSucces, data } = args || {};

    try {
      query =
        query ||
        (window.craftJsJobBuilderQuery as Delete<
          useInternalEditorReturnType<any>['query'],
          'deserialize'
        >);

      const json = useCraftSerializedState(query);
      const encodedJson = lz.encodeBase64(lz.compress(JSON.stringify(json)));

      const shouldSaveFunnelJobPost = !!(
        jobBuilderUI.job.craftState !== encodedJson ||
        !encodedJson
      );

      if (shouldSaveFunnelJobPost) {
        const {
          data: {
            setFunnelJobPost: { craftState, validThrough, status }
          }
        } = await setFunnelJobPost({ craftState: encodedJson, ...data });

        if (callbackOnSucces) callbackOnSucces();

        return {
          craftState,
          validThrough,
          status
        };
      }

      if (callbackOnSucces) callbackOnSucces();

      return {
        craftState: jobBuilderUI.job.craftState,
        validThrough: jobBuilderUI.job.craftState,
        status: jobBuilderUI.job.status
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);
