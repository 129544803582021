import { useNode, useEditor } from '@craftjs/core';
import React from 'react';

interface Props {
  children?: any;
  style?: any;
  className?: string;
}

export const BasicElementWrapper = ({ children, className = '', ...props }: Props) => {
  const {
    connectors: { connect, drag },
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id
    };
  });

  const { selected } = useEditor((state, query) => {
    const currentNodeId = query.getEvent('selected').last();
    let selected: any;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings: state.nodes[currentNodeId].related && state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }

    return {
      selected
    };
  });

  return (
    <div
      {...props}
      ref={(ref: any) => connect(drag(ref))}
      className={`actions-controller__wrapper ${className} ${
        selected?.id === nodeId ? 'active-actions-controller__wrapper' : ''
      }`}
    >
      {children}
    </div>
  );
};
