import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const CREATE_OR_UPDATE_BOOKING_RATING = gql`
  mutation createOrUpdateBookingRating($input: CreateBookingRatingInput!) {
    createOrUpdateBookingRating(input: $input) {
      id
      coachId
      competancy
      communicationSkills
      teamCompatibility
      partialUser {
        firstName
        lastName
        imageURL
      }
    }
  }
`;

const useCreateOrUpdateRating = () => {
  const [createOrUpdateRating, { data, loading, error }] = useMutation(CREATE_OR_UPDATE_BOOKING_RATING);
  return { createOrUpdateRating, data, loading, error };
};

export default useCreateOrUpdateRating;
