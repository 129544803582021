import React, { useEffect, useMemo } from 'react';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import Table, { ColumnsType } from 'antd/lib/table';
import dateFormats from '../../../config/dateFormats';
import moment from 'moment';
import { useAppSelector } from '../../../redux/hooks';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';
import { Role } from '../../../UI/utils/roleTypes';
import { selectUserData } from '../../../UI/redux/userSlice';
import { useEventsContext } from '../../context/EventsContext';
import { SelectedEvent } from '../../context/EventsContext.types';
import { Spin } from 'antd';
import { ApplicantEngagementData } from './ApplicantEngagement';

const GET_BOOKING_INFO = gql`
  query getBookingInfo($filter1: BookingFilterV2!) {
    getBookings(input: $filter1) {
      id
      name
      email
      createdAt
      funnelId
      sortOrder
      bookingStatus {
        color
        status
        value
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
      partialFunnel {
        id
        title
      }
    }
  }
`;

const applicantApplications: ColumnsType<{
  channel: string;
  type: string;
  message: string;
  sentDate: Date;
}> = [
  {
    title: 'Funnel',
    dataIndex: 'partialFunnel',
    key: 'partialFunnel',
    render: (partialFunnel: any) => {
      return <span>{partialFunnel?.title}</span>;
    }
  },

  {
    title: 'Booking Status',
    dataIndex: 'bookingStatus',
    key: 'bookingStatus',
    render: bookingStatus => <span>{bookingStatus?.status}</span>
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: createdAt => (
      <span>{createdAt ? moment(createdAt).format(dateFormats?.dateTime) : ''}</span>
    )
  }
];

const ApplicantEngagementTable = ({
  applicantEngagementData,
  isCardOpen
}: {
  applicantEngagementData: ApplicantEngagementData;
  isCardOpen: boolean;
}) => {
  const {
    handleSetSelectedEvent,
    selectedRowKey,
    handleCreateBookingTags,
    selectedEvent
  } = useEventsContext();
  const userData = useAppSelector(selectUserData);
  const funnels = useAllFunnels();
  const isAgencyCustomer = userData?.role === Role.AGENCY_CUSTOMER;
  const variables = {
    filter1: {
      email: (selectedEvent as any).event?.email,
      phone: (selectedEvent as any).event?.phone,
      coachId: (selectedEvent as any).event?.coachId,
      useAllFunnels: true,
      funnelIds: isAgencyCustomer ? funnels?.map(funnel => funnel.id) : []
    },
    filter2: {
      funnelIds: []
    }
  };

  const applicantBookingVariables = useMemo(() => {
    if (!applicantEngagementData || !applicantEngagementData.formUpdate || !selectedEvent) return variables;

    return {
      filter1: {
        email: applicantEngagementData?.email,
        phone: applicantEngagementData?.phone,
        coachId: (selectedEvent as any).event?.coachId,
        useAllFunnels: true,
        funnelIds: isAgencyCustomer ? funnels?.map(funnel => funnel.id) : []
      },
      filter2: {
        funnelIds: []
      }
    };
  }, [applicantEngagementData, selectedEvent, isAgencyCustomer, funnels]);

  const [loadBookingInfo, { loading: bookingLoading, data }] = useLazyQuery(GET_BOOKING_INFO, {
    fetchPolicy: 'network-only',
    variables: applicantBookingVariables
  });

  useEffect(() => {
    if (isCardOpen && !data) {
      loadBookingInfo();
    }
  }, [isCardOpen, data]);

  return bookingLoading ? (
    <Spin />
  ) : (
    <>
      {(selectedEvent as any).event && data?.getBookings.length > 1 ? (
        <div>
          <Table
            pagination={false}
            columns={applicantApplications}
            dataSource={data?.getBookings}
            rowClassName={record =>
              // @ts-ignore
              record.id === selectedRowKey ? 'table-selected-row cursor-pointer' : 'cursor-pointer'
            }
            onRow={(record: any) => {
              return {
                onClick: () => {
                  if (record.id !== selectedRowKey) {
                    handleSetSelectedEvent({
                      event: {
                        id: record?.id,
                        name: record?.name,
                        email: record?.email,
                        createdAt: record?.createdAt,
                        funnelId: record?.funnelId,
                        sortOrder: record?.sortOrder,
                        bookingStatus: record?.bookingStatus,
                        bookingActionEvent: record?.bookingActionEvent
                      },
                      index: 0
                    });
                    selectedEvent?.event?.id &&
                      handleCreateBookingTags(
                        selectedEvent?.event?.id,
                        selectedEvent as SelectedEvent
                      );
                  }
                }
              };
            }}
            rowKey={(record: any) => record?.id}
          />
        </div>
      ) : (
        <p>Es stehen keine weiteren Informationen zur Verfügung</p>
      )}
    </>
  );
};

export default ApplicantEngagementTable;
