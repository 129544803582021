import { Tooltip } from 'antd';
import React from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { BsFillTrashFill } from 'react-icons/bs';
import { useEditor, useNode } from '@craftjs/core';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';
import { useSetSelectedElementTab } from '../../reduxCommonMutations/setSelectedElementTab';

interface propTypes {
  disabled: boolean
}

const DeleteAction = ({disabled}:propTypes) => {
  const { actions, query } = useEditor();
  const setSelectedElementTab = useSetSelectedElementTab();

  const { name, nodeId } = useNode(node => {
    return {
      nodeId: node.id,
      name: node.data.custom.displayName || node.data.displayName
    };
  });

  const handleDeleteClick = () => {
    if (Object.keys(query.getNodes()).find(key => key === nodeId)) {
      actions.history.throttle().delete(nodeId);

      trackInteraction({
        type: 'FUNCTION_CALL',
        customEventName: TRACKINGS.HANDLE_DELETE_CLICK_IN_RENDER_NODE,
        additionalData: { name, nodeId }
      });

      setSelectedElementTab({
        payload: '1'
      });
    }
  };

  return (
    <Tooltip title={tooltipText.delete}>
      <li
        className={`cursor-pointer action-icon trash-icon ${disabled ? 'disabled' : ''}`}
        onMouseDown={(e: React.MouseEvent) => {
          e.stopPropagation();
          !disabled && handleDeleteClick();
        }}
      >
        <BsFillTrashFill />
      </li>
    </Tooltip>
  );
};

export default DeleteAction;
