import React, { useEffect, createContext, useContext, useState, ReactElement } from 'react';
import { DropResult, DraggableLocation } from 'react-beautiful-dnd';
import {
  BookingActionEventInterface,
  BookingFilterInterface,
  BookingInterface,
  BookingStatus,
  BookingTag,
  EventStatus,
  LeadQualifierInterface,
  // SelectedEvent,
  StateInterface,
  StatusInterface,
  TrackingInterface
} from '../container/Events';
import { EventMap } from '../components/EventKanban/types';
import { convertToMachineReadable, reorder, reorderQuoteMap } from '../utils/kanbanHelpers';
import { message } from 'antd';
import { EventsMessages, KanbanActionMessages, ManualBookingMessages } from '../../config/messages';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../../UI/redux/userSlice';
import { Role } from '../../UI/utils/roleTypes';
import mutateDeleteApplication from '../../hooks/mutateDeleteApplication';
import mutateDeleteBooking from '../../hooks/mutateDeleteBooking';
import mutateManualBooking from '../../hooks/mutateManualBooking';
import DeclineApplicantModal from '../components/Event/DeclineApplicantModal';
import { getSingleBooking } from '../../Builder/hooks/useSingleBooking';
import EventModal from '../components/Event/EventModal';
import { useAllFunnels } from '../../Funnel/redux/funnelSlice';
import { LEAD_QUALIFIER_TYPES } from '../components/EventKanban/LeadQualifierRenderer';
import { OldLeadQualifier } from '../../Builder/interfaces/builderSliceTypes';
import moment from 'moment';
import mutateDeleteTracking from '../../hooks/mutateDeleteTracking';
import getDownloadFunnelData from '../helper/getDownloadFunnelData';
import { csvMaker } from '../helper/csvMaker';
import download from 'downloadjs';
import { useNavigate } from 'react-router-dom';
import { ActionInterface } from '../components/EventTable';
import { Store } from 'antd/lib/form/interface';
import {
  useChangeBookingStatusAndOrder,
  useCreateBookingStatus,
  useDeleteBookingStatus,
  useGetBookingInfo,
  useUpdateBookingStatus,
  useUpdateBookingStatusAndAddNote,
  useUpdateBookingStatusOrder,
  useCreateBookingTags,
  useDeleteBookingTag,
  useUpdateBookingTags,
} from '../graphql/bookingQueries';
import { updateUrlQueryParams } from '../helper/updateUrlQueryParams';
import { getApplicationFiltersFromQueryParams } from '../helper/getApplicationFiltersFromQueryParams';
import { BookingType, EventDrawerTabView, SelectedEvent } from './EventsContext.types';
import { eventsContextInitialState } from './EventsContextInitialState';

const EventsContext = createContext(eventsContextInitialState);


function EventsProvider({ children }: { children: ReactElement }) {
  const tempColumns = {};
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const [selectedEvent, setSelectedEvent] = useState<SelectedEvent | undefined>();
  const bookingFilters: BookingFilterInterface = getApplicationFiltersFromQueryParams();
  const [changeAllBookingStatus] = useChangeBookingStatusAndOrder();
  const [updateBookingStatusAndAddNote] = useUpdateBookingStatusAndAddNote();
  const [createBookingStatus, { loading: loadingCreateBookingStatus }] = useCreateBookingStatus();
  const [updateBookingStatusOrder] = useUpdateBookingStatusOrder();
  const [updateBookingStatus, { loading: loadingUpdateBookingStatus }] = useUpdateBookingStatus();
  const [removeBookingStatus, { loading: loadingDeleteBookingStatus }] = useDeleteBookingStatus();
  const [deleteBooking, { loading: deleteBookingLoading }] = mutateDeleteBooking();
  const [deleteApplication, { loading: deleteApplicationLoading }] = mutateDeleteApplication();
  const [deleteTracking, { loading: deleteTrackingLoading }] = mutateDeleteTracking();
  const [manualBooking, { loading: manualBookingLoading }] = mutateManualBooking();
  const [updateBookingTags] = useUpdateBookingTags();
  const [createBookingTags] = useCreateBookingTags();
  const [deleteBookingTag] = useDeleteBookingTag();
  const [isBookingTagsChange, setIsBookingTagsChange] = useState(false);
  const [allBookingTagsHashMap, setAllBookingTagsHashMap] = useState({});
  const [applicationState, setApplicationState] = useState<BookingInterface[]>([]);
  const [eventSearchInput, setEventSearchInput] = useState(bookingFilters?.searchQuery || '');
  const [kanBanState, setKanBanState] = useState<{
    columns: { [key: string]: BookingInterface[] };
    ordered: string[];
  }>({
    columns: {},
    ordered: Object.keys(tempColumns)
  });
  const [declineApplicationId, setDeclineApplicationId] = useState<number | undefined>();
  const [eventDrawerTabView, setEventDrawerTabView] = useState<EventDrawerTabView>(
    EventDrawerTabView.CONTACT
  );
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [eventsRefreshLoading, setEventsRefreshLoading] = useState(false);
  const [selectedEventLoading, setSelectedEventLoading] = useState(false);
  const [eventCache, setEventCache] = useState<{ [key: number]: BookingInterface }>({});
  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);

  const filter = {
    start: bookingFilters.start,
    end: bookingFilters.end,
    funnelIds: bookingFilters?.funnelIds,
    type: bookingFilters.type
  };

  const { type: dummyType, ...filterForTracking } = filter;
  const variables = {
    filter1: {
      ...filter,
      searchQuery: bookingFilters.searchQuery,
      statusId: bookingFilters.statusId,
      tagIds: bookingFilters?.tagIds,
      includeChoices: true
    },
    filter2: {
      ...filterForTracking,
      minProgressCollectorCount: 3,
      funnelIds: [0]
    },
    filter3: {
      showAll: !bookingFilters?.funnelIds?.length,
      funnelIds: bookingFilters.funnelIds
    }
  };
  let { loading: bookingLoading, data, refetch: refetchBookingInfoApiCall } = useGetBookingInfo({
    fetchPolicy: 'network-only',
    variables,
  });

  const handleSetSelectedEvent = async (evnt: any) => {
    if (evnt) {
      const id = evnt.event.id;
      const cachedEvent = eventCache[id];
      setSelectedRowKey(id);
      if (cachedEvent) {
        setSelectedEvent({ ...evnt, event: cachedEvent });
        return;
      }
      setSelectedEventLoading(true);

      const { data } = await getSingleBooking(id, evnt.event.funnelId);
      const eventData = data.getSingleBooking;
      eventData.leadQualifiers = data.getLeadQualifier;
      setSelectedEvent({ ...evnt, event: eventData });
      setEventCache(prev => ({ ...prev, [id]: eventData }));
      setSelectedEventLoading(false);
    } else {
      setSelectedEvent(evnt);
      setSelectedRowKey(null);
    }
  };

  const refetchBookingInfo = () => {
      setEventsRefreshLoading(true);
      refetchBookingInfoApiCall().then(() => setEventsRefreshLoading(false));
  };

  const funnels = useAllFunnels();

  const {
    getBookings: allBookings,
    getTrackings: trackings,
    getLeadQualifier: leadQualifier,
    getAllBookingStatus: availableStatus,
    getAllBookingTags: allAvailableBookingTags
  } = data || {
    getBookings: [],
    getTrackings: [],
    getAllBookingStatus: [],
    getAllBookingTags: []
  };

  const [availableColumnStatus, setAvailableColumnStatus] = useState(availableStatus);
  const [allBookingTags, setAllBookingTags] = useState<BookingTag[]>(allAvailableBookingTags);

  useEffect(() => {
    setAllBookingTags(allAvailableBookingTags);
  }, [allAvailableBookingTags.length]);

  useEffect(() => {
    if (allBookingTags?.length) {
      const transformed = allBookingTags?.reduce((acc: any, item: any) => {
        const { id, ...rest } = item;
        acc[id] = rest;
        return acc;
      }, {});

      setAllBookingTagsHashMap(transformed);
    }
  }, [allBookingTags]);

  useEffect(() => {
    if (availableStatus.length) {
      setAvailableColumnStatus(availableStatus);
    }
  }, [availableStatus.length]);

  useEffect(() => {
    if (!data) return;
    handleKanBanAndApplicationState(allBookings, availableStatus);
    setEventCache({});
  }, [allBookings, availableStatus]);

  useEffect(() => {
    if (allBookings && allBookings?.length >= 500) message.info(EventsMessages.tooManyBookings, 10);
  }, [allBookings]);

  const changeBookingStatus = async (id: number, value: string, newSortOrder: any[]) => {
    const tempData = { ...eventCache };
    delete tempData[id];
    setEventCache(tempData);
    await changeAllBookingStatus({
      variables: {
        status: { value, id },
        newSortOrder
      }
    });

    if (selectedEvent) {
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          // @ts-ignore
          bookingStatus: { ...selectedEvent?.event?.bookingStatus, value }
        }
      });
    }
  };

  const onEventDragEnd = (result: DropResult, shouldShowEmailPopup: boolean = true) => {
    try {
      if (result.combine) {
        return;
      }

      if (!result.destination) {
        return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      if (source.droppableId === destination.droppableId && source.index === destination.index) {
        return;
      }
      // In Case of Column Order Change
      if (result.type === 'COLUMN') {
        const ordered: string[] = reorder(kanBanState.ordered, source.index, destination.index);

        const payload = ordered.map((item, index) => ({
          id: availableColumnStatus.find((status: any) => status.value === item)?.id,
          sortOrder: index
        }));

        updateBookingStatusOrder({
          variables: {
            input: payload
          }
        }).catch(() => message.error(KanbanActionMessages.columnUpdateError));

        const updatedOrderHashMap: { [key: string]: any } = payload?.reduce(
          (acc: { [key: string]: any }, item) => {
            acc[item?.id] = item;
            return acc;
          },
          {}
        );

        const updatedAvailableColumnStatus = availableColumnStatus.map(
          (booking: BookingInterface) => {
            const data = updatedOrderHashMap[booking.id];
            if (data.id === booking.id) {
              booking.sortOrder = data.sortOrder;
            }
            return booking;
          }
        );

        setAvailableColumnStatus(updatedAvailableColumnStatus);
        handleKanBanAndApplicationState(applicationState, availableColumnStatus, ordered);
        return;
      }

      const bookingStatus = availableColumnStatus.find(
        (status: any) => status.value === destination.droppableId
      );
      // @ts-ignore
      const dragEndSource = kanBanState.columns[source.droppableId][source.index];

      const data = reorderQuoteMap({
        quoteMap: kanBanState.columns,
        source,
        destination
      });

      data.quoteMap[destination.droppableId][destination.index].bookingStatus = bookingStatus;

      const dragSource = data.quoteMap[source.droppableId].map((value: any, i: number) => {
        return { id: value.id, sortOrder: i };
      });

      const dragDestination = data.quoteMap[destination.droppableId].map(
        (value: any, i: number) => {
          return { id: value.id, sortOrder: i };
        }
      );

      let onDragCombine;
      // If the Ticket is sorted in same Column
      if (source.droppableId === destination.droppableId) {
        onDragCombine = dragSource;
      } else {
        onDragCombine = dragSource.concat(dragDestination);
      }

      if (
        destination.droppableId === 'DECLINE' &&
        dragEndSource?.version !== 'V1' &&
        shouldShowEmailPopup &&
        source.droppableId !== destination.droppableId
      ) {
        setDeclineApplicationId(dragEndSource.id);
      }

      changeBookingStatus(dragEndSource.id, destination.droppableId, onDragCombine);

      setKanBanState({
        ...kanBanState,
        columns: data.quoteMap
      });
      setApplicationState(Object.values(data.quoteMap).flat() as BookingInterface[]);
    } catch (error) {
      console.log(' error', error);
    }
  };

  const handleUpdateBookingEvents = (
    eventId: number,
    bookingEvents: BookingActionEventInterface
  ) => {
    const updatedApplicationState = applicationState.map((booking: BookingInterface) => {
      if (booking.id === eventId) {
        booking.bookingActionEvent = bookingEvents;
      }
      return booking;
    });
    handleKanBanAndApplicationState(updatedApplicationState, availableColumnStatus);
  };

  const handleAddNewColumn = async (newColumn: string, color: string) => {
    const newName = convertToMachineReadable(newColumn);

    const doesAlreadyExist = availableColumnStatus?.find(
      (el: BookingStatus) => el.value === newName
    );

    if (doesAlreadyExist) {
      message.info(`Status "${newColumn}" existiert bereits.`);
      return;
    }

    try {
      const createdBooking = await createBookingStatus({
        variables: {
          input: { status: newColumn, value: newName, color }
        }
      });
      if (createdBooking) {
        const createdBookingStatus = createdBooking.data.createBookingStatus;
        setAvailableColumnStatus([...availableColumnStatus, createdBookingStatus]);
        setKanBanState({
          ordered: [...kanBanState.ordered, newName],
          columns: { ...kanBanState.columns, [newName]: [] }
        });
      }
    } catch (err) {
      // @ts-ignore
      if (err?.message?.toLowerCase()?.includes('already exists')) {
        message.info(`Status "${newColumn}" existiert bereits.`);
        return;
      }
      message.error(KanbanActionMessages.columnAddError);
    }
  };

  const handleDeleteStatusColumn = async ({ id, value }: BookingStatus) => {
    try {
      const deletedBooking = await removeBookingStatus({ variables: { input: id } });
      if (deletedBooking) {
        const deletedColumnStatus = availableColumnStatus.filter((status: any) => status.id !== id);
        setAvailableColumnStatus(deletedColumnStatus);
        setKanBanState((kanBanState: { columns: EventMap; ordered: string[] }) => {
          const ordered = kanBanState.ordered.filter(status => status !== value);
          const columns = { ...kanBanState.columns };
          delete columns[value];
          return {
            ordered: [...ordered],
            columns: { ...columns }
          };
        });
      }
    } catch {
      message.error(KanbanActionMessages.columnDeleteError);
    }
  };

  const [columnStatusUpdating, setColumnStatusUpdating] = useState(false);

  const handleUpdateStatusColumn = async (
    { id, value, status, color, ...props }: BookingStatus,
    newColor: string,
    newStatus: string
  ) => {
    setColumnStatusUpdating(true);
    try {
      const updatedBooking = await updateBookingStatus({
        variables: {
          input: {
            id,
            ...(newStatus && { status: newStatus }),
            ...(newColor && { color: newColor })
          }
        }
      });

      const updateBookingRes = updatedBooking.data.updateBookingStatus;
      setAvailableColumnStatus((prevStatus: any) =>
        prevStatus.map((status: any) => {
          return status.id === id ? updateBookingRes : status;
        })
      );
      refetchBookingInfoApiCall();
      setColumnStatusUpdating(false);
    } catch {
      message.error(KanbanActionMessages.columnUpdateError);
    }
  };

  const handleUpdateManualLeadQualifier = async (
    values: any,
    leadQualifierData: OldLeadQualifier[]
  ) => {
    let choices: any = [];
    Object.entries(values).map(([key, value]: any) => {
      const leadQualifier = leadQualifierData.find((item: OldLeadQualifier) => {
        return key == item.id;
      });
      if (leadQualifier) {
        switch (leadQualifier.type) {
          case LEAD_QUALIFIER_TYPES.TEXT:
            leadQualifier.choices.map((choice: any) => {
              choices.push({
                choiceId: choice.id,
                leadQualifierId: leadQualifier.id,
                stringValue: value
              });
            });
            break;
          case LEAD_QUALIFIER_TYPES.RANGE:
            leadQualifier.choices.map((choice: any) => {
              choices.push({
                choiceId: choice.id,
                leadQualifierId: leadQualifier.id,
                numberValue: value
              });
            });
            break;
          case LEAD_QUALIFIER_TYPES.RADIO:
            leadQualifier.choices.map((choice: any) => {
              if (value === choice.id) {
                choices.push({
                  choiceId: value,
                  leadQualifierId: leadQualifier.id
                });
              }
            });
            break;
          case LEAD_QUALIFIER_TYPES.MULTIPLE:
            leadQualifier.choices.map((choice: any) => {
              if (value.includes(choice.id)) {
                choices.push({
                  choiceId: choice.id,
                  leadQualifierId: leadQualifier.id
                });
              }
            });
            break;
        }
      }
    });

    await manualBooking({
      variables: {
        input: {
          id: selectedEvent?.event?.id,
          funnelId: selectedEvent?.event?.funnelId,
          choices
        }
      }
    });

    const singleBooking = await getSingleBooking(selectedEvent?.event?.id as number,selectedEvent?.event?.funnelId as number);
    const eventData = singleBooking.data.getSingleBooking;
    setSelectedEvent({ ...(selectedEvent as any), event: eventData as any });
    setEventCache(prev => ({ ...prev, [selectedEvent?.event?.id as number]: eventData }));
  };
  const handleAddManualBookingEvent = async (values: Store) => {
    const { funnel, status, phoneNumber, applicationDate, ...rest } = values;
    const name = rest.lastName ? `${rest.firstName} ${rest.lastName}` : rest.firstName;

    const { data } = await manualBooking({
      variables: {
        input: {
          funnelId: funnel,
          bookingStatusId: status,
          createdAt: applicationDate,
          name,
          type: BookingType.MANUAL,
          ...rest
        }
      }
    });

    const newBooking = data.setManualBooking;

    message.success(ManualBookingMessages.success);

    setEventDrawerTabView(EventDrawerTabView.INPUTS);
    const singleBooking = await getSingleBooking(newBooking.id as number,funnel);
    const eventData = singleBooking.data.getSingleBooking;
    setSelectedEvent({ ...(selectedEvent as any), event: eventData as any, index: 0 });
    setEventCache(prev => ({ ...prev, [eventData.id as number]: eventData }));
    const updatedApplicationState = [eventData, ...applicationState];
    handleKanBanAndApplicationState(updatedApplicationState, availableColumnStatus);
  };

  const handleAddCSVBookingEvent = async (createdBulkBookings:BookingInterface[]) => {
    const updatedApplicationState = [...createdBulkBookings, ...applicationState];

    handleKanBanAndApplicationState(
      updatedApplicationState as BookingInterface[],
      availableColumnStatus
    );

  };

  const navigateWithQueryParams = (params: string) => {
    navigate(`kontakte?${params}`);
  }

  const handleFunnelSelectionChange = (value: number[]) => {
    const funnelIdsParam = updateUrlQueryParams({
      funnelIds: value.join(',')
    });
    navigateWithQueryParams(funnelIdsParam);
  };

  const handleTagsSelectionChange = (value: number[]) => {
    const tagIdsParam = updateUrlQueryParams({
      tagIds: value.join(',')
    });
    navigate(`kontakte?${tagIdsParam}`);
  };

  function handleOnSearchQueryChange(value: string) {
    const searchQueryParam = updateUrlQueryParams({
      searchQuery: value
    });
    navigateWithQueryParams(searchQueryParam);
  }

  function handleOnDateRangeChange(dates: any) {
    const updatedQueryString = updateUrlQueryParams({
      start: dates[0].toISOString(),
      end: dates[1].toISOString()
    });
    navigateWithQueryParams(updatedQueryString);
  }

  function handleOnTypeChange(value: string) {
    const typeParams = updateUrlQueryParams({
      type: value
    });
    navigateWithQueryParams(typeParams);
  }

  const handleStatusChanged = (val: number[]) => {
    const statusParams = updateUrlQueryParams({
      statusId: val.join(',')
    });
    navigateWithQueryParams(statusParams);
  };

  const handleKanBanAndApplicationState = (
    allBookings: BookingInterface[],
    availableStatus: StatusInterface[],
    ordered?: string[]
  ) => {
    const tempColumns: { [key: string]: BookingInterface[] } = {};
    availableStatus.map((val: StatusInterface, index: number) => {
      tempColumns[val.value] = [];
    });
    allBookings?.map((booking: BookingInterface) => {
      if (booking.bookingStatus)
        tempColumns[booking.bookingStatus.value]?.push &&
          tempColumns[booking.bookingStatus.value].push(booking);
    });
    const sortedData: { [key: string]: any } = {};
    Object.keys(tempColumns).map(key => {
      const sorted = tempColumns[key].sort((a: BookingInterface, b: BookingInterface) => {
        return a.sortOrder - b.sortOrder;
      });
      sortedData[key] = sorted;
    });

    const sortedStatus = availableStatus
      .sort((a: StatusInterface, b: StatusInterface) => {
        return a.sortOrder - b.sortOrder;
      })
      .map((value: StatusInterface) => {
        return value.value;
      });

    setKanBanState({
      columns: sortedData,
      ordered: ordered?.length ? ordered : sortedStatus
    });

    setApplicationState(allBookings);
  };

  const handleDeleteEvent = async (
    id: number,
    version: string,
    type: string,
    callBack?: Function
  ) => {
    if (type === 'booking') {
      if (version === 'V1')
        return deleteBooking({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteBooking) {
              message.success(EventsMessages.deleteBookingSuccess);
              refetchBookingInfoApiCall();
              if (callBack) {
                callBack(id);
              }
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
          });
      else
        return deleteApplication({ variables: { bookingId: id } })
          .then((res: any) => {
            if (res.data.deleteApplication) {
              message.success(EventsMessages.deleteBookingSuccess);
              if (callBack) {
                callBack(id);
              }
              const updatedApplicationState = applicationState.filter(event => event.id !== id);
              handleKanBanAndApplicationState(updatedApplicationState, availableColumnStatus);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            message.error(
              userData.role === Role.AGENCY_CUSTOMER
                ? EventsMessages.deleteBookingNotPermitted
                : EventsMessages.deleteBookingError
            );
          });
    } else {
      return deleteTracking({ variables: { trackingId: id } })
        .then((res: any) => {
          if (res.data.deleteTracking) {
            message.success(EventsMessages.deleteTrackingSuccess);
            refetchBookingInfoApiCall();
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          message.error(
            userData.role === Role.AGENCY_CUSTOMER
              ? EventsMessages.deleteBookingNotPermitted
              : EventsMessages.deleteTrackingError
          );
        });
    }
  };

  const handleDownload = async () => {
    if (!bookingFilters.funnelIds?.length)
      return message.info(EventsMessages.pleaseChooseAFunnelBeforeExport);

    setDownloadLoading(true);
    const { data } = await getDownloadFunnelData({
      filter: variables.filter1
    });
    setDownloadLoading(false);
    const { getBookings, getLeadQualifier } = data;
    if (getBookings.length === 0) return message.info(EventsMessages.noDataToExport);

    const csvData = csvMaker({
      bookings: getBookings,
      leadQualifiers: getLeadQualifier,
      funnels
    });

    const file = new Blob([csvData], { type: 'text/csv' });

    download(file, `Export_${moment().format('DD.MM.YYYY_HH.mm')}.csv`);
  };

  const handleLocalDelete = (eventId: number) => {
    const updatedBooking = applicationState.filter((single: any) => single.id !== eventId);
    setApplicationState(updatedBooking);
  };

  const handleLocalUpdateBookingEvents = (
    eventId: number,
    bookingEvents: BookingActionEventInterface
  ) => {
    const updatedBooking = data.map((single: any) => {
      if (single.id === eventId) {
        single.bookingActionEvent = bookingEvents;
      }
      return single;
    });
    setApplicationState(updatedBooking);
  };

  const handleUpdateBookingStatus = async (e: Store, action: ActionInterface | undefined) => {
    await updateBookingStatusAndAddNote({
      variables: {
        status: { value: action?.action, id: action?.event.id },
        note: { bookingId: action?.event.id, note: e.note }
      }
    });
  };

  const handleKanbanRating = (newBookingRating: any) => {
    if (selectedEvent) {
      setSelectedEvent({
        ...selectedEvent,
        event: {
          ...selectedEvent.event,
          bookingRating: newBookingRating
        }
      });

      const updatedApplicationState = applicationState.map(booking => {
        if (booking.id === selectedEvent?.event.id) {
          booking.bookingRating = newBookingRating;
        }
        return booking;
      });

      handleKanBanAndApplicationState(updatedApplicationState, availableColumnStatus);
    }
  };

  const handleBookingChange = (field: string, value: string) => {
    if (selectedEvent) {
      setSelectedEvent((prev: any) => ({
        ...prev,
        event: {
          ...prev.event,
          [field]: value
        }
      }));

      const updatedApplicationState = applicationState.map((booking: { [key: string]: any }) => {
        if (booking.id === selectedEvent?.event.id) {
          booking[field] = value;
        }
        return booking;
      });

      handleKanBanAndApplicationState(
        updatedApplicationState as BookingInterface[],
        availableColumnStatus
      );
    }
  };

  const getIndexFromColumns = (columns: any, id?: number) => {
    columns.map(() => {});
  };

  const clearEventCache = () => {
    setEventCache({});
  };

  const changeBookingStatusLocally = (id: number, eventStatus: EventStatus) => {
    const newBooking = applicationState.map((single: any) => {
      if (single.id === id) {
        single.bookingStatus = {
          status: eventStatus?.status,
          value: eventStatus?.value,
          color: eventStatus?.color || 'default'
        };
      }
      return single;
    });
    setApplicationState(newBooking);
  };

  const handleSetApplicationStateForTags = (selectedEvent: any) => {
    if (selectedEvent) {
      const updatedApplicationState = applicationState.map(booking => {
        if (booking.id === selectedEvent?.event.id) {
          booking.tags = selectedEvent.event.tags;
        }
        return booking;
      });

      handleKanBanAndApplicationState(updatedApplicationState, availableColumnStatus);
    }
  };

  const handleUpdateBookingTags = async (updatedTag: BookingTag, oldTag: BookingTag) => {
    if (isBookingTagsChange) {
      if (oldTag?.id) {
        const { coachId, agencyCoachId, ...rest } = updatedTag;
        await updateBookingTags({
          variables: {
            input: {
              ...rest
            }
          }
        });
      }
    }
  };

  const handleDeleteBookingTag = async (deletedTag: BookingTag) => {
    if (deletedTag.id) {
      await deleteBookingTag({ variables: { id: deletedTag.id } });
    }
  };

  const handleCreateBookingTags = async (bookingId: number, selectedEvent: SelectedEvent) => {
    if (isBookingTagsChange) {
      const createdTagsInput = selectedEvent?.event.tags
        ?.filter((tag: BookingTag) => tag !== undefined)
        .map(({ coachId, agencyCoachId, ...rest }: BookingTag) => {
          return {
            ...rest
          };
        });
      const response = await createBookingTags({
        variables: {
          input: { bookingId, tags: createdTagsInput }
        }
      });

      const selectedTagsNameWithoutIds = createdTagsInput
        .filter(tag => !tag.id)
        .map(tag => tag.name);

      const selectedTagsWithIds = createdTagsInput.filter(tag => tag.id);

      const responseWithTagIds = response.data.createBookingTags.filter((tag: BookingTag) =>
        selectedTagsNameWithoutIds.includes(tag.name)
      );
      const allBookingTagsWithIds = allBookingTags.filter(tag => tag.id);

      const event = {
        ...selectedEvent,
        event: {
          ...selectedEvent?.event,
          tags: [...selectedTagsWithIds, ...responseWithTagIds]
        }
      };
      const allTags = [...allBookingTagsWithIds, ...responseWithTagIds];
      setAllBookingTags(allTags);
      handleSetApplicationStateForTags(event);
      setIsBookingTagsChange(false);
      setEventCache(prev => ({ ...prev, [selectedEvent?.event?.id as number]: event.event }));
    }
  };

  const deleteEventLoading =
    deleteBookingLoading || deleteApplicationLoading || deleteTrackingLoading;

  return (
    <EventsContext.Provider
      value={{
        leadQualifier:selectedEvent?.event?.leadQualifiers || [],
        selectedEvent,
        handleSetSelectedEvent,
        onEventDragEnd,
        handleAddNewColumn,
        handleDeleteStatusColumn,
        handleUpdateStatusColumn,
        trackings,
        funnels,
        availableStatus: availableColumnStatus,
        refetchBookingInfo,
        kanBanState,
        eventDrawerTabView,
        setEventDrawerTabView,
        handleAddManualBookingEvent,
        handleAddCSVBookingEvent,
        handleUpdateManualLeadQualifier,
        applicationState,
        setApplicationState,
        handleFunnelSelectionChange,
        handleOnSearchQueryChange,
        handleOnDateRangeChange,
        handleOnTypeChange,
        handleStatusChanged,
        handleDeleteEvent,
        handleDownload,
        handleLocalDelete,
        handleLocalUpdateBookingEvents,
        handleUpdateBookingStatus,
        changeBookingStatusLocally,
        eventLoaders: {
          deleteBookingLoading,
          deleteApplicationLoading,
          deleteTrackingLoading,
          manualBookingLoading,
          loadingCreateBookingStatus,
          loadingUpdateBookingStatus,
          loadingDeleteBookingStatus,
          downloadLoading,
          eventsRefreshLoading,
          bookingLoading,
          columnStatusUpdating,
          deleteEventLoading
        },
        eventSearchInput,
        setEventSearchInput,
        bookingFilters,
        allBookingTags,
        allBookingTagsHashMap,
        setAllBookingTags,
        setIsBookingTagsChange,
        handleUpdateBookingTags,
        handleDeleteBookingTag,
        handleCreateBookingTags,
        handleTagsSelectionChange,
        handleSetApplicationStateForTags,
        handleKanbanRating,
        deleteEventLoading,
        selectedEventLoading,
        selectedRowKey,
      }}
    >
      {children}
      <EventModal
        leadQualifier={selectedEvent?.event?.leadQualifiers || []}
        eventType={'booking'}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        clearEventCache={clearEventCache}
        handleBookingChange={handleBookingChange}
        changeBookingStatusLocally={(
          eventId: number,
          eventStatus: EventStatus,
          event: BookingInterface
        ) => {
          const sourceEvent = event?.bookingStatus?.value;
          // @ts-ignore
          const sourceIndex = kanBanState.columns[sourceEvent].findIndex((_: BookingInterface) => {
            return _.id === eventId;
          });
          onEventDragEnd(
            {
              type: 'EVENT',
              combine: null,
              destination: { droppableId: eventStatus.value, index: 0 },
              draggableId: `${eventId}`,
              mode: 'FLUID',
              reason: 'DROP',
              source: {
                index: sourceIndex,
                droppableId: `${selectedEvent?.event.bookingStatus?.value}`
              }
            },
            false
          );
        }}
        deleteLoading={deleteEventLoading}
        eventLoading={selectedEventLoading}
        handleUpdateBookingEvents={handleUpdateBookingEvents}
      />
      <DeclineApplicantModal
        visible={!!declineApplicationId}
        setVisibility={() => {
          setDeclineApplicationId(undefined);
        }}
        eventId={declineApplicationId as number}
      />
    </EventsContext.Provider>
  );
}

const useEventsContext = () => useContext(EventsContext);

export { EventsProvider, useEventsContext };
