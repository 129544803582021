import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Alert, message, Checkbox } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { getUrlParam } from '../../helper/url';
import { getCookie } from '../../helper/cookies';
import { EmailLoginAndRegistrationMessages } from '../../config/messages';
import LoginValidationRules from '../utils/LoginValidationRules';
import { useNavigate } from 'react-router';
import { getEnvironment } from '../../helper/environment';
import { forceLowerCase } from '../../Connect/utils/domainInput';
import { factorsAiTrackEvent } from '../../helper/factors-ai';
import { getSessionStorageData } from '../helper/login';
import { SessionStorageKeys } from '../../Share/constants/sessionStorageKeys';

const LOGIN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
      userInfo {
        coachId
        firstName
        lastName
        email
      }
    }
  }
`;

export const REGISTRATION = gql`
  mutation registerViaEmailAndPassword($input: EmailRegisterProperties!) {
    registerViaEmailAndPassword(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
      userInfo {
        coachId
        firstName
        lastName
        email
      }
    }
  }
`;

const SEND_CHANGE_PASSWORD_TOKEN = gql`
  mutation sendPasswordChangeToken($email: String!) {
    sendPasswordChangeToken(email: $email) {
      email
    }
  }
`;

const CHANGE_PASSWORD_VIA_TOKEN = gql`
  mutation changePasswordViaToken($token: String!, $password: String!) {
    changePasswordViaToken(token: $token, password: $password) {
      email
    }
  }
`;

interface EmailLoginAndRegistrationProps {
  mode: string;
  afterLogin: Function;
  registerButtonText?: string;
  loginButtonText?: string;
  validator?(): any;
  validationFailedMessage?: string;
  disableModeSwitch?: boolean;
  hidePolicyCheckbox?: boolean;
  emailIcon?: any;
  passwordIcon?: any;
  disabledLoginButton?: boolean;
}

const EmailLoginAndRegistration = ({
  mode,
  afterLogin,
  registerButtonText = 'Jetzt einloggen',
  loginButtonText = 'Einloggen',
  validator,
  validationFailedMessage = 'Die Angaben sind nicht vollständig.',
  disableModeSwitch,
  hidePolicyCheckbox,
  emailIcon,
  passwordIcon,
  disabledLoginButton
}: EmailLoginAndRegistrationProps) => {
  const { isDev } = getEnvironment();
  const isRegistrationAllowed =
    !isDev ||
    !!localStorage.getItem('meetovoRegistrationAllowed') ||
    window.location.host.includes('localhost');

  const navigate = useNavigate();
  const [login, { loading: loadingLogin }] = useMutation(LOGIN);
  const [registration, { loading: loadingRegistration }] = useMutation(REGISTRATION);
  const [sendPasswordChangeToken, { loading: loadingSendPasswordChangeToken }] = useMutation(
    SEND_CHANGE_PASSWORD_TOKEN
  );
  const [changePasswordViaToken, { loading: loadingChangePasswordViaToken }] = useMutation(
    CHANGE_PASSWORD_VIA_TOKEN
  );
  const [infoMessage, setInfoMessage] = useState<{
    description: string;
    message: string;
    type: string;
  }>({
    type: 'error',
    description: '',
    message: ''
  });
  const [legalAgreement, setLegalAgreement] = useState(false);
  const [currentMode, setCurrentMode] = useState(mode || 'email-login');

  const isLoading =
    loadingLogin ||
    loadingRegistration ||
    loadingSendPasswordChangeToken ||
    loadingChangePasswordViaToken;
  const partnerId = getUrlParam('pid') || getCookie('meetovoPartnerId');
  const passwordResetToken = getUrlParam('passwordResetToken');

  const source = JSON.stringify({
    UTMParams: getSessionStorageData(SessionStorageKeys.MEETOVO_UTM_PARAMETERS),
    CookiesReferrer: getCookie('meetovoFirstUserReferrer')
  });

  useEffect(() => {
    if (mode != currentMode) setCurrentMode(mode);
  }, [mode]);

  useEffect(() => {
    if (passwordResetToken) setCurrentMode('email-reset-password');
  }, []);

  function handleLogin(email: string, password: string) {
    factorsAiTrackEvent('LOGIN');

    login({
      variables: { input: { email, password } }
    })
      .then(res => {
        afterLogin(res.data.login, currentMode);
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.login.error.message,
          description: EmailLoginAndRegistrationMessages.login.error.description
        });
      });
  }

  function handleRegistration(
    firstName: string,
    lastName: string,
    email: string,
    password: string
  ) {
    factorsAiTrackEvent('REGISTRATION');

    if (validator ? !validator() : !legalAgreement) {
      message.error(validationFailedMessage);
      return;
    }

    if (!isRegistrationAllowed) return;

    firstName = firstName?.trim() || firstName;
    lastName = lastName?.trim() || lastName;

    registration({
      variables: { input: { firstName, lastName, email, password, source, partnerId } }
    })
      .then(res => {
        afterLogin(res.data.registerViaEmailAndPassword, currentMode);
      })
      .catch(e => {
        if (e.message.includes('Email is a temp mail')) {
          setInfoMessage({
            type: 'error',
            message: EmailLoginAndRegistrationMessages.registration.error.isTempMail.message,
            description: EmailLoginAndRegistrationMessages.registration.error.isTempMail.description
          });
        } else {
          setInfoMessage({
            type: 'error',
            message: EmailLoginAndRegistrationMessages.registration.error.emailExisting.message,
            description:
              EmailLoginAndRegistrationMessages.registration.error.emailExisting.description
          });
        }
      });
  }

  function handleSendPasswordChangeToken(email: string) {
    sendPasswordChangeToken({
      variables: { email }
    })
      .then(res => {
        setInfoMessage({
          type: 'success',
          message: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.success.message,
          description: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.success.description
        });
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.error.message,
          description: EmailLoginAndRegistrationMessages.sendPasswordChangeToken.error.description
        });
      });
  }

  function handleChangePasswordViaToken(password: string) {
    changePasswordViaToken({
      variables: { token: passwordResetToken, password }
    })
      .then(res => {
        message.success(EmailLoginAndRegistrationMessages.changePasswordViaToken.success.message);
        navigate('/login');
        setCurrentMode('email-login');
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.changePasswordViaToken.error.message,
          description: EmailLoginAndRegistrationMessages.changePasswordViaToken.error.description
        });
      });
  }

  return (
    <div className="email-login-and-registration">
      {infoMessage.message && (
        <Alert
          message={infoMessage.message}
          description={infoMessage.description}
          type={infoMessage.type as any}
          showIcon
        />
      )}

      {currentMode === 'email-login' && (
        <Form
          layout="vertical"
          name="email-login"
          onFinish={({ email, password }) => {
            disabledLoginButton || handleLogin(email, password);
          }}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            //@ts-ignore
            rules={LoginValidationRules.email}
          >
            <Input autoCapitalize="none" prefix={emailIcon} onInput={forceLowerCase} />
          </Form.Item>

          <Form.Item label="Passwort" name="password" rules={LoginValidationRules.password}>
            <Input.Password prefix={passwordIcon} />
          </Form.Item>

          <Form.Item>
            <Button
              className="login_button"
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              {isLoading ? 'Wird geladen...' : loginButtonText}
            </Button>
            {!disableModeSwitch && (
              <Button type="link" onClick={() => setCurrentMode('email-register')}>
                Noch nicht registriert?
              </Button>
            )}
            <Button
              className="forget-password"
              type="link"
              onClick={() => setCurrentMode('email-send-password-reset')}
            >
              Passwort vergessen?
            </Button>
          </Form.Item>
        </Form>
      )}

      {currentMode === 'email-register' && (
        <Form
          layout="vertical"
          name="email-register"
          onFinish={({ firstName, lastName, email, password }) =>
            handleRegistration(firstName, lastName, email, password)
          }
        >
          <div className="form-group-inline">
            <Form.Item label="Vorname" name="firstName" rules={LoginValidationRules.name}>
              <Input />
            </Form.Item>

            <Form.Item label="Nachname" name="lastName" rules={LoginValidationRules.name}>
              <Input />
            </Form.Item>
          </div>

          <div className="form-group-inline">
            <Form.Item
              label="E-Mail"
              name="email"
              //@ts-ignore
              rules={LoginValidationRules.email}
            >
              <Input autoCapitalize="none" onInput={forceLowerCase} />
            </Form.Item>

            <Form.Item label="Passwort" name="password" rules={LoginValidationRules.password}>
              <PasswordInput />
            </Form.Item>
          </div>
          <Form.Item>
            <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
              {isLoading ? 'Wird geladen...' : registerButtonText}
            </Button>
            {!disableModeSwitch && (
              <Button type="link" onClick={() => setCurrentMode('email-login')}>
                Bereits registriert?
              </Button>
            )}
          </Form.Item>
        </Form>
      )}

      {currentMode === 'email-send-password-reset' && (
        <Form
          layout="vertical"
          name="email-send-password-reset"
          onFinish={({ email }) => {
            disabledLoginButton || handleSendPasswordChangeToken(email);
          }}
        >
          <Form.Item
            label="E-Mail"
            name="email"
            //@ts-ignore
            rules={LoginValidationRules.email}
          >
            <Input autoCapitalize="none" onInput={forceLowerCase} />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
              {isLoading ? 'Wird geladen...' : 'Passwort zurücksetzen'}
            </Button>
            <Button type="link" onClick={() => setCurrentMode('email-login')}>
              Zum Login
            </Button>
          </Form.Item>
        </Form>
      )}

      {currentMode === 'email-reset-password' && (
        <Form
          layout="vertical"
          name="email-reset-password"
          onFinish={({ password }) => handleChangePasswordViaToken(password)}
        >
          <Form.Item label="Neues Passwort" name="password" rules={LoginValidationRules.password}>
            <PasswordInput />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
              {isLoading ? 'Wird geladen...' : 'Passwort ändern'}
            </Button>
          </Form.Item>
        </Form>
      )}

      {currentMode == 'email-register' && !hidePolicyCheckbox && (
        <Checkbox
          className="login__legal-checkbox"
          value={legalAgreement}
          onChange={() => setLegalAgreement(!legalAgreement)}
        >
          Ich akzeptiere die{' '}
          <a
            href="https://meetovo-file-bucket.s3.eu-central-1.amazonaws.com/vertraege/MEETOVO-Nutzungsbedingungen-V3.pdf"
            target="_blank"
          >
            Nutzungsbedingungen
          </a>{' '}
          und die{' '}
          <a href="https://www.meetovo.de/datenschutz" target="_blank">
            Datenschutzerklärung
          </a>
          .
        </Checkbox>
      )}
    </div>
  );
};

export default EmailLoginAndRegistration;
