import { FAQ } from "../../../../AdBuilder/interfaces/TextTypeInterface";

export interface ElementStylesResetType {
    name: string;
    styles: any;
}


export const ElementStylesToReset: ElementStylesResetType[] = [{
    name: FAQ.QUESTION,
    styles: {
        fontWeight: 'bold',
        fontSize: '1.5em'
    }
}]