import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Select, Tabs, Form as AntdForm, Input, Switch, Slider } from 'antd';
import { Col as RBCol, Form } from 'react-bootstrap';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';

import {
  useBuilderPages,
  useBuilderSelectedPageId,
  useDisqualificationPage,
  useElementsPresets
} from '../../../../redux/builderSlice';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { ActionsController } from '../../../sharedUI/ActionsController';
import { useEditor, useNode } from '@craftjs/core';

import { defaultImage } from '../../../../config/builderUi';
import EmojiPicker from '../../../../../GeneralComponents/EmojiPicker';
import FrequentlyUsedIconPicker from '../../../../../GeneralComponents/FrequentlyUsedIconPicker/container/FrequentlyUsedIconPicker';
import IconPickerGrid from '../../../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import {
  BuilderPageDataType,
  FUNNEL_THEME_KEYS,
  builderPresetElements
} from '../../../../interfaces/builderSliceTypes';
import { ImageLoadingSpinner } from '../../../../../SharedUI/components/ImageLoadingSpinner';
import {
  CraftElementBaseProps,
  getParentNodes,
  getValueByKey,
  getKeyByValue,
  getKeyValueObjectByKeyValueArrayOfObjects,
  getElementColor
} from '../../../../helper/craftJs';
import { CHOICE_TYPES } from '../../common/ChoiceContainerHOC';
import ImageWithoutCacheOnError from '../../../sharedUI/ImageWithoutCacheOnError';
import { urlFormValidator } from '../../../../../helper/url';
import { getTextFromHtml } from '../../../../helper/sharedFunctions';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { TEMPLATE_KEYS } from '../../Container/helper';
import { BiBorderRadius } from 'react-icons/bi';
import { BsGearFill } from 'react-icons/bs';
import { ButtonAvailableBorderRadius } from '../../../../interfaces/ButtonSizeInterface';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { checkType, doesPageExist } from '../../Container/helper';
import { enhanceStockImage } from '../../../../helper/images';
import ChoiceTemplates from './ChoiceTemplates';
import BuilderSettingsTitleWithSwitch from '../../../sharedUI/BuilderSettingsTitleWithSwitch';
import { ChoiceAvailableBorderRadius } from '../../../../interfaces/ChoiceSizeInterface';
import ImageUploadV3 from '../../../../../GeneralComponents/ImageUploadV3';

const { TabPane } = Tabs;

export enum IconType {
  icon = 'icon',
  emoji = 'emoji',
  image = 'image'
}
interface Props extends CraftElementBaseProps {
  content?: string;
  type?: string;
  value?: string;
  color?: string;
  isLink?: string;
  url?: string;
  src?: string;
  choiceType?: string;
  imageForm?: string;
  isFirstRender?: boolean;
  backgroundColor?: string;
  cardIconColor?: string;
  borderRadius?: string;
  deactivateIconButton?: boolean;
  templateKey?: TEMPLATE_KEYS | undefined;
  isPresetOn?: boolean;
}
export const iconDeactivationAllowTemplates = [
  TEMPLATE_KEYS.ICON_ON_CENTER,
  TEMPLATE_KEYS.ICON_ON_RIGHT
];

export default function Choice({ content, backgroundColor, ...props }: Props) {
  const [loading, setLoading] = useState(false);
  const isMobileView = useIsMobileView();
  const theme = useCurrentlyEditingBuilderTheme();
  const isIconOrEmoji = [IconType.icon, IconType.emoji].includes(props.type as any);
  const isImage = props.type === IconType.image;
  const [value, setValue] = useState<boolean>(false);

  useEffect(() => {
    if (!props.src) {
      return;
    }
    setLoading(true);
  }, [props.src]);

  const { nodeId, currentNode } = useNode(node => {
    return {
      nodeId: node.id,
      currentNode: node
    };
  });

  const { query, actions } = useEditor();

  useEffect(() => {
    if (!query.node(nodeId).get()) return;
    if (getParentNodes(nodeId, query).length === 1) {
      actions.history.ignore().setProp(nodeId, (props: any) => (props.deleteNotReq = true));
    } else {
      actions.history.ignore().setProp(nodeId, (props: any) => (props.deleteNotReq = false));
    }
  }, [getParentNodes(nodeId, query).length]);

  const handleSetChoiceTitle = (e: ContentEditableEvent) => {
    const value = getTextFromHtml(e.target.value);
    actions.history.throttle().setProp(nodeId, (props: any) => (props.content = value));
  };

  const presets = useElementsPresets(builderPresetElements.CHOICE);

  const presetsOrProps = props?.isPresetOn ? { ...props, ...presets } : props;

  const defaultBorderRadius = getKeyByValue(
    presetsOrProps.borderRadius as string,
    ChoiceAvailableBorderRadius,
    'pixelValue'
  ).pixelValue;

  const gradient =
    props?.isPresetOn && presetsOrProps.isDefaultGradient
      ? getElementColor('defaultGradient', theme)
      : getElementColor(backgroundColor, theme);

  const customBorderRadius =
    presetsOrProps.templateKey === TEMPLATE_KEYS.ICON_ON_CENTER
      ? getKeyByValue(
          presetsOrProps.borderRadius as string,
          ChoiceAvailableBorderRadius,
          'pixelValue'
        ).key === 3
        ? '50px'
        : defaultBorderRadius
      : defaultBorderRadius;

  const isPresetIconOnCenter = presetsOrProps.templateKey === TEMPLATE_KEYS.ICON_ON_CENTER;
  return (
    <ActionsController
      {...props}
      ParentTag={RBCol}
      className={`choice-wrapper ${isMobileView ? 'mobile-choice-view' : ''}`}
      deleteNotReq={getParentNodes(nodeId, query).length === 1}
      ParentTagProps={{
        sm: 12,
        md: isPresetIconOnCenter ? 3 : 6,
        ...(isMobileView && {
          xs:  isPresetIconOnCenter ? 6 : 12,
          sm:  isPresetIconOnCenter ? 6 : 12,
          md:  isPresetIconOnCenter ? 6 : 12,
          lg:  isPresetIconOnCenter ? 6 : 12
        })
      }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div
        className={`choice-inner-wrapper ${isImage &&
          TEMPLATE_KEYS.ICON_ON_CENTER &&
          'justify-space-between'} ${value && '_active'} ${isImage && `isImageChoice`}`}
        style={{
          borderRadius: customBorderRadius,
          background: gradient
        }}
      >
        <div
          className={`choice-content ${isImage &&
            TEMPLATE_KEYS.ICON_ON_CENTER &&
            'text-when-image'}`}
        >
          {props.choiceType && props.choiceType === CHOICE_TYPES.SINGLE ? (
            <Icon
              name={'IoIosArrowForward'}
              style={{
                color: getElementColor(props.color, theme),
                width: 20
              }}
            />
          ) : (
            <Form.Check checked={value} onChange={() => setValue(!value)} />
          )}
          <div className="content-wrapper">
            <ContentEditable
              html={content || ''}
              onChange={handleSetChoiceTitle}
              tagName="p"
              className="mx-2 my-2 pe-3 ps-sm-3"
              style={{ color: getElementColor(props.color, theme) }}
              onBlur={e => {
                if (e.target.textContent?.trim()?.length === 0) {
                  actions.history
                    .throttle()
                    .setProp(nodeId, (props: any) => (props.content = 'Text ...'));
                }
              }}
            />
          </div>
        </div>
        <div
          style={{ borderRadius: customBorderRadius }}
          className={`choice-icon ${isImage ? 'media-wrapper' : ''}`}
        >
          {(!presetsOrProps.deactivateIconButton &&
            iconDeactivationAllowTemplates.includes(props?.templateKey as TEMPLATE_KEYS)) ||
            (isIconOrEmoji ? (
              <Icon
                className="icon-element"
                name={props.value}
                color={props.color}
                style={{
                  color: getElementColor(props.cardIconColor, theme)
                }}
              />
            ) : (
              <div className="img-element__wrapper">
                <ImageWithoutCacheOnError
                  className={` choice-img  ${
                    presetsOrProps.imageForm === 'round' ? 'choice-round' : ''
                  }`}
                  src={props.src || defaultImage}
                  onLoad={() => {
                    setLoading(false);
                  }}
                />
                {loading && <ImageLoadingSpinner />}
              </div>
            ))}
        </div>
      </div>
    </ActionsController>
  );
}

const ChoiceComponentDefaultProps = {
  content: 'Antworten',
  type: IconType.icon,
  value: 'FaSmileWink',
  color: FUNNEL_THEME_KEYS.CARD_TEXT_COLOR,
  optionLogic: 'next-page',
  borderRadius: ChoiceAvailableBorderRadius[2].pixelValue,
  isFirstRender: true,
  backgroundColor: FUNNEL_THEME_KEYS.CARD_BACKGROUND_COLOR,
  cardIconColor: FUNNEL_THEME_KEYS.CARD_ICON_COLOR,
  templateKey: TEMPLATE_KEYS.ICON_ON_RIGHT,
  deactivateIconButton: true,
  isPresetOn: true
};

export const ChoiceComponentSettings = () => {
  const { query, actions } = useEditor();
  const {
    actions: { setProp },
    props,
    childNodes
  } = useNode(node => {
    const parentID = node.data.parent;
    const SerializedNodes = query.getSerializedNodes();
    const parentNode = SerializedNodes[parentID];
    const childNodes = parentNode.nodes;
    return {
      childNodes: childNodes,
      props: node.data.props
    };
  });
  const setImageSrc = (src: string, form: any) => {
    setProp((props: any) => {
      props.src = src;
      props.imageForm = form;
      props.type = IconType.image;
    });
  };
  const [pexelsImage, setPexelsImage] = useState();
  const commonPages = useBuilderPages();
  const disqualificationPage = useDisqualificationPage();
  const pages = [...commonPages, ...(disqualificationPage ? [disqualificationPage] : [])];
  const selectedPageId = useBuilderSelectedPageId();
  const [form] = AntdForm.useForm();
  const iconDeactivationAllowTemplates = [
    TEMPLATE_KEYS.ICON_ON_CENTER,
    TEMPLATE_KEYS.ICON_ON_RIGHT
  ];

  const theme = useCurrentlyEditingBuilderTheme();
  const src = enhanceStockImage(props.src);

  const optionLogicType = checkType(props.optionLogic, 'number');

  if (optionLogicType) {
    const pageExists = doesPageExist(props.optionLogic, pages);
    if (!pageExists) {
      setProp((state: any) => (state.optionLogic = 'next-page'));
    }
  }

  const presets = useElementsPresets(builderPresetElements.CHOICE);

  const settingDisabled = props.isPresetOn;

  const presetsOrProps = props.isPresetOn ? { ...props, ...presets } : props;

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Auswahlkarte" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitleWithSwitch
              title={'Voreinstellungen übernehmen'}
              checked={presetsOrProps.isPresetOn}
              onChange={checked =>
                actions.setProp(childNodes, (props: any) => (props.isPresetOn = checked))
              }
              description={'Voreinstellungen, die du unter “Design” festgelegt hast'}
              icon={<BsGearFill />}
            />
          </Col>
        </Row>
        <ChoiceTemplates />
        <Row
          className={`builder__settings-sidebar__row ${settingDisabled &&
            'disable-layer__disabled'}`}
        >
          <Col span={24}>
            <BuilderSettingsTitle title="Randradius" icon={<BiBorderRadius />} />
            <Slider
              className="builder-slider-style"
              marks={getKeyValueObjectByKeyValueArrayOfObjects(ChoiceAvailableBorderRadius)}
              min={1}
              max={3}
              tooltipVisible={false}
              value={
                getKeyByValue(
                  presetsOrProps.borderRadius,
                  ChoiceAvailableBorderRadius,
                  'pixelValue'
                ).key
              }
              onChange={value => {
                actions.setProp(
                  childNodes,
                  (props: any) =>
                    (props.borderRadius = getValueByKey(
                      // @ts-ignore
                      value,
                      ChoiceAvailableBorderRadius
                    )?.pixelValue)
                );
              }}
            />
          </Col>
        </Row>
        <Row
          className={`builder__settings-sidebar__row ${settingDisabled &&
            'disable-layer__disabled'}`}
        >
          <Col span={7}>
            <BuilderSettingsTitle title="Hintergrund" />
            <BuilderColorPickerButton
              color={getElementColor(presetsOrProps.backgroundColor, theme)}
              onChange={(backgroundColor, colorKey) => {
                actions.setProp(childNodes, (props: any) => {
                  props.backgroundColor = colorKey || backgroundColor;
                });
              }}
              showColorTypeBtns={true}
            />
          </Col>
          <Col span={7} offset={1}>
            <BuilderSettingsTitle title="Text" />
            <BuilderColorPickerButton
              color={getElementColor(presetsOrProps.color, theme)}
              onChange={(color, colorKey) => {
                actions.setProp(childNodes, (props: any) => {
                  props.color = colorKey || color;
                });
              }}
              showColorTypeBtns={false}
            />
          </Col>
          <Col span={7} offset={1}>
            <BuilderSettingsTitle title="Icon" />
            <BuilderColorPickerButton
              color={getElementColor(presetsOrProps.cardIconColor, theme)}
              onChange={(cardIconColor, colorKey) => {
                actions.setProp(childNodes, (props: any) => {
                  props.cardIconColor = colorKey || cardIconColor;
                });
              }}
              showColorTypeBtns={false}
            />
          </Col>
        </Row>
        {presetsOrProps.choiceType === CHOICE_TYPES.SINGLE && (
          <Row className="builder__settings-sidebar__row">
            <Col span={24}>
              <BuilderSettingsTitle title="Logik" />
              <Select
                style={{ width: '100%' }}
                value={presetsOrProps.optionLogic}
                onChange={e => {
                  e === 'linking'
                    ? setProp((props: any) => (props.isLink = true))
                    : setProp((props: any) => (props.isLink = false));
                  setProp((state: any) => (state.optionLogic = e));
                }}
              >
                <Select.Option value="next-page">Nächste Seite</Select.Option>
                {pages
                  .filter((page: BuilderPageDataType) => page.id !== selectedPageId)
                  .map((page: BuilderPageDataType) => (
                    <Select.Option value={page.id} key={page.id}>
                      {page.name}
                    </Select.Option>
                  ))}
                {/* temporrary disable because of incomplete funnel implementation */}
                <Select.Option value="linking">Verlinkung</Select.Option>
              </Select>
            </Col>
            {presetsOrProps.isLink && (
              <Col span={24} className="mt-2">
                <BuilderSettingsTitle title="Link" />
                <AntdForm
                  form={form}
                  initialValues={{ url: presetsOrProps.url || '' }}
                  onFinish={values => {
                    setProp((props: any) => (props.url = values.url));
                  }}
                  autoComplete="off"
                >
                  <AntdForm.Item
                    name="url"
                    rules={[
                      {
                        required: true,
                        message: 'URL ist erforderlich'
                      },
                      urlFormValidator
                    ]}
                  >
                    <Input placeholder="https://deine-seite.de/impressum" onChange={form.submit} />
                  </AntdForm.Item>
                </AntdForm>
              </Col>
            )}
          </Row>
        )}

        <Row className="builder__settings-sidebar__row">
          <Col span={22}>
            <Tabs
              className={`tabs__reset-left-padding ${
                !presetsOrProps.deactivateIconButton ? 'choice-tabs-disabled' : ''
              }`}
              defaultActiveKey={presetsOrProps.type}
            >
              <TabPane tab="Icons" key={IconType.icon}>
                <Row className="builder__settings-sidebar__row ">
                  <Col span={24}>
                    <BuilderSettingsTitle title="Häufig verwendet" />
                    <FrequentlyUsedIconPicker
                      value={presetsOrProps.value}
                      onChange={(value: string) => {
                        setProp((props: any) => {
                          props.value = value;
                          props.type = IconType.icon;
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row className="builder__settings-sidebar__row">
                  <Col span={24}>
                    <IconPickerGrid
                      onChange={value => {
                        setProp((props: any) => {
                          props.value = value;
                          props.type = IconType.icon;
                        });
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Emoji's" key={IconType.emoji} style={{ height: 600 }}>
                <EmojiPicker
                  onChange={value => {
                    setProp((props: any) => {
                      props.value = value;
                      props.type = IconType.emoji;
                    });
                  }}
                />
              </TabPane>
              <TabPane tab="Bild" key={IconType.image}>
                <Row className="builder__settings-sidebar__row">
                  <Col span={24}>
                    <BuilderSettingsTitle title="Hochladen" />
                    <ImageUploadV3
                      previewImage={src}
                      onChange={(url: string, form: any) => {
                        setImageSrc(url, form);
                        setPexelsImage(undefined);
                      }}
                      showRatioSelector={false}
                      minWidth={200}
                      minHeight={150}
                      maxWidth={800}
                      maxHeight={600}
                      uploadedImage
                      grid
                      minZoom={0}
                      restrictPosition={false}
                      existingImageURL={pexelsImage}
                      onModalCancel={() => {
                        setPexelsImage(undefined);
                      }}
                      cropperIsNotMandatoryAfterSelectionFromLibrary={false}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
          <Col span={2}>
            <Switch
              className="mt-3"
              size="small"
              disabled={!iconDeactivationAllowTemplates.includes(presetsOrProps?.templateKey)}
              checked={presetsOrProps.deactivateIconButton}
              onChange={e => {
                actions.setProp(
                  childNodes,
                  (props: any) => (props.deactivateIconButton = !props.deactivateIconButton)
                );
              }}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

Choice.craft = {
  name: CRAFT_ELEMENTS.CHOICE,
  props: ChoiceComponentDefaultProps,
  related: {
    settings: ChoiceComponentSettings
  }
};
