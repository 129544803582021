import React, { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Alert, Card, Col, Input, Row, Select } from 'antd';
import { ActionsController } from '../../../sharedUI/ActionsController';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { CraftElementBaseProps } from '../../../../helper/craftJs';
import { getElementColor } from '../../../../helper/craftJs';
import { BuilderPageDataType, FUNNEL_THEME_KEYS } from '../../../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { LoadingComponentMessages } from '../../../../../config/messages';
import { useBuilderPages, useBuilderSelectedPageData, useBuilderSelectedPageId } from '../../../../redux/builderSlice';
import { thankyouPageType } from '../../../../helper/defaultValues';

interface LoadingComponentDefaultPropsInterface extends CraftElementBaseProps {
  value?: string;
  loadingIconColor?: string;
  checkMarkColor?: string;
  isFirstRender?: boolean;
  isColumnIcon?: boolean;
  className?: string;
  loadingIconRotating?: boolean;
  loadingTime?: string;
  checkVisible?: boolean;
}

const LoadingComponent = (props: LoadingComponentDefaultPropsInterface) => {
  const { currentNode, actions: { setProp }, } = useNode(node => ({
    props: node.data.props,
    nodeId: node.id,
    currentNode: node
  }));

  /*
  Resetting animation values on load,
  to contain scenario if the craft gets saved while animation 
  */
  useEffect(() => {
    setProp((props: any) => {
      props.loadingIconRotating = false;
      props.checkVisible = false
    });
  }, [])

  const theme = useCurrentlyEditingBuilderTheme();

  const checkIcon = (
    <Icon
      name={"AiOutlineCheckCircle"}
      color={getElementColor(props?.checkMarkColor, theme)}
      style={{
        display: 'block',
        fontSize: "10em",
        animation: 'fadeInScale 1s ease',
        color: getElementColor(props?.checkMarkColor, theme),
      }}
    />
  );

  return (
    <ActionsController
      className={`loading__element ${props.className}`}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      <div className='loading-wrapper'>
        {!props.checkVisible && (
          <div className={`loading-icon-container ${props.loadingIconRotating && "rotate-infinite"}`}>
            <Icon
              className='loading-icon'
              name={props.value}
              color={getElementColor(props.loadingIconColor, theme)}
              style={{
                color: getElementColor(props.loadingIconColor, theme),
              }}
            />
          </div>
        )}

        {props.checkVisible && (
          <Icon
            className='check-mark-icon'
            name="AiOutlineCheckCircle"
            color={getElementColor(props.checkMarkColor, theme)}
            style={{
              color: getElementColor(props.checkMarkColor, theme),
            }}
          />
        )}
      </div>
    </ActionsController>
  );
};

const LoadingComponentDefaultProps = {
  value: 'AiOutlineLoading3Quarters',
  loadingIconColor: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  checkMarkColor: '#0fb90f',
  isCoumnIcon: false,
  isFirstRender: true,
  loadingIconRotating: false,
  loadingTime: 3,
  checkVisible: false
};

export const LoadingComponentSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => {
    return {
      props: node.data.props,
      nodeId: node.id,
    };
  });
  const theme = useCurrentlyEditingBuilderTheme();
  let actionLogicUpdated: string | number = 'next-page';
  const pages = useBuilderPages();

  if (typeof props.actionLogic === 'number') {
    actionLogicUpdated = pages.some(page => page.id === props.actionLogic)
      ? props.actionLogic
      : 'next-page';
  } else {
    actionLogicUpdated = props.actionLogic;
  }

  const selectedPageId = useBuilderSelectedPageId();
  const selectedPage = useBuilderSelectedPageData();

  const handleAlertClick = () => {
    setProp((props: any) => {
      props.loadingIconRotating = true;
      props.checkIcon = false
    });
    const timer = setTimeout(() => {
      setProp((props: any) => {
        props.loadingIconRotating = false;
        props.checkVisible = true
      });

      setTimeout(() => {
        setProp((props: any) => {
          props.checkVisible = false
        });
      }, 1000);
    }, props.loadingTime * 1000);

    return () => clearTimeout(timer);
  };

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Ladeanimation" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Dauer" />
            <Input
              type="number"
              min={3}
              defaultValue={3}
              value={props.loadingTime}
              onChange={(e) => {
                const value = Math.max(3, Number(e.target.value));
                setProp((props: any) => {
                  props.loadingTime = value;
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={11}>
            <BuilderSettingsTitle title="Ladekreis" />
            <BuilderColorPickerButton
              color={getElementColor(props.loadingIconColor, theme)}
              onChange={(color, colorKey) => {
                setProp((props: any) => {
                  props.loadingIconColor = colorKey || color;
                });
              }}
            />
          </Col>
          <Col span={11} offset={1}>
            <BuilderSettingsTitle title="Haken" />
            <BuilderColorPickerButton
              color={getElementColor(props.checkMarkColor, theme)}
              onChange={(color, colorKey) => {
                setProp((props: any) => {
                  props.checkMarkColor = colorKey || color;
                });
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Aktion" />
            <Select
              data-testid="craftjs-button-component-action-selector-select"
              {...(process.env.NODE_ENV === 'test' && { open: true })}
              className="mt-2 mb-3 w-100"
              value={actionLogicUpdated || "Nächste Seite"}
              onChange={e => {
                setProp((props: any) => props.isLink = false);
                setProp((state: any) => state.actionLogic = e);
              }}
            >
              {selectedPage?.type !== thankyouPageType && (
                <Select.Option value="next-page">Nächste Seite</Select.Option>
              )}
              {pages
                .filter((page: BuilderPageDataType) => page.id !== selectedPageId)
                .map((page: BuilderPageDataType) => (
                  <Select.Option value={page.id} key={page.id}>
                    {page.name}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col span={24}>
            <Alert
              className='cursor-pointer'
              message={LoadingComponentMessages.executeTestLoadingInfo}
              type="warning"
              showIcon
              onClick={handleAlertClick}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

LoadingComponent.craft = {
  name: CRAFT_ELEMENTS.LOADING_COMPONENT,
  props: LoadingComponentDefaultProps,
  related: {
    settings: LoadingComponentSettings
  }
};

export default LoadingComponent;
